<template>
	<div>
		
		<b-modal
			v-model="addNewDivisionSwitch" :width="600">
			<template #default="props">
				<div class="modal-card" style="width: 600px;">
					<header class="modal-card-head">
						<p class="modal-card-title">Add New Division</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<b-field label="Enter a Division" vertical style="margin-top: 20px;">
							<b-select placeholder="Select Gender" v-model="addDivisionDetails.gender">
								<option value="mens" >Mens</option>
								<option value="ladies">Ladies</option>
								<option value="coed">Coed</option>
							</b-select>
							
							<b-select style="margin-left: 10px;" placeholder="Select Division" v-model="addDivisionDetails.rank_id">
								<option :value="division" :key="division.id" v-for="division in divisions">{{ division.name }}</option>
							</b-select>
						</b-field>
						<b-field label="Min Age" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.min_age"></b-numberinput>
						</b-field>
						<b-field label="Max Age" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.max_age"></b-numberinput>
						</b-field>
						<b-field label="Min # of Teams" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.min_number_of_teams"></b-numberinput>
						</b-field>
						<b-field label="Max # of Teams" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.max_number_of_teams"></b-numberinput>
						</b-field>

						<p class="note">Note: team changing gendered division will change their league membership.</p>
						<div>
							<a v-on:click="addNewDivision()" style="margin-top: 10px;" class="admin-button">Add Division</a>
						</div>
						
					</section>
				</div>
			</template>
		</b-modal>
		<b-modal
			v-model="changeDivisionSwitch" :width="600">
			<template #default="props">
				<div class="modal-card" style="width: 600px;">
					<header class="modal-card-head">
						<p class="modal-card-title">Change Divisions</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<b-field label="Enter a Division" vertical style="margin-top: 20px;">
							<b-select placeholder="Select Gender" v-model="changeDivisionDetails.gender">
								<option value="mens" v-if="totalDivisions.mens.length > 0">Mens</option>
								<option value="ladies" v-if="totalDivisions.ladies.length > 0">Ladies</option>
								<option value="coed" v-if="totalDivisions.coed.length > 0">Coed</option>
							</b-select>
							
							<b-select style="margin-left: 10px;" placeholder="Select Division" v-model="changeDivisionDetails.division">
								<option :value="division" :key="division.id" v-for="division in totalDivisions[changeDivisionDetails.gender]">{{ division.rank_id }}</option>
							</b-select>
						</b-field>
						<p class="note">Note: team changing gendered division may affect their membership (example: from mens to coed). When completing the change please review their membership under the "Registered" column.</p>
						<div>
							<a v-on:click="moveTeamToNewDivision()" style="margin-top: 10px;" class="admin-button">Change Division</a>
						</div>
						
					</section>
				</div>
			</template>
		</b-modal>

		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper" v-if="tournament">
                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="page-header-title">
                                Manage {{ tournament.name }}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a onclick="history.back(); return false;" class="button">Back to Manage Tournaments</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="admin-area">
					<span>
						<b-field label="Copy and share this link to advertise the event!" v-if="tournament.status !== 'unapproved'">
							<b-input :value="'https://slo-pitch.com/tournament/' + tournament.slug"></b-input>
						</b-field>
					</span>
					<div class="row">
						<div class="col-md-12">
							<a v-on:click="sendWaiversToTournament()" style="margin-top: 10px;" class="button is-small"><i class="fas fa-envelope-open-text"></i>&nbsp;&nbsp;&nbsp;Send Waiver Reminder to All Unsigned Players</a>
							<p style="margin-top: 10px;"><b>Last time sent: </b> {{ moment(tournament.last_waiver_reminder_sent).format('YYYY-MM-DD') }}</p>

							<b-message type="is-warning">Poster Upload has been moved to Edit Tournament Tab</b-message>
							<br>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="padding" style="padding-top: 0px;" v-if="tournament">
								<b-tabs>														
									<b-tab-item label="Registered Teams">
										<RegisteredTeams 
											:eventId="tournament.id" 
											:eventType="'tournament'">
										</RegisteredTeams>
									</b-tab-item>
									<b-tab-item label="Executive Access">
										<ExecutiveAccess
											:eventId="tournament.id"
											:eventType="'tournament'">
										</ExecutiveAccess>
									</b-tab-item>
									<b-tab-item label="Edit Tournament">
										<div class="row">
											<div class="col-md-3">
												<LeagueTournamentPreviewCard
													:event="tournament"
													:bottomHalf="true"
													:eventType="'tournament'"
													:posterUploadAbility="true">
												</LeagueTournamentPreviewCard>
											</div>
											<div class="col-md-9">
												<EditTournament
													:eventId="tournament.id"
													:eventType="'tournament'">
												</EditTournament>									
											</div>
										</div>
										
									</b-tab-item>
									<b-tab-item label="Umpires/Rulesheet">
										<UmpireRuleSheet
											:eventId="tournament.id"
											:eventType="'tournament'">
										</UmpireRuleSheet>			
									</b-tab-item>
								</b-tabs>
							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import LeagueTournamentPreviewCard from '../components/LeagueTournamentPreviewCard.vue'
import RegisteredTeams from '../../components/ViewEvent/RegisteredTeams.vue'
import ExecutiveAccess from '../../components/ViewEvent/ExecutiveAccess.vue'
import EditTournament from '../../components/ViewEvent/EditTournament.vue'
import UmpireRuleSheet from '../../components/ViewEvent/UmpireRuleSheet.vue'


import axios from 'axios'
import moment from 'moment'
export default {
	data() {

		return {
			timer: null,
			selectedRegisteredTeams: [],
			executive_access_players: null,
			playersSelected: [],
			searchList: [],
			selectedTeam: null,
			addDivisionDetails: {
				gender: null,
				rank_id: null,
				min_number_of_teams: 4,
				max_number_of_teams: 8,
				min_age: 18,
				max_age: 99
			},
			addNewDivisionSwitch: false,
			registerForTournament: {
                team: null,
                gender: null,
                division: null,
				create_order: false
			},
            regions: null,
            authUser: null,
            content_width: false,
            tournament: null,
			divisions: null,
            umpSearch: null,
            toggleCallSwitch: false,
            cheatSheet: null,
            savedCheatSheet: false,
            addedToCheatSheet: null,
            registered_teams: [],
			searchForTeam: null,
			addTeamToEventSwitch: false,
			newTournament: {
				headquarter_location: null,
				headquarter_google_address: null,
			},
            canvasAdditionalDetailsLeft: null,
			umpireFound: null,
			changeDivisionSwitch: null,
			changeDivisionDetails: {
				gender: null,
				team: null,
				division: null
			},
			searchResults: null,
			editTournament: null,
			selectRegion: false,
            cheatSheet: {
				innings: 7,
				call_last_inning_after: 75,
				minimum_players: 10,
				hr_rule: '1',
				anticipation: true,
				ties: true,
				courtesy_runners: true,
				max_runs_per_inning: 6,
				mercy_rule: true,
				tag_at_home: true,
				throw_to_first_from_outfield: true,
				additional_rules: null
			},
			columnTemplate: [
				{ title: 'ID', label: 'ID', field: 'id', visible: false, searchable: false },
				{ title: 'Team Name', label: 'Team Name', field: 'team_name', visible: true, searchable: false },
				{ title: 'Role', label: 'Role', field: 'role', visible: true, searchable: false },
				{ title: 'Rank', label: 'Rank', field: 'level', visible: true, searchable: false },
				{ title: 'First Name', label: 'First Name', field: 'first_name', visible: false, searchable: false },
				{ title: 'Last Name', label: 'Last Name', field: 'last_name', visible: false, searchable: false },
				{ title: 'Gender', label: 'Gender', field: 'gender', visible: false, searchable: false },
				{ title: 'Date of Birth', label: 'Date of Birth', field: 'date_of_birth', visible: true, searchable: false },
				{ title: 'Email', label: 'Email', field: 'email', visible: false, searchable: false },
				{ title: 'Street Address', label: 'Street Address', field: 'street_address', visible: true, searchable: false },
				{ title: 'City', label: 'City', field: 'city', visible: true, searchable: false },
				{ title: 'Province', label: 'Province', field: 'province', visible: false, searchable: false },
				{ title: 'Postal Code', label: 'Postal Code', field: 'postal_code', visible: false, searchable: false },
				{ title: 'Phone', label: 'Phone', field: 'phone', visible: false, searchable: false },
				{ title: 'Waiver', label: 'Waiver', field: 'waiver', visible: false, searchable: false },
			]
		};
	},
	components: {
		Header,
		Footer,
		LeagueTournamentPreviewCard,
		RegisteredTeams,
		ExecutiveAccess,
		EditTournament,
		UmpireRuleSheet
    },
    watch: {

    },
	mounted: function() {
		// grab all the dashboard information now
        var vm = this
        var id = this.$route.params.id
        
        var options = {}

        
        axios.get('/auth/regions').then(results => {
			vm.regions = results.data.regions
		})

		if(window.innerWidth < 800) {
            vm.content_width = true
		}

		axios.get('/api/superuser/divisions').then(results => {
			vm.divisions = results.data.divisions
		})
        
		axios.get('/api/tournaments/tournament-executive-access/' + id).then(results => {
			if(results.data.success === true) {
				vm.executive_access_players = results.data.executive_access
			}
		})

		
		axios.get('/auth/check').then(results => {  
			 
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.manage_players !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }

                

                axios.get('/api/superuser/grab-tournament/' + id).then(results => {
                    if(results.data.success === true) {
                        
                        vm.tournament = results.data.tournament
						vm.editTournament = results.data.tournament

						if(vm.editTournament.event_start_date) {
							vm.editTournament.event_start_date = new Date(vm.editTournament.event_start_date)
						}
						if(vm.editTournament.event_end_date) {
							vm.editTournament.event_end_date = new Date(vm.editTournament.event_end_date)
						}
						if(vm.editTournament.registration_start_date) {
							vm.editTournament.registration_start_date = new Date(vm.editTournament.registration_start_date)
						}
				
						if(vm.editTournament.registration_end_date) {
							vm.editTournament.registration_end_date = new Date(vm.editTournament.registration_end_date)
						}

						if(vm.editTournament.roster_lock_date) {
							vm.editTournament.roster_lock_date = new Date(vm.editTournament.roster_lock_date)
						}

						
                        axios.get(`/api/superuser/tournament-get-registered-teams/${vm.tournament.id}`).then(results => {
                            if(results.data.success === true) {
                                vm.registered_teams = results.data.registrants
                            }
                        })


                        // grab cheat sheet and grab umpires
                        axios.get('/api/umpires/cheat-sheet/' + vm.tournament.id + '/tournament').then(results => {
                            if(results.data.success === true) {
                                
                                if(results.data.cheat_sheet) {
                                    vm.cheatSheet = results.data.cheat_sheet
                                    vm.savedCheatSheet = true
                                
                                    axios.get('/api/umpires/added-to-cheat-sheet/' + vm.cheatSheet.id + '/tournament').then(results => {
                                        if(results.data.success === true) {
                                            
                                            vm.addedToCheatSheet = results.data.added_to_cheat_sheet
                                            
                                        }
                                    })
                                }
                            }
                        })
                    }
                })
                // axios.get('/api/superuser/pages').then(results => {
                //     if(results.data.success === true) {
                //         vm.pages = results.data.pages
                //     }
                // })
            }
        })
	},
	computed: {
		computedSelectedRegisteredTeams: function() {
			var vm = this
			
			var teams = this.selectedRegisteredTeams
			var array = []
			var i = 0

			for(i; i < teams.length; i++) {
				var team = teams[i]
				
				var s = 0

				for(s; s < team.roster.roster_players.length; s++) {
					var roster = team.roster.roster_players[s]

					var role = 'Player'

					if(roster.core_roster_player.role_on_team === 1) {
						role = 'Coach'

						if(roster.user_id === team.team.primary_coach_id) {
							role = "Primary Coach"
						}
					}
					if(roster.core_roster_player.role_on_team === 2) {
						role = 'Team Contact'
					}

					array.push({
						id: team.id,
						team_name: team.team.name,
						first_name: roster.user.first_name,
						last_name: roster.user.last_name,
						gender: roster.user.gender,
						date_of_birth: roster.user.date_of_birth,
						email: roster.user.email,
						street_address: roster.user.street_address,
						city: roster.user.city,
						province: roster.user.province,
						postal_code: roster.user.postal_code,
						phone: roster.user.phone,
						level: roster.user.current_rank_id,
						waiver: (roster.user.latest_waiver_signed === this.$latest_waiver ? 'Yes' : 'No'),
						role: role
					})
				}	
			}

			return array
		},
		spnRegistration: function() {
            // grab the registrations of insurance from the team
            // cross reference the division
            var vm = this

            var team = vm.selectedTeam

            var selected_gender = vm.registerForTournament.gender
            
            if(team) {
                
                var insurance = team.insurance
                
                var find_insurance = _.find(insurance, function(paper) {
                    if(paper.gender === selected_gender) {
                        return true
                    }
                })
                // check validity of the insurance that was found (must be 2020, must not be single use - if it's single use it means it's been used already (because they signed up and it was single use))
                // if a single use one is found, it must be upgraded
                // when updated it will remove single use from the row

                if(find_insurance) {

                    vm.currentInsurance = find_insurance
                    vm.triggerFinalize = true
                    return true
                }
                vm.triggerFinalize = false
                return false
            } else {
                vm.triggerFinalize = false
                return false
            }
        },
        totalDivisions: function() {
            var vm = this
            var mens = vm.filterDivision(vm.tournament.divisions, 'mens')
            var ladies = vm.filterDivision(vm.tournament.divisions, 'ladies')
            var coed = vm.filterDivision(vm.tournament.divisions, 'coed')

            var count = 0
            if(mens.length > 0) {
                count = count + 1
            } 
            if(ladies.length > 0) {
                count = count + 1
            }
            if(coed.length > 0) {
                count = count + 1
            }

            return {
                mens: mens,
                ladies: ladies,
                coed: coed,
                count: count
            }
        },
		filterGroup: function() {
			var vm = this
			var grouped = _.groupBy(vm.mountCheckboxes, 'group')

			return grouped
		},
	},
	methods: {
		refreshTournament: function() {
			var vm = this

			axios.get('/api/superuser/grab-tournament/' + vm.tournament.id).then(results => {
				if(results.data.success === true) {
					
					vm.tournament = results.data.tournament
					vm.editTournament = results.data.tournament

					if(vm.editTournament.event_start_date) {
						vm.editTournament.event_start_date = new Date(vm.editTournament.event_start_date)
					}
					if(vm.editTournament.event_end_date) {
						vm.editTournament.event_end_date = new Date(vm.editTournament.event_end_date)
					}
					if(vm.editTournament.registration_start_date) {
						vm.editTournament.registration_start_date = new Date(vm.editTournament.registration_start_date)
					}
			
					if(vm.editTournament.registration_end_date) {
						vm.editTournament.registration_end_date = new Date(vm.editTournament.registration_end_date)
					}

					if(vm.editTournament.roster_lock_date) {
						vm.editTournament.roster_lock_date = new Date(vm.editTournament.roster_lock_date)
					}

					
					axios.get(`/api/tournaments/get-registered-teams/${vm.tournament.slug}`).then(results => {
						if(results.data.success === true) {
							
							vm.registered_teams = results.data.registrants
						}
					})
				}
			})
		},
		updateTournamentInfo: function() {
			var vm = this

			axios.post('/api/superuser/update-tournament', {
				tournament: vm.editTournament
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
						message: 'Tournament Info Has Been Updated',
						type: 'is-success' // is-danger
					})
					vm.tournament = vm.editTournament
					vm.refreshTournament()
				} else {
					this.$buefy.snackbar.open({
						message: 'Something went wrong when saving',
						type: 'is-danger' // is-danger
					})
				}
			})
		},
        switchWidth: function($event) {
			this.content_width = !$event
		},
		
        saveUmpireCheatSheet: function() {
			var vm = this

			axios.post('/api/umpires/save-cheat-sheet', {
				cheat_sheet: vm.cheatSheet,
				event_id: vm.tournament.id,
				type: 'tournament'
			}).then(results => {
				if(results.data.success === true) {
					
					vm.savedCheatSheet = true
					vm.refreshTournament()
					
				}
			})
		},
		umpireEmailSearch: function(text) {
			var vm = this
			if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
				axios.post('/api/umpires/search-for-umpire', {
					search: text
				}).then(results => {
					if(results.data.success === true) {
						
						vm.umpireFound = results.data.umpire
						
					}
				})
			}, 1000)
		},
		addUmpireToTournament: function() {
			
			var vm = this
			axios.post('/api/umpires/add-to-cheat-sheet', {
				umpire_found: vm.umpireFound,
				cheat_sheet: vm.cheatSheet.id
			}).then(results => {
				if(results.data.success === true) {
					vm.addedToCheatSheet = results.data.added_to_cheat_sheet
					vm.refreshTournament()
					
					this.$buefy.snackbar.open({
                        message: 'Added user to the cheat sheet',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'User was not added to the cheat sheet',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
        filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
		},
		
		changeDivisionFunction: function(team) {
			
			var vm = this
			vm.changeDivisionDetails.team = team
			vm.changeDivisionSwitch = true
		},
		moveTeamToNewDivision: function(team) {
			var vm = this

			var data = {
				details: {
					team: {
						id: vm.changeDivisionDetails.team.id,
						insurance_id: vm.changeDivisionDetails.team.insurance_id
					},
					division: {
						id: vm.changeDivisionDetails.division.id
					},
					gender: vm.changeDivisionDetails.gender
				}
			}
			
			axios.post('/api/tournaments/change-team-to-new-division', data).then(results => {
				if(results.data.success === true) {
					vm.updateTeams()
					vm.changeDivisionSwitch = false
					this.$buefy.snackbar.open({
                        message: 'Team moved to new division successfully.',
                        type: 'is-success' // is-danger
                    })
				}
			})
		},
		updateAddressTab: function() {
			var vm = this

		},
		moment: function(date, format = null) {
            return moment(date, format)
        },
		fillInAddress: function() {
			var vm = this
			var place = this.autocompleteAddress.getPlace();
			var fields = this.autocompleteAddress.getFields()
			this.newTournament.headquarter_location = place.formatted_address

			// find components
			var address_components = place.address_components

			var postal_code = _.find(address_components, function(address) {
				if(address.types.includes('postal_code')) {
					return true
				}
			})

			var province = _.find(address_components, function(address) {
				if(address.types.includes('administrative_area_level_1')) {
					return true
				}
			})

			if(province.short_name === 'NS' || province.short_name === 'PE' || province.short_name === 'NB' ) {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MT') {
						return true
					}
				})

				
				vm.newTournamentRegion = region_data
			} else if (province.short_name === 'NT') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'AB') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else if (province.short_name === 'YT') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'BC') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === province.short_name) {
						return true
					}
				})
				
				vm.newTournamentRegion = region_data
			}

			var city = _.find(address_components, function(address) {
				if(address.types.includes('locality')) {
					return true
				} else {
                    if(address.types.includes('sublocality')) {
                        return true
                    }
                }
			})

			var street_number = _.find(address_components, function(address) {
				if(address.types.includes('street_number')) {
					return true
				}
			})

			var route = _.find(address_components, function(address) {
				if(address.types.includes('route')) {
					return true
				}
			})

			var street_number_long_name = null

			if(street_number) {
				street_number_long_name = street_number.long_name
			}

			var route_long_name = null

			if(route) {
				route_long_name = route.long_name
			}


			vm.newTournament.headquarter_google_address = {
				written_address: place.formatted_address,
				street_address: street_number_long_name + ' ' + route_long_name,
				city: city.long_name,
				province: province.short_name,
				postal_code: postal_code.long_name,
				latitude:  place.geometry.location.lat(),
				longitude: place.geometry.location.lng()
			}

		},
		removeTeam: function(team) {
			var vm = this
			confirm("Are you sure you want to remove this team from your event?")

			axios.post('/api/tournaments/remove-team-from-event', {
				team_id: team.id
			}).then(results => {
				vm.refreshTournament()
				this.$buefy.snackbar.open({
					message: 'Removed team from this event',
					type: 'is-success', // is-danger
					duration: 4000,
					queue: false
				})
			})
		},
		addNewDivision: function() {
			var vm = this

			axios.post('/api/tournaments/add-new-division', {
				tournament: vm.tournament.id,
				division: vm.addDivisionDetails
			}).then(results => {
				if(results.data.success === true) {
					vm.refreshTournament()
					vm.addNewDivisionSwitch = false
					this.$buefy.snackbar.open({
						message: 'Added new division',
						type: 'is-success', // is-danger
						duration: 4000,
					})
				} else {
					this.$buefy.snackbar.open({
						message: 'Division not added successfully. Are you sure you do not already have that division?',
						type: 'is-danger', // is-danger
						duration: 4000,
					})
				}
			})
		},
		teamCount: function(division) {
			var vm = this

			var teams = _.filter(vm.registered_teams, function(team) {
				return team.league_division_id === division.id
			})

			return teams.length
		},
		updateLocation: function() {
			var vm = this
			axios.post('/api/superuser/update-tournament-location', {
				tournament: vm.tournament.id,
				headquarters: vm.newTournament.headquarter_google_address
			}).then(results => {
				if(results.data.success === true) {
					vm.refreshTournament()
					this.$buefy.snackbar.open({
						message: 'Updated tournament location.',
						type: 'is-success', // is-danger
						duration: 4000,
					})
				}
			})
		},
		saveDivision: function(division) {
			
			var vm = this
			
			axios.post('/api/superuser/save-tournament-division', {
				division: division
			}).then(results => {
				if(results.data.success === true) {
					vm.refreshTournament()
					this.$buefy.snackbar.open({
						message: 'Updated the division',
						type: 'is-success' // is-danger
					})
				} else {
					this.$buefy.snackbar.open({
						message: 'Something went wrong',
						type: 'is-danger' // is-danger
					})
				}
			})
		
			
		},
		deleteDivision: function(division) {
			var r = confirm('Are you sure you want to delete this division?')
			var vm = this
			if(r === true) {
				axios.post('/api/superuser/delete-tournament-division', {
					division: division.id
				}).then(results => {
					if(results.data.success === true) {
						vm.refreshTournament()
						this.$buefy.snackbar.open({
							message: 'Deleted the division',
							type: 'is-success' // is-danger
						})
					} else {
						this.$buefy.snackbar.open({
							message: 'Something went wrong',
							type: 'is-danger' // is-danger
						})
					}
				})
			}
			
		},
		addTeamToEvent: function() {
			var vm = this

			vm.addTeamToEventSwitch = true
		},

		registerTeamToEvent: function() {
			var vm = this
			axios.post('/api/tournaments/add-team-to-event', {
				registration: vm.registerForTournament,
				insurance: vm.currentInsurance,
				correct_insurance: vm.spnRegistration,
				tournament_name: vm.tournament.name,
				tournament_id: vm.tournament.id
			}).then(results => {
				if(results.data.success === true) {
					vm.updateTeams()
					vm.addTeamToEventSwitch = false
					this.$buefy.snackbar.open({
                        message: 'Team added successfully to your event.',
                        type: 'is-success' // is-danger
                    })
					
				}
			})
		},
		searchForTeamFunction: function($event = null) {
			var vm = this


            if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
				if($event) {
					axios.post('/api/tournaments/search-for-team', {
						tournament_id: vm.tournament.id,
						search: $event,
						region_on: vm.selectRegion,
						region_id: vm.tournament.region_id
					}).then(results => {
						if(results.data.success === true) {
							vm.searchResults = results.data.teams
						}
					})
				} else {
					axios.post('/api/tournaments/search-for-team', {
						tournament_id: vm.tournament.id,
						search: vm.searchForTeam,
						region_on: vm.selectRegion,
						region_id: vm.tournament.region_id
					}).then(results => {
						if(results.data.success === true) {

							vm.searchResults = results.data.teams
						}
					})
				}
			}, 500)
		},
		updateTeams: function() {
			var vm = this

			axios.get(`/api/tournaments/get-registered-teams/${vm.tournament.slug}`).then(results => {
				if(results.data.success === true) {
					vm.registered_teams = results.data.registrants
				}
			})
		},
		refreshTournament: function() {
			var vm = this

			axios.get(`/api/tournaments/get-tournament/${vm.tournament.slug}`).then(results => {
				if(results.data.success === true) {				
					vm.tournament = results.data.tournament
					vm.editTournament = results.data.tournament
		
					if(vm.editTournament.event_start_date) {
						vm.editTournament.event_start_date = new Date(vm.editTournament.event_start_date)
					}
					if(vm.editTournament.event_end_date) {
						vm.editTournament.event_end_date = new Date(vm.editTournament.event_end_date)
					}
					if(vm.editTournament.registration_start_date) {
						vm.editTournament.registration_start_date = new Date(vm.editTournament.registration_start_date)
					}
			
					if(vm.editTournament.registration_end_date) {
						vm.editTournament.registration_end_date = new Date(vm.editTournament.registration_end_date)
					}

					if(vm.editTournament.roster_lock_date) {
						vm.editTournament.roster_lock_date = new Date(vm.editTournament.roster_lock_date)
					}
					
					if(vm.tournament.previous_tournament_id) {
						axios.get(`/api/tournaments/get-tournament/${vm.tournament.previous_tournament_id}`).then(results => {
							if(results.data.success === true) {			
							
								vm.previous_tournament = results.data.tournament

								axios.get('/api/tournaments/get-registered-teams/' + vm.previous_tournament.slug).then(results => {
									if(results.data.success === true) {
										vm.previous_registered_teams = results.data.registrants
									}
								})
							}
						})
					}
				

					axios.post('/api/tournaments/review-checklist', {
						event_id: vm.tournament.id,
						event_type: 'tournament',	
					}).then(results => {
						if(results.data.success === true) {

							vm.mountCheckboxes = results.data.checklist
							if(results.data.notes) {
								vm.canvasAdditionalDetailsLeft = results.data.notes.result
							}
						}
					})

					axios.get('/api/umpires/cheat-sheet/' + vm.tournament.id + '/tournament').then(results => {
						if(results.data.success === true) {
							if(results.data.cheat_sheet) {
								vm.cheatSheet = results.data.cheat_sheet
								vm.savedCheatSheet = true
							
								axios.get('/api/umpires/added-to-cheat-sheet/' + vm.cheatSheet.id + '/tournament').then(results => {
									if(results.data.success === true) {
										vm.addedToCheatSheet = results.data.added_to_cheat_sheet
										
									}
								})
							}
						}
					})
				}
			})
		},
	
		sendWaiversToTournament: function() {
			var vm = this

			var r = confirm('Are you sure you want to send an email to all unsigned waiver players?')

			if(r === true) {
				axios.post('/api/tournaments/send-waivers', {
					tournament_id: vm.tournament.id
				}).then(results => {
					if(results.data.success === true) {
						vm.refreshTournament()
						this.$buefy.snackbar.open({
							message: 'You have sent your waiver reminder email to all unsigned players.',
							type: 'is-success', // is-danger
							duration: 4000,
						})
					}
				})
			}
		}
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>