<template>
	<div>
		<Header></Header>
		
		<div class="content-area" >
			<div class="standard-page-header">
				Miller Lite Challenge 2021
			</div>
            <div class="frontend-content-wrapper">
				<div class="section-spacing">
                    <div class="row">
                        <div class="col-md-4">
                            <img src="../../images/home/2021millerlite.png" width="100%" alt="">
                        </div>
                        <div class="col-md-8">
                            <div id="map"></div>
                        </div>
                    </div>
                    <img src="../../images/other/millerlitechallenge.png" width="100%" alt="">
                    <div style="margin-top: 20px;">
                        <b-tabs >
                            <b-tab-item label="2021 Miller Lite Challenge">
                                <h3 style="margin-top: 0px;">Slo-Pitch National announces new event for July 30 – August 2, 2021 Weekend!</h3>

                                <p>The 2021 Miller Lite Slo-Pitch Challenge will take place July 30 – August 2 in 5 confirmed locations across Canada. The event will be hosted in Kelowna, Leduc, Winnipeg, Brampton and Moncton with possible additional cities to be added in the coming months.</p>
                                <p>These events will take the place of the 2021 National Championships. The decision to create a unique event for the 2021 season is the result of the many contributing factors associated with the COVID-19 pandemic across Canada.</p>
                                <p>Many regions are currently impacted with strict travel restrictions and have been affected by airline route cancellations, making it impossible for teams to consider attending a championship far away from their province this summer. Teams and participants have been impacted with social and economic uncertainly. In addition, due to a lack of tournament play many teams throughout Canada were unable to qualify for National Championships. With all these factors to consider, it was important for Slo-Pitch National to work on the necessary changes during these unprecedented times that would allow us to come closer to our teams and basically bring a championship style event with more divisions to more locations.</p>
                                <p>Each hosting city will be developing and offering an event based on popular divisions to the teams within their region. Based on the recovery in the months ahead, it may be possible for teams from neighboring regions to attend. Those teams interested, please contact the host/ convenor of the event to discuss participation. It may be necessary to alter or cancel events based on the pandemic situation in specific regions. </p>
                                <p><b>Stay up to date on the 2021 Miller Lite Slo-Pitch Challenge weekend at <a href="https://www.slo-pitch.com/miller-lite-challenge">slo-pitch.com/miller-lite-challenge</a> for frequent updates and event information.</b></p>
                                <p>Trish Harrow</p>
                            </b-tab-item>
                            <b-tab-item label="Kelowna, BC" v-if="mlckelowna">
                                <PageContentBox :content="parseMainContent(mlckelowna)"></PageContentBox>
                            </b-tab-item>
                            <b-tab-item label="Leduc, AB"  v-if="mlcleduc">
                                <PageContentBox :content="parseMainContent(mlcleduc)"></PageContentBox>
                            </b-tab-item>
                            <b-tab-item label="Winnipeg, MB" v-if="mlcwinnipeg">
                                <PageContentBox :content="parseMainContent(mlcwinnipeg)"></PageContentBox>
                            </b-tab-item>
                            <b-tab-item label="Brampton, ON" v-if="mlcbrampton">
                                <PageContentBox :content="parseMainContent(mlcbrampton)"></PageContentBox>
                            </b-tab-item>
                            <b-tab-item label="Moncton, NB" v-if="mlcmoncton">
                                <PageContentBox :content="parseMainContent(mlcmoncton)"></PageContentBox>
                            </b-tab-item>

                        </b-tabs>
                    </div>
				</div>
			</div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
import PageContentBox from '../frontend/PageContentBox.vue'
export default {
    metaInfo() {
        return { 
            title: "2021 Miller Lite Challenge - Slo-Pitch National",
            meta: [
                { name: 'description', content:  '' },
                { property: 'og:title', content: "2021 Miller Lite Challenge - Slo-Pitch National"},
                { property: 'og:url', content:  'https://www.slo-pitch.com/miller-lite-challenge' },
                { property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/2021millerlitechallenge.png' },
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			en: true,
            page_url: null,
            page: null,
            previous_results: null,
            first_decade: false,
            second_decade: false,
            third_decade: false,
            nationals_hosts: null,
            brampton: false,
            winnipeg: false,
            edmonton: false,
            leduc: false,
            kelowna: false,
            mlckelowna: null,
            mlcleduc: null,
            mlcbrampton: null,
            mlcwinnipeg: null,
            mlcmoncton: null,
		};
	},
	components: {
		Header,
		Footer,
        Form,
        PageContentBox
	},
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        var vm = this

   

        // The location of Uluru
        
        const kelowna = { lat: 49.88307, lng: -119.48568 }
        const center = { lat: 52.1167, lng: -97.2500 }
        
        const leduc = { lat: 46.09793504641128, lng: -64.8078178933827 }
        const edmonton = { lat: 53.5557121, lng: -113.6331451 }
        
        const winnipeg = { lat: 49.8538008, lng: -97.2224356 }
        
        const brampton = { lat: 43.7249125, lng:-79.8297545 }
        // The map, centered at Uluru
        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 3,
            center: center,
        });
        // The marker, positioned at Uluru
        //  const contentString =
        //     '<div id="content">' +
        //     '<div id="siteNotice">' +
        //     "</div>" +
        //     '<h1 id="firstHeading" style="font-size: 20px; margin-top: 0px;" class="firstHeading">Kelowna</h1>' +
        //     '<div id="bodyContent">' +
        //     "<p>Coed Comp SS</p>" +
        //     "<p>Coed C SS</p>" +
        //     "<p>Coed D SS</p>" +
        //     "<p>Coed E SS</p>" +
        //     "</div>" +
        //     "</div>";
        // const infowindow = new google.maps.InfoWindow({
        //     content: contentString,
        // });
        const marker = new google.maps.Marker({
            position: kelowna,
            map: map,
        });
        // marker.addListener("click", () => {
        //     infowindow.open(map, marker);
        // });

        const marker2 = new google.maps.Marker({
            position: leduc,
            map: map
        });

        const marker3 = new google.maps.Marker({
            position: edmonton,
            map: map,
        });

        // const contentString2 =
        //     '<div id="content">' +
        //     '<div id="siteNotice">' +
        //     "</div>" +
        //     '<h1 id="firstHeading" style="font-size: 20px; margin-top: 0px;" class="firstHeading">Leduc & Edmonton</h1>' +
        //     '<div id="bodyContent">' +
        //     "<h4 style='margin-top: 0px;'>Leduc</h4>" +
        //     "<p>Men's C</p>" +
        //     "<p>Men's E</p>" +
        //     "<p>Men's 35 Comp</p>" +
        //     "<p>Women's B</p>" +
        //     "<p>Women's D</p>" +
        //     "<h4 style='margin-top: 20px;'>Edmonton</h4>" +
        //     "<p>Men's 60</p>" +
        //     "</div>" +
        //     "</div>";
        // const infowindow2 = new google.maps.InfoWindow({
        //     content: contentString2,
        // });

        // marker2.addListener("click", () => {
        //     infowindow2.open(map, marker2);
        // });

        // marker3.addListener("click", () => {
        //     infowindow2.open(map, marker3);
        // });

        // const contentString3 =
        //     '<div id="content">' +
        //     '<div id="siteNotice">' +
        //     "</div>" +
        //     '<h1 id="firstHeading" style="font-size: 20px; margin-top: 0px;" class="firstHeading">Winnipeg</h1>' +
        //     '<div id="bodyContent">' +
        //     "<p>Men's D</p>" +
        //     "<p>Men's 35 E</p>" +
        //     "<p>Women's C</p>" +
        //     "<p>Women's E</p>" +
        //     "</div>" +
        //     "</div>";
        // const infowindow3 = new google.maps.InfoWindow({
        //     content: contentString3,
        // });

        const marker4 = new google.maps.Marker({
            position: winnipeg,
            map: map,
        });

        // marker4.addListener("click", () => {
        //     infowindow3.open(map, marker4);
        // });

        // const contentString4 =
        //     '<div id="content">' +
        //     '<div id="siteNotice">' +
        //     "</div>" +
        //     '<h1 id="firstHeading" style="font-size: 20px; margin-top: 0px;" class="firstHeading">Brampton</h1>' +
        //     '<div id="bodyContent">' +
        //     "<p>Men's B</p>" +
        //     "<p>Men's 35 C</p>" +
        //     "<p>Men's 35 D</p>" +
        //     "<p>Men's 50 A</p>" +
        //     "<p>Men's 50 IM</p>" +
        //     "<p>Men's 50 E</p>" +
        //     "<p>Women's 35 Comp</p>" +
        //     "<p>Women's 35 IM/E</p>" +
        //     "<p>Coed Comp</p>" +
        //     "<p>Coed C</p>" +
        //     "<p>Coed D</p>" +
        //     "<p>Coed E</p>" +
        //     "</div>" +
        //     "</div>";
        // const infowindow4 = new google.maps.InfoWindow({
        //     content: contentString4,
        // });

        const marker5 = new google.maps.Marker({
            position: brampton,
            map: map,
        });

        // marker5.addListener("click", () => {
        //     infowindow4.open(map, marker5);
        // });

        axios.get('/api/pages/miller-lite').then(results => {

            if(results.data.success === true) {
                vm.mlckelowna = _.find(results.data.challenge, function(page) {
                    return page.slug === 'mlc-kelowna'
                })
                vm.mlcleduc = _.find(results.data.challenge, function(page) {
                    return page.slug === 'mlc-leduc'
                })
                vm.mlcbrampton = _.find(results.data.challenge, function(page) {
                    return page.slug === 'mlc-brampton'
                })
                vm.mlcwinnipeg = _.find(results.data.challenge, function(page) {
                    return page.slug === 'mlc-winnipeg'
                })
                vm.mlcmoncton = _.find(results.data.challenge, function(page) {
                    return page.slug === 'mlc-moncton'
                })
                
            }
        })
     
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
	
	},
	methods: {
        grabNationalsHost: function(city) {
            var vm = this
            var tourney = _.find(vm.nationals_hosts, function(result) {
                return result.slug === 'nationals-host-' + city
            })

            if(tourney) {
                var content = JSON.parse(tourney.content)

                return content
            }
            
            // var content = JSON.parse(tourney)
            return []
            // return tourney
        },
        grabTournamentResults: function(year) {
            var vm = this
            var tourney = _.find(vm.previous_results, function(result) {
                return result.slug === 'nc-' + year
            })

            if(tourney) {
                var content = JSON.parse(tourney.content)

                return content
            }
            
            // var content = JSON.parse(tourney)
            return []
            // return tourney
        },
		openPanel:function(panel) {
			if(panel.switched === "false") {
				panel.switched = "true"
			} else {
				panel.switched = "false"
			}
		},
		createProperTableFunction(table) {
			// first row is your columns
			var first_row = table[0]
			var all_rows = table
			var columns = []
			var data = []
	
			var i = 0
			for(i; i < all_rows.length; i++) {
				var row = all_rows[i]
				if(i === 0) {
					// this is the first row, need to for loop again though
					var s = 0

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						columns.push({
							field: column.name,
							label: column.name
						})
					}
				} else {
					// this is the rest of the rows, need to loop and then call via the loop to the first row
					var s = 0
					
					var object = {}

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						var col_name = all_rows[0].row[s].name
						
						object[col_name] = column.name
					}
					data.push(object)
				}
			}

			// every row after is your data
			


			return {
				columns: columns,
				data: data,
				table: table
			}
        },
        parseMainContent: function(content) {
            
            return JSON.parse(content.content)
        },
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
