<template>
<span>
    <div class="dashboard-header">
        <div class="menu-slide-bar" v-on:click="menuSwitcher()">
            <i class="fas fa-bars"></i>
        </div>
        <div class="logo-main">
            <a href="/">
                <img src="../../../images/header/SPNLogo2019-White.png" alt="" style="height: 50px;">
            </a>
        </div>

    </div>
    <div class="search-bar-results" v-if="false">

    </div>
    <Sidebar v-if="menuSwitch === true"></Sidebar>
</span>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

import Sidebar from '../../dashboard/components/Sidebar.vue'

export default {
    data() {
        return {
            menuSwitch: true,
            user_coordinates: {
                lat: null,
                long: null
            },
            menu: [],
            submenus: []
        };
    },
    components: {
        Sidebar
    },
    computed: {
        
    },
    mounted: function() {
        var vm = this

        
		


        if(window.sessionStorage.getItem('nav')) {
            var nav = window.sessionStorage.getItem('nav')

            vm.menuSwitch = (window.sessionStorage.getItem('nav') === 'true')
            vm.$emit('switch:navigation', vm.menuSwitch)
        }

        

        axios.get('/auth/check').then(results => {
            if(results.data.type === "success") {
                vm.user = results.data.user
            }
        })

        axios.get('/api/navigation/primary').then(results => {
            if(results.data) {
                vm.menu = results.data.main_menu
                vm.submenus = results.data.submenus
            }
        }).catch(err => {
            // console.log(err)
        })

        if(window.innerWidth < 800) {
			vm.menuSwitch = false
		}
    },
    methods: {
        
        selectMenu(item) {
            var vm = this
            
            if(item.has_submenu) {
                var filter = _.filter(vm.submenus, function(sub_item) {
                    return sub_item.submenu_id === item.slug
                })

                vm.submenu_filtered = filter
            }
            if(item.href) {
                // redirect
                // router.push({ path: '/content/' + item.href })
            }
        },
        selectSubMenu(item) {
            var vm = this
        
            this.$router.push({ path: '/content/' + item.href }).catch(err => {})   
        },
        menuSwitcher: function() {
            var vm = this
            vm.menuSwitch = !vm.menuSwitch
            window.sessionStorage.setItem('nav', vm.menuSwitch)
            vm.$emit('switch:navigation', vm.menuSwitch)
        }
    }
}
</script>

<style lang="scss">
    @import '../../../styles/Dashboard.scss';
</style>
