<template>
	<div>
		<div >
            <div class="content-wrapper" v-if="tournament">
				<div :class="['page-header']" >
                    <div class="row">
                        <div class="col-md-6">
                            <div class="page-header-title" style="font-size: 18px;" v-if="eventTeam">
								<img :src="eventTeam.team.logo" width="40px" style="display: inline; float: left; margin-top: 10px; margin-right: 10px;"  alt="">
                                Event Roster For <span :style="'color: ' + eventTeam.team.color_1">{{ eventTeam.team.name }}</span> in {{ tournament.name }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a :href="'/dashboard/convenors/' + eventType + '/' + tournament.slug + '/event-roster/' + eventTeam.id" class="button is-small">Back to Edit</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="admin-area"  v-if="eventTeam">
					<div class="row">
						<div class="col-md-2">
                            <div class="padding">
                                <h1 :style="'margin-top: 0px; font-size: 18px; font-weight: 700; color: ' + eventTeam.team.color_1">{{ eventTeam.team.name }}</h1>
                                
                                <p v-if="eventTeam.division"><b>Division Entered: </b> {{ eventTeam.division.gender_slug.charAt(0).toUpperCase() }}{{ eventTeam.division.gender_slug.slice(1) }} {{ eventTeam.division.rank_id }}</p>
                                <p><b>Recommended Division: </b> {{ eventTeam.roster.roster_score }}</p> 
                                <p><b>Team ID:</b> {{ eventTeam.team_id }}</p>
							    <p><b>Event Team ID:</b> {{ eventTeam.id }}</p>
                                <hr>
                            </div>
						</div>
						<div class="col-md-10">
							<h3 style="margin-top: 0px; font-size: 18px; font-weight: 700;">Team Roster Registered for {{ tournament.name }}</h3>
					
					
							<b-table
                                    :narrowed="true"
                                    style="margin-top: 10px;"
                                    :data="eventTeam.roster.roster_players"
                                    :striped="true"
                                >
                                    <b-table-column
                                        field="waiver_signed"
                                        label="Waiver"
                                    >
                                        <template slot="header" slot-scope="{ column }">
                                            <b-tooltip :label="column.label" dashed>
                                                {{ column.label }}
                                            </b-tooltip>
                                        </template>
                                        <template v-slot="props">
                                            <span v-if="checkWaivers(props.row.user.waivers) === true">
												<b-tag type="is-success">
													<i class="fas fa-check"></i>
												</b-tag>
											</span>
											<span v-if="checkWaivers(props.row.user.waivers) === false">
												<b-tag type="is-danger">
													<i class="fas fa-times"></i>
												</b-tag>
											</span>
                                        </template>
                                    </b-table-column>
                    
                                    <b-table-column
                                        field="user.current_rank_id"
                                        label="Level"
                                    >
                                        <template v-slot="props">
                                            {{ props.row.user.current_rank_id }}
                                        </template>
                                    </b-table-column>
                                    
                                    <b-table-column field="user.gender" label="Gender">
                                        <template v-slot="props">
                                            <span class="tag is-light">
                                                <b>{{ props.row.user.gender }}</b>
                                                <p>{{ props.row.team }}</p>
                                            </span>
                                        </template>
                                    </b-table-column>
                                    <b-table-column field="role" label="Role">
                                        <template slot="header" slot-scope="{ column }">
                                            <b-tooltip :label="column.label" dashed>
                                                {{ column.label }}
                                            </b-tooltip>
                                        </template>
                                        <template v-slot="props">
                                            <span class="tag is-success" v-if="props.row.core_roster_player.role_on_team === 1">
                                                Coach
                                            </span>
                                            <span class="tag is-warning" v-if="props.row.core_roster_player.role_on_team === 2">
                                                Team Contact
                                            </span>
                                            <span class="tag is-danger" v-if="props.row.core_roster_player.role_on_team === 3">
                                                Player
                                            </span>
                                            <span class="tag is-info" v-if="props.row.coach_only === 1">
                                                Coach Only
                                            </span>
                                            
                                        </template>
                                    </b-table-column>
                                    <b-table-column
                                        field="user.first_name"
                                        label="First Name"
                                    >
            
                                        <template v-slot="props">
                                            {{ props.row.user.first_name }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column
                                        field="user.last_name"
                                        label="Last Name"
                                    >
                                        <template v-slot="props">
                                            <b>{{ props.row.user.last_name }}</b>
                                        </template>
                                    </b-table-column>
                                   
                                    <b-table-column field="user.phone" label="Phone">
                                        <template v-slot="props">
                                            
                                            <b>{{ props.row.user.phone }}</b>
                                            
                                        </template>
                                    </b-table-column>
                                    <b-table-column field="user.email" label="Email">
                                        <template v-slot="props">
                                            
                                            <b>{{ props.row.user.email }}</b>
                                            
                                        </template>
                                    </b-table-column>
                                    <b-table-column
                                        field="user.street_address"
                                        label="Street Address"
                                    >
                                        <template v-slot="props">
                                            {{ props.row.user.street_address }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column
                                        field="user.city"
                                        label="City"
                                    >
                                        <template v-slot="props">
                                            {{ props.row.user.city }}
                                        </template>
                                    </b-table-column>
                                  
                                    <b-table-column field="user.postal_code" label="Postal Code">
                                        <template v-slot="props">
                                            {{ props.row.user.postal_code }}
                                        </template>
                                    </b-table-column>
                                    
                                    <b-table-column field="user.date_of_birth" label="Date of Birth">
                                
                                        <template v-slot="props">
                                            <span class="tag is-light">
                                                <b>{{ moment(props.row.user.date_of_birth).utc().format('YYYY-MM-DD') }}</b>
                                            </span>
                                        </template>
                                    </b-table-column>
                                    
                                </b-table>
							
			
						</div>
					</div>
                
                </div>
				
            </div>
            
		</div>
		
	</div>
</template>

<script>

import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'

import axios from 'axios'
import moment from 'moment'
export default {
	data() {
		return {
			tournament: null,
			registered_teams: [],
			eventTeam: null,
            eventType: null
		};
	},
	components: {
		Header,
		Footer,
	},
	mounted: function() {
        // grab all the dashboard information now
		var vm = this
		this.page = this.$route.params.page
		var slug = this.$route.params.slug
		var event_roster_id = this.$route.params.id
        var event_type = this.$route.params.eventType
        
        vm.eventType = this.$route.params.eventType

        if(event_type === 'leagues') {
            axios.get(`/api/leagues/get-league/${slug}`).then(results => {
			    if(results.data.success === true) {	
                    vm.tournament = results.data.league

                    axios.get('/api/leagues/get-registered-team/' + event_roster_id).then(results => {
                        if(results.data.success === true) {
                            vm.eventTeam = results.data.registrant
                        }
                    })
                }
            })

            
        }
        if(event_type === 'tournaments') {
            axios.get(`/api/tournaments/get-tournament/${slug}`).then(results => {
			    if(results.data.success === true) {				
                    vm.tournament = results.data.tournament
                }
            })

            axios.get(`/api/tournaments/get-registered-team/${event_roster_id}`).then(results => {
                if(results.data.success === true) {
                    vm.eventTeam = results.data.registrant
                }
            })
        }

		
	},
	computed: {
		
	},
	methods: {
		filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        },
		
		moment: function(date, format = null) {
			return moment(date, format)
		},
        checkWaivers: function(row) {
			
			var vm = this
			var filter = _.filter(row, function(waiver) {
				if(waiver.waiver_id == vm.$latest_waiver) { return true }
				return false
			})

			return filter.length > 0
		}
	}
}
</script>

<style lang="scss">
    
</style>
