<template>
    <div>

        
        
            <div class="content-wrapper" v-if="tournament">
                <div :class="['page-header']">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="page-header-title">
                                <img :src="tournament.event_roster_youre_on.roster.team.logo" width="50px" style="display: inline; float: left; margin-top: 10px; margin-right: 10px;" alt="">
                                <span v-if="type === 'league'">
                                    League
                                </span>
                                <span v-if="type === 'tournament'">
                                    Tournament
                                </span>
                                 You're Entered In - {{ tournament.name }} -  {{ tournament.event_roster_youre_on.roster.team.name }}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a href="/dashboard/players/events-registered-in" class="button">Back to My Events</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="admin-area">
                    
                            
                    <b-message type="is-danger" v-if="computedRosterPlayers.overLimit === true">
                        This team is over the limit of players for this event. <br>
                        Max size allowed: {{ tournament.max_roster_size }}<br>
                        This roster: {{ eventRoster.roster.length }}

                    </b-message>
                    <b-message type="is-danger" v-if="computedRosterPlayers.wrong_age === true">
                        This team has players outside of the age range permitted for this event:

                        <div v-for="player in computedRosterPlayers.wrong_age_array" :key="player.id">
                            {{ player.user.first_name }} {{ player.user.last_name }} - {{ player.age }} years old
                        </div>
                    </b-message>
                    <div class="row">
						<div class="col-md-2">
                            <div class="padding">
                                <h1 :style="'margin-top: 0px; font-size: 18px; font-weight: 700; color: ' + tournament.event_roster_youre_on.roster.team.color_1">{{  tournament.event_roster_youre_on.roster.team.name }}</h1>
                                <p v-if="tournament.division"><b>Division Entered: </b> {{ tournament.division.gender_slug.charAt(0).toUpperCase() }}{{ tournament.division.gender_slug.slice(1) }} {{ tournament.division.rank_id }}</p>

                             
                                <p><b>Team ID:</b> {{ tournament.event_roster_youre_on.roster.team.id }}</p>
							    <p><b>Event Team ID:</b> {{ roster_id }}</p>
                                
                            </div>
						</div>
                        <div class="col-md-10">
							<h3 style="margin-top: 0px; font-size: 18px; font-weight: 700;">Team Roster Registered for {{ tournament.name }}</h3>
                            <b-table
                                :data="tournament.full_roster"
                                :striped="true"
                                :focusable="true"
                            >
                                <b-table-column
                                    field="waiver_signed"
                                    label="Waiver"
                                >
                                    <template slot="header" slot-scope="{ column }">
                                        <b-tooltip :label="column.label" dashed>
                                            {{ column.label }}
                                        </b-tooltip>
                                    </template>
                                    <template v-slot="props">
                                        <div style="text-align: center;">
                                            <i v-if="props.row.user.latest_waiver_signed === $latest_waiver" class="fas fa-check-circle"></i>
                                        </div>
                                    </template>
                                </b-table-column>
                                <b-table-column
                                    field="first_name"
                                    label="First Name"
                                    
                                >
                                    <template slot="header" slot-scope="{ column }">
                                        <b-tooltip :label="column.label" dashed>
                                            {{ column.label }}
                                        </b-tooltip>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.user.first_name }}
                                    </template>
                                </b-table-column>

                                <b-table-column
                                    field="last_name"
                                    label="Last Name"
                                    
                                >

                                    <template slot="header" slot-scope="{ column }">
                                        <b-tooltip :label="column.label" dashed>
                                            {{ column.label }}
                                        </b-tooltip>
                                    </template>
                         
                                    <template v-slot="props">
                                        <b>{{ props.row.user.last_name }}</b>
                                    </template>
                                </b-table-column>
                                <b-table-column
                                    field="last_name"
                                    label="Email"
                                    v-if="tournament.region_id === 2"
                                    
                                >

                                    <template slot="header" slot-scope="{ column }">
                                        <b-tooltip :label="column.label" dashed>
                                            {{ column.label }}
                                        </b-tooltip>
                                    </template>
                         
                                    <template v-slot="props">
                                        <b>{{ props.row.user.email }}</b>
                                    </template>
                                </b-table-column>
                                <b-table-column
                                    field="current_rank_id"
                                    label="Level"
                                    
                                >
                             
                                    <template v-slot="props">
                                        {{ props.row.user.current_rank_id }}
                                    </template>
                                </b-table-column>
                                <b-table-column field="role" label="Role">
                                    <template slot="header" slot-scope="{ column }">
                                        <b-tooltip :label="column.label" dashed>
                                            {{ column.label }}
                                        </b-tooltip>
                                    </template>
                                    <template v-slot="props">
                                        <span class="tag is-success" v-if="props.row.core_roster_player.role_on_team === 1">
                                            {{ props.row.core_roster_player.user_id === tournament.event_roster_youre_on.roster.team.primary_coach_id ? "Primary" : ""}} Coach
                                        </span>
                                        <span class="tag is-warning" v-if="props.row.core_roster_player.role_on_team === 2">
                                            Team Contact
                                        </span>
                                        <span class="tag is-danger" v-if="props.row.core_roster_player.role_on_team === 3">
                                            Player
                                        </span>
                                    </template>
                                </b-table-column>

                                <b-table-column field="city" label="City">
                                    <template slot="header" slot-scope="{ column }">
                                        <b-tooltip :label="column.label" dashed>
                                            {{ column.label }}
                                        </b-tooltip>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.user.city }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="gender" label="Gender">
                                    <template slot="header" slot-scope="{ column }">
                                        <b-tooltip :label="column.label" dashed>
                                            {{ column.label }}
                                        </b-tooltip>
                                    </template>
                                    <template v-slot="props">
                                        <span class="tag is-light">
                                            <b>{{ props.row.user.gender }}</b>
                                        </span>
                                    </template>
                                </b-table-column>
                                <b-table-column field="date_of_birth" label="Date of Birth" v-if="myRole">
                                    <template slot="header" slot-scope="{ column }">
                                        <b-tooltip :label="column.label" dashed>
                                            {{ column.label }}
                                        </b-tooltip>
                                    </template>
                                    <template v-slot="props">
                                        <span class="tag is-light">
                                            <b>{{ moment(props.row.user.date_of_birth).utc().format('YYYY-MM-DD') }}</b>
                                        </span>
                                    </template>
                                </b-table-column>
                                
                               
                            </b-table>
                        </div>
                    </div>                         
                </div>
            </div>
        </div>
    

</template>

<script>

import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'
var getAge = require('get-age')
import axios from 'axios'
import moment from 'moment'
export default {
	data() {
		return {
            content_width: false,
            slug: null,
            tournament: null,
            overLimit: false,
			wrong_age: false,
			wrong_age_array: [],
            addPlayerToRosterSwitch: false,
            selectedRosterPlayers: [],
            eventType: null
		};
	},
	components: {
		Header,
		Footer,
		
	},
	mounted: function() {
        // grab all the dashboard information now
		var vm = this
        this.slug = this.$route.params.slug
        this.roster_id = this.$route.params.roster
        this.type = this.$route.params.type
        
        // grab the user logged in

        // grab the tournament with the user logged in (so we can make sure they have permission to see/edit the roster)
        if(this.type === 'tournament') {
            
            axios.get('/api/users/tournament-registered-in/' + this.slug + '/' + this.roster_id).then(results => {
                if(results.data.success === true) {
                    vm.tournament = results.data.tournament    
                }    
            })
            
        } else {
            axios.get('/api/users/league-registered-in/' + this.slug + '/' + this.roster_id).then(results => {
                if(results.data.success === true) {
                    vm.tournament = results.data.tournament                        
                }       
            })
        }


        // make editable if they're a coach of that team on the core roster
	},
	computed: {
        computedRosterPlayers: function($event) {
            var vm = this
			// eventTeam.roster.roster_players
            var overLimit = false
                var wrong_age = false
                var wrong_age_array = []
            if(vm.tournament.division) {
                var min_age = vm.tournament.division.min_age
                
                var i = 0


                for(i; i < vm.tournament.full_roster.length; i++) {
                    var user = vm.tournament.full_roster[i].user
                    var date_of_birth = user.date_of_birth

                    var format_to_first_of_year = moment(date_of_birth).format('YYYY') + '-01-01'
                    var their_age = getAge(format_to_first_of_year)
                    if(their_age < min_age) {
                        wrong_age_array.push({ ...vm.tournament.full_roster[i], age: their_age })
                        wrong_age = true
                    }
                }
                
                if(wrong_age === true) {
                    vm.wrong_age = true
                    vm.wrong_age_array = wrong_age_array
                } else {
                    vm.wrong_age = false
                }
            }

            if($event.length > vm.tournament.max_roster_size) {
                this.$buefy.snackbar.open({
                    message: 'This puts your roster over the max players limit',
                    type: 'is-danger', // is-danger
                    duration: 4000,
                    queue: false
                })

                vm.overLimit = true
				overLimit = true
            } else {
                vm.overLimit = false
				overLimit = false
            }

			return {
				overLimit: overLimit,
				wrong_age: wrong_age,
				wrong_age_array: wrong_age_array
			}
        },
		myRole: function() {
            var vm = this
            if(vm.tournament.event_roster_youre_on.core_roster_player.role_on_team === 3) {
                return false
            } else {
                return true
            }
            
        }
	},
	methods: {
		switchWidth: function($event) {
			this.content_width = !$event
        },
        moment: function(date, format) {
            return moment(date, format)
        },
        addRosterPlayersToEventTeam: function() {
            
        },
        updateRoster: function() {
            var vm = this
            if(this.type === 'league') {
                axios.get('/api/users/league-registered-in/' + this.slug + '/' + this.roster_id).then(results => {
                    if(results.data.success === true) {
                        vm.tournament = results.data.tournament    
                    }       
                })
            } else {
                axios.get('/api/users/tournament-registered-in/' + this.slug + '/' + this.roster_id).then(results => {
                    if(results.data.success === true) {
                        vm.tournament = results.data.tournament
                    }
                })
            }
            
        },
        addToRoster: function() {
            
			var vm = this

            axios.post('/api/users/add-to-event-roster', {
                roster_players: vm.selectedRosterPlayers,
                team_id: vm.tournament.event_roster_youre_on.roster.team.id,
                team_roster_id: vm.tournament.event_roster_youre_on.team_roster_id,
            }).then(results => {
                if(results.data.success === true) {
                    vm.updateRoster()
					vm.selectedRosterPlayers = []
					vm.addPlayerToRosterSwitch = false

					this.$buefy.snackbar.open({
						message: 'Players added to roster successfully.',
						type: 'is-success' // is-danger
					})
                } else {
                    vm.error = results.data.error
                }
            })
        },
        removeFromEventRoster: function(player) {

            var r = confirm('Are you sure you want to remove this player from the event roster?')

            var vm = this
            if(r === true) {
                axios.post('/api/users/remove-from-event-roster', {
                    id: player.id
                }).then(results => {
                    if(results.data.success === true) {
                        vm.updateRoster()

                        this.$buefy.snackbar.open({
                            message: 'Players removed from roster successfully.',
                            type: 'is-success' // is-danger
                        })
                    }
                })
            }
        }
	}
}
</script>

<style lang="scss">
    
</style>
