<template>
    <span>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
        <h2>{{ en === true ? 'Tournament Calendar' : 'Calendrier des tournois' }}</h2>
        <b-tabs multiline v-model="activeTab">
            <b-tab-item :label="(en === true ? 'Tournament Calendar' : 'Calendrier des tournois')">
                <h3 style="text-align: center;" v-if="en === true">Tournament Calendar</h3>           
                <h3 style="text-align: center;" v-if="en === false">Calendrier des tournois</h3>           
                <TournamentCalendar :en="en">
                </TournamentCalendar>
            </b-tab-item>
        </b-tabs>
    </span>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

import UpcomingTournaments from '../../../components/UpcomingTournaments.vue'
import RegisterToTournament from '../../../components/RegisterToTournament.vue'
import UpcomingLeagues from '../../../components/UpcomingLeagues.vue'
import TeamRegistration from '../../../components/TeamRegistration.vue'
import TournamentCalendar from '../../../frontend/TournamentCalendar.vue'


export default {
	data() {
		return {
            carouselSize: 3,
            isLoading: true,
            activeTab: 0,
            authUser: null,
            registerTeam: false,
            event: null,
            leaguesRegisteredIn: [],
            upcomingTournamentsAwayStep: 0,
            upcomingTournaments: [],
            registerForTournamentSwitch: false,
            registerForTournamentDetails: null,
            allLeaguesRegisteredIn: [],
            allTournamentsRegisteredIn: [],
            tournamentsRegisteredIn: null,
            test: 0,
		};
	},
	components: {
        UpcomingTournaments,
        UpcomingLeagues,
        TeamRegistration,
        RegisterToTournament,
        TournamentCalendar
	},
    props: ['en'],
	mounted: function() {
        var vm = this

        var vm = this
		if(window.innerWidth < 800) {
			vm.carouselSize = 2
		}
		if(window.innerWidth < 600) {
			vm.carouselSize = 1
		}

        if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }

        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
               

                
                axios.get('/api/users/tournaments-registered-in').then(results => {
                    if(results.data.success === true) {
                        vm.tournamentsRegisteredIn = results.data.tournaments_youre_in
                    }
                })

                axios.get('/api/users/leagues-registered-in').then(results => {
                    if(results.data.success === true) {
                        vm.leaguesRegisteredIn = results.data.leagues_youre_in
                    }
                })

                axios.get('/api/users/all-tournaments-registered-in').then(results => {
                    if(results.data.success === true) {
                        vm.allTournamentsRegisteredIn = _.orderBy(results.data.tournaments_youre_in, 'event_start_date', 'desc')
                    }
                })

                axios.get('/api/users/all-leagues-registered-in').then(results => {
                    if(results.data.success === true) {
                        vm.allLeaguesRegisteredIn = _.orderBy(results.data.leagues_youre_in, 'season_id', 'desc')
                    }
                })

                var location = null

                if(sessionStorage.getItem('user_location')) {
                    location = sessionStorage.getItem('user_location')
                }

                axios.get('/api/users/upcoming-tournaments-near-you', {
                    params: {
                        geolocate: location
                    }
                }).then(results => {
                    if(results.data.success === true) {
                        vm.upcomingTournaments = results.data.tournaments
                    }
                })

                axios.get('/api/users/upcoming-leagues-near-you', {
                    params: {
                        geolocate: location
                    }
                }).then(results => {
                    if(results.data.success === true) {
                    }
                })

                vm.isLoading = false
            }
        })
        
	},
	computed: {
        isPassedRosterLockDate: function() {

        }
	},
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        },
        registerButtonModal: function($event) {
			this.registerForTournamentSwitch = true
            this.registerForTournamentDetails = $event
            this.$emit('register:now', $event)
			// console.log('registerButtonModal', )
		}
	}
}
</script>

<style lang="scss">
    
</style>
