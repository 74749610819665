<template>
	<div>
		<b-modal
			v-model="addPlayerModal"
			trap-focus
			:destroy-on-hide="false"
			aria-role="dialog"
			aria-modal>
			<template #default="props">
				<AddPlayerToTeamModal
					:eventTeam="eventTeam"
					:regionId="tournament.region_id"
					v-on:addPlayersToRoster="addPlayersToRoster"></AddPlayerToTeamModal>
			</template>
		</b-modal>
	
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper" v-if="tournament">
				<div :class="['page-header', { sidebarClosed: !content_width }]" >
                    <div class="row">
                        <div class="col-md-9">
                            <div class="page-header-title" v-if="eventTeam">
								<span v-if="eventTeam.team.logo !== 'null'">
									<img :src="eventTeam.team.logo" width="50px" style="display: inline; float: left; margin-top: 10px; margin-right: 10px;"   alt="">
								</span>
                                Event Roster For <span :style="'color: ' + eventTeam.team.color_1">{{ eventTeam.team.name }}</span> in {{ tournament.name }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a :href="'/dashboard/convenors/tournament/' + tournament.slug + '/dashboard'" class="button">Back to Edit Tournament</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="admin-area">
					<div class="row" v-if="eventTeam">
					
						<div class="col-md-12">
							<h1 :style="'margin-top: 0px; color: ' + eventTeam.team.color_1">{{ eventTeam.team.name }}</h1>
							
							<p v-if="eventTeam.division"><b>Division Entered: </b> {{ eventTeam.division.gender_slug.charAt(0).toUpperCase() }}{{ eventTeam.division.gender_slug.slice(1) }} {{ eventTeam.division.rank_id }}</p>
							<p><b>Recommended Division: </b> {{ eventTeam.roster.roster_score }}</p> 
							<p><b>Team ID:</b> {{ eventTeam.team_id }}</p>
							<p><b>Event Team ID:</b> {{ eventTeam.id }}</p>
							<b-message type="is-danger" v-if="selectedRosterPlayers.overLimit === true">
								This team is over the limit of players for this event. <br>
								Max size allowed: {{ tournament.max_roster_size }}<br>
								This roster: {{ eventRoster.roster.length }}

							</b-message>
							<b-message type="is-danger" v-if="selectedRosterPlayers.wrong_age === true">
								This team has players outside of the age range permitted for this event:

								<div v-for="player in selectedRosterPlayers.wrong_age_array" :key="player.id">
									{{ player.user.first_name }} {{ player.user.last_name }} - {{ player.age }} years old
								</div>
							</b-message>
							<h3 style="margin-top: 0px;">Team Roster Registered for {{ tournament.name }}</h3>
							<a class="button is-info" v-on:click="addPlayerModal = true"><i class="fas fa-plus"></i>&nbsp;&nbsp;Add Player To Roster</a>

							<a :href="'/dashboard/convenors/tournaments/' + tournament.slug + '/event-roster/' + eventTeam.id + '/printable'" style="float: right;" class="button is-success"><i class="fas fa-save"></i>&nbsp;&nbsp;Download Roster PDF</a>
							
							<b-table
								style="margin-top: 30px;"
								:data="eventTeam.roster.roster_players"
								:striped="true"
								:focusable="true"
							>
								<b-table-column
									field="waiver_signed"
									label="Waiver"
								>
									<template slot="header" slot-scope="{ column }">
										<b-tooltip :label="column.label" dashed>
											{{ column.label }}
										</b-tooltip>
									</template>
									<template v-slot="props">
										<div style="text-align: center;">
											
											<span v-if="checkWaivers(props.row.user.waivers) === true">
												<b-tag type="is-success">
													<i class="fas fa-check"></i>
												</b-tag>
											</span>
											<span v-if="checkWaivers(props.row.user.waivers) === false">
												<b-tag type="is-danger">
													<i class="fas fa-times"></i>
												</b-tag>
											</span>
										</div>
									</template>
								</b-table-column>
								<b-table-column
									field="user.first_name"
									label="First Name"
									:searchable="true"
								>
									<template slot="header" slot-scope="{ column }">
										<b-tooltip :label="column.label" dashed>
											{{ column.label }}
										</b-tooltip>
									</template>
									<template slot="searchable" slot-scope="props">
										<b-input
											v-model="props.filters[props.column.field]"
											placeholder="Search"
											size="is-small"
										></b-input>
									</template>
									<template v-slot="props">
										{{ props.row.user.first_name }}
									</template>
								</b-table-column>
								<b-table-column
									field="user.last_name"
									label="Last Name"
									:searchable="true"
								>

									<template slot="header" slot-scope="{ column }">
										<b-tooltip :label="column.label" dashed>
											{{ column.label }}
										</b-tooltip>
									</template>
									<template slot="searchable" slot-scope="props">
										<b-input
											v-model="props.filters[props.column.field]"
											placeholder="Search"
											size="is-small"
										></b-input>
									</template>
									<template v-slot="props">
										<b>{{ props.row.user.last_name }}</b>
									</template>
								</b-table-column>
								<b-table-column
									field="user.current_rank_id"
									label="Level"
									:searchable="true"
								>
									<template slot="searchable" slot-scope="props">
										<b-input
											v-model="props.filters[props.column.field]"
											size="is-small"
											style="width: 30px;"
										></b-input>
									</template>
									<template v-slot="props">
										<RankComponent :currentRank="props.row.user.current_rank_id"></RankComponent>
									</template>
								</b-table-column>
								<b-table-column field="role" label="Role">
									<template slot="header" slot-scope="{ column }">
										<b-tooltip :label="column.label" dashed>
											{{ column.label }}
										</b-tooltip>
									</template>
									<template v-slot="props">
										<span class="tag is-success" v-if="props.row.core_roster_player.role_on_team === 1">
											Coach
										</span>
										<span class="tag is-warning" v-if="props.row.core_roster_player.role_on_team === 2">
											Team Contact
										</span>
										<span class="tag is-danger" v-if="props.row.core_roster_player.role_on_team === 3">
											Player
										</span>
										<span class="tag is-info" v-if="props.row.core_roster_player.coach_only === 1">
											Coach Only
										</span>
									</template>
								</b-table-column>
							
								<b-table-column field="user.city" label="City">
									<template slot="header" slot-scope="{ column }">
										<b-tooltip :label="column.label" dashed>
											{{ column.label }}
										</b-tooltip>
									</template>
									<template v-slot="props">
										{{ props.row.user.city }}
									</template>
								</b-table-column>
								<b-table-column field="user.gender" label="Gender">
									<template slot="header" slot-scope="{ column }">
										<b-tooltip :label="column.label" dashed>
											{{ column.label }}
										</b-tooltip>
									</template>
									<template v-slot="props">
										<span class="tag is-light">
											<b>{{ props.row.user.gender }}</b>
										</span>
									</template>
								</b-table-column>
								<b-table-column field="user.date_of_birth" label="Date of Birth">
									<template slot="header" slot-scope="{ column }">
										<b-tooltip :label="column.label" dashed>
											{{ column.label }}
										</b-tooltip>
									</template>
									<template v-slot="props">
										<span class="tag is-light">
											<b>{{ moment(props.row.user.date_of_birth).utc().format('YYYY-MM-DD') }}</b>
										</span>
									</template>
								</b-table-column>
								<b-table-column label="Actions">
									<template v-slot="props">
										
										<a v-on:click="removePlayer(props.row)" class="button is-small is-danger">Remove Player</a>
									</template>
								</b-table-column>
								
							</b-table>
						
						</div>
					</div>
                
                </div>
				
            </div>
            
		</div>
	</div>
</template>

<script>

import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'
import RankComponent from '../../components/RankComponent.vue'
import AddPlayerToTeamModal from '../../components/AddPlayerToTeamModal.vue'
var getAge = require('get-age')
import axios from 'axios'
import moment from 'moment'
export default {
	data() {
		return {
			timer: null,
			page: null,
			addPlayerModal: false,
			tournament: null,
			previous: null,
			upcoming: null,
			searchForTeam: null,
			addTeamToEventSwitch: false,
			umpSearch: null,
			registered_teams: [],
			content_width: false,
			mountCheckboxes: null,
			searchList: [],
			canvasAdditionalDetailsLeft: null,
			savedCheatSheet: false,
			umpireFound: null,
			addedToCheatSheet: null,
			searchResults: null,
			selectedTeam: null,
			selectRegion: false,
			eventTeam: null,
			overLimit: false,
			wrong_age: false,
			wrong_age_array: [],
			playersSelected: [],
			registerForTournament: {
                team: null,
                gender: null,
                division: null
            },
			cheatSheet: {
				innings: 7,
				call_last_inning_after: 75,
				minimum_players: 10,
				hr_rule: '1',
				anticipation: true,
				ties: true,
				courtesy_runners: true,
				max_runs_per_inning: 6,
				mercy_rule: true,
				tag_at_home: true,
				throw_to_first_from_outfield: true,
				additional_rules: null
			}
		};
	},
	components: {
		Header,
		Footer,
		RankComponent,
		AddPlayerToTeamModal
	},
	mounted: function() {
        // grab all the dashboard information now
		var vm = this
		this.page = this.$route.params.page
		var slug = this.$route.params.slug
		var event_roster_id = this.$route.params.id

		

		axios.post('/api/tournaments/review-checklist').then(results => {
			if(results.data.success === true) {
				vm.mountCheckboxes = results.data.checklist
			}
		})

		axios.get(`/api/tournaments/get-tournament/${slug}`).then(results => {
			if(results.data.success === true) {				
				vm.tournament = results.data.tournament

				axios.get('/auth/check').then(results => {
					var user = results.data.user
					var user_permissions = results.data.user_permissions
					
					var good = false
					if(vm.tournament.convenor_user_id === user.id) {
						// good to 
						good = true
					}
					
					if(user_permissions) {
						if(user_permissions.superadmin_access === 1) {
							// good to go
							good = true
						}
					}
					

					if(good === false) {
						// redirect back
						this.$router.push({ name: 'Dashboard' })
						this.$buefy.snackbar.open({
							message: 'You do not have access to this page',
							type: 'is-danger', // is-danger
							duration: 4000,
							queue: false
						})
					}
				})
			}
		})

		axios.get(`/api/tournaments/get-registered-team/${event_roster_id}`).then(results => {
			if(results.data.success === true) {
				vm.eventTeam = results.data.registrant
			}
		})
	},
	computed: {
		selectedRosterPlayers: function($event) {
            var vm = this
			// eventTeam.roster.roster_players
			
            var min_age = vm.eventTeam.division.min_age

			var overLimit = false
            var wrong_age = false
			var wrong_age_array = []
            var i = 0

            for(i; i < vm.eventTeam.roster.roster_players.length; i++) {
                var user = vm.eventTeam.roster.roster_players[i].user
                var date_of_birth = user.date_of_birth

                var format_to_first_of_year = moment(date_of_birth).format('YYYY') + '-01-01'
                var their_age = getAge(format_to_first_of_year)
                if(their_age < min_age) {
					wrong_age_array.push({ ...vm.eventTeam.roster.roster_players[i], age: their_age })
                    wrong_age = true
                }
            }
            
            if(wrong_age === true) {
                vm.wrong_age = true
				vm.wrong_age_array = wrong_age_array
            } else {
                vm.wrong_age = false
            }


            if($event.length > vm.tournament.max_roster_size) {
                this.$buefy.snackbar.open({
                    message: 'This puts your roster over the max players limit',
                    type: 'is-danger', // is-danger
                    duration: 4000,
                    queue: false
                })

                vm.overLimit = true
				overLimit = true
            } else {
                vm.overLimit = false
				overLimit = false
            }

			return {
				overLimit: overLimit,
				wrong_age: wrong_age,
				wrong_age_array: wrong_age_array
			}
        },
		spnRegistration: function() {
            // grab the registrations of insurance from the team
            // cross reference the division
            var vm = this

            var team = vm.selectedTeam

            var selected_gender = vm.registerForTournament.gender

            
            if(team) {
                
                var insurance = team.insurance
                
                var find_insurance = _.find(insurance, function(paper) {
                    if(paper.gender === selected_gender) {
                        return true
                    }
                })
                // check validity of the insurance that was found (must be 2020, must not be single use - if it's single use it means it's been used already (because they signed up and it was single use))
                // if a single use one is found, it must be upgraded
                // when updated it will remove single use from the row

                if(find_insurance) {

                    vm.currentInsurance = find_insurance
                    vm.triggerFinalize = true
                    return true
                }
                vm.triggerFinalize = false
                return false
            } else {
                vm.triggerFinalize = false
                return false
            }
        },
		totalDivisions: function() {
            var vm = this
            var mens = vm.filterDivision(vm.tournament.divisions, 'mens')
            var ladies = vm.filterDivision(vm.tournament.divisions, 'ladies')
            var coed = vm.filterDivision(vm.tournament.divisions, 'coed')

            var count = 0
            if(mens.length > 0) {
                count = count + 1
            } 
            if(ladies.length > 0) {
                count = count + 1
            }
            if(coed.length > 0) {
                count = count + 1
            }

            return {
                mens: mens,
                ladies: ladies,
                coed: coed,
                count: count
            }
        },
		filterGroup: function() {
			var vm = this
			var grouped = _.groupBy(vm.mountCheckboxes, 'group')

			return grouped
		}
	},
	methods: {
		filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        },
		switchWidth: function($event) {
			this.content_width = !$event
		},
		saveUmpireCheatSheet: function() {
			var vm = this

			axios.post('/api/umpires/save-cheat-sheet', {
				cheat_sheet: vm.cheatSheet,
				event_id: vm.tournament.id,
				type: 'tournament'
			}).then(results => {
				if(results.data.success === true) {
					vm.savedCheatSheet = true
					// then go over and make it so you can add umpires to a tournament
					// then go over to umpire cheat sheet and make it display the cheat sheet you just got added to

					// then go back and connect the event checklistbnmkj
				}
			})
		},
		umpireEmailSearch: function(text) {
			var vm = this
			axios.post('/api/umpires/search-for-umpire', {
				search: text
			}).then(results => {
				if(results.data.success === true) {
					
					vm.umpireFound = results.data.umpire
				}
			})
		},
		addUmpireToTournament: function() {
			
			var vm = this
			axios.post('/api/umpires/add-to-cheat-sheet', {
				umpire_found: vm.umpireFound,
				cheat_sheet: vm.cheatSheet.id
			}).then(results => {
				if(results.data.success === true) {
					vm.addedToCheatSheet = results.data.added_to_cheat_sheet
					this.$buefy.snackbar.open({
                        message: 'Added user to the cheat sheet',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'User was not added to the cheat sheet',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		eventChecklistSave: function($event, box) {

			var vm = this
			axios.post('/api/tournaments/save-checklist', {
				box: box,
				event_id: vm.tournament.id,
				event_type: 'tournament',
				result: $event
			}).then(results => {
				if(results.data.success === true) {
					// TO DO
				}
			})
		},
		saveEventNotes: function($event) {
			
			var vm = this
			
			axios.post('/api/tournaments/save-checklist-notes', {
				event_id: vm.tournament.id,
				event_type: 'tournament',
				result: $event
			}).then(results => {
				if(results.data.success === true) {
					// TO DO
				}
			})
		},
		removeUmpire: function(user) {
			var vm = this
			axios.post('/api/umpires/remove-from-cheat-sheet', {
				user_id: user.user.id,
				cheat_sheet: vm.cheatSheet.id
			}).then(results => {
				if(results.data.success === true) {
					vm.addedToCheatSheet = results.data.added_to_cheat_sheet
					
					this.$buefy.snackbar.open({
                        message: 'Removed user from the cheat sheet',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'User was not deleted to the cheat sheet',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		addTeamToEvent: function() {
			var vm = this

			vm.addTeamToEventSwitch = true
		},
		searchForTeamFunction: function($event = null) {
			var vm = this
			
			if($event) {
				axios.post('/api/tournaments/search-for-team', {
					tournament_id: vm.tournament.id,
					search: $event,
					region_on: vm.selectRegion,
					region_id: vm.tournament.region_id
				}).then(results => {
					if(results.data.success === true) {

						vm.searchResults = results.data.teams
					}
				})
			} else {
				axios.post('/api/tournaments/search-for-team', {
					tournament_id: vm.tournament.id,
					search: vm.searchForTeam,
					region_on: vm.selectRegion,
					region_id: vm.tournament.region_id
				}).then(results => {
					if(results.data.success === true) {

						vm.searchResults = results.data.teams
					}
				})
			}
		},
		checkIfPreviousDate: function(tournament) {
			
			var start_date = moment(tournament.event_start_date)

			var is_after = start_date.isAfter(moment())
			// var now = moment().format('YYYY-MM-DD')
			// var tournament_start_date = moment(tournament.event_start_date).format('YYYY-MM-DD')
			// console.log('isAfter', now.isAfter(tournament.event_start_date))
			return is_after
		},
		registerTeamToEvent: function() {
			var vm = this
			axios.post('/api/tournaments/add-team-to-event', {
				registration: vm.registerForTournament
			}).then(results => {

				// TO DO
			})
		},
		removeTeam: function(team) {
			confirm("Are you sure you want to remove this team from your event?")

			axios.post('/api/tournaments/remove-team-from-event', {
				team_id: team.id
			}).then(results => {
				// TO DO	


			})
		},
		moment: function(date, format = null) {
			return moment(date, format)
		},
		updatePlayerSearch: function($event) {
            var vm = this

			if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {

				axios.post(`/api/users/search/players`, {
					search_name: $event,
					region: vm.tournament.region_id
				}).then(results => {

					if(results.data.success === true) {
						vm.searchList = results.data.users
					}
				})
			}, 1000)
		},
		addPlayersToRoster: function(data) {
			this.addToRoster(data.playersSelected)
		},
		addToRoster: function(players) {
			var vm = this

            axios.post('/api/tournaments/add-to-event-roster', {
                roster_players: players,
				team_id: vm.eventTeam.team_id,
				team_roster_id: vm.eventTeam.team_roster_id
            }).then(results => {
                if(results.data.success === true) {
                    vm.updateRoster()
					vm.playersSelected = []
					vm.addPlayerModal = false

					this.$buefy.snackbar.open({
						message: 'Players added to roster successfully.',
						type: 'is-danger' // is-danger
					})
                } else {
                    vm.error = results.data.error
                }
            })
		},
		updateRoster: function() {
            var vm = this
            axios.get(`/api/tournaments/get-registered-team/${vm.eventTeam.id}`).then(results => {
				if(results.data.success === true) {
					vm.eventTeam = results.data.registrant
				}
			})
		},
		removePlayer: function(row) {
			var vm = this
			var r = confirm('Are you sure you want to remove a player?')

			if(r === true) {
				axios.post('/api/tournaments/remove-player-from-event-team', {
					player_id: row.id
				}).then(results => {
					if(results.data.success === true) {

						vm.updateRoster()

						this.$buefy.snackbar.open({
							message: 'Player removed from event roster.',
							type: 'is-success' // is-danger
						})
					}
				})
			}

		},
		checkWaivers: function(row) {
			var vm = this
			var filter = _.filter(row, function(waiver) {
				if(waiver.waiver_id == vm.$latest_waiver) { return true }
				return false
			})

			return filter.length > 0
		}
	}
}
</script>

<style lang="scss">
    
</style>
