<template>
	<div>
		<Header></Header>
		<div class="content-area" v-if="tournament">
            <div class="register-banner">
                Registration for {{ tournament.name }}
            </div>
            <div class="steps-padding">
                <b-steps
                    v-model="activeStep"
                  >
                    <!-- Login/Auth -->
                    <!-- If Provincial/National Event, Pay To Enter -->
                    <!-- Check for Insurance -->
                    <!-- Select Roster Members -->
                    <b-step-item :label="step.label" :step="key+1" v-for="(step, key) in steps" :key="key">
                        <h1 class="title has-text-centered">{{ step.label }}</h1>
                        <div v-if="step.label === 'Login to SPN'">                       
                            <div style="max-width: 600px; margin: 0 auto;">
                                <LoginRegister
                                    v-if="authUser === null"
                                    :redirect="false"
                                    :redirect-to="false"
                                    v-on:logged:in="triggerAuth($event)">
                                </LoginRegister>
                            </div>
                        </div>
                        <div v-if="step.label === 'Pay for Event'">
                            <div style="max-width: 600px; margin: 0 auto;">
                                <h3 style="font-size: 20px; font-weight: 700; padding: 20px; padding-bottom: 0px;">Your Receipt</h3>
                                <div class="full-payment-reciept-area">
                                    <div class="row" style="margin-bottom: 10px; padding-bottom: 5px; border-bottom: 1px solid #ddd;">
                                        <div class="col-md-12" style="text-align: center;">
                                            <b>{{ tournament.name }}</b>
                                        </div>
                                    </div>
                                    <div class="row" style="background: #eee; padding: 0 10px;">
                                        <div class="col-md-6">
                                            <b>You owe:</b>
                                        </div>
                                        <div class="col-md-3" style="text-align: right;">
                                            <b>$</b>
                                        </div>
                                        <div class="col-md-3" style="text-align: right;">
                                            {{ roundMoney(computingTheBill.total).toFixed(2) }}
                                        </div>
                                    </div>
                                    <div class="row"  style="background: #eee; padding: 0 10px;" v-if="computingTheBill.hst_amount">
                                        <div class="col-md-6">
                                            <b style="color: red;">Tax:</b>
                                        </div>
                                        <div class="col-md-3" style="text-align: right;">
                                            <b style="color: red;">$</b>
                                        </div>
                                        <div class="col-md-3" style="text-align: right; color: red;">
                                            {{ roundMoney(computingTheBill.hst_amount).toFixed(2) }}
                                        </div>
                                    </div>
                                  
                                    <div class="row"  style="background: #eee; padding: 0 10px;" v-if="computingTheBill.processing_fee">
                                        <div class="col-md-6">
                                            <b style="color: red;">Processing fee:</b>
                                        </div>
                                        <div class="col-md-3" style="text-align: right;">
                                            <b style="color: red;">$</b>
                                        </div>
                                        <div class="col-md-3" style="text-align: right; color: red;">
                                            {{ roundMoney(computingTheBill.processing_fee).toFixed(2) }}
                                        </div>
                                    </div>
                                    <div class="row"  style="background: #eee; padding: 0 10px;" v-if="computingTheBill.amount_due">
                                        <div class="col-md-6">
                                            <b>Amount due:</b>
                                        </div>
                                        <div class="col-md-3" style="text-align: right;">
                                            <b>$</b>
                                        </div>
                                        <div class="col-md-3" style="text-align: right;">
                                            {{ roundMoney(computingTheBill.amount_due).toFixed(2) }}
                                        </div>
                                    </div>
                                </div>
                                <h3 style="font-size: 20px; font-weight: 700; margin-top: 20px;">Process Your Payment</h3>
                                <p class="note">Note: Credit card payments include a processing fee.</p>
                                <b-field label="How would you like to pay?">
                                    <b-select v-model="payment_type_selected">
                                        <option :value="'card'">Credit Card (Secured)</option>
                                        <option :value="'emt'">EMT</option>
                                        <option :value="'cheque'">Cheque</option>
                                    </b-select>
                                </b-field>
                                <div class="payment-details-box" v-if="payment_type_selected === 'card'">
                                <div class="payment-processor-box">
                                    <div class="row">
                                        <div :class="[{ 'col-md-7': previously_used_cards.length > 0 }, { 'col-md-12': previously_used_cards.length === 0}]" v-if="!previous_selected_card">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-field label="Card Number (16 or 17 digits)">
                                                        <b-input v-model="selected_card_details.card_number"></b-input>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <div class="row" style="margin-top: 10px;">
                                                <div class="col-md-5">
                                                    <b-field label="Month">
                                                        <b-select v-model="selected_card_details.month">
                                                            <option 
                                                                v-for="(month, key) in months"
                                                                :value="month.value"
                                                                :key="key">
                                                                {{ month.date }}
                                                            </option>
                                                        </b-select>
                                                    </b-field>
                                                </div>
                                                <div class="col-md-5">
                                                    <b-field label="Year">
                                                        <b-select v-model="selected_card_details.year">
                                                            <option 
                                                                v-for="(year, key) in years"
                                                                :value="year"
                                                                :key="key">
                                                                {{ year }}
                                                            </option>
                                                        </b-select>
                                                    </b-field>
                                                </div>
                                                <div class="col-md-2">
                                                    <b-field label="CVC">
                                                        <b-input v-model="selected_card_details.cvc"></b-input>
                                                    </b-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-5" v-if="previously_used_cards.length > 0">
                                            <div class="previously-saved-cards" >
                                                <p style="font-size: 10px; margin-bottom: 10px; border-bottom: 1px solid #ddd;"><b>PREVIOUSLY USED</b></p>
                                                <b-field :key="key" v-for="(card, key) in previously_used_cards">
                                                    <b-radio 
                                                        v-model="previous_selected_card"
                                                        :native-value="card"
                                                        >
                                                        {{ card.brand }} <span style="font-size: 10px;"><b>ends with {{ card.last4 }}</b></span>
                                                    </b-radio>
                                                </b-field>
                                                <b-field>
                                                    <b-radio 
                                                        v-model="previous_selected_card"
                                                        :native-value="null"
                                                        >
                                                        New Card</span>
                                                    </b-radio>
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="payment-details-box" v-if="payment_type_selected === 'emt'">
                                <p class="note">Please save the below details and EMT us as soon as possible. We cannot fully process this until funds are received.</p>
                                <p><b>Please send payment to: </b>payspn@slo-pitch.com</p>
                                <p>When sending an e-transfer please use the password SPN and the current year. Like this: SPN2023 OR send the password to Tara!</p>

                                <b-field label="What email will the EMT come from?">
                                    <b-input v-model="emt_payment_email"></b-input>
                                </b-field>
                            </div>
                            <div class="payment-details-box" v-if="payment_type_selected === 'cheque'">
                                <p class="note">Please save the below details and send us the cheque as soon as possible. We cannot fully process this until funds are received.</p>
                                <p><b>Please make all cheques out to:</b> Slo-Pitch National</p>
                                <p v-if="tournament.region_id === 1"><b>Please mail or deliver your cheque to:</b> #115, 1925 – 18 Avenue NE Calgary AB T2E-7T8</p>
                                <p v-else-if="tournament.region_id === 2"><b>Please mail or deliver your cheque to:</b> 2581 Spring Bank Avenue, Merritt BC V1K 1S1</p>
                                <p v-else-if="tournament.region_id === 3"><b>Please mail or deliver your cheque to:</b> 20 De La Seigneurie Boulevard Winnipeg, MB R3X 0E9</p>
                                <p v-else-if="tournament.region_id === 5"><b>Please mail or deliver your cheque to:</b> 1163 Beaver Bank Road Beaver Bank, NS B4G 1B1</p>
                                <p v-else-if="tournament.region_id === 6"><b>Please mail or deliver your cheque to:</b> 1840 Route 222, St Denis de Brompton, QC J0B 2P0</p>
                                <p v-else-if="tournament.region_id === 7"><b>Please mail or deliver your cheque to:</b> 26 Castors Drive Mount Pearl, NL A1N 4Y7</p>
                                <p v-else-if="tournament.region_id === 8"><b>Please mail or deliver your cheque to:</b> 538 Highland Terrace Saskatoon, SK S7H 4Y6</p>
                                <p v-else><b>Please mail or deliver your cheque to:</b> 9-20 Lightbeam Terrace, Brampton ON L6Y 6H9</p>

                                <b-field label="How do you plan to handle the cheque?">
                                    <b-select v-model="cheque_tracking">
                                        <option value="mail">I will mail it</option>
                                    </b-select>
                                </b-field>
                            </div>
                        
                            <a class="admin-button" style="margin-top: 20px;" v-on:click="submitPaymentAttempt()" v-if="awaiting_response_from_server === false"><i class="fas fa-save" ></i> Submit Payment</a>   
                            </div>
                        </div>
                        <div v-if="step.label === 'Check for Membership'">
                            <div style="margin: 0 auto; max-width: 600px;">
                                <div class="row" style="margin-top: 10px;">
                                    <div class="col-md-4">
                                        <b-field label="Select Your Team">
                                            <b-select v-if="teams" v-model="selectedTeam" placeholder="Select your team">
                                                <option :value="team" :key="key" v-for="(team,key) in teams.coach">{{ team.team_name }}</option>
                                                <option :value="team" :key="key" v-for="(team,key) in teams.team_contact">{{ team.team_name }}</option>
                                            </b-select>
                                        </b-field>
                                    </div>
                                    <div class="col-md-6">
                                        <b-field label="Enter a Division" vertical>
                                            <b-select placeholder="Select Gender" v-model="registerForTournament.gender">
                                                <option value="mens" v-if="totalDivisions.mens.length > 0">Mens</option>
                                                <option value="ladies" v-if="totalDivisions.ladies.length > 0">Ladies</option>
                                                <option value="coed" v-if="totalDivisions.coed.length > 0">Coed</option>
                                            </b-select>
                                            
                                            <b-select style="margin-left: 10px;" placeholder="Select Division" v-model="registerForTournament.division">
                                                <option :value="division" :key="division.id" v-for="division in totalDivisions[registerForTournament.gender]">{{ division.rank_id }}</option>
                                            </b-select>
                                        </b-field>
                                        
                                    </div>
                                </div>
                                <div v-if="spotAvailableForTeamRegistration">
                                    <div v-if="selectedTeam !== null && registerForTournament.gender !== null && registerForTournament.division !== null">
                                    
                                        <div class="spn-number-box does-not-have-spn-number" v-if="spnRegistration === false || spnRegistration === 'upgrade'">
                                            <h4 style="margin-top: 0px; font-weight: 700;">Your SPN4You Membership & Insurance</h4>
                                            <p>You must have an SPN4You Membership for each gendered division your team enters. So after this payment, you will be able to play any {{ registerForTournament.gender }} division in future tournaments this year.</p>
                                            
                                            <div class="row">
                                                <div class="col-md-4" :key="key" v-for="(option, key) in regionRateOptions">
                                                    <div
                                                        v-if="paymentSuccess === false"
                                                        v-on:click="selectInsuranceOption(option)" :class="['selection-box', { 'selected': option === selectedInsuranceOption }]">
                                                        <div class="select-box-header">{{ option.name }}</div>
                                                        <div class="select-box-price">${{ option.with_insurance === 1 ? (option.price + 28.50).toFixed(2) : option.price.toFixed(2) }} + TAX & FEES</div>
                                                    </div>
                                                    <div
                                                        v-else
                                                        :class="['selection-box', { 'selected': option === selectedInsuranceOption }]">
                                                        <div class="select-box-header">{{ option.name }}</div>
                                                        <div class="select-box-price">${{ option.with_insurance === 1 ? (option.price + 28.50).toFixed(2) : (option.price).toFixed(2) }} + TAX & FEES</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="selectedInsuranceOption">
                                                
                                                <NewPaymentProcessor
                                                    :productId="selectedInsuranceOption.id"
                                                    :regionId="region_rate.id"
                                                    :tournamentName="tournament.name"
                                                    v-on:payment:successful="insurancePaymentSuccess($event)"
                                                    :teamName="selectedTeam.name"
                                                    >
                                                </NewPaymentProcessor>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p>You already have purchased insurance for this team. Press next to continue to the next step.</p>
                                            
                                            <a v-on:click="hasInsuranceNextStep()" class="admin-button">Next Step</a>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="spotAvailableForTeamRegistration === false">
                                    <b-message type="is-danger">There are no spots available, please go back to the tournament page and reach out to the convenor.</b-message>
                                    <a :href="'/tournament/' + tournament.slug" class="admin-button">Go Back</a>
                                </div>

                            </div>
                        </div>
                        <div v-if="step.label === 'Select Roster Members'">
                            <div class="players-selected">
                                <p style="text-align: center;"><b>{{ selectedRosterPlayers.length }} Players Selected</b></p>
                            </div>
                            <!-- <b-message type="is-danger" ><b>MAKE SURE THIS PAGE IS COMPLETED AND SUBMITTED USING THE BUTTON AT THE BOTTOM TO COMPLETE YOUR REGISTRATION.</b></b-message> -->
                            <b-message type="is-danger" v-if="tournament.region_id === 4">You must have a minimum of 10 players on your roster upon submission. Rosters are locked after completion of this form for Provincial and National events. <b>Please mark coaches who are not playing as coach only as well as selecting them.</b></b-message>
                            <div v-if="team_roster.length > 0">
                                <b-table
                                    :data="filterRoster(team_roster)"
                                    checkable
                                    :checked-rows.sync="selectedRosterPlayers"
                                    ref="table">

                                    <b-table-column
                                        field="coach_only"
                                        label="Coach Only">
                                        <template v-slot="props">
                                            <b-checkbox v-model="props.row.coach_only"></b-checkbox>
                                        </template>
                                    </b-table-column>

                                    <b-table-column
                                        field="gender"
                                        label="Gender">
                                        <template v-slot="props">
                                            <span v-if="props.row.user.gender === 'Male'">
                                                <i class="fas fa-mars"></i>
                                            </span>
                                            <span v-if="props.row.user.gender === 'Female'">
                                                <i class="fas fa-venus"></i>
                                            </span>
                                        </template>
                                    </b-table-column>

                                    <b-table-column
                                        field="first_name"
                                        label="First Name">
                                        <template v-slot="props">
                                            {{ props.row.user.first_name }}
                                        </template>
                                    </b-table-column>
                                    
                                    <b-table-column
                                        field="last_name"
                                        label="Last Name">
                                        <template v-slot="props">
                                            {{ props.row.user.last_name }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column
                                        field="city"
                                        label="City">
                                        <template v-slot="props">
                                            {{ props.row.user.city }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column
                                        field="correct_age"
                                        label="">
                                        <template v-slot="props">
                                            <span v-if="props.row.user.correct_age === false">
                                                Player does not fulfill the age restriction.
                                            </span>
                                        </template>
                                    </b-table-column>
                                </b-table>
                            </div>
                            <div class="players-selected">
                                <p style="text-align: center;"><b>{{ selectedRosterPlayers.length }} Players Selected</b></p>
                            </div>
                

                            <b-message type="is-danger" has-icon aria-close-label="Close message" v-if="overLimit">
                                Your roster is over the max roster size limit. You may be asked to remove players by the convenor of the event.
                            </b-message>
                            <b-message type="is-danger" has-icon aria-close-label="Close message" v-if="wrong_age === true">
                                Someone on your roster is not in the age group for this division and needs to be removed.
                            </b-message>

                            <div v-if="tournament.region_id === 4">
                                <a v-if="selectedRosterPlayers.length > 9" v-on:click="goToPayForEvent()" class="admin-button">Save Roster & Register to Event</a>
                            </div>
                            <div v-else>
                                <a v-if="selectedRosterPlayers.length > 0" v-on:click="goToPayForEvent()" class="admin-button">Save Roster & Register to Event</a>
                            </div>
                        </div>
                        <div v-if="step.label === 'Completed'"> 
                            <div style="max-width: 600px; margin: 0 auto; text-align: center;">
                                <p><b>You have completed your registration to this event! Please check your account under "My Leagues & Tournaments Rosters to view your team roster, and other various information about the event!</b></p>

                                <a href="/dashboard/players/events-registered-in" class="admin-button">Go There</a>
                            </div>
                        </div>

                    </b-step-item>

                    <template
                        #navigation="{previous, next}">
                        <div></div>
                        <!-- <b-button
                            outlined
                            type="is-danger"
                            icon-pack="fas"
                            icon-left="backward"
                            :disabled="previous.disabled"
                            @click.prevent="previous.action">
                            Previous
                        </b-button>
                        <b-button
                            outlined
                            type="is-success"
                            icon-pack="fas"
                            icon-right="forward"
                            :disabled="next.disabled"
                            @click.prevent="next.action">
                            Next
                        </b-button> -->
                    </template>
                </b-steps>
            </div>
        </div>
		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import Header from './components/UmpireHeader.vue'
import Footer from './components/UmpireFooter.vue'
import moment from 'moment'
import LoginRegister from '../components/LoginRegister.vue'
import roundMoney from 'round-money'
import NewPaymentProcessor from '../components/NewPaymentProcessor.vue'
var getAge = require('get-age')
import _ from 'lodash'
export default {
    metaInfo() {
        if(!this.tournament) {
            return {}
        }
		var tournament_poster_image = 'https://slo-pitch.com/uploads/sanctionedtournament.png'
		if(this.tournament.poster_image_url) {
			tournament_poster_image = 'https://slo-pitch.com/' + (this.tournament.poster_image_url)
		}

        return {
			title: this.tournament.name + " Tournament - Register Now! Limited Spots! - Slo-Pitch National",
			meta: [
				{ name: 'description', content:  this.tournament.description },
				{ property: 'og:url', content:  'https://www.slo-pitch.com/tournament/' + this.tournament.slug },
				{ property: 'og:title', content: this.tournament.name + " Tournament - Register Now! Limited Spots! - Slo-Pitch National"},
				{ name: 'og:description', content:  this.tournament.description },
				{ property: 'og:site_name', content: 'Slo-Pitch National'},
				{ property: 'og:image', content: tournament_poster_image },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
            tournament: null,
			activeStep: 0,
            authUser: null,
            team_roster: [],
            overLimit: false,
            wrong_age: false,
            selectedRosterPlayers: [],
            previously_used_cards: [],
            page_url: null,
            event_order: null,
            emt_payment_email: null,
            paymentSuccess: false,
            cheque_tracking: null,
            region_rate: null,
            currentInsurance: null,
            triggerFinalize: false,
            selectedInsuranceOption: null,
            previous_selected_card: null,
            awaiting_response_from_server: false,
            payment_complete: false,
            spotAvailableForTeamRegistration: null,
            registerForTournament: {
                team: null,
                gender: null,
                division: null
            },
            selected_card_details: {
                card_number: null,
                month: null,
                year: null,
                cvc: null
            },
            payment_type_selected: null,
            selectedTeam: null,
			en: true,
            teams: null,
            checkIfNeedsUpgrade: false,
            steps: [{
                label: 'Login to SPN'
            },{
                label: 'Check for Membership'
            },{
                label: 'Select Roster Members'
            }, {
                label: 'Completed'
            }]
		};
	},
	components: {
		Header,
		Footer,
		NewPaymentProcessor,
		LoginRegister,
		
	},
    watch: {
        selectedRosterPlayers: function($event) {
            var vm = this
            var min_age = vm.registerForTournament.division.min_age

            var wrong_age = false
            var i = 0

            for(i; i < vm.selectedRosterPlayers.length; i++) {
                var user = vm.selectedRosterPlayers[i].user
                var date_of_birth = user.date_of_birth

                var format_to_first_of_year = moment(date_of_birth).format('YYYY') + '-01-01'
                var their_age = getAge(format_to_first_of_year)
                if(their_age < min_age) {

                    vm.selectedRosterPlayers[i].user.correct_age = false

                    wrong_age = true
                }
            }
            
            if(wrong_age === true) {
                vm.wrong_age = true
            } else {
                vm.wrong_age = false
            }


            if($event.length > vm.tournament.max_roster_size) {
                
                this.$buefy.snackbar.open({
                    message: 'This puts your roster over the max players limit',
                    type: 'is-danger', // is-danger
                    duration: 4000,
                    queue: false
                })

                vm.overLimit = true
            } else {
                vm.overLimit = false
            }
        },
        selectedTeam: function($event) {
            var vm = this

            var slug = $event.team_slug

            axios.get('/api/rosters/grab-roster/' + slug).then(results => {
                if(results.data.success === true) {
                    vm.team_roster = results.data.team.core_roster
                    var i = 0

                    for (i; i < vm.team_roster.length; i++) {
                        vm.team_roster[i].user.correct_age = true
                    }
                }
            })

            axios.get(`/api/users/check-for-single-event-validity/${$event.team_id}`).then(results => {
                if(results.data.second_event === 1) {
                    vm.checkIfNeedsUpgrade = true
                }
                if(results.data.second_event === 0) {
                    vm.checkIfNeedsUpgrade = false
                }
            })
        },
        registerForTournament: {
            deep: true,
            handler(value) {

                var vm = this
                if(value.division) {
                    if(this.registerForTournament.gender !== value.division.gender_slug) {
                        this.registerForTournament.division = null

                    }
                }

                if(this.registerForTournament.division && this.registerForTournament.gender) {

                    // now, check axios for number of spots available in the division

                    axios.post('/api/users/check-for-spots-remaining', {
                        registerForTournament: vm.registerForTournament
                    }).then(results => {
                        if(results.data.success === true) {
                            
                            vm.spotAvailableForTeamRegistration = true
                        } else {
                            // there is no spots available
                            
                            vm.spotAvailableForTeamRegistration = false
                        }
                    })
                } else {
                    
                }
            }
        }
    },
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }

        
        var vm = this
		vm.page_url = this.$route.params.page   
		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                vm.activeStep = vm.activeStep + 1
                
                axios.get('/api/payments/previously-used-cards').then(results => {
                    if(results.data.success === true) { vm.previously_used_cards = results.data.cards }
                })

                

                axios.post('/api/users/region-rate', {
                    region: vm.authUser.default_region_id
                }).then(results => {
                    if(results.data.success === true) {
                        vm.region_rate = results.data.region_rate
                        
                    }
                })
            }
        })

        // axios.get('/api/users/teams').then(results => {
        //     if(results.data.type === 'success') {
        //         vm.teams = results.data.data.teams
        //     }
        // })


        axios.get('/api/pages/grab-tournament/' + vm.page_url).then(results => {
            if(results.data.success === true) {
                vm.tournament = results.data.tournament

                // axios.post('/api/users/region-rate', {
                //     region: vm.tournament.region_id
                // }).then(results => {
                //     if(results.data.success === true) {
                //         vm.region_rate = results.data.region_rate
                        
                //     }
                // })

                axios.get('/api/users/teams').then(results => {
                    if(results.data.type === 'success') {
                        vm.teams = results.data.data.teams
                        axios.get(`/api/tournaments/get-registered-teams/${vm.page_url}`).then(results => {
                            
                            var keys = Object.keys(vm.teams)
                            
                            var registered_teams = results.data.registrants
                            
                            
                            var teams_array = {
                                coach: [],
                                team_contact: []
                            }
                            var i = 0
                            for(i; i < keys.length; i++) {
                                var key = keys[i]
                                
                                for(const final_team of vm.teams[key]) {
                                    var find = registered_teams.find(registered_team => {
                                        
                                        return registered_team.team_id === final_team.team_id
                                    })
                                    if(typeof find === 'undefined') {
                                        teams_array[key].push(final_team)
                                    }
                                }
                            }
                            vm.teams = teams_array
                        })
                    }
                })

                if(vm.tournament.status === 'provincials' && vm.tournament.team_registration_fee > 0) {
                    vm.steps =  [{
                        label: 'Login to SPN'
                    },{
                        label: 'Check for Membership'
                    },{
                        label: 'Select Roster Members'
                    },{
                        label: 'Pay for Event'
                    }, {
                        label: 'Completed'
                    }]
                }
                if(vm.tournament.status === 'nationals' && vm.tournament.team_registration_fee > 0) {
                    vm.steps =  [{
                        label: 'Login to SPN'
                    },{
                        label: 'Check for Membership'
                    },{
                        label: 'Select Roster Members'
                    },{
                        label: 'Pay for Event'
                    }, {
                        label: 'Completed'
                    }]
                }
            }
        })

		// 		vm.tournament = results.data.tournament

		// 		if(vm.tournament.status === 'provincials') {
		// 			vm.provincialsOrNationals = true

		// 		}
		// 		if(vm.tournament.status === 'nationals') {
		// 			vm.provincialsOrNationals = true
		// 		}

		// 		if(vm.tournament.status === 'rejected') {
		// 			this.$buefy.snackbar.open({
		// 				message: 'This tournament is not approved yet.',
		// 				type: 'is-danger' // is-danger
		// 			})
		// 			window.location.href = '/'
		// 		}
		// 		if(vm.tournament.status === 'unapproved') {
		// 			this.$buefy.snackbar.open({
		// 				message: 'This tournament is not approved yet.',
		// 				type: 'is-danger' // is-danger
		// 			})
		// 			window.location.href = '/'
		// 		}
        //     } else {
		// 		this.$buefy.snackbar.open({
		// 			message: 'This tournament does not exist.',
		// 			type: 'is-danger' // is-danger
		// 		})
		// 		window.location.href = '/'
				
		// 	}
        // })
	},
	computed: {
        
        spnRegistration: function() {
            // grab the registrations of insurance from the team
            // cross reference the division
            
            var vm = this

            var team = vm.selectedTeam

            var selected_gender = vm.registerForTournament.gender
            if(team) {   
                           
                var insurance = team.insurance

                var year = moment().format('YYYY')
                
                var find_insurance = _.find(insurance, function(paper) {
                    if(paper.gender === selected_gender && paper.season == year) {
                        return true
                    }
                })

                // check validity of the insurance that was found (must be 2020, must not be single use - if it's single use it means it's been used already (because they signed up and it was single use))
                // if a single use one is found, it must be upgraded
                // when updated it will remove single use from the row

                // code in the upgradable pricing
                if(find_insurance) {
                    vm.currentInsurance = find_insurance
                    
                    if(find_insurance.single_event !== 1) {
                        vm.triggerFinalize = true
                        return 'fully_insured'
                    } else {
                        if(vm.checkIfNeedsUpgrade === true) {
                            return 'upgrade'
                        } else {
                            return 'fully_insured'
                        }
                    }
                }  else {
                    vm.currentInsurance = null
                    vm.triggerFinalize = false
                    return false
                }
                
            } else {
                vm.currentInsurance = null
                vm.triggerFinalize = false
                return false
            }
        },
        regionRateOptions: function() {
            var vm = this

            var array = []
            if(vm.region_rate) {
                if(vm.spnRegistration === false) {
                    if(vm.region_rate.rate.single_use_tournament_team !== 0) {
                        if(vm.tournament.status !== 'nationals') {
                            if(vm.tournament.region_id === 4) {
                                array.push({
                                    name: 'Play One Time',
                                    price: vm.region_rate.rate.single_use_tournament_team,
                                    with_insurance: null,
                                    id: 8
                                })
                            }
                        }
                    }
                    if(vm.region_rate.rate.un_insured_tournament_team !== 0) {
                        if(vm.tournament.status !== 'nationals') {
                            array.push({
                                name: 'Uninsured Tournament Team',
                                price: vm.region_rate.rate.un_insured_tournament_team,
                                with_insurance: null,
                                id: 7
                            })
                        }
                    }
                    if(vm.region_rate.rate.fully_insured_tournament_team !== 0) {
                        array.push({
                            name: 'Fully Insured Tournament Team',
                            price: vm.region_rate.rate.fully_insured_tournament_team,
                            with_insurance: 1,
                            id: 6
                        })
                    }
                }
                if(vm.spnRegistration === 'upgrade') {
                    if(vm.region_rate.rate.upgrade_to_un_insured_tournament_team !== 0) {
                        if(vm.tournament.status !== 'nationals') {
                            array.push({
                                name: 'Upgrade - Uninsured Tournament Team',
                                price: vm.region_rate.rate.upgrade_to_un_insured_tournament_team,
                                id: 11
                            })
                        }
                    }
                    if(vm.region_rate.rate.upgrade_to_fully_insured_tournament_team !== 0) {
                        array.push({
                            name: 'Upgrade - Fully Insured Tournament Team',
                            price: vm.region_rate.rate.upgrade_to_fully_insured_tournament_team,
                            with_insurance: 1,
                            id: 10
                        })
                    }
                }
            }

            return array
        },
        months: function() {
            var i = 1
            var months = []
            for(i; i < 13; i++) {
                var date = moment(i, 'M').format('MMMM')
                months.push({
                    date: date,
                    value: i
                })
            }
            return months
        },
        years: function() {
            var i = parseInt(moment().format('YYYY'))
            var final = parseInt(i) + 10
            var years = []
            for(i; i < final; i++) {
                years.push(i)
            }
            return years
        },
        totalDivisions: function() {
            var vm = this
            var mens = vm.filterDivision(vm.tournament.divisions, 'mens')
            var ladies = vm.filterDivision(vm.tournament.divisions, 'ladies')
            var coed = vm.filterDivision(vm.tournament.divisions, 'coed')
            
            var count = 0
            if(mens.length > 0) {
                count = count + 1
            } 
            if(ladies.length > 0) {
                count = count + 1
            }
            if(coed.length > 0) {
                count = count + 1
            }

            return {
                mens: mens,
                ladies: ladies,
                coed: coed,
                count: count
            }
        },
        computingTheBill: function() {
            var vm = this

            var cart = {
                total: this.tournament.team_registration_fee,
                hst_amount: 0,
                processing_fee: 0,
                amount_due: this.tournament.team_registration_fee
            }
            if(vm.tournament.status === 'provincials') {
                if(vm.tournament.region_id === 4) {
                    if(vm.currentInsurance) {
                        // flip from null to 1 if wrong - this is if the insurance is from a tournament
                        if(vm.currentInsurance.from_league === 1) {
                            cart.total = cart.total - 25
                            cart.amount_due = cart.amount_due - 25
                        }

                        
                    }
                }
            }

            if(vm.payment_type_selected === 'card') {
                var new_amount_due = (cart.total + 0.30) / (1 - 2.9 / 100)
                var old_amount_due = cart.total
                cart.processing_fee = roundMoney(new_amount_due - old_amount_due)
                cart.amount_due = roundMoney(new_amount_due)
            }
            
            if(vm.tournament.region_id === 4) {
                cart.hst_amount = cart.total * 0.13
                cart.amount_due = cart.total + cart.hst_amount + cart.processing_fee
            }

            if(vm.tournament.status === 'nationals') {
                cart.hst_amount = cart.total * 0.13
                if(vm.tournament.region_id === 1) {
                    cart.hst_amount = cart.total * 0.05
                }
                if(vm.tournament.region_id === 2) {
                    cart.hst_amount = cart.total * 0.05
                }
                if(vm.tournament.region_id === 3) {
                    cart.hst_amount = cart.total * 0.05
                }
                if(vm.tournament.region_id === 5) {
                    cart.hst_amount = cart.total * 0.15
                }
                if(vm.tournament.region_id === 6) {
                    cart.hst_amount = cart.total * 0.05
                }
                
                if(vm.tournament.region_id === 7) {
                    cart.hst_amount = cart.total * 0.15
                }
                if(vm.tournament.region_id === 8) {
                    cart.hst_amount = cart.total * 0.05
                }
                
                
                cart.amount_due = cart.total + cart.hst_amount + cart.processing_fee
            }

            return cart
        }
	},
	methods: {
	
        moment: function(date, format = null) {
            return moment(date, format)
		},
		filterDivision: function(divisions, filter) {
			var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
		},
	
		registrationComplete: function($event) {
			var vm = this
			vm.buttonSelected = null
		},
        triggerAuth: function(event) {
            var vm = this
            axios.get('/auth/check').then(results => {  
                if(results.data.type === "success") {
                    vm.authUser = results.data.user
                    vm.activeStep = vm.activeStep + 1

                    axios.get('/api/payments/previously-used-cards').then(results => {
                        if(results.data.success === true) { vm.previously_used_cards = results.data.cards }
                    })

                    axios.get('/api/users/teams').then(results => {
                        if(results.data.type === 'success') {
                            vm.teams = results.data.data.teams
                            
                            axios.get(`/api/tournaments/get-registered-teams/${vm.page_url}`).then(results => {
                                
                                var keys = Object.keys(vm.teams)
                                
                                var registered_teams = results.data.registrants
                                
                                
                                
                                var teams_array = {
                                    coach: [],
                                    team_contact: []
                                }
                                var i = 0
                                for(i; i < keys.length; i++) {
                                    var key = keys[i]
                                    
                                    for(const final_team of vm.teams[key]) {
                                        var find = registered_teams.find(registered_team => {
                                            
                                            return registered_team.team_id === final_team.team_id
                                        })
                                        
                                        if(!find) {
                                            
                                            teams_array[key].push(find)
                                        } 
                                    }
                                }
                                vm.teams = teams_array
                            })
                        }
                    })

                    axios.post('/api/users/region-rate', {
                        region: vm.authUser.default_region_id
                    }).then(results => {
                        if(results.data.success === true) {
                            vm.region_rate = results.data.region_rate
                            
                        }
                    })
                }
            })
        },
        roundMoney: function(item) {
            return roundMoney(item)
        },
        submitPaymentAttempt: function() {
            // submit to the payment processing server

            // store in orders under a new product and attach the tournament ID, region, etc

            // move to the next step when completed successfully
            

             var vm = this
            // send in computingTheBill, if done effectively this should have everything i need

            vm.awaiting_response_from_server = true
        

            if(vm.payment_type_selected === null && vm.computingTheBill.amount_due > 0) {
                vm.$buefy.snackbar.open({
                    message: 'Please select a method of payment!',
                    type: 'is-danger' // is-danger
                })
                vm.awaiting_response_from_server = false   
            } else {
                axios.post('/api/payments/tournament-payment', {
                    computed_bill: vm.computingTheBill,
                    payment_type_selected: vm.payment_type_selected,
                    selected_card_details: vm.selected_card_details,
                    previous_selected_card: vm.previous_selected_card,
                    emt_payment_email: vm.emt_payment_email,
                    cheque_tracking: vm.cheque_tracking,
                    tournament: vm.tournament.id,
                    team: vm.selectedTeam.team_id,
                    user_id: vm.authUser.id
                }).then(results => {
                    if(results.data.success === true) {
                        vm.event_order = results.data.order
                        vm.payment_complete = true

                        vm.$buefy.snackbar.open({
                            message: 'Payment submission was successful!',
                            type: 'is-success' // is-danger
                        })

                        this.saveRoster()

                        // trigger an emit to the level up that it's done

                        vm.activeStep = vm.activeStep + 1
                    } else {
                        vm.payment_complete = false
                        vm.awaiting_response_from_server = false
                        vm.$buefy.snackbar.open({
                            message: 'Payment submission was successful!',
                            type: 'is-success' // is-danger
                        })
                    }
                })
            }
        },
        selectInsuranceOption: function(option) {
            var vm = this
            vm.selectedInsuranceOption = option

            
        },
        insurancePaymentSuccess: async function($event) {

            var vm = this
            if($event.order) {
                
                 var test_insurance = await axios.post('/api/users/assign-insurance-on-purchase', {
                    info: $event,
                    insurance: vm.selectedInsuranceOption,
                    team: vm.selectedTeam.team_id,
                    gender: vm.registerForTournament.gender
                })

                vm.paymentSuccess = $event
                
                vm.activeStep = vm.activeStep + 1
                vm.insuranceOrder = $event.order
            }
        },
         hasInsuranceNextStep: function() {
            var vm = this
            vm.activeStep = vm.activeStep + 1
        },
        filterRoster: function(roster) {
            // cannot have ladies in mens, cannot have mens in ladies

            var vm = this

            var roster = vm.team_roster

            // if(vm.registerForTournament.gender === 'mens') {
            //     roster = _.filter(roster, function(player) {
            //         if(player.user.gender === 'Male') {
            //             return true
            //         }
            //     })
            // }
            // if(vm.registerForTournament.gender === 'ladies') {
            //     roster = _.filter(roster, function(player) {
            //         if(player.user.gender === 'Female') {
            //             return true
            //         }
            //     })
            // }

            return roster
        },
        goToPayForEvent: function() {
            var vm = this

            if(vm.tournament.status === 'provincials' && vm.tournament.team_registration_fee === 0) {
                this.saveRoster()
            }
            if(vm.tournament.status === 'nationals' && vm.tournament.team_registration_fee === 0) {
                this.saveRoster()
            }
            if(vm.tournament.status === 'provincials' && vm.tournament.team_registration_fee === null) {
                this.saveRoster()
            }
            if(vm.tournament.status === 'nationals' && vm.tournament.team_registration_fee === null) {
                this.saveRoster()
            }
            if(vm.tournament.status === 'sanctioned') {
                this.saveRoster()
            }

            vm.activeStep = vm.activeStep + 1
        },
        saveRoster: async function() {
            var vm = this
            var order_id = null
            
            if(vm.paymentSuccess) {
                order_id = vm.paymentSuccess.order.id
            }
            
            
            var current_insurance_id = null
            if(vm.currentInsurance) {
                current_insurance_id = vm.currentInsurance.id
            } 

            
            var selected_roster_players = []

            if(vm.selectedRosterPlayers) {
                var s = 0

                for(s; s < vm.selectedRosterPlayers.length; s++) {
                    var selected_player = vm.selectedRosterPlayers[s]

                    selected_roster_players.push({
                        id: selected_player.id,
                        team_id: selected_player.team_id,
                        user_id: selected_player.user_id,
                        role_on_team: selected_player.role_on_team,
                        coach_only: selected_player.coach_only
                    })
                }
            }

            var event_order_id = null

            if(vm.event_order) {
                event_order_id  = vm.event_order.id
            }

            
            // save and store this roster, create an event roster, etc.
            axios.post('/api/users/register-for-tournament', {
                team_id: vm.selectedTeam.team_id,
                roster: selected_roster_players,
                gender_id: vm.registerForTournament.gender,
                division_id: vm.registerForTournament.division.id,
                tournament_id: vm.tournament.id,
                insurance_id: current_insurance_id,
                order: event_order_id
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'You have successfully registered for this tournament!',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })

                    vm.activeStep = vm.activeStep + 1

                    this.$emit('register:complete')
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Registration failed. Please review that all information is submitted correctly and try again. If this persists, contact us.',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            }).catch(err => {
                this.$buefy.snackbar.open({
                    message: 'Registration failed. Please review that all information is submitted correctly and try again. If this persists, contact us.',
                    type: 'is-danger', // is-danger
                    duration: 4000,
                    queue: false
                })
            })
        }
		
	},
   
    
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
