<template>

    <div style="max-width: 600px;">
        <b-loading v-model="isLoading"></b-loading>
        
        <b-tabs expanded 
            v-model="opening"
            >
            <b-tab-item value="Login" :label="(en === true ? 'Login' : 'Connexion')">
                
                <b-tabs
                    expanded>
                    <b-tab-item 
                        :label="(en === true ? 'By Email' : 'Par Votre Courriel')"
                        icon="at"
                        iconPack="fa">
                        <b-field :label="(en === true ? 'Enter Your Email Address' : 'Votre Adresse Courriel')">
                            <b-input v-model="email" v-on:keyup.enter.native="loginToAccount()"></b-input>
                        </b-field>
                        <b-field :label="(en === true ? 'Password' : 'Nouveau Mot de Passe')">
                            <b-input type="password" v-model="password" password-reveal v-on:keyup.enter.native="loginToAccount()"></b-input>
                        </b-field>

                        <b-field :label="(en === true ? 'Remember Me' : 'Se souvenir de moi')">
                            <b-checkbox v-model="rememberMe"></b-checkbox>
                        </b-field>
                    </b-tab-item>
                </b-tabs>
                <div style="text-align: center;">
                    <span v-if="error"><b>{{ error }}</b></span>
                    <br><br>
                    <a class="admin-button" style="margin: 0 auto; display: inline-block;" v-on:click="loginToAccount()">Login</a>
                </div>
            </b-tab-item>
            <b-tab-item value="Register" :label="(en === true ? 'Register' : `S'inscrire`)">
                <div v-if="registerComplete === false">
                    <div class="first-page-registration" v-if="nowRegistered === false">
                        <p class="note" v-if="en === true">Do not create a new account if you've already had one (with our old or new system) - please reset password or contact us using the chat bubble in the corner.</p>
                        <p class="note" v-if="en === false">Ne créez pas de nouveau compte si vous en avez déjà eu un (avec notre ancien ou notre nouveau système) - veuillez réinitialiser le mot de passe ou nous contacter en utilisant la bulle de discussion dans le coin.</p>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="padding">
                                    <b-field :label="(en === true ? 'First Name*' : 'Prénom*')">
                                        <b-input v-model="registerInfo.first_name"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="padding">
                                    <b-field :label="(en === true ? 'Last Name*' : 'Nom de famille*')">
                                        <b-input v-model="registerInfo.last_name"></b-input>
                                    </b-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="padding">
                                    <b-field :label="(en === true ? 'Email Address*' : 'Votre email*')">
                                        <b-input v-model="registerInfo.email"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="padding">
                                    <b-field :label="(en === true ? 'Confirm Email*' : 'Confirmez votre email*')">
                                        <b-input v-model="registerInfo.confirm_email"></b-input>
                                    </b-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="padding">
                                    <b-field :label="(en === true ? 'Password*' : 'Le mot de passe*')">
                                        <b-input type="password" v-model="registerInfo.password"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="padding">
                                    <b-field :label="(en === true ? 'Confirm Password*' : 'Confirmez le mot de passe*')">
                                        <b-input type="password" v-model="registerInfo.confirm_password"></b-input>
                                    </b-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="padding">
                                    <b-field :label="(en === true ? 'Date of Birth*' : 'Date de naissance*')">
                                        <b-datepicker v-model="registerInfo.date_of_birth"></b-datepicker>
                                    </b-field>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="padding">
                                    <b-field :label="(en === true ? 'Gender*' : 'Le genre*')">
                                        <b-select v-model="registerInfo.gender">
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Not Disclosed">Prefer Not To Disclose</option>
                                            <option value="Self Identify As">Self Identify As</option>
                                        </b-select>
                                    </b-field>
                                    <b-field v-if="registerInfo.gender === 'Self Identify As'" :label="(en === true ? 'Identified Gender*' : 'Le genre' )">
                                        <b-input v-model="registerInfo.identify_as"></b-input>
                                    </b-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="padding">
                                    <b-field :label="(en === true ? 'Current Player Rank*' : 'Classement actuel du joueur*')">
                                        <b-select v-model="registerInfo.current_rank_id">
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                            <option value="D">D</option>
                                            <option value="E">E</option>
                                        </b-select>
                                    </b-field>
                                    <p class="note" v-if="en === true">If you've never played softball before, leave it as "E"</p>
                                    <p class="note" v-if="en === true">League divisions do not necessarily equal your rank in SPN. If you're unsure, check with your league co-ordinator on what rank you should enter.</p>
                                    <p class="note" v-if="en === false">SI VOUS N'AVEZ JAMAIS JOUÉ AU SOFTBALL AVANT, LAISSEZ-LE COMME "E"</p>
                                    <p class="note" v-if="en === false">LES DIVISIONS DE LIGUE N'ÉGALENT PAS NÉCESSAIREMENT VOTRE RANG EN SPN. SI VOUS N'ÊTES PAS SÛR, VÉRIFIEZ AVEC VOTRE COORDINATEUR DE LIGUE LE CLASSEMENT QUE VOUS DEVRIEZ INSCRIRE.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="padding">
                                     <b-field :label="(en === true ? 'Region*' : 'Region')">
                                        <b-select v-model="registerConfirm.region">
                                            <option v-for="region in regions" :value="region" :key="region.id">{{ region.name }}</option>
                               
                                        </b-select>
                                    </b-field>
                                </div>
                            </div>
                        </div>
                        <div style="color: red; text-align: center;" v-if="errors.length > 0">
                            <div v-for="(error,key) in errors" :key="key">{{ error }}</div>
                        </div>
                        <div style="text-align: center; margin-top: 10px;">
                            <a class="admin-button" style="margin: 0 auto; display: inline-block;" v-on:click="checkEmailAvailability()">{{ en === true ? 'Register' : `S'inscrire` }}</a>
                        </div>
                    </div>
                    <div class="second-page-registration" v-if="nowRegistered === true">
                        <div class="login-header">
                            {{ en === true ? 'Complete your registration!' : 'Complétez votre inscription' }}
                        </div>
                        <div style="background: #fff; padding: 10px;">
                            <div style="text-align: center;">
                                <h3 style="font-size: 22px; font-weight: 700; color: #da0000;" v-if="en === true">Let's finish up the rest of your profile.</h3>
                                <h3 style="font-size: 22px; font-weight: 700; color: #da0000;" v-if="en === true">Finissons le reste de votre profil.</h3>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="padding">
                                        <b-field :label="(en === true ? 'Your Street Address*' : 'Votre adresse résidentielle*')">
                                            <b-input v-model="registerConfirm.street_address"></b-input>
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="padding">
                                        <b-field :label="(en === true ? 'Your City*' : 'Votre ville*')">
                                            <b-input v-model="registerConfirm.city"></b-input>
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="padding">
                                        <b-field :label="(en === true ? 'Your Province*' : 'Votre région*')">
                                            <b-select v-model="registerConfirm.province">
                                                <option :value="option" :key="key" v-for="(option, key) in provinces">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="padding">
                                        <b-field :label="(en === true ? 'Your Postal Code*' : 'Votre codé postal*')">
                                            <b-input v-model="registerConfirm.postal_code"></b-input>
                                        </b-field>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="padding">
                                        <b-field :label="(en === true ? 'Primary Phone #*' : 'Téléphone primaire #*')">
                                            <b-input v-model="registerConfirm.primary_phone"></b-input>
                                        </b-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="padding">
                                        <b-field :label="(en === true ? 'Secondary Phone #*' : 'Téléphone secondaire #*')">
                                            <b-input v-model="registerConfirm.secondary_phone"></b-input>
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="padding">
                                        <b-field :label="(en === true ? 'Security Question*' : 'Question de sécurité*')">
                                            <b-select v-model="registerConfirm.security_question">
                                                <option :value="option" :key="key" v-for="(option, key) in securityQuestionOptions">
                                                    {{ option }}
                                                </option>
                                            </b-select>

                                        </b-field>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="padding">
                                        <b-field :label="(en === true ? 'Security Answer*' : 'Réponse de sécurité*')">
                                            <b-input v-model="registerConfirm.security_answer"></b-input>
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="padding">
                                        <b-field :label="(en === true ? 'Emergency Contact Name*' : `Nom à contacter en cas d'urgence*`)">
                                            <b-input v-model="registerConfirm.emergency_contact_name"></b-input>
                                        </b-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="padding">
                                        <b-field :label="(en === true ? 'Emergency Contact Phone #*' : `Numéro de téléphone du contact d'urgence*`)">
                                            <b-input v-model="registerConfirm.emergency_contact_phone"></b-input>
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                           
                                <div class="col-md-12">
                                    <div class="padding">
                                        <b-field :label="(en === true ? 'Positions You Play*' : `Positions que vous jouez*`)">
                                            <b-dropdown
                                                v-model="registerConfirm.positions"
                                                multiple
                                                aria-role="list">
                                                <button class="button is-secondary" type="button" slot="trigger">
                                                    <span>Selected ({{ registerConfirm.positions.length }})</span>
                                                    <b-icon icon="menu-down"></b-icon>
                                                </button>

                                                <b-dropdown-item value="P" aria-role="listitem">
                                                    <span>Pitcher</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item value="C" aria-role="listitem">
                                                    <span>Catcher</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item value="1B" aria-role="listitem">
                                                    <span>First Base</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item value="2B" aria-role="listitem">
                                                    <span>Second Base</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item value="SS" aria-role="listitem">
                                                    <span>Shortstop</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item value="3B" aria-role="listitem">
                                                    <span>Third Base</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item value="LF" aria-role="listitem">
                                                    <span>Left Field</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item value="LCF" aria-role="listitem">
                                                    <span>Left Center Field</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item value="RCF" aria-role="listitem">
                                                    <span>Right Center Field</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item value="RF" aria-role="listitem">
                                                    <span>Right Field</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item value="Ro" aria-role="listitem">
                                                    <span>Rover</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="color: red; text-align: center;" v-if="errors.length > 0">
                            <div v-for="(error,key) in errors" :key="key">{{ error }}</div>
                        </div>
                        <a class="login-button" v-on:click="registerMyAccount()">Complete My Account</a>
                    </div>
                </div>
                <div v-if="registerComplete === true">
                    <div class="payment-complete">
                        <div class="icon-row">
                            <i class="far fa-check-circle"></i>
                        </div>
                        <div class="complete-text">
                            <p><b>Your account is registered.</b></p>
                            <p class="smal">Please check your email to confirm your account. If you cannot find it, check your spam.</p>
                            <p>You can now login in the login tab!</p>
                        </div>
                    </div>
                </div>
                <img src="../../images/register.jpg"  style="margin-top: 20px;" width="100%" alt="">
            </b-tab-item>
            <b-tab-item
                value="Reset"
                :label="(en === true ? 'Reset Password' : 'Nouveau Mot de Passe')">
                
                <b-tabs expanded>
                    <b-tab-item :label="(en === true ? 'I Remember My Email' : 'Je me souviens de mon e-mail')">
                        <div class="padding" v-if="rememberEmail === false">
                            <b-field :label="(en === true ? 'Email Address' : 'Je me souviens de Mon e-mail')">
                                <b-input v-model="recoverPassword.email"></b-input>
                            </b-field>
                            <div style="text-align: center;">
                                <a class="admin-button" style="margin: 0 auto; display: inline-block;" v-on:click="recoverMyAccount()">Recover My Account</a>
                            </div>
                        </div>
                        <div class="payment-complete" v-if="rememberEmail === true">
                            <div class="icon-row">
                                <i class="far fa-check-circle"></i>
                            </div>
                            <div class="complete-text">
                                <p><b>We may have found your account.</b></p>
                                <p class="smal">Please check your email. If you receive an email within the next 10 minutes, we found it. If not, we didn't. This is a safety feature.</p>
                            </div>
                        </div>
                    </b-tab-item>
                    
                    <b-tab-item :label="(en === true ? `I Don't Remember` : `Je ne me souviens pas`)">
                        <div class="padding">
                            <p>Please start a chat using the bubble in the bottom right.</p>
                        </div>
                    </b-tab-item>
                </b-tabs>
                <img src="../../images/login.jpg" width="100%" alt="">
                
            </b-tab-item>
        </b-tabs>
        
        
    </div>
</template>

<script>
import axios from 'axios'
var getAge = require('get-age')
import moment from 'moment-timezone';
export default {
    data() {
		return {
            en: true,
            isLoading: false,
            authUser: null,
            email: null,
            phone: null,
            rememberEmail: false,
            registerComplete: false,
            username: null,
            password: null,
            rememberMe: false,
            redirectQuery: null,
            errors: [],
            error: null,
            slug: null,
            roster_id: null,
            type: null,
            recoverPassword: {
                email: null,
                password: null
            },
            opening: 'Login',
            registerInfo: {
                first_name: null,
                last_name: null,
                email: null,
                retype_email: null,
                gender: null,
                password: null,
                confirm_password: null,
                date_of_birth: null,
                current_rank_id: 'E',
                identify_as: null
            },
            registerConfirm: {
                primary_phone: null,
                secondary_phone: null,
                emergency_contact_name: null,
                emergency_contact_phone: null,
                positions: [],
                region: null,
                location: null,
                
                street_address: null,
                city: null,
                province: null,
                postal_code: null
                
            },
            regions: [],
            error: false,
            nowRegistered: false,
            provinces: ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'],
            securityQuestionOptions: [
                'What was the house number and street name you lived in as a child?',
                'What were the last four digits of your childhood telephone number?',
                'What primary school did you attend?',
                'In what town or city was your first full time job?',
                'In what town or city did you meet your spouse or partner?',
                'What is the middle name of your oldest child?',
                "What are the last five digits of your driver's license number?",
                "What is your spouse or partner's mother's maiden name?",
                "What is your grandmother's (on your mother's side) maiden name?",
                "In what town or city did your parents meet?",
                "What is your favourite sports team?",
                "What is your mother's maiden name?",
                "What was the name of your elementary / primary school?",
                "What is your favorite color?",
                "Where is your Place of Birth?"
            ]
		};
    },
    mounted: function() {
        var vm = this
        var q = this.$route.query.q ? this.$route.query.q : null;
        var r = this.$route.query.r ? this.$route.query.r : null;
        var slug = this.$route.query.slug ? this.$route.query.slug : null;
        var roster_id = this.$route.query.roster_id ? this.$route.query.roster_id : null;
        var type = this.$route.query.type ? this.$route.query.type : null;

        this.redirectQuery = { q, r, slug, roster_id, type };

        const start = 9 * 60;
        const end = 17 * 60;
        const date = new Date(); 
        const now = date.getHours() * 60 + date.getMinutes();
        const day_of_week = date.getDay()
        
        if(start <= now && now <= end) {
            // console.log('Chat is hidden.')
        } else {
            $('#front-chat-container').hide()    
        }
        // if(day_of_week === 5) {
        //     $('#front-chat-container').hide()    
        // }
        if(day_of_week === 6) {
            $('#front-chat-container').hide()    
        }
        if(day_of_week === 0) {
            $('#front-chat-container').hide()    
        }
        
        if(sessionStorage.getItem('lang')) {    
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }

        

        // var q = null
        // var r = null

        // // if(vm.$route.query.q) {
        // //     q = '?q=' + vm.$route.query.q
        // // }
        // // if(vm.$route.query.r) {
        // //     r = '&r=' + vm.$route.query.r
        // // }
        // // if(q === null && r === null) {
        // //     vm.redirectQuery = '/'
        // // } else {
        // //     vm.redirectQuery = q + r
        // // }
        vm.opening = vm.defaultOpening

        var options = {
        }


        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                this.$buefy.toast.open({
                    message: 'You are already logged in!',
                    type: 'is-success', // is-danger
                    duration: 1000,
                    queue: false
                })
                this.$router.push({ 
                    path: '/dashboard', 
                    query: {
                        slug: vm.slug,
                        roster_id: vm.roster_id,
                        type: vm.type
                    } 
                })
            }
        })
        
        if(this.$route.query.q) {
            if(this.$route.query.q === 'reset') {
                vm.opening = 'Reset'
        
            }
        }

        axios.get('/auth/regions').then(results => {
			vm.regions = results.data.regions
		})
    },
    props: ['redirect', 'redirectToLogin', 'defaultOpening'],
    computed: {

    },
    watch: {
        nowRegistered: function(result) {
            var vm = this
            if(result === true) {
                
            }
            
        }
    },
    methods: {
        
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        checkEmailAvailability: function() {
            var vm = this

            vm.errors = []

            // need to make sure none of the areas are empty
            var info = vm.registerInfo
            if(info.first_name === null || info.first_name === '') {
                vm.errors.push('First name cannot be empty.')
            }
            if(info.last_name === null || info.last_name === '') {
                vm.errors.push('Last name cannot be empty.')
            }
            if(info.email === null || info.email === '') {
                vm.errors.push('Email cannot be empty.')
            }
            if(info.confirm_email === null || info.confirm_email === '') {
                vm.errors.push('Retype your email to confirm it.')
            }
            if(info.password === null || info.password === '') {
                vm.errors.push('Your password cannot be empty.')
            }
            if(info.confirm_password === null || info.confirm_password === '') {
                vm.errors.push('Your confirmed password cannot be empty.')
            }
            if(info.date_of_birth === null || info.date_of_birth === '') {
                vm.errors.push('Your date of birth cannot be empty.')
            }
            if(info.gender === null || info.gender === '') {
                vm.errors.push('Your gender cannot be empty.')
            }
            
            if(getAge(info.date_of_birth) < 16) {
                vm.errors.push('You must be at least 16 years of age.')
            }
            if(info.email !== info.confirm_email) {
                vm.errors.push('Your email and retype email fields do not match.')
            }
            if(info.password !== info.confirm_password) {
                vm.errors.push('Your password and confirmed password do not match.')
            }

            if(info.first_name === null || info.first_name === '') {
                vm.errors.push('First name cannot be empty.')
            }
            
            if(vm.registerConfirm.region === null) {
                vm.errors.push('Region must be selected.')
            }
            
            if(!vm.validateEmail(info.email)) {
                vm.errors.push('Your email is invalid.')
            }

            if(vm.errors.length === 0) {
                axios.post('/api/users/is-email-available', {
                    email: vm.registerInfo.email
                }).then(results => {
                    // simply checks to see if the email is taken and spits back if it is or isn't and moves them to the next step
                    if(results.data.success === true) {
                        vm.nowRegistered = true 
                    } else {
                        vm.errors.push(results.data.error)
                    }
                })
            }
        },
        loginToAccount: async function() {
            // send username and password via axios to auth
            // if successful, send back just the email address - maybe a few other fields
            var vm = this
            vm.isLoading = true
            var vmip = null
            
            try { 
                await axios.get('https://api.ipify.org/?format=json').then(ip => { vmip = ip.data.ip })
            } catch(err) {

            }

            axios.post('/auth/attempt', {
                email: vm.email,
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe,
                ip: vmip
            }).then(results => {
                // spit back results
                vm.error = null
                if(results.data.type === "success") {
                    // redirect the user after saving everything to localstorage

                    this.$buefy.snackbar.open({
                        message: 'Successfully logged in.',
                        type: 'is-success' // is-danger
                    })

                    vm.authUser = results.data.user
                    this.$emit('logged:in')

                    vm.isLoading = false
                    if(vm.redirect === true) {
                        console.log('redirectQuery', vm.redirectQuery)
                        // this.$router.push({ path: vm.redirectToLogin + vm.redirectQuery }).catch(err => {})   

                        var query = {};

                        if (this.redirectQuery.slug) {
                            query.slug = this.redirectQuery.slug;
                        }
                        if (this.redirectQuery.roster_id) {
                            query.roster_id = this.redirectQuery.roster_id;
                        }
                        if (this.redirectQuery.type) {
                            query.type = this.redirectQuery.type;
                        }
                        if (this.redirectQuery.q) {
                            query.q = this.redirectQuery.q;
                        }
                        if (this.redirectQuery.r) {
                            query.r = this.redirectQuery.r;
                        }

                        this.$router.push({ 
                            path: this.redirectToLogin, 
                            query: query
                        });
                    }

                } else {

                    // display error
                    vm.error = results.data.error
                    vm.isLoading = false
                    
                    if(vm.error === 'neverloggedin') {
                        vm.recoverPassword.email = vm.email
                        vm.opening = 'Login'

                        this.$buefy.snackbar.open({
                            message: 'You have not logged in before. Please reset your password so we can verify your email.',
                            type: 'is-danger' // is-danger
                        })
                    }

                    if(vm.error === 'Too many failed attempts.') {
                        vm.opening = 'Login'
                        this.$buefy.snackbar.open({
                            message: 'Too many failed attempts. Please reset your password.',
                            type: 'is-danger' // is-danger
                        })
                    }

                    
                }
                
            }).catch(err => {
                console.log(err)
                vm.isLoading = false
                this.$buefy.snackbar.open({
                    message: 'We could not log you in at this time.',
                    type: 'is-danger' // is-danger
                })
            })
            
        },
        registerMyAccount: function() {
            var vm = this
            vm.isLoading = true
            // create verification

            var info = this.registerConfirm

            if(info.primary_phone === null || info.primary_phone === '') {
                vm.errors.push('Phone number cannot be empty.')
            }
            if(info.emergency_contact_name === null || info.emergency_contact_phone === '') {
                vm.errors.push('Emergency contact name and phone cannot be empty.')
            }
            
            if(info.street_address === null || info.street_address === '') {
                vm.errors.push('Street address cannot be empty.')
            }

            if(info.city === null || info.city === '') {
                vm.errors.push('City cannot be empty.')
            }

            if(info.province === null || info.province === '') {
                vm.errors.push('Province cannot be empty.')
            }
            
            if(info.postal_code === null || info.postal_code === '') {
                vm.errors.push('Postal code cannot be empty.')
            }


            vm.registerInfo.date_of_birth = moment(vm.registerInfo.date_of_birth).utc().format('YYYY-MM-DD');


            

            vm.error = null
            if(vm.errors.length === 0) {
                axios.post('/auth/user/create', {
                    registerInfo: vm.registerInfo,
                    registerConfirm: vm.registerConfirm
                }).then(results => {
                    if(results.data.success === true) {
                        
                        // receive back the user in progress
                        // send it back with complete my registration

                        vm.registerComplete = true
                        vm.isLoading = false
                        
                    } else {
                        
                        vm.error = results.data.error
                        vm.isLoading = false

                        this.$buefy.snackbar.open({
                            message: 'Register failed. ',
                            type: 'is-danger' // is-danger
                        })
                    }
                })  
            }
        },
    
        recoverMyAccount: function(email_override = null) {
            var vm = this
            vm.isLoading = true
            var email = vm.recoverPassword.email
            if(email_override) {
                email = email_override
            }

            axios.post('/auth/reset-password', {
                email: email
            }).then(results => {
                if(results.data.success === true) {
                    vm.rememberEmail = true
                    vm.isLoading = false
                    if(vm.redirect === true) {
                        
                    }
                } else {
                    vm.isLoading = false
                }
            })

            
        }
    }
}
</script>