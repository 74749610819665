<template>
	<div>

		<b-modal 
            v-model="createNewClinicSwitch"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            full-screen
			style="z-index: 40 !important;"
            aria-modal>
            <template #default="props">
                <div class="modal-card">
					<header class="modal-card-head">
						<p class="modal-card-title">Create a New Clinic</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<div class="row">
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Clinic Name">
										<b-input v-model="clinic.name"></b-input>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Clinic Name (FR)">
										<b-input v-model="clinic.fr_name"></b-input>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<b-field label="Region">
									<b-tooltip type="is-dark" label="This is the region your tournament is hosted in.">
										<b-dropdown hoverable aria-role="list" v-model="newTournamentRegion">
											<div class="button is-info" slot="trigger">
												<span v-if="newTournamentRegion">
													{{ newTournamentRegion.name }}
												</span>
												<span v-if="!newTournamentRegion">
													Select your HQ below
												</span>
												<b-icon icon="menu-down"></b-icon>
											</div>

											<b-dropdown-item :key="key" :value="region" v-for="(region, key) in regions" aria-role="listitem">{{ region.name }}</b-dropdown-item>
										</b-dropdown>	
									</b-tooltip>
								</b-field>
							</div>
							<div class="col-md-3"></div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Street Name">
										<b-input icon-pack="fas"
											icon="street-view"
											expanded
											v-model="clinic.street_name"></b-input>
									</b-field>
									<b-field label="City">
										<b-input icon-pack="fas"
											icon="street-view"
											expanded
											v-model="clinic.city"></b-input>
									</b-field>
									<b-field label="Postal Code">
										<b-input icon-pack="fas"
											icon="street-view"
											expanded
											v-model="clinic.postal_code"></b-input>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Cost to Attend ($)">
										<b-numberinput v-model="clinic.fee_to_register"></b-numberinput>
									</b-field>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Clinic Date">
										<b-datepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinic.clinic_date"
											horizontal-time-picker>
										</b-datepicker>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Clinic Start Time">
										<b-timepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinic.start_time"
											horizontal-time-picker>
										</b-timepicker>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Clinic End Time">
										<b-timepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinic.end_time"
											horizontal-time-picker>
										</b-timepicker>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">

							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Registration Start Date">
										<b-datepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinic.registration_start_date"
											horizontal-time-picker>
										</b-datepicker>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Registration End Date">
										<b-datepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinic.registration_end_date"
											horizontal-time-picker>
										</b-datepicker>
									</b-field>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="padding">
									<b-field extended label="Description">
										
										<b-input v-model="clinic.description" maxlength="200" type="textarea"></b-input>
										
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Maximum Registrants">
										<b-numberinput v-model="clinic.max_participants"></b-numberinput>
									</b-field>
									<b-checkbox v-model="clinic.allow_waitlist">
                                        Allow a waitlist
                                    </b-checkbox>
								</div>
							</div>
						</div>
						
					</section>
					<footer class="modal-card-foot">
                        <button v-on:click="saveNewClinic()" class="admin-button"><i class="fas fa-save"></i> Save New Clinic</button>
                    </footer>
                </div>
            </template>
		</b-modal>
		<b-modal has-modal-card v-model="registerForClinicSwitch">
			
			<template #default="props">
                <div class="modal-card">
					<header class="modal-card-head">
                        <p class="modal-card-title">Register For Umpire Clinic</p>
                    </header>
					<section class="modal-card-body">
						<table class="table">
							<tr>
								<td><b>Clinic Name:</b></td>
								<td>{{ clinicSelected.name }}</td>
							</tr>
							<tr>
								<td><b>Address:</b></td>
								<td>{{ clinicSelected.written_address }}</td>
							</tr>
							<tr>
								<td><b>Max Entries:</b></td>
								<td>{{ clinicSelected.max_participants }}</td>
							</tr>
							<tr>
								<td><b>Currently Registered:</b></td>
								<td>{{ clinicSelected.registrants.length }}</td>
							</tr>
							<tr>
								<td><b>Hosted By:</b></td>
								<td>{{ clinicSelected.clinician.first_name }} {{ clinicSelected.clinician.last_name }}</td>
							</tr>
							<tr v-if="clinicSelected.fee_to_register > 0">
								<td><b>Cost to Enter (to be paid to the host):</b></td>
								<td>${{ clinicSelected.fee_to_register }}</td>
							</tr>
							<tr v-if="clinicSelected.fee_to_register === 0">
								<td><b>Cost to Enter:</b></td>
								<td>Free</td>
							</tr>
						</table>
						<p><b>Description & Notes</b></p>
						<p>{{ clinicSelected.description }}</p>
					
						<div v-if="clinicSelected.max_participants > clinicSelected.registrants.length">
							<a  v-on:click="submitUmpireRegistration(false)" class="admin-button" style="margin-top: 10px;"><i class="fas fa-save"></i>Register to Umpire Clinic</a>
						</div>
						<div v-else>
							<a  v-on:click="submitUmpireRegistration(true)"  v-if="clinicSelected.allow_waitlist === 1" class="admin-button" style="margin-top: 10px;"><i class="fas fa-save"></i>Join Waitlist</a>
						</div>
					</section>
                </div>
			</template>
		</b-modal>
		<Header @update-lang="updateLanguage($event)" v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper">
				<div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="page-header-title">
                                {{ en === true ? 'Umpire Central' : 'Centrale Des Arbitres' }}
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="padding">
                            <b-field  grouped position="is-right">
                                <a v-on:click="addClinicSwitch()" class="button">{{ en == true ? 'Create Clinic' : 'Créer Une Clinique' }}</a>
                            </b-field>
                            </div>
                        </div>
                    </div>
                </div>
				
                <div v-if="page === 'exam'">
					<div class="row">
						<div class="col-md-12">
							<Exam :en="en"></Exam>
						</div>
						
					</div>
				</div>
				<div v-if="page === 'cheat-sheet'">
					<div class="row">
						<div class="col-md-12">
							<UmpireCheatSheet :en="en"></UmpireCheatSheet>
						</div>
						
					</div>
				</div>
				<div v-if="page === 'clinics'">
					<div class="row">
						<div class="col-md-12">
							<UmpireClinics
							 	:en="en"
								v-on:register:clinic="registerForClinicTrigger($event)"></UmpireClinics>
						</div>
						
					</div>
				</div>
				<div v-if="page === 'your-certificate'">
					<div class="row">
						<div class="col-md-12">
							<YourCertificate :en="en"></YourCertificate>
						</div>
					</div>
				</div>
				<div v-if="page === 'find-gigs'">
					<FindGigs></FindGigs>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Exam from './components/Exam.vue'
import YourCertificate from './components/YourCertificate.vue'
import UmpireClinics from './components/UmpireClinics.vue'
import UmpireCheatSheet from './components/UmpireCheatSheet.vue'
import FindGigs from './components/FindGigs.vue'
import UmpireTools from './components/UmpireTools.vue'
import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'
import axios from 'axios'
export default {
	data() {
		return {
		   page: null,
		   content_width: false,
		   registerForClinicSwitch: false,
		   clinicSelected: null,
		   authUser: null,
		   newTournamentRegion: null,
		   en: true,
		   regions: [],
		   clinic: {
				name: null,
				fr_name: null,
				description: null,
				location: null,
				secondary_clinician: null,
				clinic_date: null,
				start_time: null,
				end_time: null,
				headquarter_google_address: null,
				max_participants: 1,
				registration_start_date: null,
				registration_end_date: null,
				allow_waitlist: false,
				fee_to_register: 0,
			},
		   createNewClinicSwitch: false,
		};
	},
	components: {
		Header,
		Footer,
		UmpireTools,
		FindGigs,
		UmpireCheatSheet,
		UmpireTools,
		UmpireClinics,
		YourCertificate,
		Exam
	},
	watch: {
		newTournamentRegion: function() {
			this.getRegionRate()
		},
	},
	mounted: function() {
        // grab all the dashboard information now
		this.page = this.$route.params.page
		var vm = this

		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user

				axios.get('/api/tournaments/regions').then(results => {
					vm.regions = results.data.regions

					var region_data = _.find(vm.regions, function(region) {
						if(region.id === vm.authUser.default_region_id) {
							return true
						}
					})

					vm.newTournamentRegion = region_data
				})
            }
		})
		
		if(sessionStorage.getItem('lang')) {
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
		


		 axios.get('/auth/regions').then(results => {
			vm.regions = results.data.regions
		})
        
	},
	computed: {
	
	},
	methods: {
		addClinicSwitch: function() {
			var vm = this
			this.createNewClinicSwitch = true
			vm.$nextTick(() => {
		
			})
		},
		getRegionRate: function() {
			var vm = this
			axios.post('/api/tournaments/region-rate', {
				region: vm.newTournamentRegion.id
			}).then(results => {
				if(results.data.success === true) {
					vm.region_rate = results.data.region_rate
				}
			})
		},
		getAddressData: function (addressData, placeResultData, id) {

		
		},
		fillInAddress: function() {
			var vm = this
			var place = this.autocompleteAddress.getPlace();
			var fields = this.autocompleteAddress.getFields()
			this.clinic.location = place.formatted_address

			// find components
			var address_components = place.address_components

			var postal_code = _.find(address_components, function(address) {
				if(address.types.includes('postal_code')) {
					return true
				}
			})

			var province = _.find(address_components, function(address) {
				if(address.types.includes('administrative_area_level_1')) {
					return true
				}
			})

			if(province.short_name === 'NS' || province.short_name === 'PE' || province.short_name === 'NB' ) {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MT') {
						return true
					}
				})

				
				vm.newTournamentRegion = region_data
			} else if (province.short_name === 'NU') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MB') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === province.short_name) {
						return true
					}
				})
				
				vm.newTournamentRegion = region_data
			}

			var city = _.find(address_components, function(address) {
				if(address.types.includes('locality')) {
					return true
				} else {
                    if(address.types.includes('sublocality')) {
                        return true
                    }
                }
			})

			var street_number = _.find(address_components, function(address) {
				if(address.types.includes('street_number')) {
					return true
				}
			})

			var route = _.find(address_components, function(address) {
				if(address.types.includes('route')) {
					return true
				}
			})

			vm.clinic.headquarter_google_address = {
				written_address: place.formatted_address,
				street_address: street_number.long_name + ' ' + route.long_name,
				city: city.long_name,
				province: province.short_name,
				postal_code: postal_code.long_name,
				latitude:  place.geometry.location.lat(),
				longitude: place.geometry.location.lng()
			}

			// set province to region  -- newTournamentRegion - need to only send the shortcode and then match them here (some provinces will have to apply to multiple areas)
				// maritimes == NS, PEI, NB

				

			// var i = 0
			// var string = ''
			// for(i; i < place.address_components.length; i++) {
			// 	if(place.address_components[i].types[0] !== 'subpremise') {
			// 		string += place.address_components[i].long_name + ', '
			// 	}
			// }
			// this.newTournament.headquarter_location = string
			// console.log(string)
		},
		switchWidth: function($event) {
			this.content_width = !$event
		},
		registerForClinicTrigger: function($event) {
			this.clinicSelected = $event
			this.registerForClinicSwitch = true
		},
		saveNewClinic: function() {
			var vm = this
			axios.post('/api/umpires/save-clinic', {
				clinic: vm.clinic,
				region: vm.newTournamentRegion
			}).then(results => {
				if(results.data.success === true) {
					vm.clinics = results.data.clinics

					vm.createNewClinicSwitch = false


					vm.clinic = {
						name: null,
						fr_name: null,
						description: null,
						location: null,
						secondary_clinician: null,
						clinic_date: null,
						start_time: null,
						end_time: null,
						headquarter_google_address: null,
						max_participants: 1,
						registration_start_date: null,
						registration_end_date: null,
						allow_waitlist: false,
						fee_to_register: 0,
					}

					this.$buefy.snackbar.open({
                        message: 'Saved new clinic! You will be notified when it is approved!',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'Clinic failed to save',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		submitUmpireRegistration: function(waitlist) {
			var vm = this
			axios.post('/api/umpires/register-to-umpire-clinic', {
				user_id: vm.authUser.id,
				clinic_id: vm.clinicSelected.id,
				waitlist: waitlist
			}).then(results => {
				if(results.data.success === true) {
					vm.registerForClinicSwitch = false
					this.$buefy.snackbar.open({
                        message: 'You have been registered to the umpire clinic',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'Something went wrong when registering you',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		updateLanguage: function($event) {
			this.en = $event
			
		}
	}
}
</script>

<style lang="scss">
    
</style>
