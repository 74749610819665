<template>
<span>
    <div class="dashboard-header">
        
        <div class="menu-slide-bar" v-on:click="menuSwitcher()">
            <i class="fas fa-bars"></i>
        </div>
        <div class="logo-main">
            <a href="/">
                <img src="../../../images/header/SPNLogo2019-White.png" alt="" style="height: 50px;">
            </a>
        </div>
        <div class="waiver-info" v-if="user">
            <b>{{ en === true ? 'Latest Waiver Signed:&nbsp;&nbsp;&nbsp;' : 'Dernière Edition de la Renonciation Signée' }}
                <b-tag v-if="user.latest_waiver_signed === $latest_waiver" type="is-success is-medium">{{ en === true ? 'Yes' : 'Oui' }}</b-tag>
                <b-tag v-if="user.latest_waiver_signed !== $latest_waiver" type="is-success is-medium">{{ en === true ? 'No' : 'Non' }}</b-tag>
            </b>
        </div>
        <div class="logged-in-bar" v-if="user">
            {{ en === true ? 'Welcome,' : 'Bienvenue,' }} {{ user.first_name }} {{ user.last_name }}
        </div>        
    </div>
    <div class="search-bar-results" v-if="false">
        
    </div>
    <b-modal v-model="joinTeamModal" :can-cancel="false">
        <div class="card">
            <header class="card-header">
                <p class="card-header-title">
                    Join This Team
                </p>
                <a role="button" class="card-header-icon" aria-label="close" @click="closeModal">
                    <i class="fas fa-times"></i>
                </a>
            </header>
            <div class="card-content">
                <p v-if="eventJoining">Your coach has sent you an invite to enter: <b>{{ eventJoining.name }}</b></p>
                <span v-if="teamJoining">
                    <p v-if="teamJoining.team">The team name you're joining is: <b>{{ teamJoining.team.name }}</b></p>
                </span>
                <br>
                <p v-if="teamParams.type"><b>By clicking accept below, you will be added to this {{ teamParams.type }} roster as well as the team’s player pool.</b></p>
                <br>
                <a class="admin-button" v-on:click="joinThisTeam()">Join This Team</a>
                <br><br>
                <p>You will be redirected to the event roster on success.</p>
                <!-- Modal content goes here -->
            </div>
        </div>
    </b-modal>
    <div v-if="user">
        <div class="updated-overlay" v-if="accountPopup === true">
            <div class="updated-panel">
                <div class="main-content-area" style="padding-top: 0px;">
                    <h1>Welcome to SPN Central</h1>
                    <p class="note" style="text-align: center; margin-top: 10px;">We want to just confirm your details once more before you log in for the first time. This popup will leave when you complete it! You're seconds away.</p>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="First Name*">
                                    <b-input v-model="confirmAccount.first_name"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Last Name*">
                                    <b-input v-model="confirmAccount.last_name"></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Email Address*">
                                    <b-input v-model="confirmAccount.email"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Date of Birth*">
                                    <b-datepicker v-model="confirmAccount.date_of_birth"></b-datepicker>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="padding">
                                <b-field label="Street Address*">
                                    <b-input v-model="confirmAccount.street_address"></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="padding">
                                <b-field label="City">
                                    <b-input v-model="confirmAccount.city"></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="padding">
                                <b-field label="Province">
                                    <b-select v-model="confirmAccount.province">
                                        <option :value="option" :key="key" v-for="(option, key) in provinces">
                                            {{ option }}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="padding">
                                <b-field label="Postal Code">
                                    <b-input v-model="confirmAccount.postal_code"></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                   
                    <div class="row">
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Primary Phone #*">
                                    <b-input v-model="confirmAccount.primary_phone"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Secondary Phone #">
                                    <b-input v-model="confirmAccount.secondary_phone"></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="padding">
                                <b-field label="Security Question*">
                                    <b-select v-model="confirmAccount.security_question">
                                        <option :value="option" :key="key" v-for="(option, key) in securityQuestionOptions">
                                            {{ option }}
                                        </option>
                                    </b-select>

                                </b-field>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="padding">
                                <b-field label="Security Answer*">
                                    <b-input v-model="confirmAccount.security_answer"></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Emergency Contact Name*">
                                    <b-input v-model="confirmAccount.emergency_contact_name"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field label="Emergency Contact Phone #">
                                    <b-input v-model="confirmAccount.emergency_contact_phone"></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    
                        <div class="col-md-12">
                            <div class="padding">
                                <b-field label="Positions You Play">
                                    <b-dropdown
                                        position="is-top-right"
                                        v-model="confirmAccount.positions"
                                        multiple
                                        aria-role="list">
                                        <button class="button is-secondary" type="button" slot="trigger">
                                            <span>Selected ({{ confirmAccount.positions.length }})</span>
                                            <b-icon icon="menu-down"></b-icon>
                                        </button>

                                        <b-dropdown-item value="P" aria-role="listitem">
                                            <span>Pitcher</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="C" aria-role="listitem">
                                            <span>Catcher</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="1B" aria-role="listitem">
                                            <span>First Base</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="2B" aria-role="listitem">
                                            <span>Second Base</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="SS" aria-role="listitem">
                                            <span>Shortstop</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="3B" aria-role="listitem">
                                            <span>Third Base</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="LF" aria-role="listitem">
                                            <span>Left Field</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="LCF" aria-role="listitem">
                                            <span>Left Center Field</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="RCF" aria-role="listitem">
                                            <span>Right Center Field</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="RF" aria-role="listitem">
                                            <span>Right Field</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item value="Ro" aria-role="listitem">
                                            <span>Rover</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div style="color: red; text-align: center;" v-if="errors.length > 0">
                        <div v-for="(error,key) in errors" :key="key">{{ error }}</div>
                    </div>
                    <a class="admin-button" style="margin-top: 10px;" v-on:click="updateYourAccount()">Update Your Account</a>
                </div>
            </div>
        </div>

        <div class="waiver-overlay" v-if="user.latest_waiver_signed !== $latest_waiver">
            <div class="waiver-panel" v-if="waiver">
                <div class="content-area" style="padding-top: 0px;">
                    <h2 style="text-align: center; padding-top: 0px; margin-top: 0px;">Please sign this waiver to continue</h2>
                    <div style="text-align: center;">
                        <b><a v-on:click="selectLang(true)">EN </a>|<a v-on:click="selectLang(false)"> FR</a></b>
                    </div>
                    <div class="scroll-box">
                        <div class="waiver-section" :key="key" v-for="(section, key) in written_waiver">
                            <div v-if="en === true">
                                <div v-html="section.section"></div>
                                <div class="i-agree-row" style="padding: 10px; background: #eee; margin-bottom: 10px; margin-top: 2px;">    
                                    <b-checkbox v-model="section.checked">
                                        I have read, agree, acknowledge, and understand the conditions above.
                                    </b-checkbox>
                                </div>
                            </div>
                            <div v-if="en === false">
                                <div v-html="section.fr_section"></div>
                                <div class="i-agree-row" style="padding: 10px; background: #eee; margin-bottom: 10px; margin-top: 2px;">    
                                    <b-checkbox v-model="section.checked">
                                        J'ai lu et compris les conditions ci-dessus.
                                    </b-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <p>Please provide your signature in the box below to confirm you have read and understand the conditions above.</p>
                    
                    <VueSignaturePad  
                        class="border-box" 
                        width="100%" 
                        height="200px" 
                        ref="signaturePad"
                        :options="{ onBegin, onEnd }" />
                    <button v-on:click="undo()">Redo Signature</button>
                    <br>
                    <button :class="['admin-button', { gray: !allowAccept }]" style="margin-top: 10px;" v-if="!allowAccept"><i class="fas fa-exclamation-triangle"></i> Please check all boxes & sign the form</button>
                    <button :class="['admin-button']" style="margin-top: 10px;" v-on:click="acceptWaiver()" v-if="allowAccept"><i class="fas fa-check"></i> I accept</button>
                    {{  error }}
                </div>
            </div>
        </div>
    </div>
    <Sidebar @update-lang="updateLanguage($event)" v-if="menuSwitch === true"></Sidebar>
</span>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
// import moment from 'moment'
import moment from 'moment-timezone';


import Sidebar from './Sidebar.vue'
export default {
    data() {
        return {
            menuSwitch: true,
            user_coordinates: {
                lat: null,
                long: null
            },
            eventJoining: null,
            teamJoining: null,
            errors: [],
            underage: false,
            signatureData: null,
            error: null,
            confirmAccount: {
                first_name: null,
                last_name: null,
                email: null,
                date_of_birth: null,
                location: '',
                primary_phone: null,
                secondary_phone: null,
                security_question: null,
                security_answer: null,
                emergency_contact_name: null,
                emergency_contact_phone: null,
                positions: [],
                street_address: null,
                city: null,
                province: null,
                postal_code: null
            },
            provinces: ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'],
            securityQuestionOptions: [
                'What was the house number and street name you lived in as a child?',
                'What were the last four digits of your childhood telephone number?',
                'What primary school did you attend?',
                'In what town or city was your first full time job?',
                'In what town or city did you meet your spouse or partner?',
                'What is the middle name of your oldest child?',
                "What are the last five digits of your driver's license number?",
                "What is your spouse or partner's mother's maiden name?",
                "What is your grandmother's (on your mother's side) maiden name?",
                "In what town or city did your parents meet?",
                "What is your favourite sports team?",
                "What is your mother's maiden name?",
                "What was the name of your elementary / primary school?",
                "What is your favorite color?",
                "Where is your Place of Birth?"
            ],
            menu: [],
            joinTeamModal: false,
            teamParams: {
                type: null,
                slug: null,
                roster_id: null
            },
            submenus: [],
            user: null,
            waiver: null,
            written_waiver: null,
            en: true,
            accountPopup: false
        };
    },
    components: {
        Sidebar
    },
    watch: {
        accountPopup: function(results) {
            var vm = this
            
        },
    },
    computed: {
        allowAccept: function() {
            var vm = this

            var count = _.filter(vm.written_waiver, function(waiver) {
                if(waiver.checked === false) {
                    return true
                }
            })

            if(count.length > 0) {
                return false
            } 
            
            if(!vm.signatureData) {
                return false
            }
            return true
        },
    },
    mounted: function() {
        var vm = this

        const start = 9 * 60;
        const end = 17 * 60;
        const date = new Date(); 
        const now = date.getHours() * 60 + date.getMinutes();
        const day_of_week = date.getDay()

        if(start <= now && now <= end) {
            // console.log('Chat is hidden.')
        } else {
            $('#front-chat-container').hide()     
        }
        // if(day_of_week === 5) {
        //     $('#front-chat-container').hide()    
        // }
        if(day_of_week === 6) {
            $('#front-chat-container').hide()    
        }
        if(day_of_week === 0) {
            $('#front-chat-container').hide()    
        }
        
        
        if(sessionStorage.getItem('lang')) {
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }

       

        if(window.sessionStorage.getItem('nav')) {
            var nav = window.sessionStorage.getItem('nav')

            vm.menuSwitch = (window.sessionStorage.getItem('nav') === 'true')
            vm.$emit('switch:navigation', vm.menuSwitch)
        }

        axios.get('/auth/check').then(results => {
            if(results.data.type === "success") {
                vm.user = results.data.user
                // check their age

                var age = moment().diff(moment(vm.user.date_of_birth), 'days')

                // console.log('age', age, age/365)
                var age_in_years = age/365
                
                if(age_in_years < 18) {
                    vm.underage = true
                }

                if(vm.user.updated_account === null) {
                    vm.confirmAccount.first_name = vm.user.first_name
                    vm.confirmAccount.last_name = vm.user.last_name
                    vm.confirmAccount.email = vm.user.email
                    vm.confirmAccount.primary_phone = vm.user.phone
                    vm.confirmAccount.date_of_birth = vm.user.date_of_birth
                    
                    if (vm.confirmAccount.date_of_birth === null) {
                        // If date of birth is not set, display a default date (January 1, 2000 in this example)
                        vm.confirmAccount.date_of_birth = new Date(2000, 0, 1);
                    } else {
                        vm.confirmAccount.date_of_birth = this.convertUTCDateToLocalDate(vm.user.date_of_birth)
                    }
                    
                    vm.confirmAccount.secondary_phone = vm.user.secondary_phone
                    vm.confirmAccount.security_question = vm.user.security_question
                    vm.confirmAccount.security_answer = vm.user.security_answer
                    vm.confirmAccount.emergency_contact_name = vm.user.emergency_contact
                    vm.confirmAccount.street_address = vm.user.street_address
                    vm.confirmAccount.city = vm.user.city
                    vm.confirmAccount.province = vm.user.province
                    vm.confirmAccount.postal_code = vm.user.postal_code
                    vm.confirmAccount.emergency_contact_phone = vm.user.emergency_contact_phone
                    vm.accountPopup = true
                    var options = {

                    }
                }

                // pull latest waiver
                axios.get('/api/users/pull-latest-waiver').then(results => {
                    if(results.data.success === true) {
                        vm.waiver = results.data.waiver
                        vm.written_waiver = JSON.parse(vm.waiver.written_waiver)

                        if(vm.underage === true) {
                            vm.written_waiver.unshift({
                                section: `<p>Players under the age of 18 must complete the "Parental Consent Form" and submit it to SLO-PITCH NATIONAL SOFTBALL INC.</p>
                                <p><a href="https://slo-pitch.com/forms">View or download the parental consent form</a> now for your Parent/Guardian to review and sign. Please do not proceed until this waiver has been reviewed by the Parent/Guardian.</p>`,
                                fr_section: `<p>Les joueurs de moins de 18 ans doivent remplir le «Formulaire de consentement parental» et le soumettre à SLO-PITCH NATIONAL SOFTBALL INC.</p><p><a href="https://slo-pitch.com/forms">Consultez ou téléchargez le formulaire de consentement parental </a> dès maintenant pour que votre parent / tuteur l'examine et le signe. Veuillez ne pas continuer tant que cette renonciation n'a pas été examinée par le parent / tuteur.</p>`,
                                checked: false
                            })
                        }
                    }
                })


            }
        })

        this.teamParams.type = this.$route.query.type ? this.$route.query.type : null;
        this.teamParams.slug = this.$route.query.slug ? this.$route.query.slug : null;
        this.teamParams.roster_id = this.$route.query.roster_id ? this.$route.query.roster_id : null;

        this.getTeamInfo()
        this.getTeamRoster()

        if (this.teamParams.type && this.teamParams.slug && this.teamParams.roster_id) {
            this.joinTeamModal = true;
        }

        axios.get('/api/navigation/primary').then(results => {
            if(results.data) {
                vm.menu = results.data.main_menu
                vm.submenus = results.data.submenus
            }
        }).catch(err => {
            
        })

        if(window.innerWidth < 800) {
            vm.menuSwitch = false
            vm.$emit('switch:navigation', false)
		}
    },
    methods: {
        convertUTCDateToLocalDate(date) {
            console.log('dateeee', date)
            if (typeof date === 'string') {
                date = new Date(date);
            }
            return date;
        },
        undo() {
            
            this.$refs.signaturePad.clearSignature();
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            this.signatureData = data
        },
        onBegin: function() {
            
        },
        onEnd: function() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            this.signatureData = data
        },
        geoLocateSuccess(position) {
            this.user_coordinates.lat = position.coords.latitude;
            this.user_coordinates.long = position.coords.longitude;
            var pos = JSON.stringify(position)

            sessionStorage.setItem('user_location', pos)

            axios.post('/auth/update-location', {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                location_accuracy: position.coords.accuracy
            }).then(results => {

            })
            // store this in sessionStorage as well, and then don't fire the popup if it's there
        },
        geoLocateError() {
            var vm = this
            //  vm.$buefy.toast.open({
            //     message: 'There was an error getting your location',
            //     type: 'is-danger', // is-danger
            //     duration: 2000,
            //     queue: false
            // })
        },

        selectMenu(item) {
            var vm = this
            
            if(item.has_submenu) {
                var filter = _.filter(vm.submenus, function(sub_item) {
                    return sub_item.submenu_id === item.slug
                })

                vm.submenu_filtered = filter
            }
            if(item.href) {
                // redirect
                // router.push({ path: '/content/' + item.href })
            }
        },
        selectSubMenu(item) {
            var vm = this
        
            this.$router.push({ path: '/content/' + item.href }).catch(err => {})   
        },
        acceptWaiver: function() {
            var vm = this
            axios.post('/api/users/accept-waiver', {
                waiver: vm.waiver.id,
                user: vm.user.id,
                signatureData: vm.signatureData,
                en: vm.en
            }).then(results => {
                if(results.data.success === true) {
                    vm.user.latest_waiver_signed = vm.waiver.id
                }
                if(results.data.success === false) {
                   this.error = results.data.err
                }
            }).catch(err => {
                this.$buefy.toast.open({
                    message: err.message,
                    type: 'is-danger', // is-danger
                    duration: 1000,
                    queue: false
                })
            })
        },
        iAgree: function(test) {
            
        },
        fillInAddress: function() {
            var vm = this
			var place = this.autocompleteAddress.getPlace();
			var fields = this.autocompleteAddress.getFields()
			this.confirmAccount.location = place.formatted_address
            
			// find components
			var address_components = place.address_components

			var postal_code = _.find(address_components, function(address) {
				if(address.types.includes('postal_code')) {
					return true
				}
			})

			var province = _.find(address_components, function(address) {
				if(address.types.includes('administrative_area_level_1')) {
					return true
				}
			})

			if(province.short_name === 'NS' || province.short_name === 'PE' || province.short_name === 'NB' ) {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MT') {
						return true
					}
				})

				
				vm.confirmAccount.region = region_data
			} else if (province.short_name === 'NT') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'AB') {
						return true
					}
				})
                
				vm.confirmAccount.region = region_data
            } else if (province.short_name === 'NU') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MB') {
						return true
					}
				})
                
				vm.confirmAccount.region = region_data
            } else if (province.short_name === 'YT') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'BC') {
						return true
					}
				})
                
				vm.confirmAccount.region = region_data
            } else {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === province.short_name) {
						return true
					}
				})
				
				vm.confirmAccount.region = region_data
			}

			var city = _.find(address_components, function(address) {
				if(address.types.includes('locality')) {
					return true
				} else {
                    if(address.types.includes('sublocality')) {
                        return true
                    }
                }
			})

			var street_number = _.find(address_components, function(address) {
				if(address.types.includes('street_number')) {
					return true
				}
			})

			var route = _.find(address_components, function(address) {
				if(address.types.includes('route')) {
					return true
				}
			})

			vm.confirmAccount.google_primary_location = {
				written_address: place.formatted_address,
				street_address: street_number.long_name + ' ' + route.long_name,
				city: city.long_name,
				province: province.short_name,
				postal_code: postal_code.long_name,
				latitude:  place.geometry.location.lat(),
				longitude: place.geometry.location.lng()
            }
            
            vm.confirmAccount.location = place.formatted_address
        },
        menuSwitcher: function() {
            var vm = this
            vm.menuSwitch = !vm.menuSwitch
            window.sessionStorage.setItem('nav', vm.menuSwitch)
            vm.$emit('switch:navigation', vm.menuSwitch)
        },
        selectLang: function(lang) {
            this.en = lang

            sessionStorage.setItem('lang', lang)
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return re.test(String(email).toLowerCase());
        },
        updateYourAccount: function() {
            var vm = this

            var info = vm.confirmAccount
            if(info.first_name === null || info.first_name === '') {
                vm.errors.push('First name cannot be empty.')
            }
            if(info.last_name === null || info.last_name === '') {
                vm.errors.push('Last name cannot be empty.')
            }
            if(info.email === null || info.email === '') {
                vm.errors.push('Email cannot be empty.')
            }
            if(info.date_of_birth === null || info.date_of_birth === '') {
                vm.errors.push('Your date of birth cannot be empty.')
            }
            if(!vm.validateEmail(info.email)) {
                vm.errors.push('Your email is invalid.')
            }

            if(info.primary_phone === null || info.primary_phone === '') {
                vm.errors.push('Phone number cannot be empty.')
            }
            if(info.emergency_contact_name === null || info.emergency_contact_name === '') {
                vm.errors.push('Emergency contact name cannot be empty.')
            }
            
            if(info.street_address === null || info.street_address === '') {
                vm.errors.push('Street address cannot be empty.')
            }

            if(info.city === null || info.city === '') {
                vm.errors.push('City cannot be empty.')
            }

            if(info.province === null || info.province === '') {
                vm.errors.push('Province cannot be empty.')
            }
            
            if(info.postal_code === null || info.postal_code === '') {
                vm.errors.push('Postal code cannot be empty.')
            }

            if(vm.errors.length === 0) {
                // let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                // vm.confirmAccount.date_of_birth = moment.tz(vm.confirmAccount.date_of_birth + ' 12:00', timezone).utc().format('YYYY-MM-DD');

                axios.post('/api/users/update-user-full', {
                    account: vm.confirmAccount
                }).then(results => {
                    if(results.data.success === true) {
                        vm.accountPopup = false 
                        this.$buefy.snackbar.open({
                            message: 'Thanks for updating! Have fun looking around!',
                            type: 'is-success' // is-danger
                        })
                    } else {
                        vm.errors.push('Something went wrong.')
                    }
                })
            }
            
        },
        updateLanguage: function($event) {
            
            this.en = $event

            this.$emit('update-lang', $event)
        },
        closeModal() {
            if (confirm('Are you sure you want to close this? You cannot undo this.')) {
                this.joinTeamModal = false;
                this.teamParams.type = null;
                this.teamParams.slug = null;
                this.teamParams.roster_id = null;
                this.$router.push({ path: this.$route.path }); // This will clear the query parameters in the URL
            }
        },
        getTeamInfo() {
            if (this.teamParams.type === 'league') {
                axios.get(`/api/leagues/get-league/${this.teamParams.slug}`).then(response => {
                    // Handle the response here
                    console.log('get-league', response.data)

                    this.eventJoining = response.data.league
                });
            } else if (this.teamParams.type === 'tournament') {
                axios.get(`/api/tournaments/get-tournament/${this.teamParams.slug}`).then(response => {
                    // Handle the response here
                    console.log('get-tournament', response.data)
                    this.eventJoining = response.data.tournament
                });
            }
        },
        getTeamRoster() {
            axios.get(`/api/teams/${this.teamParams.roster_id}/event-roster`).then(response => {
                // Handle the response here
                console.log('get-team-roster', response.data)
                this.teamJoining = response.data.team_roster
                // this.eventJoining = response.data.roster
            });
        },
        joinThisTeam: function() {
            var vm = this
            axios.post('/api/teams/join-event-team', {
                team: vm.teamJoining.team.id,
                user: vm.user.id,
                event: vm.eventJoining.id,
                roster: vm.teamJoining.id
            }).then(results => {
                if(results.data.success === true) {
                    vm.$router.push({ path: `/dashboard/players/event/${vm.teamParams.slug}/${vm.teamParams.roster_id}/${vm.teamParams.type}` })
                    vm.joinTeamModal = false
                    vm.teamParams.type = null;
                    vm.teamParams.slug = null;
                    vm.teamParams.roster_id = null;
                    
                    this.$buefy.snackbar.open({
                        message: 'You have successfully joined this team!',
                        type: 'is-success' // is-danger
                    })
                    
                    // redirect them to the league/tournament roster area
                }
            })
        }
    }
}
</script>

<style lang="scss">
    @import '../../../styles/Dashboard.scss';

    .border-box {
        border: 1px solid #333;
    }
</style>
