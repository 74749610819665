<template>
	<div>
		<b-modal 
			v-model="contactUsSwitch"
			has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal>
			<template #default="props">
				<div class="modal-card">
					<header class="modal-card-head">
						<p class="modal-card-title">
							Contact This Person
						</p>
						<button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<div class="row  image-boxes">
							<div class="col-md-4">
								<div class="image-box">
									<div class="image"><img :src="'/uploads/headshots/'+ hashIt(contactUsSelected.name) + '.jpg'" width="100%" alt=""></div>
									<div class="image-name">{{ contactUsSelected.name }}</div>
									<div class="image-role">{{ contactUsSelected.role_name }}</div>
								</div>
							</div>
							<div class="col-md-8" style="text-align: left !important;">
								<div class="row">
									<div class="col-md-12">
										<b-field label="Your Name" style="width: 100%;">
											<b-input v-model="contactInfo.name" placeholder="Start typing..."></b-input>
										</b-field>
									</div>
								</div>
								<div class="row" style="margin-top: 10px;">
									<div class="col-md-6" style="padding-right: 5px;">
										<b-field label="Your Email" style="width: 100%;">
											<b-input v-model="contactInfo.email" placeholder="Start typing..."></b-input>
										</b-field>
									</div>
									<div class="col-md-6">
										<b-field label="Your Phone" style="width: 100%;">
											<b-input v-model="contactInfo.phone" placeholder="Start typing..."></b-input>
										</b-field>
									</div>
								</div>
								<div class="row" style="margin-top: 10px;">
									<div class="col-md-12">
										<b-field label="Your Subject" style="width: 100%;">
											<b-input type="textarea"  v-model="contactInfo.subject" placeholder="Start typing..."></b-input>
										</b-field>
									</div>
								</div>
								<div class="row" style="margin-top: 10px;">
									<div class="col-md-12">
										<b-field label="Your Message" style="width: 100%;">
											<b-input type="textarea"  v-model="contactInfo.message" placeholder="Start typing..."></b-input>
										</b-field>
									</div>
								</div>

								<a v-if="awaiting_switch === false" class="admin-button" v-on:click="sendMessageToUser()" style="margin-top: 20px;">Send Message to {{ contactUsSelected.name }}</a>

								<a v-if="awaiting_switch === true" class="admin-button" style="margin-top: 20px;">Sending ...</a>

								<div class="errors" style="margin-top: 20px;">
									<div class="error" v-for="(error, key) in errors" :key="key">
										{{ error }}
									</div>
								</div>
							</div>
						</div>
						
					</section>
				</div>
			</template>
		</b-modal>
		<Header></Header>
		
		<div class="content-area">
			<div class="standard-page-header">
				Our Team
			</div>
            <div class="frontend-content-wrapper" style='text-align: center;'>
				<h3>National Office</h3>
				<p><b>9-20 Lightbeam Terrace Brampton, ON L6Y 6H9</b></p>
			
				<div class="image-boxes" v-if="president">
					<div class="image-box">
						<div class="image"><img :src="'/uploads/headshots/'+ hashIt(president.name) + '.jpg'" width="100%" alt=""></div>
						<div class="image-name">{{ president.name }}</div>
						<div class="image-role">{{ president.role_name }}</div>
						<a v-on:click="contactUsSelected = president; contactUsSwitch = true" class="email-button"><i class="fas fa-envelope"></i>&nbsp;&nbsp;Contact Me</a>
					</div>
				</div>

				<div v-if="national_office">
					<div class="image-boxes" >
						<div class="image-box" v-for="contact in national_office" :key="contact.id">
							<div class="image">
								<img v-if="contact.has_pic === 1" :src="'/uploads/headshots/'+ hashIt(contact.name) + '.jpg'" width="100%" alt="">
								<span v-if="contact.has_pic !== 1"> 
									<img v-if="contact.gender === 'M'" src="../../images/headshots/male.jpg" width="100%" alt="">
									<img v-if="contact.gender === 'F'" src="../../images/headshots/female.jpg" width="100%" alt="">
								</span>
							</div>
							<div class="image-name">{{ contact.name }}</div>
							<div class="image-role">{{ contact.role_text }}</div>
							<a v-on:click="contactUsSelected = contact; contactUsSwitch = true" class="email-button"><i class="fas fa-envelope"></i>&nbsp;&nbsp;Contact Me</a>
						</div>
					</div>
				</div>
				<h3>Board of Governors</h3>
				<div v-if="board_of_governors">
					<div class="image-boxes" >
						<div class="image-box" v-for="contact in board_of_governors" :key="contact.id">
							<div class="image">
								<img v-if="contact.has_pic === 1" :src="'/uploads/headshots/'+ hashIt(contact.name) + '.jpg'" width="100%" alt="">
								<span v-if="contact.has_pic !== 1"> 
									<img v-if="contact.gender === 'M'" src="../../images/headshots/male.jpg" width="100%" alt="">
									<img v-if="contact.gender === 'F'" src="../../images/headshots/female.jpg" width="100%" alt="">
								</span>
							</div>
							<div class="image-name">{{ contact.name }}</div>
							<div class="image-role">{{ contact.role_text }}</div>
							<a v-on:click="contactUsSelected = contact; contactUsSwitch = true" class="email-button"><i class="fas fa-envelope"></i>&nbsp;&nbsp;Contact Me</a>
						</div>
					</div>
				</div>

				<h1>Our Regional Teams</h1>
				<p><b>Tap on a region to reveal the full team.</b></p>
				<div class="provinces">
					<div :class="['province', { selected: currentProvince === 2 }]">
						<a v-on:click="contactUsProvince(2)">
							<img src="../../images/header/crest/BCCrest.png" width="100%" alt="">
						</a>
					</div>
					<div :class="['province', { selected: currentProvince === 1 }]">
						<a v-on:click="contactUsProvince(1)">
							<img src="../../images/header/crest/ABCrest.png" width="100%" alt="">
						</a>
					</div>
					<div :class="['province', { selected: currentProvince === 8 }]">
						<a v-on:click="contactUsProvince(8)">
							<img src="../../images/header/crest/SKCrest.png" width="100%" alt="">
						</a>
					</div>
					<div :class="['province', { selected: currentProvince === 3 }]">
						<a v-on:click="contactUsProvince(3)">
							<img src="../../images/header/crest/MBCrest.png" width="100%" alt="">
						</a>
					</div>
					<div :class="['province', { selected: currentProvince === 4 }]">
						<a v-on:click="contactUsProvince(4)">
							<img src="../../images/header/crest/ONCrest.png" width="100%" alt="">
						</a>
					</div>
					<div :class="['province', { selected: currentProvince === 6 }]">
						<a v-on:click="contactUsProvince(6)">
							<img src="../../images/header/crest/QCCrest.png" width="100%" alt="">
						</a>
					</div>
					<div :class="['province', { selected: currentProvince === 5 }]">
						<a v-on:click="contactUsProvince(5)">
							<img src="../../images/header/crest/MTCrest.png" width="100%" alt="">
						</a>
					</div>
					<div :class="['province', { selected: currentProvince === 7 }]">
						<a v-on:click="contactUsProvince(7)">
							<img src="../../images/header/crest/NLCrest.png" width="100%" alt="">
						</a>
					</div>                
				</div>



				<div v-if="currentProvince !== null">
					<div v-for="(contactRoles, title) in computedContacts" :key="title">
						<h1>{{ title }}</h1>
						<div class="image-boxes" >
							<div class="image-box" v-for="contact in contactRoles" :key="contact.id">
								<div class="image">
									<img v-if="contact.has_pic === 1" :src="'/uploads/headshots/'+ hashIt(contact.name) + '.jpg'" width="100%" alt="">
									<span v-if="contact.has_pic !== 1"> 
										<img v-if="contact.gender === 'M'" src="../../images/headshots/male.jpg" width="100%" alt="">
										<img v-if="contact.gender === 'F'" src="../../images/headshots/female.jpg" width="100%" alt="">
									</span>
								</div>
								<div class="image-name">{{ contact.name }}</div>
								<div class="image-role">{{ contact.role_text }}</div>
								<a v-on:click="contactUsSelected = contact; contactUsSwitch = true" class="email-button"><i class="fas fa-envelope"></i>&nbsp;&nbsp;Contact Me</a>
							</div>
						</div>
					</div>
					
				</div>
				


            </div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import Form from '../components/Form.vue'

import axios from 'axios'

import _ from 'lodash'
export default {
	metaInfo() {
        return { 
            title: "Contact Us - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Slo-Pitch National regional contacts can be found here, as well as our National Office. We are happy to talk! Send us a message if you have a question.' },
				{ property: 'og:title', content: "Contact Us - Slo-Pitch National"},
				{ property: 'og:url', content:  'https://www.slo-pitch.com/contact-us' },
				{ property: 'og:site_name', content: 'Slo-Pitch National'},
				{ property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/contactus.png' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			contactUsSwitch: false,
			errors: [],
			contactInfo: {
				name: '',
				email: '',
				phone: '',
				message: '',
				subject: ''
			},
			contactUsSelected: null,
			currentProvince: null,
			contacts: null,
			national_office: null,
			president: null,
			awaiting_switch: false,
			board_of_governors: null
		};
	},
	components: {
		Header,
		Footer,
		Form
	},
	mounted: function() {
		var vm = this
		axios.get('/api/pages/contact-us').then(results => {
			if(results.data.success === true) {
				vm.contacts = results.data.contacts
				vm.president = results.data.president
				vm.national_office = results.data.national_office
				vm.board_of_governors = results.data.board_of_govenors

				
			}
		})
		
	},
	computed: {
		computedContacts: function() {
			var vm = this
			var filtered_contacts = _.filter(vm.contacts, function(contact) {
				return contact.region_id === vm.currentProvince
			})

			var group_by = _.groupBy(filtered_contacts, 'role_name')

			return group_by
		}
	},
	methods: {
		contactUsProvince: function(province) {
			this.currentProvince = province
		},
		hashIt: function(name) {
			return name.replace(' ', '-')
		},
		sendMessageToUser: function() {
			var vm = this
			vm.errors = []

			var verify = true
			var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

			if(vm.contactInfo.name.length === 0) {
				verify = false
				vm.errors.push('Name needs to be filled out')
			}
			if(vm.contactInfo.email.length === 0) {
				verify = false
				vm.errors.push('Email needs to be filled out')
			}

			if(vm.contactInfo.subject.length === 0) {
				verify = false
				vm.errors.push('Subject needs to be filled out')
			}
			if(!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(vm.contactInfo.email)) {
				verify = false
				vm.errors.push('Email is invalid')
			}
			if(vm.contactInfo.phone.length === 0) {
				verify = false
				vm.errors.push('Phone needs to be filled out')
			}
			if(!vm.contactInfo.phone.match(phoneno)) {
				verify = false
				vm.errors.push('Phone is invalid')
			}
			if(vm.contactInfo.message.length === 0) {
				verify = false
				vm.errors.push('Message needs to be filled out')
			}

			if(verify === true) {
				vm.awaiting_switch = true

				axios.post('/api/pages/contact-us-form', {
					contact_selected: vm.contactUsSelected,
					contact_info: vm.contactInfo
				}).then(results => {
					if(results.data.success === true) {
						this.$buefy.snackbar.open({
							message: 'Message sent! Please wait 24-48 hours for response.',
							type: 'is-success', // is-danger
							duration: 4000,
							queue: false
						})

						vm.contactInfo = {
							name: '',
							email: '',
							phone: '',
							message: ''
						}

						vm.contactUsSwitch = false

					} else {
						this.$buefy.snackbar.open({
							message: 'Message did not send. Make sure you have filled everything out.',
							type: 'is-danger', // is-danger
							duration: 4000,
							queue: false
						})

						vm.awaiting_switch = false
					}
				})
			} else {
				this.$buefy.snackbar.open({
					message: 'Message did not send. Make sure you have filled everything out.',
					type: 'is-danger', // is-danger
					duration: 4000,
					queue: false
				})
				vm.awaiting_switch = false
			}
		}
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
