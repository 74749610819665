<template>
	<div>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>

        <b-modal
            v-model="createNewPageSwitch"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            full-screen
            aria-modal>    
            <template #default="props">
                <div class="modal-card" style="overflow: scroll;">
                    <header class="modal-card-head">

                        <p class="modal-card-title" v-if="pageSelectedForEditing === null">Create a New Page</p>
                        <p class="modal-card-title" v-if="pageSelectedForEditing">Edit Page {{ pageSelectedForEditing.name }}</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
                    </header>
                    <CreatePage :editPage="pageSelectedForEditing" :latestNews="latestNews"></CreatePage>
                </div>
            </template>
        </b-modal>

        

		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper">
                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="page-header-title">
                                Manage Site Pages
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a v-on:click="createNewPageSwitch = true" class="button">Create New Page</a>
                                    <a v-on:click="createNewPageSwitch = true; latestNews = true" class="button">Create Latest News</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
				
                <b-message type="is-danger" has-icon aria-close-label="Close message">
                    To create a Provincial Results page, when creating the page use the url <b>pc-{region}-{year}</b> for example, pc-sk-2022.
                    <br>
                    To create a National Results page, <b>nc-{year}</b>
                    <br>
                    To create a newsletter page, <b>newsletter-{3 letter month}-{year}</b>
                    <br>
                    To create a National Host page, <b>nationals-host-{city name}</b>. You can also just edit a host page and change the slug if you are removing a host.
                    <br>
                    To create a Can Am Results page, <b>canam-results-{year}</b>.
                    <br>
                    When creating a blog post, the slug area must be unique and only display the unique portion. For example: breaking-news-rule-changes-2023. Do not add /content/ or /news/ before it, and do not duplicate slugs.
                </b-message>
                <br><br>
                <b-tabs v-model="tabSelected">
                    <b-tab-item value="pages" label="Pages">
                       
                            
                        <div class="row">
                            <div class="col-md-12">
                                
                                <b-table
                                    :data="pages"
                                    ref="table"
                                    :paginated="true"
                                    :current-page="1"
                                    per-page="20"
                                    :pagination-simple="false"
                                    :pagination-position="'bottom'"
                                    :opened-detailed="defaultOpenedDetails"
                                    detailed
                                    detail-key="id"
                                    @details-open="(row) => $buefy.toast.open(`Expanded ${row.user.first_name}`)"
                                    :show-detail-icon="showDetailIcon"
                                    aria-next-label="Next page"
                                    aria-previous-label="Previous page"
                                    aria-page-label="Page"
                                    aria-current-label="Current page">

                                    <b-table-column :searchable="true" field="name" label="Page Title" sortable v-slot="props">
                                        {{ props.row.name }}
                                        
                                    </b-table-column>

                                    <b-table-column :searchable="true"  field="slug" label="Page Url" sortable v-slot="props">
                                        /content/{{ props.row.slug }}
                                    </b-table-column>

                                    <b-table-column field="updated_at" label="Updated At" sortable v-slot="props">
                                        {{ moment(props.row.updated_at).format('YYYY-MM-DD h:mmA') }}
                                    </b-table-column>

                                    <b-table-column label="Edit Page" v-slot="props">
                                        <button class="button is-small" v-on:click="parsePageForEditing(props.row)">Edit</button>
                                        <a class="button  is-small" :href="'/content/' + props.row.slug">View Page</a>
                                        <a class="button is-small is-danger" v-on:click="deleteSitePage(props.row)">Delete</a>
                                    </b-table-column>

                                    <template slot="detail" slot-scope="props">
                                        
                                    </template>
                                </b-table>
                            </div>
                        </div>

                    </b-tab-item>
                    <b-tab-item value="latest_news" label="Latest News">
                        <div class="row">
                            <div class="col-md-12">
                                
                                <b-table
                                    :data="latest_news"
                                    ref="table"
                                    :paginated="true"
                                    :current-page="1"
                                    per-page="10"
                                    :pagination-simple="false"
                                    :pagination-position="'bottom'"
                                    :opened-detailed="defaultOpenedDetails"
                                    detailed
                                    detail-key="id"
                                    @details-open="(row) => $buefy.toast.open(`Expanded ${row.user.first_name}`)"
                                    :show-detail-icon="showDetailIcon"
                                    aria-next-label="Next page"
                                    aria-previous-label="Previous page"
                                    aria-page-label="Page"
                                    aria-current-label="Current page">

                                    <b-table-column :searchable="true" field="name" label="Page Title" sortable v-slot="props">
                                        {{ props.row.name }}
                                        <br>
                                        <a
                                            href="#"
                                            v-if="props.row.hide_post !== 1"
                                            v-on:click="hidePost(props.row, 1)"
                                            class="button is-small">Hide This Post</a>
                                        <a
                                            href="#"
                                            v-if="props.row.hide_post === 1"
                                            v-on:click="hidePost(props.row, null)"
                                             class="button is-small is-danger">Show This Post</a>
                                    </b-table-column>
                                    <b-table-column :searchable="true"  field="slug" label="Page Url" sortable v-slot="props">
                                        /content/{{ props.row.slug }}
                                    </b-table-column>

                                    <b-table-column field="created_at" label="Created At" sortable v-slot="props">
                                        {{ moment(props.row.created_at).format('YYYY-MM-DD h:mmA') }}
                                    </b-table-column>

                                    <b-table-column label="Edit Page" v-slot="props">
                                        <button class="button is-small" v-on:click="parsePageForEditing(props.row, true)">Edit</button>
                                        <a class="button is-small" :href="'/content/' + props.row.slug">View Page</a>
                                        <a class="button is-small is-danger" v-on:click="deleteNews(props.row)">Delete</a>
                                    </b-table-column>

                                    <template slot="detail" slot-scope="props">
                                        
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </b-tab-item>
                    <b-tab-item value="faq" label="FAQ">
                        <div class="row">
                            <div class="col-md-7">
                                <div class="faq" :key="question.id" v-for="question in faqs">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="faq-question">
                                                {{ question.question }} | {{ question.question_fr }}
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="faq-answer" v-html="question.answer">

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="faq-answer" v-html="question.fr_answer">

                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-md-3">
                                            
                                            <a style="float: right;" v-on:click="removeQuestion(question.id)" class="button is-small">Remove FAQ</a>
                                            <a style="float: right; margin-right: 10px;" v-on:click="editQuestion(question)" class="button is-small">Edit FAQ</a>
                                        </div>
                                    </div>
                                    
                                </div>

                            </div>
                            <div class="col-md-1"></div>
                            <div class="col-md-4">
                                <div class="padding">
									<div class="panel">
										<div class="panel-heading">
											<p>Add a Question</p>
										</div>
										<div class="panel-content" style="padding: 20px;">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <b-field label="Question">
                                                        <b-input v-model="newFaq.question"></b-input>
                                                    </b-field>
                                                </div>
                                                <div class="col-md-6">
                                                    <b-field label="Question (FR)">
                                                        <b-input v-model="newFaq.question_fr"></b-input>
                                                    </b-field>
                                                </div>
                                            </div>
                                            <b-field label="Answer">        
                                                <vue-editor 
                                                    useCustomImageHandler @image-added="handleImageAdded"
                                                    v-model="newFaq.answer">
                                                </vue-editor>
                                            </b-field>
                                            
                                             <b-field label="Answer (FR)">    
                                                <vue-editor 
                                                    useCustomImageHandler @image-added="handleImageAdded"
                                                    v-model="newFaq.answer_fr">
                                                </vue-editor>
                                            </b-field>
											
											<a class="admin-button" v-on:click="saveNewFaq()" style="margin-top: 10px;">Add FAQ to Website</a>
										</div>
									</div>
								</div>
                            </div>
                        </div>
                    </b-tab-item>
                </b-tabs>
            </div>
        </div>
    </div>

        
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import CreatePage from './components/CreatePage.vue'
import moment from 'moment'

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { VueEditor, Quill } from "vue2-editor";

import axios from 'axios'
export default {
	data() {

		return {
            isLoading: true,
            editPageSwitch: false,
            authUser: { first_name: null },
            createNewPageSwitch: false,
            latestNews: false,
            content_width: false,
            faqs: [],
            newFaq: {
                question: null,
                question_fr: null,
                answer: null,
                answer_fr: null
            },
            newPage: {
                name: null,
                slug: null,
                content: null,
                fr_content: null,  
            },
            contentAreas: [{
                type: 'textarea',
                widget_type: null,
                form_id: null,
                columns: [
                    {
                        width: 12,
                        content: null,
                        fr_content: null
                    },
                ]
            }],
            forms: [],
            tabSelected: 0,
            defaultOpenedDetails: [1],
            showDetailIcon: true,
            pageSelectedForEditing: null,
            pages: [],
            latest_news: []
            
		};
	},
	components: {
		Header,
        Footer,
        VueEditor,
        CreatePage
	},
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
			 
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.manage_site_pages !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }

                axios.get('/api/superuser/pages').then(results => {
                    if(results.data.success === true) {
                        vm.pages = results.data.pages
                        vm.latest_news = results.data.latest_news
                        // format the content
                    }
                })

                axios.get('/api/superuser/faqs').then(results => {
                    if(results.data.success === true) {
                        vm.faqs = results.data.faqs
                    }
                })

                axios.get('/api/superuser/forms').then(results => {
                    if(results.data.success === true) {
                        vm.forms = results.data.forms
                    }
                })

                vm.isLoading = false
            }
        })
	},
	computed: {
        
    },
    watch: {
        createNewPageSwitch: function(result) {
            var vm = this
            if(result === false) {
                vm.pageSelectedForEditing = null
            }
        }
    },
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        goBack: function(data){
            var r = confirm('Are you sure you want to do this? You will lose this work.')

            if(r === true) {
                this.pageSelectedForEditing = null
            }
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        saveNewPage: function() {
            var vm = this
            
            axios.post('/api/superuser/save-new-page', {
                page: vm.newPage,
                content: vm.contentAreas
            }).then(results => {
                
                if(results.data.success === true) {
                    vm.pages = results.data.pages
                    vm.$buefy.snackbar.open({
                        message: 'Saved page successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    vm.createNewPageSwitch = false
                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Something went wrong when saving this page',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        updatePage: function() {
            var vm = this
            console.log('vm.pageSelectedForEditing', vm.pageSelectedForEditing)
            axios.post('/api/superuser/update-page', {
                page: vm.pageSelectedForEditing
            }).then(results => {
                if(results.data.success === true) {
                    vm.pages = results.data.pages
                    vm.editPageSwitch = false
                    vm.$buefy.snackbar.open({
                        message: 'Updated page successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    
                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Something went wrong when updating this page',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        saveNewFaq: function() {
            var vm = this

            axios.post('/api/superuser/save-faq', {
                newFaq: vm.newFaq
            }).then(results => {
                if(results.data.success === true) {
                    
                    vm.$buefy.snackbar.open({
                        message: 'Updated/saved FAQ successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    vm.faqs = results.data.faqs
                }
            })
        },
        removeQuestion: function(question_id) {
            var vm = this

            var r = confirm('Are you sure you want to remove this question?')
            if(r === true) {
                axios.post('/api/superuser/remove-faq', {
                    question_id: question_id
                }).then(results => {
                    if(results.data.success === true) {
                        
                        vm.faqs = results.data.faqs
                    }
                })
            }
        },
        switchWidth: function($event) {
			this.content_width = !$event
        },
        addPageRow: function(area = null) {
            var vm = this
            
            if(area) {
                area.push({
                    type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    columns: [
                        {
                            width: 12,
                            content: null,
                            fr_content: null
                        },
                    ]
                })
            } else {
                vm.contentAreas.push({
                    type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    columns: [
                        {
                            width: 12,
                            content: null,
                            fr_content: null
                        },
                    ]
                })
            }
        },
        removeRow: function(index) {
            this.contentAreas.splice(index, 1);
        },
        handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("logo", file);

            

            axios.post('/api/teams/upload-photo', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(results => {
                if(results.data.success === true) {
                    Editor.insertEmbed(cursorLocation, "image", results.data.data.logo_path);
                    resetUploader();
                    // console.log('upload_path', results.data.data.logo_path)
                }
            })
        },
        closeModal: function() {
            this.createNewPageSwitch = false
        },
        changeColumns: function(number, key, area, edit = false) {
            
            if(number === 1) {
                var contentArea = {
                   type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    columns: [
                        {
                            width: 12,
                            content: null,
                            fr_content: null
                        },
                    ]
                }
            }
            if(number === 2) {
                var contentArea = {
                   type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    columns: [
                        {
                            width: 6,
                            content: null,
                            fr_content: null
                        },
                        {
                            width: 6,
                            content: null,
                            fr_content: null
                        },
                    ]
                }
            }
            if(number === 3) {
                var contentArea = {
                   type: 'textarea',
                    widget_type: null,
                    form_id: null,
                    columns: [
                        {
                            width: 4,
                            content: null,
                            fr_content: null
                        },
                        {
                            width: 4,
                            content: null,
                            fr_content: null
                        },
                        {
                            width: 4,
                            content: null,
                            fr_content: null
                        },
                    ]
                }
            }
            
            if(edit === false) {
                this.contentAreas.splice(key, 1, contentArea)
            } else {
                this.pageSelectedForEditing.content.splice(key,1, contentArea)
            }
        },
        editQuestion: function(question) {
            
            var vm = this
            vm.newFaq = question
        },
        parsePageForEditing: function(page, news = false) {
            var vm = this
            page.contentAreas = page.content

            vm.pageSelectedForEditing = page
            vm.latestNews = news
            vm.createNewPageSwitch = true
        },
        triggerCreatePageClose: function() {
            var vm = this

            vm.createNewPageSwitch = false
            
            vm.pageSelectedForEditing = null
        },
        deleteNews: function(page) {
            var r = confirm('Are you sure you want to do this?')
            var vm = this
            if(r === true) {
                axios.post('/api/superuser/delete-news-page', {
                    page_id: page.id
                }).then(results => {
                    if(results.data.success === true) {
                        axios.get('/api/superuser/pages').then(results => {
                            if(results.data.success === true) {
                                vm.pages = results.data.pages
                                vm.latest_news = results.data.latest_news
                                // format the content
                            }
                        })
                        this.$buefy.snackbar.open({
                            message: 'Page deleted!',
                            type: 'is-success' // is-danger
                        })
                    }
                })
            }
        },
        hidePost: function(page, flip) {
            
            axios.post('/api/superuser/hide-post', {
                post_id: page.id,
                flip: flip
            }).then(results => {
                if(results.data.success === true) {
                    page.hide_post = flip
                }
            })
        },
        deleteSitePage: function(page) {
            var r = confirm('Are you sure you want to do this?')
            var vm = this
            if(r === true) {
                axios.post('/api/superuser/delete-site-page', {
                    page_id: page.id
                }).then(results => {
                    if(results.data.success === true) {
                        axios.get('/api/superuser/pages').then(results => {
                            if(results.data.success === true) {
                                vm.pages = results.data.pages
                                vm.latest_news = results.data.latest_news
                                // format the content
                            }
                        })
                        this.$buefy.snackbar.open({
                            message: 'Page deleted!',
                            type: 'is-success' // is-danger
                        })
                    }
                })
            }
        }
	},
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
