<template>
	<div>
		<b-loading :is-full-page="true" v-model="isLoading"></b-loading>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<b-modal
			has-modal-card
			full-screen
			style="z-index: 40 !important;"
			v-model="editClinicSwitch">
			<template #default="props">
                <div class="modal-card">
					<header class="modal-card-head">
						<p class="modal-card-title">Edit {{ clinicSelected.name }}</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<div class="row">
							<div class="col-md-3">
								<div class="padding">
									<b-field :label="(en === true ? 'Clinic Name' : 'Nom de la Clinique')">
										<b-input v-model="clinicSelected.name"></b-input>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field :label="(en === true ? 'Clinic Name (FR)' : 'Nom de la Clinique Fr')">
										<b-input v-model="clinicSelected.fr_name"></b-input>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<b-field :label="(en === true ? 'Assign to a new clinician' : 'Assign to a new clinician')">
										<b-input v-model="clinicSelected.assign_to_new_user"></b-input>
									</b-field>
							</div>
							<div class="col-md-3"></div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="padding">
										<div class="padding">
											<b-field label="Street Name">
												<b-input icon-pack="fas"
													icon="street-view"
													expanded
													id="autocomplete" v-model="clinicSelected.street_name"></b-input>
											</b-field>
											<b-field label="City">
												<b-input icon-pack="fas"
													icon="street-view"
													expanded
													id="autocomplete" v-model="clinicSelected.city"></b-input>
											</b-field>
											<b-field label="Postal Code">
												<b-input icon-pack="fas"
													icon="street-view"
													expanded
													id="autocomplete" v-model="clinicSelected.postal_code"></b-input>
											</b-field>
										</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Cost to Attend ($)">
										<b-numberinput v-model="clinicSelected.fee_to_register"></b-numberinput>
									</b-field>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<b-field label="Registration Start Date">
									<b-datepicker
										placeholder="Click to select..."
										icon="calendar-today"
										v-model="clinicSelected.registration_start_date"
										horizontal-time-picker>
									</b-datepicker>
								</b-field>
							</div>
							<div class="col-md-3">
								<b-field label="Registration End Date">
									<b-datepicker
										placeholder="Click to select..."
										icon="calendar-today"
										v-model="clinicSelected.registration_end_date"
										horizontal-time-picker>
									</b-datepicker>
								</b-field>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Clinic Date">
										<b-datepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinicSelected.event_date"
											horizontal-time-picker>
										</b-datepicker>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Clinic Start Time">
										<b-timepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinicSelected.start_time"
											horizontal-time-picker>
										</b-timepicker>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Clinic End Time">
										<b-timepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinicSelected.end_time"
											horizontal-time-picker>
										</b-timepicker>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">

							</div>
						</div>
						
						<div class="row">
							<div class="col-md-6">
								<div class="padding">
									<b-field extended label="Description">
										
										<b-input v-model="clinicSelected.description" maxlength="200" type="textarea"></b-input>
										
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Maximum Registrants">
										<b-numberinput v-model="clinicSelected.max_participants"></b-numberinput>
									</b-field>
									<b-checkbox v-model="clinicSelected.allow_waitlist">
                                        Allow a waitlist
                                    </b-checkbox>
								</div>
							</div>
						</div>
						
					</section>
					<footer class="modal-card-foot">
                        <button v-on:click="updateClinic()" class="admin-button"><i class="fas fa-save"></i> Update Clinic</button>
                    </footer>
                </div>
            </template>
		</b-modal>
		<b-modal
			has-modal-card
			v-model="viewRegistrantsSwitch">
			<template #default="props">
                <div class="modal-card">
					<header class="modal-card-head">
						<p class="modal-card-title" style="font-size: 18px;">View Registrants for {{ displayRegistrants.name }} </p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<b-tabs>
							<b-tab-item label="View Registrants">
								<table style="width: 100%" class="table">
									<tr :key="registrant.id" v-for="(registrant, key) in displayRegistrants.registrants">
										<td style="width: 50%;">{{ registrant.user.first_name }} {{ registrant.user.last_name }}</td>
										<td>
											<span v-if="registrant.on_waitlist === 1 || registrant.on_waitlist === true">On Waitlist</span>
										</td>
										<td>
											<a v-on:click="moveRegistrantWaitlist(registrant)" v-if="registrant.on_waitlist === 0 || registrant.on_waitlist === false" class="button is-small is-info">Move Onto Waitlist</a>
											<a v-on:click="moveRegistrantWaitlist(registrant)" v-if="registrant.on_waitlist === 1 || registrant.on_waitlist === true" class="button is-small is-warning">Move Off Waitlist</a>
										</td>
										<td>
											<a v-on:click="removeRegistrant(registrant, key)" class="button is-small is-danger">Remove from Registrants</a>
										</td>
									</tr>
								</table>
							</b-tab-item>
							<b-tab-item label="Add to Registrants">
								<div class="add-a-registrant">
									<div class="row">
										<div class="col-md-12">
											<b-field label="Add a Registrant">
												<b-input v-on:input="updatePlayerSearch($event)" placeholder="Add their SPN registered email"></b-input>
											</b-field>
										</div>
									</div>
								</div>
								<div style="margin: 20px;">
									<nav class="level" v-for="(user, key) in searchList" :key="key">
										<div class="level-left">
											<p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
											<p class="level-item">{{ user.city }}</p>
											<p class="level-item">{{ user.gender }}</p>
                                
											<p class="level-item">
												<RankComponent :currentRank="user.current_rank_id"
												:previousRank="user.previous_rank_id"></RankComponent>
											</p>
											<p class="level-item">
												<span v-if="user.latest_waiver_signed === $latest_waiver">Signed</span>
											</p>
										</div>
										<div class="level-right">
											<a class="button is-small" v-on:click="addToPlayersSelected(user)"><i class="fas fa-plus"></i></a>
										</div>
									</nav>
								</div>
								<div class="adding">
									<p><b>Adding:</b></p>
								</div>
								
								<section class="modal-card-body">
									<nav class="level" v-for="(user, key) in playersSelected" :key="key">
										<div class="level-left">
											<p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
											<p class="level-item">{{ user.city }}</p>
										</div>
										<div class="level-right">
											<a class="button is-danger is-small" v-on:click="removeFromPlayersSelected(key)"><i class="fas fa-trash"></i></a>
										</div>
									</nav>
								</section>
								<footer class="modal-card-foot">
									<a class="admin-button" v-on:click="addToClinic()">Add Players To Clinic</a>
								</footer>
							</b-tab-item>
						</b-tabs>
						
					</section>
			
                </div>
            </template>


			
		</b-modal>
		<b-modal 
            v-model="createNewClinicSwitch"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            full-screen
            aria-modal>
            <template #default="props">
                <div class="modal-card">
					<header class="modal-card-head">
						<p class="modal-card-title">Create a New Clinic</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<div class="row">
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Clinic Name">
										<b-input v-model="clinic.name"></b-input>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Clinic Name (FR)">
										<b-input v-model="clinic.fr_name"></b-input>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<b-field :label="(en === true ? 'Region' : 'Région')">
									<b-tooltip type="is-dark" label="This is the region your tournament is hosted in.">
										<b-dropdown hoverable aria-role="list" v-model="newTournamentRegion">
											<div class="button is-info" slot="trigger">
												<span v-if="newTournamentRegion">
													{{ newTournamentRegion.name }}
												</span>
												<span v-if="!newTournamentRegion">
													{{ en === true ? 'Select your HQ below' : 'Sélectionnez votre QG' }}
												</span>
												<b-icon icon="menu-down"></b-icon>
											</div>

											<b-dropdown-item :key="key" :value="region" v-for="(region, key) in regions" aria-role="listitem">{{ region.name }}</b-dropdown-item>
										</b-dropdown>	
									</b-tooltip>
								</b-field>
							</div>
							<div class="col-md-3"></div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Street Name">
										<b-input icon-pack="fas"
											icon="street-view"
											expanded
											id="autocomplete" v-model="clinic.street_name"></b-input>
									</b-field>
									<b-field label="City">
										<b-input icon-pack="fas"
											icon="street-view"
											expanded
											id="autocomplete" v-model="clinic.city"></b-input>
									</b-field>
									<b-field label="Postal Code">
										<b-input icon-pack="fas"
											icon="street-view"
											expanded
											id="autocomplete" v-model="clinic.postal_code"></b-input>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field :label="(en === true ? 'Cost to Attend ($)' : 'Cout de Participation')">
										<b-numberinput v-model="clinic.fee_to_register"></b-numberinput>
									</b-field>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Clinic Date">
										<b-datepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinic.event_date"
											horizontal-time-picker>
										</b-datepicker>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Clinic Start Time">
										<b-timepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinic.start_time"
											horizontal-time-picker>
										</b-timepicker>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Clinic End Time">
										<b-timepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinic.end_time"
											horizontal-time-picker>
										</b-timepicker>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">

							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Registration Start Date">
										<b-datepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinic.registration_start_date"
											horizontal-time-picker>
										</b-datepicker>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Registration End Date">
										<b-datepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinic.registration_end_date"
											horizontal-time-picker>
										</b-datepicker>
									</b-field>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="padding">
									<b-field extended label="Description">
										
										<b-input v-model="clinic.description" maxlength="200" type="textarea"></b-input>
										
									</b-field>
								</div>
							</div>
							<div class="col-md-3">
								<div class="padding">
									<b-field label="Maximum Registrants">
										<b-numberinput v-model="clinic.max_participants"></b-numberinput>
									</b-field>
									<b-checkbox v-model="clinic.allow_waitlist">
                                        Allow a waitlist
                                    </b-checkbox>
								</div>
							</div>
						</div>
						
					</section>
					<footer class="modal-card-foot">
                        <button v-on:click="saveNewClinic()" class="admin-button"><i class="fas fa-save"></i> Save New Clinic</button>
                    </footer>
                </div>
            </template>
		</b-modal>

		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper">
				<div :class="['page-header', { sidebarClosed: !content_width }]">
					<div class="row">
						<div class="col-md-3">
							<div class="page-header-title">
								Manage Clinics
							</div>
						</div>
						<div class="col-md-9">
							<div class="padding">
							<b-field  grouped position="is-right">
								<a v-on:click="createNewClinicSwitch = true" class="button">{{ en === true ? 'Create New Clinic' : 'Créer une Nouvelle Clinique' }}</a>
							</b-field>
							</div>
						</div>
					</div>
				</div>
				<div class="admin-area">
					<b-table 
						:data="clinics" 
						:striped="true"
						:focusable="true"
						paginated
						per-page="10"
					>
						<b-table-column field="id" label="ID">
							<template v-slot="props">
								{{ props.row.id }}
							</template>
						</b-table-column>
						<b-table-column field="region.name" :label="(en === true ? 'Region' : 'Région')">
							<template v-slot="props">
								{{ props.row.region.short_name }}
							</template>
						</b-table-column>
						<b-table-column field="clinician.last_name" label="Hosted By">
							<template v-slot="props">
								{{ props.row.clinician.first_name }} {{ props.row.clinician.last_name }}
							</template>
						</b-table-column>
						<b-table-column field="name" label="Name">
							<template v-slot="props">
								{{ props.row.name }}/{{ props.row.fr_name }}
							</template>
						</b-table-column>
						<b-table-column field="event_date" label="Event Date">
							<template v-slot="props">
								{{ moment(props.row.event_date).utc().format('YYYY-MM-DD') }}
							</template>
						</b-table-column>
						<b-table-column field="start_time" label="Time">
							<template v-slot="props">
								{{ momentUtc(props.row.start_time, 'HH:mm:ss').local().format('h:mm A') }} -  {{ momentUtc(props.row.end_time, 'HH:mm:ss').local().format('h:mm A') }}
							</template>
						</b-table-column>
						<b-table-column field="street_address" label="Street Address">
							<template v-slot="props">
								{{ props.row.street_address }}
							</template>
						</b-table-column>
						<b-table-column field="city" label="City">
							<template v-slot="props">
								{{ props.row.city }}
							</template>
						</b-table-column>
						<b-table-column field="postal_code" label="Postal Code">
							<template v-slot="props">
								{{ props.row.postal_code }}
							</template>
						</b-table-column>
						<b-table-column field="postal_code" label="Spots">
							<template v-slot="props">
								{{ props.row.registrants.length}}/<b>{{ props.row.max_participants }}</b>
							</template>
						</b-table-column>
						<b-table-column field="buttons">
							<template v-slot="props">
								<a class="button is-small is-warning" v-on:click="editClinic(props.row)">Edit</a>
								<a class="button is-small is-success" v-on:click="approveClinic(props.row)" v-if="props.row.status === 'unapproved'">Approve Clinic</a>
								<a class="button is-small is-danger" v-on:click="revokeApproval(props.row)" v-if="props.row.status === 'approved'">Revoke Approval</a>
								<a class="button is-small is-info" v-on:click="viewRegistrants(props.row)">View Registrants</a>
							</template>
						</b-table-column>
					</b-table>
				</div>
			</div>
		</div>
		
		
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import RankComponent from '../components/RankComponent.vue'
import axios from 'axios'
import moment from 'moment'
export default {
	data() {
		return {
			en: true,
			isLoading: true,
			authUser: { first_name: null },
			content_width: false,
			createNewClinicSwitch: false,
			clinicSelected: null,
			editClinicSwitch: false,
			viewRegistrantsSwitch: false,
			displayRegistrants: null,
			clinic: {
				name: null,
				fr_name: null,
				description: null,
				location: null,
				secondary_clinician: null,
				clinic_date: null,
				start_time: null,
				end_time: null,
				headquarter_google_address: null,
				max_participants: 1,
				registration_start_date: null,
				registration_end_date: null,
				allow_waitlist: false,
				fee_to_register: 0,
			},
			regions: [],
			searchList: [],
			playersSelected: [],
			clinics: [],
			region_rate: null,
			user_permissions: null,
			newTournamentRegion: null
		};
	},
	watch: {
		newTournamentRegion: function() {
			this.getRegionRate()
		},
		createNewClinicSwitch: function(result) {
            var vm = this
            if(result === true) {
                vm.$nextTick(() => {
             

                })
            }
		},
		editClinicSwitch: function(result) {
			var vm = this

			if(result === true) {
				vm.$nextTick(() => {
					
                })
			}
		}
	},
	components: {
		Header,
		Footer,
		RankComponent
	},
	mounted: function() {
		// grab all the dashboard information now
		var vm = this
		
		axios.get('/auth/check').then(results => {  
			
            if(results.data.type === "success") {
                vm.authUser = results.data.user
				vm.user_permissions = results.data.user_permissions
                if(results.data.user_permissions.manage_clinics !== 1) {
                    this.$router.push({ name: 'Dashboard' })
				}
				
				vm.isLoading = false

				axios.get('/api/superuser/clinics').then(results => {
					if(results.data.success === true) {
						vm.clinics = results.data.clinics
						if(vm.user_permissions) {
							if(vm.user_permissions.specific_region_only) {
								vm.clinics = _.filter(vm.clinics, function(clinic) {
									if(clinic.region_id === vm.user_permissions.specific_region_only) {
										return true
									}
									return false
								})
							}
						}

						var i = 0

						for(i; i < vm.clinics.length; i++) {
							var clinic = vm.clinics[i]

							vm.clinics[i].event_date = new Date(clinic.event_date)
							vm.clinics[i].registration_start_date = new Date(clinic.registration_start_date)
							vm.clinics[i].registration_end_date = new Date(clinic.registration_end_date)
							vm.clinics[i].start_time = new Date(clinic.start_time)
							vm.clinics[i].end_time = new Date(clinic.end_time)
						}
					}
				})
			}
		})

		if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
			
		axios.get('/auth/regions').then(results => {
			vm.regions = results.data.regions
		})
        

		
	},
	computed: {
	
	},
	methods: {
		getRegionRate: function() {
			var vm = this
			axios.post('/api/tournaments/region-rate', {
				region: vm.newTournamentRegion.id
			}).then(results => {
				if(results.data.success === true) {
					vm.region_rate = results.data.region_rate
				}
			})
		},
		getAddressData: function (addressData, placeResultData, id) {


		},
		fillInAddress: function() {
			var vm = this
			var place = this.autocompleteAddress.getPlace();
			var fields = this.autocompleteAddress.getFields()
			this.clinic.location = place.formatted_address

			// find components
			var address_components = place.address_components

			var postal_code = _.find(address_components, function(address) {
				if(address.types.includes('postal_code')) {
					return true
				}
			})

			var province = _.find(address_components, function(address) {
				if(address.types.includes('administrative_area_level_1')) {
					return true
				}
			})

			if(province.short_name === 'NS' || province.short_name === 'PE' || province.short_name === 'NB' ) {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MT') {
						return true
					}
				})

				
				vm.newTournamentRegion = region_data
			} else if (province.short_name === 'NT') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'AB') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else if (province.short_name === 'NU') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MB') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else if (province.short_name === 'YT') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'BC') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === province.short_name) {
						return true
					}
				})
				
				vm.newTournamentRegion = region_data
			}

			var city = _.find(address_components, function(address) {
				if(address.types.includes('locality')) {
					return true
				} else {
                    if(address.types.includes('sublocality')) {
                        return true
                    }
                }
			})

			var street_number = _.find(address_components, function(address) {
				if(address.types.includes('street_number')) {
					return true
				}
			})

			var route = _.find(address_components, function(address) {
				if(address.types.includes('route')) {
					return true
				}
			})

			vm.clinic.headquarter_google_address = {
				written_address: place.formatted_address,
				street_address: street_number.long_name + ' ' + route.long_name,
				city: city.long_name,
				province: province.short_name,
				postal_code: postal_code.long_name,
				latitude:  place.geometry.location.lat(),
				longitude: place.geometry.location.lng()
			}

			// set province to region  -- newTournamentRegion - need to only send the shortcode and then match them here (some provinces will have to apply to multiple areas)
				// maritimes == NS, PEI, NB

				

			// var i = 0
			// var string = ''
			// for(i; i < place.address_components.length; i++) {
			// 	if(place.address_components[i].types[0] !== 'subpremise') {
			// 		string += place.address_components[i].long_name + ', '
			// 	}
			// }
			// this.newTournament.headquarter_location = string
			// console.log(string)
		},
		switchWidth: function($event) {
			this.content_width = !$event
		},
		saveNewClinic: function() {
			var vm = this

			axios.post('/api/superuser/save-clinic', {
				clinic: vm.clinic,
				region: vm.newTournamentRegion
			}).then(results => {
				if(results.data.success === true) {
					vm.clinics = results.data.clinics

					vm.createNewClinicSwitch = false


					vm.clinic = {
						name: null,
						fr_name: null,
						description: null,
						location: null,
						secondary_clinician: null,
						clinic_date: null,
						start_time: null,
						end_time: null,
						headquarter_google_address: null,
						max_participants: 1,
						registration_start_date: null,
						registration_end_date: null,
						allow_waitlist: false,
						fee_to_register: 0,
					}

					this.$buefy.snackbar.open({
                        message: 'Saved new clinic',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'Clinic failed to save',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		moment: function(date, format = null) {
			return moment(date, format)
		},
		approveClinic: function(clinic) {
			var vm = this
			axios.post('/api/superuser/change-clinic-status', {
				clinic: clinic.id,
				status: 'approved'
			}).then(results => {
				if(results.data.success === true) {
					vm.clinics = results.data.clinics
				}
			})
		},
		revokeApproval: function(clinic) {
			var vm = this
			axios.post('/api/superuser/change-clinic-status', {
				clinic: clinic.id,
				status: 'unapproved'
			}).then(results => {
				if(results.data.success === true) {
					vm.clinics = results.data.clinics
				}
			})
		},
		editClinic: function(clinic) {
			var vm = this
			clinic.assign_to_new_user = null
			vm.clinicSelected = clinic

			if(vm.clinicSelected.event_date) {
				var formatted = moment(vm.clinicSelected.event_date, 'YYYY-MM-DD').utc()
				
				vm.clinicSelected.event_date = new Date(parseInt(formatted.format('YYYY')), parseInt(formatted.format('M')) - 1, parseInt(formatted.format('D')))
			}

			vm.$nextTick(() => {

			})

			// vm.clinicSelected.start_time = vm.momentUtc(vm.clinicSelected.start_time, 'HH:mm:ss').local()
			// vm.clinicSelected.end_time = vm.momentUtc(vm.clinicSelected.end_time, 'HH:mm:ss').local()
			
			vm.editClinicSwitch = true
		},
		viewRegistrants: function(clinic) {
			var vm = this

			vm.viewRegistrantsSwitch = true

			vm.displayRegistrants = clinic
		},
		updateClinic: function() {
			var vm = this

			vm.clinicSelected.headquarter_google_address = vm.clinic.headquarter_google_address
			
			axios.post('/api/superuser/update-clinic', {
				clinic: vm.clinicSelected
			}).then(results => {
				if(results.data.success === true) {
					vm.clinics = results.data.clinics

					vm.editClinicSwitch = false

					vm.clinic = {
						name: null,
						fr_name: null,
						description: null,
						location: null,
						secondary_clinician: null,
						clinic_date: null,
						start_time: null,
						end_time: null,
						headquarter_google_address: null,
						max_participants: 1,
						registration_start_date: null,
						registration_end_date: null,
						allow_waitlist: false,
						fee_to_register: 0,
					}

					this.$buefy.snackbar.open({
                        message: 'Saved new clinic',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'Clinic failed to save',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		moveRegistrantWaitlist: function(registrant) {
			var vm = this
			axios.post('/api/superuser/update-registrant-waitlist', {
				waitlist: registrant.id
			}).then(results => {
				if(results.data.success === true) {
					
					registrant.on_waitlist = !registrant.on_waitlist
				}
			})
		},
		removeRegistrant: function(registrant, key) {
			var vm = this
			axios.post('/api/superuser/remove-registrant-waitlist', {
				waitlist: registrant.id
			}).then(results => {
				if(results.data.success === true) {
					
					this.displayRegistrants.registrants.splice(key, 1)
				}
			})
		},
		parseDate: function($event) {
			
		},
		addToPlayersSelected: function(user) {
            this.playersSelected.push(user)
        },
        removeFromPlayersSelected: function(key) {
            this.playersSelected.splice(key, 1)
        },
		updatePlayerSearch: function($event) {
            var vm = this

            vm.isLoading = true

            if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                axios.post(`/api/users/search/players`, {
                    search_name: $event,
					region: vm.displayRegistrants.region_id
                }).then(results => {
                    if(results.data.success === true) {
                        vm.isLoading = false
                        vm.searchList = results.data.users
                    }
                })
            }, 500)
        },
		addToClinic: function() {
            
            var vm = this

            axios.post('/api/superuser/umpires-add-to-clinic', {
                registrants: vm.playersSelected,
                clinic_id: vm.displayRegistrants.id
            }).then(results => {
                
                if(results.data.success === true) {
                    
                    vm.playersSelected = []
                    vm.viewRegistrantsSwitch = false

                    this.$buefy.snackbar.open({
                        message: 'You have successfully added registrants to your clinic.',
                        type: 'is-success' // is-danger
                    })
                } else {
                    vm.error = results.data.error
                    this.$buefy.snackbar.open({
                        message: 'Adding registrants to your clinic did not work.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
		momentUtc: function(date, format) {
			return moment.utc(date, format)
		},
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
