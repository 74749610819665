<template>
	<div>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper">

                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="page-header-title">
                                Manage Waivers
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="padding">
                    
                            </div>
                        </div>
                    </div>
                </div>
                <div class="admin-area">
                    <div class="row">
                        <div class="col-md-5">
                            <p class="note">
                                <span>Please note:</span> the rollback feature will allow you to rollback to the previous waiver (including restoring anyone waivers who signed it). <u>You cannot roll back more than once until a new waiver is created.</u>
                            </p>

                            <b-table
                                :data="waivers"
                                ref="table"
                                paginated
                                per-page="5"
                                :opened-detailed="defaultOpenedDetails"
                                detailed
                                detail-key="id"
                                @details-open="(row) => $buefy.toast.open(`Expanded ${row.user.first_name}`)"
                                :show-detail-icon="showDetailIcon"
                                aria-next-label="Next page"
                                aria-previous-label="Previous page"
                                aria-page-label="Page"
                                aria-current-label="Current page">

                            

                                <b-table-column field="date" label="Waiver Date" sortable centered v-slot="props">
                                    {{ new Date(props.row.created_at).toLocaleDateString() }}    
                                </b-table-column>

                            

                                <b-table-column label="Rollback" v-slot="props">
                                    <button class="button">Rollback to this Version</button>
                                </b-table-column>

                                <template slot="detail" slot-scope="props">
                                    <article class="media">
                                        <div class="media-content">
                                        
                                            <pre :key="key" v-for="(section,key) in JSON.parse(props.row.written_waiver)" v-html="section.section">
                                                
                                            </pre>
                                            <pre>------------------------------</pre>
                                            <pre :key="key" v-for="(section,key) in JSON.parse(props.row.written_waiver)" v-html="section.fr_section">
                                                
                                            </pre>
                                        </div>
                                    </article>
                                    <a class="button" style="margin-top: 30px;" v-on:click="copyToNewWaiver(props.row.written_waiver)">Copy This To New Waiver</a>
                                </template>
                            </b-table>
                        </div>
                        <div class="col-md-7">
                            <div class="column-padding">
                                <div class="create-new-waiver">
                                    <div class="panel">
                                        <div class="panel-heading">
                                            <p><b>Create New Waiver</b></p>
                                        </div>
                                        <div class="panel-content" style="padding: 20px;">
                                            <p class="note">
                                                <span>Please note:</span> creating a new waiver will prompt all accounts to sign it upon next login.
                                            </p>
                                            <div style="margin-bottom: 10px;" :key="key" v-for="(agreeable, key) in agreeableSections">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <ckeditor :editor="editor" v-model="agreeable.section" :config="editorConfig"></ckeditor>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <ckeditor :editor="editor" v-model="agreeable.fr_section" :config="editorConfig"></ckeditor>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <a class="button button-primary" v-on:click="addAgreeableSection()"><i class="fas fa-plus"></i> Add Agreeable Section</a>
                                            <br>
                                            <a v-on:click="saveWaiver()" class="admin-button" style="margin-top: 20px; font-size: 16px;"><i class="fas fa-save"></i>Save Waiver & Reset Memberbase</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
		
	</div>
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import axios from 'axios'
export default {
	data() {
		return {
            isLoading: true,
            authUser: { first_name: null },
            defaultOpenedDetails: [1],
            showDetailIcon: true,
            waivers: [],
            agreeableSections: [
                {
                    section: '<p>English column</p>',
                    fr_section: '<p>French column</p>',
                    checked: false
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the rich-text editor.
            },
            confirmSaveWaiver: false,
            content_width: false,
            
		};
	},
	components: {
		Header,
		Footer,
    },
    computed: {
        computedWaiverSubmission: function() {
            
        }
    },
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
			
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.manage_waivers !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }
			
                axios.get('/api/superuser/waivers').then(results => {
                    
                    if(results.data.success === true) {
                        vm.waivers = results.data.waivers
                    }
                })

                vm.isLoading = false

            }
        })
	},
	computed: {
	
	},
	methods: {
        saveWaiver: function() {
            var vm = this
            
            var r = confirm('Are you sure you want to do this? This will clear all current members of waivers they have previously signed, requiring them to agree when logging in next.')

            if(r === true) {
                axios.post('/api/superuser/save-new-waiver', {
                    written_waiver: JSON.stringify(vm.agreeableSections)
                }).then(results => {
                    if(results.data.success === true) {
                        vm.waivers = results.data.waivers
                    }
                })
            }
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        addAgreeableSection() {
            this.agreeableSections.push({
                section: '<p>This is your next agreeable section</p>',
                fr_section: '<p>This is your next agreeable section in French</p>',
                checked: false
            })
        },
        copyToNewWaiver: function(waiver) {
            
            this.agreeableSections = JSON.parse(waiver)
        },
        switchWidth: function($event) {
			this.content_width = !$event
		}
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
