<template>
    <span>
        <h2 v-if="en">Umpire Cheat Sheet</h2>
		<h2 v-if="en === false">
			Notes pour Règles Local
		</h2>
        <p v-if="en">This page displays a cheat sheet based on events that you are marked to umpire. If you are participating as an umpire for an event not on this list, please contact the convenor! </p>
		<p v-if="en === false">Cette page affiche une feuille des règlements spéciaux à un événement ou vous êtes cédulé comme arbitre. Si vous participez en tant qu’arbitre pour un événement qui ne fait pas partie de cette liste, veuillez communiquer avec l’organisateur!</p>
		<b-field :label="en === true ? 'Select An Event' : 'Choisir Un Événement'" style="margin-top: 10px;">
            <b-select :placeholder="en === true ? 'Select a name' : 'Choisir un nom'" v-model="selectedCheatSheet">
                <option
                    v-for="sheet in cheat_sheets"
                    :value="sheet"
                    :key="sheet.id">
                    {{ sheet.event.name }}
                </option>
            </b-select>
        </b-field>

		<table class="table" style="width: 100%; margin-top: 20px;" v-if="selectedCheatSheet">
			<tr>
				<td><b>Innings</b></td>
				<td>
					{{ selectedCheatSheet.sheet.innings }}
				</td>
			</tr>
			<tr>
				<td><b>Call last inning at __ minutes</b></td>
				<td>
					{{ selectedCheatSheet.sheet.call_last_inning_after }}
				</td>
			</tr>
			<tr>
				<td><b>Minimum players on field</b></td>
				<td>
					{{ selectedCheatSheet.sheet.minimum_players }}
				</td>
			</tr>
			<tr>
				<td><b>HR Rule</b></td>
				<td>
					{{ selectedCheatSheet.sheet.hr_rule }}
				</td>
			</tr>
			<tr>
				<td><b>Anticipation</b></td>
				<td>
					{{ selectedCheatSheet.sheet.anticipation === 1 ? 'True' : 'False' }}
				</td>
			</tr>
			<tr>
				<td><b>Ties</b></td>
				<td>
					{{ selectedCheatSheet.sheet.ties === 1 ? 'True' : 'False' }}
				</td>
			</tr>
			<tr>
				<td><b>Courtesy Runners</b></td>
				<td>
					{{ selectedCheatSheet.sheet.throw_to_first_from_outfield === 1 ? 'True' : 'False' }}
				</td>
			</tr>
			<tr>
				<td><b>Max runs per inning</b></td>
				<td>
					{{ selectedCheatSheet.sheet.max_runs_per_inning === 1 ? 'True' : 'False' }}
				</td>
			</tr>
			<tr>
				<td><b>Mercy Rule</b></td>
				<td>
					{{ selectedCheatSheet.sheet.mercy_rule === 1 ? 'True' : 'False' }}
				</td>
			</tr>
			<tr>
				<td><b>Tag at home</b></td>
				<td>
					{{ selectedCheatSheet.sheet.tag_at_home === 1 ? 'True' : 'False' }}
				</td>
			</tr>
			<tr>
				<td><b>Throw to first from outfield</b></td>
				<td>
					{{ selectedCheatSheet.sheet.throw_to_first_from_outfield === 1 ? 'True' : 'False' }}
				</td>
			</tr>
			<tr>
				<td><b>Additional Rules</b></td>
				<td>
					{{ selectedCheatSheet.sheet.additional_rules }}
				</td>
			</tr>
		</table>
    </span>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
export default {
	data() {
		return {
			cheat_sheets: null,
         	selectedCheatSheet: null
		};
	},
	components: {
		
	},
	mounted: function() {
		var vm = this
        axios.get('/api/umpires/get-your-cheat-sheets').then(results => {
			if(results.data.success === true) {
				vm.cheat_sheets = results.data.cheat_sheets
			}
		})
	},
	props: ['en'],
	computed: {
	
	},
	methods: {
		moment: function(date, format = null) {
            return moment(date, format)
        }
	}
}
</script>

<style lang="scss">
    
</style>
