var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('b-loading',{attrs:{"is-full-page":true},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_vm._v(" "),_c('h2',[_vm._v(_vm._s(_vm.en === true ? 'Leagues & Tournaments I Am Registered In' : 'Tournois & Ligues'))]),_vm._v(" "),_c('Accordion',{attrs:{"preOpen":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.en === true ? 'Leagues I Am Registered In' : 'Ligues')+"\n        ")]},proxy:true},{key:"content",fn:function(){return [(_vm.en === true)?_c('b-message',[_c('p',[_vm._v("If you are the coach or team contact, you can edit your league rosters in here. Click the Edit League Roster button below on the league you are in.")]),_vm._v(" "),_c('p',[_c('b',[_vm._v("If you do not see a team listed that you expect to be here, contact your league president to make sure they have added your team, and have you listed as a coach or player.")])])]):_vm._e(),_vm._v(" "),(_vm.en === false)?_c('b-message',[_c('p',[_vm._v("Si vous êtes l’entraîneur ou le contact de l’équipe, vous pouvez modifier les listes de votre ligue ici. Cliquez sur le bouton Modifier la liste de la ligue ci-dessous sur la ligue dans laquelle vous vous trouvez.")]),_vm._v(" "),_c('p',[_c('b',[_vm._v("Si vous ne voyez pas une équipe répertoriée que vous prévoyez être ici, contactez votre président de ligue pour vous assurer qu’il a ajouté votre équipe et que vous êtes répertorié en tant qu’entraîneur ou joueur.")])])]):_vm._e(),_vm._v(" "),_c('b-table',{attrs:{"data":_vm.leaguesRegisteredIn,"striped":true,"focusable":true,"paginated":"","per-page":"5"}},[_c('b-table-column',{attrs:{"field":"season","label":"Season"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":column.label,"dashed":""}},[_vm._v("\n                            "+_vm._s(column.label)+"\n                        ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"tag is-light"},[_c('b',[_vm._v(_vm._s(props.row.season_id)+" ")])])]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"name","label":"League Name"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":column.label,"dashed":""}},[_vm._v("\n                            "+_vm._s(column.label)+"\n                        ")])]}},{key:"default",fn:function(props){return [_vm._v("\n                        "+_vm._s(props.row.name)+"\n                    ")]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"event_roster.roster.team.name","label":"Team Name"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.event_roster.roster.team.logo)?_c('img',{staticStyle:{"height":"25px","float":"left","margin-right":"20px"},attrs:{"src":props.row.event_roster.roster.team.logo}}):_vm._e(),_vm._v(" "),_c('b',[_vm._v(_vm._s(props.row.event_roster.roster.team.name))]),_vm._v(" "),_c('b-tag',{staticStyle:{"text-transform":"uppercase"}},[_vm._v("\n                            "+_vm._s(props.row.registration_details.division.gender_slug)+" "+_vm._s(props.row.registration_details.division.rank_id)+"\n                        ")])]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"city","label":"City"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":column.label,"dashed":""}},[_vm._v("\n                            "+_vm._s(column.label)+"\n                        ")])]}},{key:"default",fn:function(props){return [_vm._v("\n                        "+_vm._s(props.row.city)+"\n                    ")]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"actions","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('a',{staticClass:"button is-small",attrs:{"href":'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/league'}},[_vm._v("View Event")]),_vm._v(" "),(!props.row.is_passed_roster_lock_date)?_c('a',{staticClass:"button is-small is-info",attrs:{"href":'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/league'}},[_c('b',[_vm._v("Edit League Roster")])]):_vm._e()]}}])})],1)]},proxy:true}])}),_vm._v(" "),_c('Accordion',{attrs:{"preOpen":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.en === true ? 'Tournaments I Am Registered In' : 'Tournois auxquels vous êtes inscrit')+"\n        ")]},proxy:true},{key:"content",fn:function(){return [_c('p',[_vm._v("If you are the coach or team contact, you can edit your tournament rosters in here. Click the Edit Tournament Roster button below on the league you are in.")]),_vm._v(" "),(_vm.tournamentsRegisteredIn)?_c('b-table',{attrs:{"data":_vm.tournamentsRegisteredIn,"striped":true,"focusable":true,"paginated":"","per-page":"5"}},[_c('b-table-column',{attrs:{"field":"start_date","label":"Start Date"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":column.label,"dashed":""}},[_vm._v("\n                            "+_vm._s(column.label)+"\n                        ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"tag is-light"},[_c('b',[_vm._v(_vm._s(_vm.moment(props.row.event_start_date).format('MMMM DD, YYYY')))])])]}}],null,false,3557194515)}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"name","label":"Tournament Name"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":column.label,"dashed":""}},[_vm._v("\n                            "+_vm._s(column.label)+"\n                        ")])]}},{key:"default",fn:function(props){return [_vm._v("\n                        "+_vm._s(props.row.name)+"\n                    ")]}}],null,false,217360814)}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"event_roster.roster.team.name","label":"Team Name"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.event_roster.roster.team.logo)?_c('img',{staticStyle:{"height":"25px","float":"left","margin-right":"20px"},attrs:{"src":props.row.event_roster.roster.team.logo}}):_vm._e(),_vm._v(" "),_c('b',[_vm._v(_vm._s(props.row.event_roster.roster.team.name))]),_vm._v(" "),_c('b-tag',{staticStyle:{"text-transform":"uppercase"}},[_vm._v("\n                            "+_vm._s(props.row.registration_details.division.gender_slug)+" "+_vm._s(props.row.registration_details.division.rank_id)+"\n                        ")])]}}],null,false,169445733)}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"city","label":"City"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":column.label,"dashed":""}},[_vm._v("\n                            "+_vm._s(column.label)+"\n                        ")])]}},{key:"default",fn:function(props){return [_vm._v("\n                        "+_vm._s(props.row.city)+"\n                    ")]}}],null,false,1113589614)}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"roster_lock_date","label":"Roster Lock Date"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":column.label,"dashed":""}},[_vm._v("\n                            "+_vm._s(column.label)+"\n                        ")])]}},{key:"default",fn:function(props){return [(props.row.roster_lock_date)?_c('span',[_c('span',{class:['tag is-light', { 'is-success': !props.row.is_passed_roster_lock_date }, { 'is-danger': props.row.is_passed_roster_lock_date }]},[_c('b',[_vm._v(_vm._s(_vm.moment(props.row.roster_lock_date).format('MMMM DD, YYYY')))])])]):_vm._e()]}}],null,false,2746034700)}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"actions","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('a',{staticClass:"button is-small",attrs:{"href":'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/tournament'}},[_vm._v("View Event")]),_vm._v(" "),(!props.row.is_passed_roster_lock_date)?_c('a',{staticClass:"button is-small",attrs:{"href":'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/tournament'}},[_vm._v("Edit Tournament Roster")]):_vm._e()]}}],null,false,4102102439)})],1):_vm._e()]},proxy:true}])}),_vm._v(" "),_c('Accordion',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.en === true ? "Previous Leagues You've Played In" : 'Ligues Précédentes')+"\n        ")]},proxy:true},{key:"content",fn:function(){return [_c('b-table',{attrs:{"data":_vm.allLeaguesRegisteredIn,"striped":true,"focusable":true,"paginated":"","per-page":"5"}},[_c('b-table-column',{attrs:{"field":"season","label":(_vm.en === true ? 'Season' : 'Saison')},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":column.label,"dashed":""}},[_vm._v("\n                            "+_vm._s(column.label)+"\n                        ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"tag is-light"},[_c('b',[_vm._v(_vm._s(props.row.season_id)+" ")])])]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"name","label":(_vm.en === true ? 'League Name' : 'Nom de la Ligue')},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":column.label,"dashed":""}},[_vm._v("\n                            "+_vm._s(column.label)+"\n                        ")])]}},{key:"default",fn:function(props){return [_vm._v("\n                        "+_vm._s(props.row.name)+"\n                    ")]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"event_roster.roster.team.name","label":(_vm.en === true ? 'Team Name' : "Nom de L’Équipe")},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.event_roster.roster.team.logo)?_c('img',{staticStyle:{"height":"25px","float":"left","margin-right":"20px"},attrs:{"src":props.row.event_roster.roster.team.logo}}):_vm._e(),_vm._v(" "),_c('b',[_vm._v(_vm._s(props.row.event_roster.roster.team.name))])]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"city","label":"City"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":column.label,"dashed":""}},[_vm._v("\n                            "+_vm._s(column.label)+"\n                        ")])]}},{key:"default",fn:function(props){return [_vm._v("\n                        "+_vm._s(props.row.city)+"\n                    ")]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"actions","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('a',{staticClass:"button is-small",attrs:{"href":'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/league'}},[_vm._v("View Event")]),_vm._v(" "),(!props.row.is_passed_roster_lock_date)?_c('a',{staticClass:"button is-small",attrs:{"href":'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/league'}},[_vm._v("Edit Event Roster")]):_vm._e()]}}])})],1)]},proxy:true}])}),_vm._v(" "),_c('Accordion',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.en === true ? "Previous tournaments you've played in" : 'Tournois Précédents')+"\n        ")]},proxy:true},{key:"content",fn:function(){return [(_vm.allTournamentsRegisteredIn)?_c('b-table',{attrs:{"data":_vm.allTournamentsRegisteredIn,"striped":true,"focusable":true,"paginated":"","per-page":"5"}},[_c('b-table-column',{attrs:{"field":"start_date","label":"Start Date"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":column.label,"dashed":""}},[_vm._v("\n                            "+_vm._s(column.label)+"\n                        ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"tag is-light"},[_c('b',[_vm._v(_vm._s(_vm.moment(props.row.event_start_date).format('MMMM DD, YYYY')))])])]}}],null,false,3557194515)}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"name","label":"Tournament Name"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":column.label,"dashed":""}},[_vm._v("\n                            "+_vm._s(column.label)+"\n                        ")])]}},{key:"default",fn:function(props){return [_vm._v("\n                        "+_vm._s(props.row.name)+"\n                    ")]}}],null,false,217360814)}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"event_roster.roster.team.name","label":"Team Name"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.event_roster.roster.team.logo)?_c('img',{staticStyle:{"height":"25px","float":"left","margin-right":"20px"},attrs:{"src":props.row.event_roster.roster.team.logo}}):_vm._e(),_vm._v(" "),_c('b',[_vm._v(_vm._s(props.row.event_roster.roster.team.name))])]}}],null,false,281494447)}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"city","label":"City"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":column.label,"dashed":""}},[_vm._v("\n                            "+_vm._s(column.label)+"\n                        ")])]}},{key:"default",fn:function(props){return [_vm._v("\n                        "+_vm._s(props.row.city)+"\n                    ")]}}],null,false,1113589614)}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"roster_lock_date","label":"Roster Lock Date"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-tooltip',{attrs:{"label":column.label,"dashed":""}},[_vm._v("\n                            "+_vm._s(column.label)+"\n                        ")])]}},{key:"default",fn:function(props){return [(props.row.roster_lock_date)?_c('span',[_c('span',{class:['tag is-light', { 'is-success': !props.row.is_passed_roster_lock_date }, { 'is-danger': props.row.is_passed_roster_lock_date }]},[_c('b',[_vm._v(_vm._s(_vm.moment(props.row.roster_lock_date).format('MMMM DD, YYYY')))])])]):_vm._e()]}}],null,false,2746034700)}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"actions","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('a',{staticClass:"button is-small",attrs:{"href":'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/tournament'}},[_vm._v("View Tournament")]),_vm._v(" "),(!props.row.is_passed_roster_lock_date)?_c('a',{staticClass:"button is-small",attrs:{"href":'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/tournament'}},[_vm._v("Edit Tournament Roster")]):_vm._e()]}}],null,false,4263966058)})],1):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }