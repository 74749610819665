<template>
    <div>
        <h1>SPN Registration Numbers</h1>
        <p>Your SPN Registration Number is your ticket to playing in tournaments and leagues across all of our regions. To learn more about the perks, you can visit <a href="https://slo-pitch.com/content/spn4you">our website.</a></p>

        <p>You can get your SPN Registration Numbers here, quickly and easily. This number will work all season within any gender-specific division: mens, ladies, coed. If you sign up for mens, you will need a new number, for example, for coed. The maximum needed is all three if you have a mens, ladies and coed team.</p>
        <!--  -->
        
        <div v-if="completedPurchase === false">
            <p><b>These are the SPN #s we have registered to you for the {{ moment().format("YYYY") }} season:</b></p>
            <b-field label="Select the gendered-specific division your team intends to enter:">
                <b-select v-model="selected_gendered_registration">
                    <option value="mens">Mens</option>
                    <option value="ladies">Ladies</option>
                    <option value="coed">Coed</option>
                </b-select>
            </b-field>
            <div v-if="selected_gendered_registration">
                <h5>SELECT YOUR MEMBERSHIP TYPE</h5>
                <div class="row">
                    <div class="col-md-6" :key="key" v-for="(option, key) in products">
                        <div
                            v-on:click="selectedInsuranceOption = option"
                            :class="['selection-box', { 'selected': option === selectedInsuranceOption }]">
                            <div class="select-box-header">{{ option.name }}</div>
                            <div class="select-box-price">${{ option.full_price.toFixed(2) }} + Tax & Fees</div>
                            <p class="note">Note: Credit card payments include a processing fee.</p>
                        </div>
                    </div>
                </div>
                <div v-if="products.length === 0">
                    You do not need to upgrade or purchase insurance for this gender!
                </div>
                <div v-if="selectedInsuranceOption">
                    <NewPaymentProcessor
                        :productId="selectedInsuranceOption.id"
                        :regionId="team.region_id"
                        :teamName="team.name"
                        v-on:payment:successful="purchaseOfInsuranceComplete($event)"
                        >
                    </NewPaymentProcessor>
                </div>
            </div>

            <hr>
            <table class="table" style="margin-top: 40px;" v-if="team">
                <tr>
                    <td><b>SPN Number</b></td>
                    <td><b>Gender</b></td>
                    <td><b>Season</b></td>
                    <td><b>With Insurance</b></td>
                    <td><b>From League</b></td>
                    <td><b>Single Event</b></td>
                </tr>
                <tr v-for="insurance in showOnlyThisYear(team.insurance)" :key="insurance.id">
                    <td>
                        <span v-if="insurance.fully_paid">{{ insurance.spn_number }}</span>
                        <span v-if="!insurance.fully_paid">Awaiting payment confirmation</span>
                    </td>
                    <td v-if="insurance.gender">{{ insurance.gender.charAt(0).toUpperCase() + insurance.gender.substr(1) }}</td>
                    <td>{{ insurance.season }}</td>
                    <td>
                        <b-tag type="is-success" v-if="insurance.with_insurance === 1">
                            Yes
                        </b-tag>
                        <b-tag type="is-danger" v-if="insurance.with_insurance !== 1">
                            No
                        </b-tag>
                    </td>
                    <td>{{ insurance.from_league }}</td>
                    <td>{{ insurance.single_event }}</td>
                    <td v-if="show_revoke"><a href="#" v-on:click="revokeInsurance(insurance)" class="button is-danger">Revoke Insurance</a></td>
                </tr>
            </table>
        </div>
        <div v-if="completedPurchase === true">
            <div class="payment-complete">
                <div class="icon-row">
                    <i class="far fa-check-circle"></i>
                </div>
                <div class="complete-text">
                    <div>
                        <p><b>Order Successful.</b></p> 
                        <p class="smal">Please make sure to send your EMT or cheque promptly. If you paid via credit card, you have instant access.</p>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import NewPaymentProcessor from './components/NewPaymentProcessor.vue'
export default {
    data() {
        return {
            selected_gendered_registration: null,
            completedPurchase: false,
            team: null,
            selectedInsuranceOption: null,
            region_rate: null,
            products: []
        }
    },
    components: {
        NewPaymentProcessor
    },
    props: ['team_slug', 'show_revoke'],
    watch: {
        team_slug: function() {
            this.triggerLoading()
        },
        selected_gendered_registration: function() {
            this.triggerInsuranceOptions()
        }
    },
    mounted: function() {
        this.triggerLoading()
    },
    methods: {
        triggerLoading: function() {
            var vm = this
            axios.get('/auth/check').then(results => {  
                if(results.data.type === "success") {
                    vm.authUser = results.data.user
                    axios.post(`/api/teams/${this.team_slug}`, {
                        user: vm.authUser
                    }).then(results => {
                        vm.team = results.data.data.team
                    })
                }
            
            })
        },
        triggerInsuranceOptions: function() {
            var vm = this
            var team = this.team
            
            axios.post('/api/users/region-rate', {
                region: vm.team.region_id
            }).then(results => {
                if(results.data.success === true) {
                    vm.region_rate = results.data.region_rate
                    vm.products = []
                    var rate = vm.region_rate.rate

                    vm.products.push({
                        id: 6,
                        name: 'Fully Insured Tournament Team',
                        price: rate.fully_insured_tournament_team,
                        full_price: rate.fully_insured_tournament_team + 28.50
                    })
                    if(rate.un_insured_tournament_team !== 0) {
                        vm.products.push({
                            id: 7,
                            name: 'Uninsured Tournament Team',
                            price: rate.un_insured_tournament_team,
                            full_price: rate.un_insured_tournament_team
                        })
                    }
                    if(rate.single_use_tournament_team !== 0) {
                        vm.products.push({
                            id: 8,
                            name: 'Play One Time',
                            price: rate.single_use_tournament_team,
                            full_price: rate.single_use_tournament_team
                        })
                    }
                    
                    if(team.insurance.length > 0) {
                        
                        var find = team.insurance.find(insurance => {
                            return insurance.gender === vm.selected_gendered_registration && insurance.season == moment().format('YYYY') && insurance.single_event === 1
                        })

                        var find_upgraded = team.insurance.find(insurance => {
                            return insurance.gender === vm.selected_gendered_registration && insurance.season == moment().format('YYYY') && insurance.single_event === null
                        })

                        if(find) {
                            vm.products = []

                            if(rate.upgrade_to_un_insured_tournament_team !== 0) {
                                vm.products.push({
                                    name: 'Upgrade - Uninsured Tournament Team',
                                    price: rate.upgrade_to_un_insured_tournament_team,
                                    id: 11,
                                    full_price: rate.upgrade_to_un_insured_tournament_team
                                })
                            }
                            if(rate.upgrade_to_fully_insured_tournament_team !== 0) {
                                vm.products.push({
                                    name: 'Upgrade - Fully Insured Tournament Team',
                                    price: rate.upgrade_to_fully_insured_tournament_team,
                                    with_insurance: 1,
                                    id: 10,
                                    full_price: rate.upgrade_to_fully_insured_tournament_team + 28.50
                                })
                            }
                        }

                        if(find_upgraded) {
                            vm.products = []
                        }
                    }
                }
            })
        },
        moment: function(date, format = null) {
            return moment(date, format)
        },
        showOnlyThisYear: function(insurances) {
            return insurances.filter(insurance => {
              
                return parseInt(insurance.season) == moment().format('YYYY')
            })
        },
        revokeInsurance: function(insurance) {
            var r = confirm('Are you sure you want to do this?')
            var vm = this
            if(r === true) {
                axios.post('/api/superuser/revoke-insurance', {
                    team: insurance
                }).then(results => {
                    this.triggerInsuranceOptions()
                    this.$buefy.snackbar.open({
                        message: 'You have successfully revoked.',
                        type: 'is-success' // is-danger
                    })
                })
            }
        },
        purchaseOfInsuranceComplete: function($event) {
            var vm = this
            // purchase is complete
            // now we need to assign the insurance based on the selection
            axios.post('/api/users/assign-insurance-on-purchase', {
                info: $event,
                insurance: vm.selectedInsuranceOption,
                team: vm.team.id,
                gender: vm.selected_gendered_registration
            }).then(results => {
                if(results.data.success === true) {
                    // success and add to their SPN registrations lists
                    var registration = results.data.team_registration
                    vm.completedPurchase = true

                    vm.latest_registration = registration

                    axios.post(`/api/teams/${vm.team.slug}`, {
                        user: vm.user
                    }).then(results => {
                        if(results.data.success === true) {
                            vm.team = results.data.data.team
                        }
                    })

                    this.$buefy.snackbar.open({
                        message: 'You have successfully received your SPN Membership.',
                        type: 'is-success' // is-danger
                    })
                }
            })
        },
    }
}
</script>