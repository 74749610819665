<template>
	<div>
		<Header></Header>
		
		<div class="content-area">
			<div class="standard-page-header">
				Official Carded Umpires
			</div>
            <div class="frontend-content-wrapper">
				
				<div class="section-spacing">
					<p><b>Select a region icon to see the region's information for umpire clinics.</b></p>
                    <div class="provinces">
                        <div :class="['province', { selected: selectedRegion === 2 }]">
                            <a v-on:click="selectedRegion = 2">
                                <img src="../../images/header/crest/BCCrest.png" width="100%" alt="">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 1 }]">
                            <a v-on:click="selectedRegion = 1">
                                <img src="../../images/header/crest/ABCrest.png" width="100%" alt="">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 8 }]">
                            <a v-on:click="selectedRegion = 8">
                                <img src="../../images/header/crest/SKCrest.png" width="100%" alt="">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 3 }]">
                            <a v-on:click="selectedRegion = 3">
                                <img src="../../images/header/crest/MBCrest.png" width="100%" alt="">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 4 }]">
                            <a v-on:click="selectedRegion = 4">
                                <img src="../../images/header/crest/ONCrest.png" width="100%" alt="">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 6 }]">
                            <a v-on:click="selectedRegion = 6">
                                <img src="../../images/header/crest/QCCrest.png" width="100%" alt="">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 5 }]">
                            <a v-on:click="selectedRegion = 5">
                                <img src="../../images/header/crest/MTCrest.png" width="100%" alt="">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 7 }]">
                            <a v-on:click="selectedRegion = 7">
                                <img src="../../images/header/crest/NLCrest.png" width="100%" alt="">
                            </a>
                        </div>                
                    </div>
                    <div class="textarea-content-block" v-if="officialUmpires">
                        <div>
                            <h1 v-if="officialUmpires[0]">{{ officialUmpires[0].region_name }}</h1>

                            <b-table
                                :data="officialUmpires"
                                
                                >
                                <b-table-column 
                                    field="first_name"
                                    :searchable="true"
                                    label="First Name">
                                    <template v-slot="props">
                                        {{ props.row.first_name }}
                                    </template>
                                </b-table-column>
                                <b-table-column 
                                    field="last_name"
                                    :searchable="true"
                                    label="Last Name">
                                    <template v-slot="props">
                                        {{ props.row.last_name }}
                                    </template>
                                </b-table-column>
                                <b-table-column 
                                    field="city"
                                    :searchable="true"
                                    label="City">
                                    <template v-slot="props">
                                        {{ props.row.city }}
                                    </template>
                                </b-table-column>
                            </b-table>
                        </div>
                    </div>
                </div>
			</div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
export default {
	metaInfo() {
        return { 
            title: "Official Carded Umpires - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Slo-Pitch National is proud to present our list of carded umpires for 2021.' },
                { property: 'og:title', content: "Official Carded Umpires - Slo-Pitch National"},
                { property: 'og:url', content:  'https://www.slo-pitch.com/official-carded-umpires' },
                { property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/officialcardedumpires.png' },
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			en: true,
            page_url: null,
			page: null,
			selectedRegion: null,
            umpires: []
		};
	},
	components: {
		Header,
		Footer,
		Form
	},
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }

        var vm = this

        axios.get('/api/umpires/officially-carded-umpires').then(results => {
            if(results.data.success === true) {
                vm.umpires = results.data.umpires
            }
        })
 
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
        officialComputedUmpires: function() {
            var umpires = this.umpires
            var grouped = _.groupBy(umpires, 'region_name')

            return grouped
		},
		officialUmpires: function() {
			var vm = this

			var umpires = this.umpires
			
			if(vm.selectedRegion) {
				umpires = _.filter(umpires, function(umpire) {
					return umpire.region_id === vm.selectedRegion
				}) 

				umpires = _.orderBy(umpires, 'last_name', 'asc')
				return umpires
			}
			
			return null
			
		}
	},
	methods: {
		openPanel:function(panel) {
			if(panel.switched === "false") {
				panel.switched = "true"
			} else {
				panel.switched = "false"
			}
		},
		createProperTableFunction(table) {
			// first row is your columns
			var first_row = table[0]
			var all_rows = table
			var columns = []
			var data = []
	
			var i = 0
			for(i; i < all_rows.length; i++) {
				var row = all_rows[i]
				if(i === 0) {
					// this is the first row, need to for loop again though
					var s = 0

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						columns.push({
							field: column.name,
							label: column.name
						})
					}
				} else {
					// this is the rest of the rows, need to loop and then call via the loop to the first row
					var s = 0
					
					var object = {}

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						var col_name = all_rows[0].row[s].name
						
						object[col_name] = column.name
					}
					data.push(object)
				}
			}

			// every row after is your data
			


			return {
				columns: columns,
				data: data,
				table: table
			}
		},
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
