<template>
	<div>
        
		<Header></Header>
        
		
		<div class="content-area" v-if="tournament">
			<div class="standard-page-header">
				{{ tournament.name }}
                <h3 style="margin-top: 0px; font-family: 'Fjalla One', sans-serif; color: #ddd !important; font-weight: 400; text-transform: uppercase;">Starts on {{ moment(tournament.event_start_date).format('dddd MMMM DD YYYY') }}!</h3>
			</div>

            <div class="row">
                <div class="col-md-4">
                    <img src="../../images/dashboard/sanctionedtournament.png" v-if="!tournament.poster_image_url" alt="">
                    <img :src="tournament.poster_image_url" v-if="tournament.poster_image_url" alt="">

                    <iframe
							
                        width="100%"
                        height="300"
                        frameborder="0" style="margin-top: 30px;border:0"
                        :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyCAnO39NnB7qlv22s3nm-mM7QOCqYFsTI8&q=' + tournament.written_address" allowfullscreen>
                    </iframe>
                </div>
                <div class="col-md-8">    
                    <div class="event-description">
                        {{ tournament.description }}
                    </div>
                    <div class="remainder-tournament-content">
                        <p><b>When: </b> {{ moment(tournament.event_start_date).format('dddd MMMM DD, YYYY hh:mm a') }} until {{ moment(tournament.event_end_date).format('dddd MMMM DD, YYYY hh:mm a') }}</p>
                        <p><b>Where: </b> {{ tournament.written_address }}</p>
                        <p><b>Run By: </b>{{ tournament.convenor.first_name }} {{ tournament.convenor.last_name }}</p> 
						<div class="divisions">
							<div class="divisions-header">Divisions Offered</div>
							<div class="divisions-content">
								<div class="row">
									<div class="col-md-4" style="flex: 1" v-if="filterDivision(tournament.divisions, 'mens').length > 0">
										<div class="custom-tournament-division-header">
											Men's
										</div>
										
										<div class="custom-tournament-division" 
											:key="key"
											v-for="(division, key) in filterDivision(tournament.divisions, 'mens')">
											{{ division.rank_id }}
										</div>
									
									</div>
									<div class="col-md-4" v-if="filterDivision(tournament.divisions, 'ladies').length > 0">
										<div class="custom-tournament-division-header">
											Ladies
										</div>
										
										<div class="custom-tournament-division" 
											:key="key"
											v-for="(division, key) in filterDivision(tournament.divisions, 'ladies')">
											{{ division.rank_id }}
										</div>
									
									</div>
									<div class="col-md-4" v-if="filterDivision(tournament.divisions, 'coed').length > 0">
										<div class="custom-tournament-division-header">
											Coed
										</div>
										
										<div class="custom-tournament-division" 
											:key="key"
											v-for="(division, key) in filterDivision(tournament.divisions, 'coed')">
											{{ division.rank_id }}
										</div>
									
									</div>
								</div>
							</div>
						</div>
						<div class="prices">
							<div class="prices-header">
								Entry Fee(s)
							</div>
							<div class="prices-content">
								<div class="entry-fee-team">
									<i class="fas fa-users"></i> ${{ tournament.team_registration_fee }}
								</div>
								<div class="entry-fee-team" v-if="tournament.individual_registration_fee">
									<i class="fas fa-user"></i> ${{ tournament.individual_registration_fee }}
								</div>
							</div>
						</div>
						
						<div class="register-now-buttons" style="margin-top: 20px;">
							<a class="admin-button">Register To This Tournament</a>
						</div>
						<div class="share-buttons" style="margin-top: 30px; margin-left: -10px;">
                            <ul>
                                <li>
                                    <a href="#"><i class="fab fa-facebook"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
						<div class="send-a-message">
							<div class="send-a-message-header">
								Send the convenor a question
							</div>
							
							<div class="padding">
								<div class="row">
									<div class="col-md-12">
										<b-field label="Your name">
											<b-input v-model="sendMessage.your_name"></b-input>
										</b-field>
									</div>
								</div>
								<div class="row" style="margin-top: 10px;">
									<div class="col-md-6">
										<b-field label="Your email">
											<b-input v-model="sendMessage.your_email"></b-input>
										</b-field>
									</div>
									<div class="col-md-6">
										<b-field label="Your phone number">
											<b-input v-model="sendMessage.your_phone"></b-input>
										</b-field>
									</div>
								</div>
								<div class="row" style="margin-top: 10px;">
									<div class="col-md-12">
										<b-field label="Write your message">
											<b-input type="textarea" v-model="sendMessage.your_message"></b-input>
										</b-field>
									</div>
								</div>
								<a v-on:click="sendMessageNow()" style="margin-top: 10px;" class="button">Send Message</a>
							</div>
						</div>
						
                        


                    </div>
                </div>
            </div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
import moment from 'moment'

import _ from 'lodash'
export default {
	metaInfo() {
        if(!this.tournament) {
            return {}
        }
		var tournament_poster_image = 'https://slo-pitch.com/uploads/sanctionedtournament.png'
		if(this.tournament.poster_image_url) {
			tournament_poster_image = 'https://slo-pitch.com/' + (this.tournament.poster_image_url)
		}

        return {
			title: this.tournament.name + "- Tournament - Slo-Pitch National",
			meta: [
                { name: 'description', content:  this.tournament.description },
				{ property: 'og:title', content: this.tournament.name + "- Tournament - Slo-Pitch National"},
				{ property: 'og:url', content:  'https://www.slo-pitch.com/league/' + this.tournament.slug },
				{ property: 'og:site_name', content: 'Slo-Pitch National'},
				{ property: 'og:image', content: tournament_poster_image },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			sendMessage: {
				your_name: null,
				your_email: null,
				your_phone: null,
				your_message: null
			},
			en: true,
            page_url: null,
            page: null,
            tournament: null
		};
	},
	components: {
		Header,
		Footer,
		Form
	},
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        var vm = this
        vm.page_url = this.$route.params.page

     
        axios.get('/api/pages/grab-league/' + vm.page_url).then(results => {
            if(results.data.success === true) {
				vm.tournament = results.data.tournament
            }
        })
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
	
	},
	methods: {
		sendMessageNow: function() {
			var vm = this
			axios.post('/api/users/send-message', {
				sendMessage: vm.sendMessage,
				id: vm.tournament.id,
				leaguetourney: 'league'
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
						message: 'Your message was successfully sent!',
						type: 'is-success' // is-danger
					})
				}
			})
		},
        moment: function(date, format = null) {
            return moment(date, format)
		},
		filterDivision: function(divisions, filter) {
			var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        },
		// createProperTableFunction(table) {
		// 	// first row is your columns
		// 	var first_row = table[0]
		// 	var all_rows = table
		// 	var columns = []
		// 	var data = []
	
		// 	var i = 0
		// 	for(i; i < all_rows.length; i++) {
		// 		console.log('row', all_rows[i])
		// 		var row = all_rows[i]
		// 		if(i === 0) {
		// 			// this is the first row, need to for loop again though
		// 			var s = 0

		// 			for(s; s < row.row.length; s++) {
		// 				var column = row.row[s]
						
		// 				columns.push({
		// 					field: column.name,
		// 					label: column.name
		// 				})
		// 			}
		// 		} else {
		// 			// this is the rest of the rows, need to loop and then call via the loop to the first row
		// 			var s = 0
					
		// 			var object = {}

		// 			for(s; s < row.row.length; s++) {
		// 				var column = row.row[s]
						
		// 				var col_name = all_rows[0].row[s].name
						
		// 				object[col_name] = column.name
		// 			}
		// 			data.push(object)
		// 		}
		// 	}

		// 	// every row after is your data
			


		// 	return {
		// 		columns: columns,
		// 		data: data,
		// 		table: table
		// 	}
		// }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
