<template>
	<div>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>


		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper">
                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    
                    <div class="row">
                        <div class="col-md-3">
                            <div class="page-header-title">
                                Merge Users
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="padding">
             
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-4">
                        <div class="padding">
                            <p class="note">The grunt work needs to be done in Superadmin Users. You can only search users by ID here.</p>
                            <h1>Take these accounts</h1>
                            
                            <span  v-for="(account, key) in accountsToMerge" :key="key">
                                <b-input placeholder="ID #" v-on:input="findAccount(account)" v-model="account.id" ></b-input>
                                <p>{{ account.accountInfo ? account.accountInfo.first_name + ' ' + account.accountInfo.last_name + ' - ' + account.accountInfo.email : '' }}</p>
                            </span>

                            <a class="button is-danger" style="margin-top: 10px;" v-on:click="addAnotherAccount()">Add another account</a>
                            
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="padding">
                            <h1>And merge them into this <u>ONE</u></h1>
                            
                            <b-input placeholder="ID #" v-on:input="findAccountMerge($event)" v-model="mergeInto"></b-input>
                            <div v-if="mergeAccount">{{ mergeAccount.first_name }} {{ mergeAccount.last_name }} - {{ mergeAccount.email }}</div>
                            
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h1>Summary Before Submission</h1>
                        <p >You are taking {{ accountsToMerge.length }} users and merging them into one user, including their teams.</p>
                      
                        <a class="admin-button" style="margin-top: 10px;" v-on:click="submitMergedAccounts()">Submit Your Merge Request</a>
                        <hr>
                        <p><b>Output</b></p>
                        <pre>
                            <span v-for="(error, key) in errors" :key="key">
                                {{ error }}
                            </span>
                        </pre>
                    </div>
                </div>
            </div>
        </div>
    </div>

        
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import CreatePage from './components/CreatePage.vue'
import moment from 'moment'

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { VueEditor, Quill } from "vue2-editor";

import axios from 'axios'
export default {
	data() {

		return {
            accountsToMerge: [{ id: null, accountInfo: null }],
            mergeInto: null,
            searchForAccount: null,
            isLoading: true,
            editPageSwitch: false,
            authUser: { first_name: null },
            createNewPageSwitch: false,
            content_width: false,
            faqs: [],
            logs: [],
            timer: null,
            newFaq: {
                question: null,
                question_fr: null,
                answer: null,
                answer_fr: null
            },
            newPage: {
                name: null,
                slug: null,
                content: null,
                fr_content: null,  
            },
            contentAreas: [{
                type: 'textarea',
                widget_type: null,
                form_id: null,
                columns: [
                    {
                        width: 12,
                        content: null,
                        fr_content: null
                    },
                ]
            }],
            forms: [],
            tabSelected: 0,
            defaultOpenedDetails: [1],
            showDetailIcon: true,
            pageSelectedForEditing: null,
            pages: [],
            latest_news: [],
            errors: [],
            mergeAccount: null
		};
	},
	components: {
		Header,
        Footer,
        VueEditor,
        CreatePage
	},
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
			 
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.communication_log !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }

                axios.get('/api/superuser/communication-logs').then(results => {
                    if(results.data.success === true) {
                        vm.logs = results.data.logs
                        // format the content
                    }
                })

                vm.isLoading = false
            }
        })
	},
	computed: {
        
    },
    watch: {
        createNewPageSwitch: function(result) {
            var vm = this
            if(result === false) {
                vm.pageSelectedForEditing = null
            }
        }
    },
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        goBack: function(data){
            var r = confirm('Are you sure you want to do this? You will lose this work.')
            if(r === true) {
                this.pageSelectedForEditing = null
            }
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
      
        switchWidth: function($event) {
			this.content_width = !$event
        },
   
        closeModal: function() {
            this.createNewPageSwitch = false
        },
        submitMergedAccounts: function() {
            var vm = this

            axios.post('/api/superuser/submit-merged-accounts', {
                accountsToMerge: vm.accountsToMerge,
                mergeInto: vm.mergeInto
            }).then(results => {
                if(results.data.success === true) {
                    vm.errors = results.data.errors
                    this.$buefy.snackbar.open({
                        message: 'Submit merged accounts was WAS successful',
                        type: 'is-success' // is-danger
                    })
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Submit merged accounts was NOT successful',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        addAnotherAccount: function() {
            var vm = this

            vm.accountsToMerge.push({ id: null, accountInfo: null })
        },
        findAccount: function(account) {
            if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                axios.post('/api/superuser/find-account-by-id', {
                    user: account.id
                }).then(results => {
                    account.accountInfo = results.data.user
                })
            }, 500)
        },
        findAccountMerge: function(id) {
            var vm = this
            if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                axios.post('/api/superuser/find-account-by-id', {
                    user: id
                }).then(results => {
                    vm.mergeAccount = results.data.user
                })
            }, 500)
        }
	},
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
