<template>
	<div>
		<Header></Header>
		
		<div class="content-area" >
			<div class="standard-page-header">
				Senior Slo-Pitch Programs
			</div>
            <div class="frontend-content-wrapper">
				<div class="section-spacing">
                    <div class="row align-items-center">
                        <div class="col-md-4">
                            <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/image003.png" style="width: 100%" alt="">
                        </div>
                        <div class="col-md-8">
                            <div id="map"></div>
                        </div>
                    </div>
   
                    <Accordion>
                        <template v-slot:header>
                            {{  tourney_of_champs.name  }}
                        </template>
                        <template v-slot:content>
                            <PageContentBox :content="parseMainContent(tourney_of_champs.content)"></PageContentBox>
                        </template>
                    </Accordion>
                    <Accordion>
                        <template v-slot:header>
                            {{ pacific_rim.name }}}
                        </template>
                        <template v-slot:content>
                            <PageContentBox :content="parseMainContent(pacific_rim.content)"></PageContentBox>
                        </template>
                    </Accordion>
                    <Accordion>
                        <template v-slot:header>
                            {{ senior_games.name }}
                        </template>
                        <template v-slot:content>
                            <PageContentBox :content="parseMainContent(senior_games.content)"></PageContentBox>
                        </template>
                    </Accordion>
                    <Accordion>
                        <template v-slot:header>
                            {{ senior_circuit.name }}
                        </template>
                        <template v-slot:content>
                            <PageContentBox :content="parseMainContent(senior_circuit.content)"></PageContentBox>
                        </template>
                    </Accordion>

         
                </div>
                
            </div>
           
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import Accordion from '../components/Accordion.vue'
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
import PageContentBox from '../frontend/PageContentBox.vue'
export default {
    metaInfo() {
        return { 
            title: "CanAm Events - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Find out more about our CanAm Events here.' },
                
                { property: 'og:title', content: "CanAm Events - Slo-Pitch National"},
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:url', content:  'https://www.slo-pitch.com/can-am-events' },
				{ property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/canamevents.png' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			en: true,
            page_url: null,
            page: null,
            previous_results: null,
            selectedRegion: 'bc',
            tourney_of_champs: null,
            pacific_rim: null,
            senior_games: null,
            senior_circuit: null,
		};
	},
	components: {
		Header,
		Footer,
        Form,
        PageContentBox,
        Accordion
	},
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        var vm = this

        const center = { lat: 43.0896, lng: -79.0849 }
        const center2 = { lat: 46.8139, lng: -71.2080 }
        
        const leduc = { lat: 53.2723418, lng: -113.5847182 }
        const edmonton = { lat: 53.5557121, lng: -113.6331451 }
        
        const winnipeg = { lat: 49.8538008, lng: -97.2224356 }
        
        const brampton = { lat: 43.7249125, lng:-79.8297545 }
        // The map, centered at Uluru
        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 9,
            center: center,
        });

  

        axios.get('/api/pages/seniors').then(results => {
            console.log('results', results)
            if(results.data.success === true) {
                vm.tourney_of_champs = results.data.tourney_of_champs
                vm.pacific_rim = results.data.pacific_rim
                vm.senior_games = results.data.senior_games
                vm.senior_circuit = results.data.senior_circuit
                // vm.previous_results = results.data.previous_results
                // console.log('vm.previous_results', vm.previous_results)
            }
        })
     
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
	
	},
	methods: {
        parseMainContent: function(content) {
            return JSON.parse(content)
        },
		openPanel:function(panel) {
			if(panel.switched === "false") {
				panel.switched = "true"
			} else {
				panel.switched = "false"
			}
		},
		createProperTableFunction(table) {
			// first row is your columns
			var first_row = table[0]
			var all_rows = table
			var columns = []
			var data = []
	
			var i = 0
			for(i; i < all_rows.length; i++) {
				var row = all_rows[i]
				if(i === 0) {
					// this is the first row, need to for loop again though
					var s = 0

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						columns.push({
							field: column.name,
							label: column.name
						})
					}
				} else {
					// this is the rest of the rows, need to loop and then call via the loop to the first row
					var s = 0
					
					var object = {}

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						var col_name = all_rows[0].row[s].name
						
						object[col_name] = column.name
					}
					data.push(object)
				}
			}

			// every row after is your data
			


			return {
				columns: columns,
				data: data,
				table: table
			}
		},
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';

    .row.align-items-center {
  display: flex;
  align-items: center;
}
</style>
