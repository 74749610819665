<template>
	<div class="app">
		<router-view />

		<Notification></Notification>
		<div class="website-background"></div>
	</div>
</template>

<script>
import Notification from './components/Notification.vue'


export default {
    metaInfo: {
        title: 'Slo-Pitch National | Sanctioned Canadian-Wide Slo-Pitch Leagues & Tournaments',
        titleTemplate: '%s - Slo-Pitch National | Sanctioned Canadian-Wide Slo-Pitch Leagues & Tournaments'
    },
	path: '/',
	data() {
		return {
			en: true
		};
	},
	components: {
		Notification,
	},
	mounted: function() {
		const start = 9 * 60;
        const end = 17 * 60;
        const date = new Date(); 
        const now = date.getHours() * 60 + date.getMinutes();
        const day_of_week = date.getDay()

        if(start <= now && now <= end) {
            // console.log('Chat is hidden.')
        } else {
            $('#front-chat-container').hide()    
        }
		// if(day_of_week === 5) {
        //     $('#front-chat-container').hide()    
        // }
        if(day_of_week === 6) {
            $('#front-chat-container').hide()    
        }
        if(day_of_week === 0) {
            $('#front-chat-container').hide()    
        }
	},
	methods: {

	}
}
</script>

<style lang="scss">
	@import './styles/Reset.scss';
	@import './styles/Grid.scss';
    @import './styles/App.scss';
</style>
