<template>
    <span>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
        <h2>{{ en === true ? 'Leagues & Tournaments I Am Registered In' : 'Tournois & Ligues' }}</h2>

        <Accordion preOpen>
            <template v-slot:header>
                {{ en === true ? 'Leagues I Am Registered In' : 'Ligues' }}
            </template>
            <template v-slot:content>
                <b-message v-if="en === true">
                    <p>If you are the coach or team contact, you can edit your league rosters in here. Click the Edit League Roster button below on the league you are in.</p>
                    <p><b>If you do not see a team listed that you expect to be here, contact your league president to make sure they have added your team, and have you listed as a coach or player.</b></p>
                </b-message>

                <b-message v-if="en === false">
                    <p>Si vous êtes l’entraîneur ou le contact de l’équipe, vous pouvez modifier les listes de votre ligue ici. Cliquez sur le bouton Modifier la liste de la ligue ci-dessous sur la ligue dans laquelle vous vous trouvez.</p>
                    <p><b>Si vous ne voyez pas une équipe répertoriée que vous prévoyez être ici, contactez votre président de ligue pour vous assurer qu’il a ajouté votre équipe et que vous êtes répertorié en tant qu’entraîneur ou joueur.</b></p>
                </b-message>

                <b-table 
                    
                    :data="leaguesRegisteredIn"
                    :striped="true"
                    :focusable="true"
                    paginated
                    per-page="5"
                >
                    
                    <b-table-column field="season" label="Season" >
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <span class="tag is-light">
                                <b>{{ props.row.season_id }} </b>
                            </span>
                        </template>
                    </b-table-column>
                    <b-table-column field="name" label="League Name">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.name }}
                        </template>
                    </b-table-column>
                    <b-table-column field="event_roster.roster.team.name" label="Team Name">
                        <template v-slot="props">
                            <img v-if="props.row.event_roster.roster.team.logo" :src="props.row.event_roster.roster.team.logo" style="height: 25px; float: left; margin-right: 20px;" />
                            <b>{{ props.row.event_roster.roster.team.name }}</b>
                            <b-tag style="text-transform: uppercase;">
                                {{ props.row.registration_details.division.gender_slug }} {{ props.row.registration_details.division.rank_id }}
                            </b-tag>
                        </template>
                    </b-table-column>
   
                    <b-table-column field="city" label="City">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.city }}
                        </template>
                    </b-table-column>
                    
                    <b-table-column field="actions" label="Actions" v-slot="props">
                       <a :href="'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/league'" class="button is-small">View Event</a>
                       <a :href="'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/league'" class="button is-small is-info" v-if="!props.row.is_passed_roster_lock_date"><b>Edit League Roster</b></a>

                    </b-table-column>
                
                </b-table>
            </template>
        </Accordion>

        <Accordion preOpen>
            <template v-slot:header>
                {{ en === true ? 'Tournaments I Am Registered In' : 'Tournois auxquels vous êtes inscrit' }}
            </template>
            <template v-slot:content>
                <p>If you are the coach or team contact, you can edit your tournament rosters in here. Click the Edit Tournament Roster button below on the league you are in.</p>
                <b-table 
                    v-if="tournamentsRegisteredIn"
                    :data="tournamentsRegisteredIn"
                    :striped="true"
                    :focusable="true"
                    paginated
                    per-page="5"
                >
                    
                    <b-table-column field="start_date" label="Start Date" >
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <span class="tag is-light">
                                <b>{{ moment(props.row.event_start_date).format('MMMM DD, YYYY') }}</b>
                            </span>
                        </template>
                    </b-table-column>
                    <b-table-column field="name" label="Tournament Name">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.name }}
                        </template>
                    </b-table-column>
                    <b-table-column field="event_roster.roster.team.name" label="Team Name">
                        <template v-slot="props">
                            <img v-if="props.row.event_roster.roster.team.logo" :src="props.row.event_roster.roster.team.logo" style="height: 25px; float: left; margin-right: 20px;" />
                            <b>{{ props.row.event_roster.roster.team.name }}</b>
                            <b-tag style="text-transform: uppercase;">
                                {{ props.row.registration_details.division.gender_slug }} {{ props.row.registration_details.division.rank_id }}
                            </b-tag>
                        </template>
                    </b-table-column>
                    
                    <b-table-column field="city" label="City">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.city }}
                        </template>
                    </b-table-column>
         
                    <b-table-column field="roster_lock_date" label="Roster Lock Date">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                            
                        </template>
                        <template v-slot="props">
                            <span v-if="props.row.roster_lock_date">
                                <span :class="['tag is-light', { 'is-success': !props.row.is_passed_roster_lock_date }, { 'is-danger': props.row.is_passed_roster_lock_date }]">
                                    <b>{{ moment(props.row.roster_lock_date).format('MMMM DD, YYYY') }}</b>
                                </span>
                            </span>
                        </template>
                    </b-table-column>
                    
                    
                    <b-table-column field="actions" label="Actions" v-slot="props">
                       <a :href="'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/tournament'" class="button is-small">View Event</a>
                       <a :href="'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/tournament'" class="button is-small" v-if="!props.row.is_passed_roster_lock_date">Edit Tournament Roster</a>
                       
                    </b-table-column>
                
                </b-table>
            </template>
        </Accordion>

        <Accordion>
            <template v-slot:header>
                {{ en === true ? `Previous Leagues You've Played In` : 'Ligues Précédentes' }}
            </template>
            <template v-slot:content>
                <b-table 
                    :data="allLeaguesRegisteredIn"
                    :striped="true"
                    :focusable="true"
                    paginated
                    per-page="5"
                >
                    
                    <b-table-column field="season" :label="(en === true ? 'Season' : 'Saison')" >
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <span class="tag is-light">
                                <b>{{ props.row.season_id }} </b>
                            </span>
                        </template>
                    </b-table-column>
                    <b-table-column field="name" :label="(en === true ? 'League Name' : 'Nom de la Ligue')">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.name }}
                        </template>
                    </b-table-column>
                    <b-table-column field="event_roster.roster.team.name" :label="(en === true ? 'Team Name' : `Nom de L’Équipe`)">
                        <template v-slot="props">
                            <img v-if="props.row.event_roster.roster.team.logo" :src="props.row.event_roster.roster.team.logo" style="height: 25px; float: left; margin-right: 20px;" />
                            <b>{{ props.row.event_roster.roster.team.name }}</b>
                        </template>
                    </b-table-column>
   
                    <b-table-column field="city" label="City">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.city }}
                        </template>
                    </b-table-column>
   
                    
                    
                    <b-table-column field="actions" label="Actions" v-slot="props">
                       <a :href="'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/league'" class="button is-small">View Event</a>
                       <a :href="'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/league'" class="button is-small" v-if="!props.row.is_passed_roster_lock_date">Edit Event Roster</a>
                    
                    </b-table-column>
                
                </b-table>
            </template>
        </Accordion>

        <Accordion>
            <template v-slot:header>
                {{ en === true ? `Previous tournaments you've played in` : 'Tournois Précédents' }}
            </template>
            <template v-slot:content>
                <b-table 
                    v-if="allTournamentsRegisteredIn"
                    :data="allTournamentsRegisteredIn"
                    :striped="true"
                    :focusable="true"
                    paginated
                    per-page="5"
                >
                    
                    <b-table-column field="start_date" label="Start Date" >
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            <span class="tag is-light">
                                <b>{{ moment(props.row.event_start_date).format('MMMM DD, YYYY') }}</b>
                            </span>
                        </template>
                    </b-table-column>
                    <b-table-column field="name" label="Tournament Name">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.name }}
                        </template>
                    </b-table-column>
                    <b-table-column field="event_roster.roster.team.name" label="Team Name">
                        <template v-slot="props">
                            <img v-if="props.row.event_roster.roster.team.logo" :src="props.row.event_roster.roster.team.logo" style="height: 25px; float: left; margin-right: 20px;" />
                            <b>{{ props.row.event_roster.roster.team.name }}</b>
                        </template>
                    </b-table-column>
                    
                    <b-table-column field="city" label="City">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                        </template>
                        <template v-slot="props">
                            {{ props.row.city }}
                        </template>
                    </b-table-column>
         
                    <b-table-column field="roster_lock_date" label="Roster Lock Date">
                        <template slot="header" slot-scope="{ column }">
                            <b-tooltip :label="column.label" dashed>
                                {{ column.label }}
                            </b-tooltip>
                            
                        </template>
                        <template v-slot="props">
                            <span v-if="props.row.roster_lock_date">
                                <span :class="['tag is-light', { 'is-success': !props.row.is_passed_roster_lock_date }, { 'is-danger': props.row.is_passed_roster_lock_date }]">
                                    <b>{{ moment(props.row.roster_lock_date).format('MMMM DD, YYYY') }}</b>
                                </span>
                            </span>
                        </template>
                    </b-table-column>
                    
                    <b-table-column field="actions" label="Actions" v-slot="props">
                       <a :href="'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/tournament'" class="button is-small">View Tournament</a>
                       <a :href="'/dashboard/players/event/' + props.row.slug + '/' + props.row.event_roster.team_roster_id + '/tournament'" class="button is-small" v-if="!props.row.is_passed_roster_lock_date">Edit Tournament Roster</a>
                       
                    </b-table-column>
                
                </b-table>
            </template>
        </Accordion>


        <!-- <b-tabs multiline v-model="activeTab">
            <b-tab-item :label="(en === true ? 'Leagues I Am Registered In' : 'Ligues')">
            </b-tab-item>
            <b-tab-item :label="(en === true ? 'Tournaments I Am Registered In' : 'Tournois')">  
                <h3 v-if="en === true">Tournaments you're registered in</h3>
                <h3 v-if="en === false">Tournois auxquels vous êtes inscrit</h3>
               
                
                

                
            </b-tab-item>
            
            
            <b-tab-item :label="(en === true ? 'Previous Tournaments' : 'Tournois Précédents')">
                <h3 v-if="en === true">Previous tournaments you've played in</h3>
                <h3 v-if="en === false">Tournois Précédents</h3>
                
            </b-tab-item>
            <b-tab-item :label="(en === true ? 'Previous Leagues' : 'Ligues Précédentes')">
                <h3 v-if="en === true">Previous leagues you've played in</h3>
                <h3 v-if="en === false">Ligues Précédentes</h3>
                
                
            </b-tab-item>
        </b-tabs> -->
    </span>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

import UpcomingTournaments from '../../../components/UpcomingTournaments.vue'
import RegisterToTournament from '../../../components/RegisterToTournament.vue'
import UpcomingLeagues from '../../../components/UpcomingLeagues.vue'
import TeamRegistration from '../../../components/TeamRegistration.vue'
import TournamentCalendar from '../../../frontend/TournamentCalendar.vue'


export default {
	data() {
		return {
            carouselSize: 3,
            isLoading: true,
            activeTab: 0,
            authUser: null,
            registerTeam: false,
            event: null,
            leaguesRegisteredIn: [],
            upcomingTournamentsAwayStep: 0,
            upcomingTournaments: [],
            registerForTournamentSwitch: false,
            registerForTournamentDetails: null,
            allLeaguesRegisteredIn: [],
            allTournamentsRegisteredIn: [],
            tournamentsRegisteredIn: null,
            test: 0,
		};
	},
    props: ['en'],
	components: {
        UpcomingTournaments,
        UpcomingLeagues,
        TeamRegistration,
        RegisterToTournament,
        TournamentCalendar
	},
	mounted: function() {
        var vm = this

        var vm = this
		if(window.innerWidth < 800) {
			vm.carouselSize = 2
		}
		if(window.innerWidth < 600) {
			vm.carouselSize = 1
		}

        if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }

        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
               

                
                axios.get('/api/users/tournaments-registered-in').then(results => {
                    if(results.data.success === true) {
                        vm.tournamentsRegisteredIn = results.data.tournaments_youre_in
                    }
                })

                axios.get('/api/users/leagues-registered-in').then(results => {
                    if(results.data.success === true) {
                        vm.leaguesRegisteredIn = results.data.leagues_youre_in
                    }
                })

                axios.get('/api/users/all-tournaments-registered-in').then(results => {
                    if(results.data.success === true) {
                        vm.allTournamentsRegisteredIn = _.orderBy(results.data.tournaments_youre_in, 'event_start_date', 'desc')
                    }
                })

                axios.get('/api/users/all-leagues-registered-in').then(results => {
                    if(results.data.success === true) {
                        vm.allLeaguesRegisteredIn = _.orderBy(results.data.leagues_youre_in, 'season_id', 'desc')
                    }
                })

                var location = null

                if(sessionStorage.getItem('user_location')) {
                    location = sessionStorage.getItem('user_location')
                }

                axios.get('/api/users/upcoming-tournaments-near-you', {
                    params: {
                        geolocate: location
                    }
                }).then(results => {
                    if(results.data.success === true) {
                        vm.upcomingTournaments = results.data.tournaments
                    }
                })

                axios.get('/api/users/upcoming-leagues-near-you', {
                    params: {
                        geolocate: location
                    }
                }).then(results => {
                    if(results.data.success === true) {
                    }
                })

                vm.isLoading = false
            }
        })
        
	},
	computed: {
        isPassedRosterLockDate: function() {

        }
	},
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        },
        registerButtonModal: function($event) {
			this.registerForTournamentSwitch = true
            this.registerForTournamentDetails = $event
            this.$emit('register:now', $event)
			// console.log('registerButtonModal', )
		}
	}
}
</script>

<style lang="scss">
    
</style>
