<template>
	<div>
		<b-loading :is-full-page="true" v-model="isLoading"></b-loading>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper">
				<div :class="['page-header', { sidebarClosed: !content_width }]">
					<div class="row">
						<div class="col-md-3">
							<div class="page-header-title">
								Manage Teams
							</div>
						</div>
						<div class="col-md-9">
							<div class="padding">
							
							</div>
						</div>
					</div>
				</div>
				<div class="admin-area">
					<div class="row">
						<div class="col-md-12">
							<b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
								<div
									slot="trigger" 
									slot-scope="props"
									class="card-header"
									role="button"
									aria-controls="contentIdForA11y3">
									<p class="card-header-title">
										Search & Control
									</p>
									<a class="card-header-icon">
										<b-icon
											:icon="props.open ? 'menu-down' : 'menu-up'">
										</b-icon>
									</a>
								</div>
								<div class="card-content">
									<div class="content">
										<b-field grouped group-multiline>
											<div v-for="(column, index) in columnTemplate"
												:key="index"
												class="control">
												<b-checkbox v-model="column.visible">
													{{ column.title }}
												</b-checkbox>
											</div>
										</b-field>
										<hr>
										<b-field grouped group-multiline >
											<b-field label="Region" v-if="specific_region === false">
												<b-select v-model="searchables.region">
													<option value="*">All</option>
													<option :value="1">Alberta</option>
													<option :value="2">British Columbia</option>
													<option :value="3">Manitoba</option>
													<option :value="4">Ontario</option>
													<option :value="5">Maritimes</option>
													<option :value="6">Quebec</option>
													<option :value="7">Newfoundland & Labrador</option>
													<option :value="8">Saskatchewan</option>
												</b-select>
											</b-field>

											<b-field label="Filter By Year">
												<b-select v-model="searchables.season" placeholder="Select a year">
													<option
														v-for="option in computedYears"
														:value="option"
														:key="option">
														{{ option }}
													</option>
												</b-select>
											</b-field>
											
											
											<b-field label="Number of Results">
												<b-select v-model="searchables.limit">
													<option :value="100">100</option>
													<option :value="500">500</option>
													<option :value="1000">1000</option>
													<option :value="2500">2500</option>
													<option :value="5000">5000</option>
												</b-select>
											</b-field>
											<b-field label="Per Page">
												<b-select v-model="perPage">
													<option value="10">10 per page</option>
													<option value="25">25 per page</option>
													<option value="50">50 per page</option>
													<option value="100">100 per page</option>
												</b-select>
											</b-field>
											<b-field label="Show Deleted">
												<b-switch v-model="searchables.deleted_at"></b-switch>
											</b-field>
										</b-field>
										<b-field grouped group-multiline style="margin-top: 30px;" >
											
											<b-field label="ID" style="width: 60px;">
												<b-input v-model="searchables.id"></b-input>
											</b-field>
											<b-field label="Team Name" style="width: 150px;">
												<b-input v-model="searchables.team_name"></b-input>
											</b-field>
											<b-field label="City" style="width: 150px;">
												<b-input v-model="searchables.city"></b-input>
											</b-field>
											<b-field label="Coach's First Name">
												<b-input v-model="searchables.coach_first_name"></b-input>
											</b-field>
											<b-field label="Coach's Last Name">
												<b-input v-model="searchables.coach_last_name"></b-input>
											</b-field>
											<b-field label="Coach's Email">
												<b-input v-model="searchables.coach_email"></b-input>
											</b-field>
											<b-field label="Coach Phone">
												<b-input v-model="searchables.coach_phone"></b-input>
											</b-field>
										</b-field>
										<hr>
										<div class="content">
											<a class="button is-info" v-on:click="sendMessage = true"><i class="fas fa-envelope-open-text"></i>&nbsp;&nbsp; Send Email To Selected Teams</a>
											<vue-excel-xlsx
												:data="selectedTeams"
												:columns="columnTemplate"
												:filename="'filename'"
												:sheetname="'sheetname'"
												:class="'button is-success'"
												>
												<i class="fas fa-file-export"></i>&nbsp;&nbsp; Export to Excel
											</vue-excel-xlsx>
											<a class="button is-warning" v-on:click="selectedTeams = teams">Select all (Multipage) ({{ selectedTeams.length }})</a>
											<div class="send-email" v-if="sendMessage" style="margin-top: 30px;">
												<div class="selectable-contacts">
													<b-field grouped group-multiline>
														<b-field label="Primary Coaches">
															<b-switch v-model="emailSelectables.primary_coaches"></b-switch>
														</b-field>
														<b-field label="All Coaches">
															<b-switch v-model="emailSelectables.all_coaches"></b-switch>
														</b-field>
														<b-field label="Team Contacts">
															<b-switch v-model="emailSelectables.team_contacts"></b-switch>
														</b-field>
														<b-field label="Rostered Players">
															<b-switch v-model="emailSelectables.rostered_players"></b-switch>
														</b-field>
													</b-field>
												</div>
												<div class="contacts-selected" style="margin-top: 30px;">
													<p><b>Sending To:</b></p>
													<b-tag v-for="(email, index) in computedEmails" :key="index">{{ email }}</b-tag>
												</div>
												<b-field label="Subject" style="margin-top: 30px;">
													<b-input v-model="sendEmail.subject"></b-input>
												</b-field>
												<b-field label="Message">
													<b-input v-model="sendEmail.message"
													maxlength="2000" type="textarea"></b-input>
												</b-field>

												<a v-on:click="sendMessageToPlayers()" class="admin-button">Send Message to Selected Players</a>
											</div>
										</div>
									</div>
								</div>
							</b-collapse>

							<b-table
                                :data="teams"
                                ref="table"
                                :sticky-header="true"
                                :height="'100%'"
                                :paginated="true"
                                :current-page="1"
                                :per-page="perPage"
                                :pagination-simple="false"
                                :pagination-position="'bottom'"
                                :checked-rows.sync="selectedTeams"
                                checkable
                                detailed
                                detail-key="id"
                                :show-detail-icon="showDetailIcon"
                                default-sort="user.first_name"
                                aria-next-label="Next page"
                                aria-previous-label="Previous page"
                                aria-page-label="Page"
                                aria-current-label="Current page">
                                <template
                                    v-for="(column, index) in columnTemplate"
                                    >
                                    <b-table-column 
                                        v-bind="column"
                                        :key="index" 
                                        :sortable="true"
                                        :label="column.title"
                                        :visible="column.visible">
										<template v-slot="props" :width="props.column.width">
											<span v-if="props.column.field === 'color'">
												<div class="color-box" :style="'background: ' + props.row.color_1">
											
												</div>
											</span>
											<span v-else-if="props.column.field === 'region.short_name'">
												{{ props.row.region.short_name }}
											</span>
											<span v-else-if="props.column.field === 'primary_coach.first_name'">
												<span v-if="props.row.primary_coach">
													{{ props.row.primary_coach.first_name }}
												</span>
											</span>
											<span v-else-if="props.column.field === 'primary_coach.last_name'">
												<span v-if="props.row.primary_coach">
													{{ props.row.primary_coach.last_name }}
												</span>
											</span>
											<span v-else-if="props.column.field === 'primary_coach.email'">
												<span v-if="props.row.primary_coach">
													{{ props.row.primary_coach.email }}
												</span>
											</span>
											<span v-else-if="props.column.field === 'primary_coach.phone'">
												<span v-if="props.row.primary_coach">
													{{ props.row.primary_coach.phone }}
												</span>
											</span>
											<span v-else-if="props.column.field === 'core_roster.length'">
												{{ props.row.core_roster.length }}
											</span>
											<span v-else-if="props.column.field === 'event_teams.length'">
												{{ props.row.event_teams.length }}
											</span>
											<span v-else-if="props.column.field === 'insurance.length'" style="text-align: center;">
												<b-tag v-for="product in props.row.insurance" :key="product.id">
													<b>{{ product.season }} {{ product.gender.toUpperCase() }} <br> {{ product.with_insurance ? 'Insured' : '' }} {{ product.from_league ? '\n League' : '' }}  {{ product.single_event ? '\n SINGLE EVENT' : '' }}</b>
													{{ (product.fully_paid === 1 ? '- PAID' : '- UNPAID') }}
												</b-tag>
											</span>
											
											<span v-else>
												{{ props.row[column.field] }}
											</span>
										</template>
									</b-table-column>
								</template>
								<template slot="detail" slot-scope="props">
									<div class="row">
										<div class="col-md-2">
											<a :href="'/superadmin/manage/teams/' + props.row.slug" class="button">View Team Profile</a>
										</div>
										<div class="col-md-4">
											<a class="button is-danger" v-if="props.row.deleted_at" v-on:click="undeleteTeam(props.row)">Undelete</a>
										</div>
										<div class="col-md-4"></div>
									</div>
								</template>
							</b-table>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import axios from 'axios'
import _ from 'lodash'
export default {
	data() {
		return {
			isLoading: true,
			authUser: { first_name: null },
			perPage: 50,
			teams: [],
			content_width: false,
			showDetailIcon: true,
			searching: {
                'name': null,
                'city': null,
                'region_short_name': null,
                'coach': null,
                'email': null,
                'phone': null,
			},
			selectedTeams: [],
			years: ['All', 2024, 2023, 2022, 2021,2020,2019,2018,2017,2016,2015,2014,2013,2012,2011,2010],
			sendMessage: false,
			specific_region: false,
			sendEmail: {
                subject: null,
                message: null
			},
			emailSelectables: {
				primary_coaches: false,
				all_coaches: false,
				team_contacts: false,
				rostered_players: false,
			},
			timer: null,
			searchables: {
				id: null,
				region: '*',
				season: 'All',
				team_name: null,
				city: null,
				coach_email: null,
				coach_phone: null,
				coach_first_name: null,
				coach_last_name: null,
				deleted_at: false,
                limit: 100,
			},
			
			columnTemplate: [
				{ title: 'ID', label: 'ID', field: 'id', visible: false, searchable: false },
				{ title: 'Color', label: 'Color', field: 'color', visible: true, searchable: false, width: 40 },
				{ title: 'Membership Purchased', label: 'Membership Purchased', field: 'insurance.length', visible: true, searchable: false },
				{ title: 'Team Name', label: 'Team Name', field: 'name', visible: true, searchable: false },
				{ title: 'City', label: 'City', field: 'city', visible: true, searchable: false },
				{ title: 'Region', label: 'Region', field: 'region.short_name', visible: true, searchable: false },
				{ title: 'Coach First', label: 'Coach First', field: 'primary_coach.first_name', visible: true, searchable: false },
				{ title: 'Coach Last', label: 'Coach Last', field: 'primary_coach.last_name', visible: true, searchable: false },
				{ title: 'Coach Email', label: 'Coach Email', field: 'primary_coach.email', visible: true, searchable: false },
				{ title: 'Coach Phone', label: 'Coach Phone', field: 'primary_coach.phone', visible: true, searchable: false },
				{ title: 'Core Players', label: 'Core Players', field: 'core_roster.length', visible: true, searchable: false },
				{ title: 'Event Teams', label: 'Event Teams', field: 'event_teams.length', visible: false, searchable: false },
				
	
			]
		};
	},
	components: {
		Header,
		Footer,
	},
	created() {
        this.debouncedCheckingThis = _.debounce(this.checkingThis, 1000);
    },
	watch: {
        searchables: {
            handler(val) {   
                this.debouncedCheckingThis()
            }, deep: true
        }
    },
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

		if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.manage_teams !== 1) {
                    this.$router.push({ name: 'Dashboard' })
				}

				if(results.data.user_permissions.specific_region_only) {
                    vm.searchables.region = results.data.user_permissions.specific_region_only
                    vm.specific_region = true
                }
				
				this.grabTeams()
				

				vm.isLoading = false
			}
        })
	},
	computed: {
		computedYears: function() {
			var years = []
			var lowest_year = 2009

			var currentDate = new Date();
			var current_year = currentDate.getFullYear();

			// If the current month is September (9) or later, increment the year
			if (currentDate.getMonth() >= 8) {
				current_year++;
			}

			years.push('All')

			var years_difference = current_year - lowest_year
			var i = 0
			for(i; i < years_difference; i++) {
				years.push(current_year - i)
			}

			return years
		},
		computedEmails: function() {
			var vm = this
			var array = []
			if(vm.selectedTeams.length > 0) {
				var i = 0

				for(i; i < vm.selectedTeams.length; i++) {
					var team = vm.selectedTeams[i]
					if(!vm.emailSelectables.all_coaches) {
						if(vm.emailSelectables.primary_coaches) {
							array.push(team.primary_coach.email)
						}
					}
					
					var s = 0

					for(s; s < team.core_roster.length; s++) {
						var roster = team.core_roster[s]

						if(vm.emailSelectables.all_coaches) {
							if(roster.role_on_team === 1) {
								array.push(roster.user.email)
							}
						}
						if(vm.emailSelectables.team_contacts) {
							if(roster.role_on_team === 2) {
								array.push(roster.user.email)
							}
						}
						if(vm.emailSelectables.rostered_players) {
							if(roster.role_on_team === 3) {
								array.push(roster.user.email)
							}
						}
					}	
				}
			}

			return array
		}
	},
	methods: {
		grabTeams: function() {
			var vm = this

			var params = this.$route.query

			var keys = Object.keys(params)
			
			for(const key of keys) {
				vm.searchables[key] = params[key]
			}	

			axios.get('/api/superuser/teams', {
				params: vm.searchables
			}).then(results => {
				if(results.data.success === true) {
					vm.teams = results.data.teams
				}
			})
		},
		editTeam: function(team) {

		},
		viewTeamProfile: function(team) {

		},
		switchWidth: function($event) {
			this.content_width = !$event
		},
		checkingThis: function() {
            var vm = this

		
			var params = new URLSearchParams()
			var keys = Object.keys(vm.searchables)
			for(const key of keys) {
				if(vm.searchables[key] !== null) {
					params.set(key, vm.searchables[key])
				}
			}

			window.history.replaceState(null, null, '?'+params.toString())
			
			axios.get('/api/superuser/teams', {
				params: vm.searchables
			}).then(results => {
				if(results.data.success === true) {
					vm.teams = results.data.teams
				}
			})
		
		},
		undeleteTeam: function(team) {
			axios.post('/api/superuser/undelete-team', {
				team: team.id
			}).then(results => {
				team.deleted_at = null
				team.deleted_by = null
				this.$buefy.snackbar.open({
					message: 'You have successfully undeleted the team!',
					type: 'is-success' // is-danger
				})
			})
		},
		sendMessageToPlayers: function() {
            var vm = this

            axios.post('/api/superuser/send-messages-to-players', {
                message: vm.sendEmail,
                emails: vm.computedEmails,
            }).then(results => {
                if(results.data.success === true) {
                    vm.sendEmail = {
                        subject: null,
                        message: null
                    }

                    vm.selectedUsers = []
                    this.$buefy.snackbar.open({
                        message: 'You have successfully sent your message!',
                        type: 'is-success' // is-danger
                    })
                }
            })
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
