<template>
    
    <div class="edit-tournament-box" v-if="tournament">
        <b-modal
			v-model="addTeamToEventSwitch" :width="900">
			<template #default="props">
				<div class="modal-card" style="width: 100%;">
					<header class="modal-card-head">
						<p class="modal-card-title">Find Team</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<div v-if="selectedTeam === null">
							<b-field label="Search for team" style="width: 100%;">
								<b-input v-model="searchForTeam" placeholder="Start typing..." v-on:input="searchForTeamFunction($event)"></b-input>
							</b-field>

							<b-field>
								<b-checkbox v-on:input="searchForTeamFunction()" v-model="selectRegion">Include out-of-region teams</b-checkbox>
							</b-field>

							<table class="table">
								<tr>
									<td></td>
									<td><b>Roster Season</b></td>
									<td><b>Team Name</b></td>
									<td><b>Division</b></td>
									<td><b>Coach Name</b></td>
									<td><b>City</b></td>
									<td><b>Region</b></td>
								</tr>
								<tr :key="team.id" v-for="team in searchResults">
									<td>
										<a class="button is-small" v-on:click="selectedTeam = team; registerForTournament.team = team">Add</a>
									</td>
									<td>
										<b-tag>{{ team.season }}</b-tag>
										<span v-for="insurance of team.insurance" :key="insurance.id">
											<b-tag type="is-primary" v-if="insurance.fully_paid === 1">{{ insurance.gender.toUpperCase() }} {{ insurance.season }} PAID</b-tag>
											<b-tag type="is-danger" v-if="insurance.fully_paid !== 1">UNPAID</b-tag>
										</span>
									</td>
									<td>
										{{ team.name }}
									</td>
									<td style="text-transform: uppercase;">
										<b-tag>{{ team.division_id }}</b-tag>
									</td>
									<td>
										{{ team.primary_coach.first_name }} {{ team.primary_coach.last_name }}
									</td>
									<td>
										{{ team.city }}
									</td>
									<td>
										{{ team.region.name }}
									</td>
									
								</tr>
							</table>
						</div>
						<div v-else>
							<a v-on:click="selectedTeam = null" class="button is-small">&lt; Select Different Team</a>
							
							<b-field label="Enter a Division" vertical style="margin-top: 20px;">
								<b-select placeholder="Select Gender" v-model="registerForTournament.gender">
									<option value="mens" v-if="totalDivisions.mens.length > 0">Mens</option>
									<option value="ladies" v-if="totalDivisions.ladies.length > 0">Ladies</option>
									<option value="coed" v-if="totalDivisions.coed.length > 0">Coed</option>
								</b-select>
								
								<b-select style="margin-left: 10px;" placeholder="Select Division" v-model="registerForTournament.division">
									<option :value="division" :key="division.id" v-for="division in totalDivisions[registerForTournament.gender]">{{ division.rank_id }}</option>
								</b-select>
							</b-field>
							
							<b-field label="Create an order?">
								
								<b-select v-if="tournament.status === 'provincials' || tournament.status === 'nationals'" style="margin-left: 10px;" placeholder="Create an Order" v-model="registerForTournament.create_order">
									<option :value="true">Yes</option>
									<option :value="false">No</option>
								</b-select>
							</b-field>
						
							<p class="note">You will be billed for adding this team and be responsible for collecting their insurance fees.</p>
							<a v-on:click="registerTeamToEvent()" style="margin-top: 10px;" class="admin-button">Register To The Event</a>
						</div>
						
					</section>
				</div>
			</template>
		</b-modal>
        <b-modal
			v-model="changeDivisionSwitch" :width="600">
			<template #default="props">
				<div class="modal-card" style="width: 600px;">
					<header class="modal-card-head">
						<p class="modal-card-title">Change Divisions</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<b-field label="Enter a Division" vertical style="margin-top: 20px;">
							<b-select placeholder="Select Gender" v-model="changeDivisionDetails.gender">
								<option value="mens" v-if="totalDivisions.mens.length > 0">Mens</option>
								<option value="ladies" v-if="totalDivisions.ladies.length > 0">Ladies</option>
								<option value="coed" v-if="totalDivisions.coed.length > 0">Coed</option>
							</b-select>
							
							<b-select style="margin-left: 10px;" placeholder="Select Division" v-model="changeDivisionDetails.division">
								<option :value="division" :key="division.id" v-for="division in totalDivisions[changeDivisionDetails.gender]">{{ division.rank_id }}</option>
							</b-select>
						</b-field>
						<p class="note">Note: team changing gendered division may affect their membership (example: from mens to coed). When completing the change please review their membership under the "Registered" column.</p>
						<div>
							<a v-on:click="moveTeamToNewDivision()" style="margin-top: 10px;" class="admin-button">Change Division</a>
						</div>	
					</section>
				</div>
			</template>
		</b-modal>

        <b>Team count: {{ registeredTeams.length }}</b>
        

        <div>
            <div>
                <p><b>Mass Features</b></p>
            </div>
            <vue-excel-xlsx
                :data="computedSelectedRegisteredTeams"
                :columns="columnTemplate"
                :filename="'filename'"
                :sheetname="'sheetname'"
                :class="'button is-success'"
                >
                <i class="fas fa-file-export"></i>&nbsp;&nbsp; Export to Excel ({{ selectedRegisteredTeams.length }})
            </vue-excel-xlsx>
			 <vue-excel-xlsx
                :data="computedSelectedOnlyTeams"
                :columns="columnTemplateRegisteredTeams"
                :filename="'filename'"
                :sheetname="'sheetname'"
                :class="'button is-success'"
                >
                <i class="fas fa-file-export"></i>&nbsp;&nbsp; Export Teams Only ({{ selectedRegisteredTeams.length }})
            </vue-excel-xlsx>

			<a class="button" v-if="tournament.status === 'provincials' || tournament.status === 'nationals'" v-on:click="emailRostersToUser = true">Email Rosters to User ({{ selectedRegisteredTeams.length }})</a>

			<div v-if="emailRostersToUser" style="max-width: 400px; margin: 30px;">
				<b-field label="Send Rosters to Email">
					<b-input placeholder="Email to send to:" v-model="sendRostersToUserEmail"></b-input>
				</b-field>
				<a class="admin-button" v-on:click="sendRostersToUserTrigger()">Send ({{ selectedRegisteredTeams.length }}) Rosters</a>
			</div>
            <b-message type="is-danger" has-icon aria-close-label="Close message">
				Use the checkboxes beside each team to select which teams you'd like to export, and then press the green export to excel button to download them!
			</b-message>
        </div>
		<a class="button is-small" style="margin-top: 20px;" v-on:click="selectedRegisteredTeams = registeredTeams">Select All Teams</a>
		<a class="button is-small is-danger" style="margin-top: 20px;" v-on:click="selectedRegisteredTeams = []" v-if="selectedRegisteredTeams.length > 0">Clear Selected Teams</a>
		<br><br>
		<b-tag type="is-success">Membership with Insurance</b-tag>
		<b-tag type="is-warning">Membership WITHOUT Insurance</b-tag>
		<b-tag type="is-info">Single Event Membership</b-tag>
		<b-tag type="is-danger">Needs Membership</b-tag>
		<br><br>
		<b-tabs v-model="currentTab">
			<b-tab-item :key="'*'" :label="'All'">
				<b-table
					:data="registeredTeams"
					ref="table"
					checkable
					:narrowed="true"
					:checked-rows.sync="selectedRegisteredTeams"
				>
					<b-table-column
						field="team.name"
						label="Name">
						<template v-slot="props">
							<div class="color-strip" :style="'background: ' + props.row.team.color_1">
								
							</div>
							<b>{{ props.row.team.name }}</b><br>
							<b-tag>
								Added on {{ moment(props.row.created_at).format('YYYY-MM-DD h:mm a') }}
							</b-tag>
							<span v-if="props.row.email_sent_to_convenor">
								<b-tag type="is-info">Email Sent to Convenor</b-tag>
							</span>
							<br>
							<span v-if="props.row.convenor_approved">
								<b-tag type="is-success">Convenor Approved</b-tag>
							</span>
							
						</template>
					</b-table-column>
					<b-table-column
						field="tournament_gender_id"
						label="Gender">
						<template v-slot="props">
							<span v-if="props.row.tournament_gender_id">
								{{ props.row.tournament_gender_id.charAt(0).toUpperCase() + props.row.tournament_gender_id.slice(1) }}
							</span>
						</template>
					</b-table-column>
					<b-table-column
						field="division.rank_id"
						label="Rank">
						<template v-slot="props">
							<span v-if="props.row.division">
								<RankComponent :currentRank="props.row.division.rank_id"></RankComponent>
							</span>
						</template>
					</b-table-column>
					<b-table-column
						field="city"
						label="City/Region">
						<template v-slot="props">
							<b-tag>
								<span v-if="props.row.team.city">
									{{ props.row.team.city }}
								</span>
							</b-tag>
							<br>
							<b-tag>
								<span v-if="props.row.team.region_id === 1">Alberta</span>
								<span v-if="props.row.team.region_id === 2">British Columbia</span>
								<span v-if="props.row.team.region_id === 3">Manitoba</span>
								<span v-if="props.row.team.region_id === 4">Ontario</span>
								<span v-if="props.row.team.region_id === 5">Maritimes</span>
								<span v-if="props.row.team.region_id === 6">Quebec</span>
								<span v-if="props.row.team.region_id === 7">Newfoundland Labrador</span>
								<span v-if="props.row.team.region_id === 8">Saskatchewan</span>
							</b-tag>
						</template>
					</b-table-column>
					<b-table-column
						field=""
						label="Player Count">
						<template v-slot="props">
							<span style="text-align: center;">
							{{ props.row.roster.roster_players.length }}
							</span>
						</template>
					</b-table-column>
					<b-table-column
						field="division.rank_id"
						label="Registered">
						<template v-slot="props">
							<span v-if="props.row.insurance">
								<b-tag type="is-success" v-if="props.row.insurance.with_insurance === 1">
									<b>{{ props.row.insurance.spn_number }}</b>
								</b-tag>
								<span v-else-if="props.row.insurance.single_event === 1">
									<b-tag type="is-danger" v-if="props.row.insurance.needs_upgrade === 1">
										<b>Already Used Single Event Insurance - TEAM NEEDS TO UPGRADE</b>
									</b-tag>
									<b-tag type="is-info" v-else>
										<b>{{ props.row.insurance.spn_number }}</b>
									</b-tag>
								</span>
								<b-tag v-else  type="is-warning">
									{{ props.row.insurance.spn_number }}
								</b-tag>
							</span>
							<b-tag v-else type="is-danger">
								<b>NEEDS MEMBERSHIP</b>
							</b-tag>
						</template>
					</b-table-column>
					<b-table-column
						field=""
						v-if="tournament.status === 'provincials' || tournament.status === 'nationals'"
						label="Approved">
						<template v-slot="props">
							<b-dropdown :triggers="['hover']" v-model="props.row.approved" aria-role="list">
								<template v-if="props.row.approved === null" #trigger>
									<button class="button is-danger">Pending&nbsp;&nbsp;<i class="fas fa-caret-down"></i></button>
								</template>
								<template v-else-if="props.row.approved === 1" #trigger>
									<button class="button is-success">Approved&nbsp;&nbsp;<i class="fas fa-caret-down"></i></button>
								</template>
								<template v-else-if="props.row.approved === -1" #trigger>
									<button class="button is-warning">Unapproved&nbsp;&nbsp;<i class="fas fa-caret-down"></i></button>
								</template>
								<b-dropdown-item v-on:click="pendingTeam(props.row)" aria-role="listitem">
									Change to Pending
								</b-dropdown-item>
								<b-dropdown-item v-on:click="unapproveTeam(props.row)" aria-role="listitem">
									Change to Unapproved
								</b-dropdown-item>
								<b-dropdown-item v-on:click="approveTeam(props.row)" aria-role="listitem">
									Change to Approved
								</b-dropdown-item>
							</b-dropdown>
						</template>
					</b-table-column>
					<b-table-column
						field="division.rank_id"
						label="Actions" v-slot="props">
						<a :href="'/superadmin/manage/tournament/' + tournament.slug + '/event-roster/' + props.row.id" class="button is-small is-info" v-if="superadmin === true">View Roster</a>
						<a :href="'/dashboard/convenors/tournaments/' + tournament.slug + '/event-roster/' + props.row.id" class="button is-small is-info" v-if="superadmin === false">View Roster</a>
						<a class="button is-small is-warning" v-on:click="changeDivisionFunction(props.row)">
							Change Division
						</a>
						<a v-on:click="removeTeam(props.row)" class="button is-small is-danger">
							Remove Team
						</a>
					</b-table-column>

					
				</b-table>
			</b-tab-item>
			<b-tab-item v-for="division in tournament.divisions" :key="division.id" :label="division.gender_slug.toUpperCase() + ' ' + division.rank_id">
				<b-table
					:data="filterOutByDivision(registeredTeams, division)"
					ref="table"
					checkable
					:checked-rows.sync="selectedRegisteredTeams"
				>
					<b-table-column field="color" label="Color">
						<template v-slot="props">
							<div class="color-box" :style="'background: ' + props.row.team.color_1">
								
							</div>
						</template>
					</b-table-column>
					<b-table-column
						field="team.name"
						label="Name">
						<template v-slot="props">
							{{ props.row.team.name }}
						</template>
					</b-table-column>
					<b-table-column
						field="tournament_gender_id"
						label="Division Gender">
						<template v-slot="props">
							<span v-if="props.row.tournament_gender_id">
								{{ props.row.tournament_gender_id.charAt(0).toUpperCase() + props.row.tournament_gender_id.slice(1) }}
							</span>
						</template>
					</b-table-column>
					<b-table-column
						field="division.rank_id"
						label="Rank">
						<template v-slot="props">
							<span v-if="props.row.division">
								<RankComponent :currentRank="props.row.division.rank_id"></RankComponent>
								
							</span>
						</template>
					</b-table-column>
					<b-table-column
						field="city"
						label="City/Region">
						<template v-slot="props">
							<b-tag>
								<span v-if="props.row.team.city">
									{{ props.row.team.city }}
								</span>
							</b-tag>
							<b-tag>
								<span v-if="props.row.team.region_id === 1">Alberta</span>
								<span v-if="props.row.team.region_id === 2">British Columbia</span>
								<span v-if="props.row.team.region_id === 3">Manitoba</span>
								<span v-if="props.row.team.region_id === 4">Ontario</span>
								<span v-if="props.row.team.region_id === 5">Maritimes</span>
								<span v-if="props.row.team.region_id === 6">Quebec</span>
								<span v-if="props.row.team.region_id === 7">Newfoundland Labrador</span>
								<span v-if="props.row.team.region_id === 8">Saskatchewan</span>
							</b-tag>
						</template>
					</b-table-column>
					<b-table-column
						field=""
						label="Player Count">
						<template v-slot="props">
							
							{{ props.row.roster.roster_players.length }}
						</template>
					</b-table-column>
					<b-table-column
						field="division.rank_id"
						label="Registered">
						<template v-slot="props">
							<span v-if="props.row.insurance">
								<b-tag type="is-success" v-if="props.row.insurance.with_insurance === 1">
									<b>{{ props.row.insurance.spn_number }}</b>
								</b-tag>
								<span v-else-if="props.row.insurance.single_event === 1">
									<b-tag type="is-danger" v-if="props.row.insurance.needs_upgrade === 1">
										<b>Already Used Single Event Insurance - TEAM NEEDS TO UPGRADE</b>
									</b-tag>
									<b-tag type="is-info" v-else>
										<b>{{ props.row.insurance.spn_number }}</b>
									</b-tag>
								</span>
								<b-tag v-else  type="is-warning">
									{{ props.row.insurance.spn_number }}
								</b-tag>
							</span>
							<b-tag v-else type="is-danger">
								<b>NEEDS MEMBERSHIP</b>
							</b-tag>
						</template>
					</b-table-column>
					<b-table-column
						field=""
						v-if="tournament.status === 'provincials' || tournament.status === 'nationals'"
						label="Approved">
						<template v-slot="props">
							<span v-if="props.row.approved === 1">
								<b-tag type="is-success">Approved</b-tag>
							</span>
							<span v-if="props.row.approved === -1">
								<b-tag type="is-warning">Unapproved</b-tag>
							</span>
							<span v-if="props.row.approved === null">
								<b-tag>Pending</b-tag>
							</span>
							
							<span v-if="props.row.email_sent_to_convenor">
								<b-tag type="is-info">Email Sent to Convenor</b-tag>
							</span>
							<span v-if="props.row.convenor_approved">
								<b-tag type="is-success">Convenor Approved</b-tag>
							</span>
							<span v-if="props.row.approved !== 1">
								<a class="button is-small" v-on:click="approveTeam(props.row)">Click to Approve Team</a>
								<a class="button is-small" v-on:click="unapproveTeam(props.row)">Move to Unapproved Team</a>
							</span>
							
						</template>
					</b-table-column>
					<b-table-column
						field="division.rank_id"
						label="Actions" v-slot="props">

						<a :href="'/superadmin/manage/tournament/' + tournament.slug + '/event-roster/' + props.row.id" class="button is-small is-info" v-if="superadmin === true">View Roster</a>
						<a :href="'/dashboard/convenors/tournament/' + tournament.slug + '/event-roster/' + props.row.id" class="button is-small is-info" v-if="superadmin === false">View Roster</a>
						<a class="button is-small is-warning" v-on:click="changeDivisionFunction(props.row)">
							Change Division
						</a>
						<a v-on:click="removeTeam(props.row)" class="button is-small is-danger">
							Remove Team
						</a>
					</b-table-column>
				</b-table>
			</b-tab-item>
		</b-tabs>
        
        <a class="admin-button" v-if="checkIfPreviousDate(tournament)" style="margin-top: 20px;" v-on:click="addTeamToEvent()">Add Team to Your Event</a>
    </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import RankComponent from '../../pages/components/RankComponent.vue'
export default {
    data() {
        return {
            tournament: null,
            registeredTeams: [],
            selectedRegisteredTeams: [],
			emailRostersToUser: false,
			sendRostersToUserEmail: null,
            columnTemplate: [
				{ title: 'ID', label: 'ID', field: 'id', visible: false, searchable: false },
				{ title: 'Team Name', label: 'Team Name', field: 'team_name', visible: true, searchable: false },
				{ title: 'Role', label: 'Role', field: 'role', visible: true, searchable: false },
				{ title: 'Rank', label: 'Rank', field: 'level', visible: true, searchable: false },
				{ title: 'First Name', label: 'First Name', field: 'first_name', visible: false, searchable: false },
				{ title: 'Last Name', label: 'Last Name', field: 'last_name', visible: false, searchable: false },
				{ title: 'Gender', label: 'Gender', field: 'gender', visible: false, searchable: false },
				{ title: 'Date of Birth', label: 'Date of Birth', field: 'date_of_birth', visible: true, searchable: false },
				{ title: 'Email', label: 'Email', field: 'email', visible: false, searchable: false },
				{ title: 'Street Address', label: 'Street Address', field: 'street_address', visible: true, searchable: false },
				{ title: 'City', label: 'City', field: 'city', visible: true, searchable: false },
				{ title: 'Province', label: 'Province', field: 'province', visible: false, searchable: false },
				{ title: 'Postal Code', label: 'Postal Code', field: 'postal_code', visible: false, searchable: false },
				{ title: 'Phone', label: 'Phone', field: 'phone', visible: false, searchable: false },
				{ title: 'Waiver', label: 'Waiver', field: 'waiver', visible: false, searchable: false },
			],
			columnTemplateRegisteredTeams: [
				{ title: 'ID', label: 'ID', field: 'id', visible: false, searchable: false },
				{ title: 'Team Name', label: 'Team Name', field: 'team_name', visible: true, searchable: false },
				{ title: 'Gender', label: 'Gender', field: 'gender', visible: true, searchable: false },
				{ title: 'Rank', label: 'Rank', field: 'rank', visible: true, searchable: false },
				{ title: 'City', label: 'City', field: 'city', visible: true, searchable: false },
				{ title: 'Insurance', label: 'Insurance', field: 'insurance', visible: true, searchable: false },
				{ title: 'Roster Count', label: 'Roster Count', field: 'roster_count', visible: true, searchable: false },
				{ title: 'Coach First Name', label: 'Coach First Name', field: 'coach_first_name', visible: true, searchable: false },
				{ title: 'Coach Last Name', label: 'Coach Last Name', field: 'coach_last_name', visible: true, searchable: false },
				{ title: 'Coach Email', label: 'Coach Email', field: 'coach_email', visible: true, searchable: false },
				{ title: 'Coach Phone', label: 'Coach Phone', field: 'coach_phone', visible: true, searchable: false },
				{ title: 'City', label: 'City', field: 'city', visible: true, searchable: false },
				{ title: 'Approved', label: 'Approved', field: 'approved', visible: true, searchable: false },
			],
            searchForTeam: null,
            changeDivisionSwitch: null,
            addTeamToEventSwitch: false,
            searchResults: null,
            selectedTeam: null,
            selectRegion: false,
            registerForTournament: {
                team: null,
                gender: null,
                division: null
			},
			changeDivisionDetails: {
				gender: null,
				team: null,
				division: null
			},
        };
    },
	components: {
		RankComponent
	},
    computed: {
        computedSelectedRegisteredTeams: function() {
            var vm = this
			
			var teams = this.selectedRegisteredTeams
			var array = []
			var i = 0

			for(i; i < teams.length; i++) {
				var team = teams[i]
				
				var s = 0

				for(s; s < team.roster.roster_players.length; s++) {
					var roster = team.roster.roster_players[s]

					var role = 'Player'

					if(roster.core_roster_player.role_on_team === 1) {
						role = 'Coach'

						if(roster.user_id === team.team.primary_coach_id) {
							role = "Primary Coach"
						}
					}
					if(roster.core_roster_player.role_on_team === 2) {
						role = 'Team Contact'
					}

					array.push({
						id: team.id,
						team_name: team.team.name,
						first_name: roster.user.first_name,
						last_name: roster.user.last_name,
						gender: roster.user.gender,
						date_of_birth: roster.user.date_of_birth,
						email: roster.user.email,
						street_address: roster.user.street_address,
						city: roster.user.city,
						province: roster.user.province,
						postal_code: roster.user.postal_code,
						phone: roster.user.phone,
						level: roster.user.current_rank_id,
						waiver: (roster.user.latest_waiver_signed === vm.$latest_waiver ? 'Waiver Signed' : 'Not Signed'),
						role: role
					})
				}	
			}

			return array
        },
		computedSelectedOnlyTeams: function() {
            var vm = this
			
			var teams = this.selectedRegisteredTeams
			var array = []
			var i = 0

			for(i; i < teams.length; i++) {
				var team = teams[i]
				
				var s = 0

				var insurance = null

				if(team.insurance) {
					insurance = team.insurance.spn_number
				}

				var object = {
					id: team.id,
					team_name: team.team.name,
					gender: team.tournament_gender_id,
					rank: team.division.rank_id,
					division: team.team.city,
					insurance: insurance,
					roster_count: team.roster.roster_players.length,
					coach_first_name: team.team.primary_coach.first_name,
					coach_last_name: team.team.primary_coach.last_name,
					coach_email: team.team.primary_coach.email,
					coach_phone: team.team.primary_coach.phone,
					city: team.team.city,
					approved: team.approved === 1 ? 'Team Approved' : 'Team Unapproved'
				}

				

				array.push(object)
		
			}

			return array
        },
        totalDivisions: function() {
            var vm = this
            
            var mens = vm.filterDivision(vm.tournament.divisions, 'mens')
            var ladies = vm.filterDivision(vm.tournament.divisions, 'ladies')
            var coed = vm.filterDivision(vm.tournament.divisions, 'coed')
            
            var count = 0
            if(mens.length > 0) {
                count = count + 1
            } 
            if(ladies.length > 0) {
                count = count + 1
            }
            if(coed.length > 0) {
                count = count + 1
            }
            
            return {
                mens: mens,
                ladies: ladies,
                coed: coed,
                count: count
            }
        },
    },
    props: {
		eventId: {
			type: Number
		},
		eventSlug: {
			type: String
		},
		eventType: {
			type: String
		},
		hideSidebar: {
			type: Boolean
		},
		superadmin: {
			type: Boolean,
			default: true
		}
	},
    mounted: function() {
        var self = this

        self.grabTournament()
        self.grabRegisteredTeams()
	
    },
    methods: {
		filterOutByDivision: function(teams, division) {

			
			return _.filter(teams, function(team) {
				if(team.division) {
					return team.division.id === division.id
				}
				return false
			})

			// return teams
		},
        grabTournament: function() {
            var vm = this

			var url = `/api/superuser/grab-tournament/${vm.eventId}`

			if(this.superadmin === false) {
				url = `/api/tournaments/get-tournament/${vm.eventSlug}`
			}
            

            axios.get(url)
            .then(results => {
                if(results.data.success === true) {
                    vm.tournament = results.data.tournament
                } else {

                }
            })
        },
        searchForTeamFunction: function($event = null) {
			var vm = this


            if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
				if($event) {
					axios.post('/api/tournaments/search-for-team', {
						tournament_id: vm.tournament.id,
						search: $event,
						region_on: vm.selectRegion,
						region_id: vm.tournament.region_id
					}).then(results => {
						if(results.data.success === true) {
							vm.searchResults = results.data.teams
						}
					})
				} else {
					axios.post('/api/tournaments/search-for-team', {
						tournament_id: vm.tournament.id,
						search: vm.searchForTeam,
						region_on: vm.selectRegion,
						region_id: vm.tournament.region_id
					}).then(results => {
						if(results.data.success === true) {

							vm.searchResults = results.data.teams
						}
					})
				}
			}, 500)
		},
        grabRegisteredTeams: function() {
            var vm = this

			var url = `/api/superuser/tournament-get-registered-teams/${vm.eventId}`

			if(this.superadmin === false) {
				url = `/api/tournaments/get-registered-teams-with-rosters/${vm.eventSlug}`
			}
            
            axios.get(url)
            .then(results => {
                if(results.data.success === true) { 
                    
                    vm.registeredTeams = results.data.registrants					
                }
            })
        },
        checkIfPreviousDate: function(tournament) {
			if(tournament) {
                var start_date = moment(tournament.event_start_date)

                var is_after = start_date.isAfter(moment())
                
                // var now = moment().format('YYYY-MM-DD')
                // var tournament_start_date = moment(tournament.event_start_date).format('YYYY-MM-DD')
                // console.log('isAfter', now.isAfter(tournament.event_start_date))
                return is_after
            }
            return false
		},
        changeDivisionFunction: function(team) {
			
			var vm = this
			vm.changeDivisionDetails.team = team
			vm.changeDivisionSwitch = true
		},
        filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
		},
        moveTeamToNewDivision: function(team) {
			var vm = this

			var data = {
				details: {
					team: {
						id: vm.changeDivisionDetails.team.id,
						insurance_id: vm.changeDivisionDetails.team.insurance_id
					},
					division: {
						id: vm.changeDivisionDetails.division.id
					},
					gender: vm.changeDivisionDetails.gender
				}
			}
			
			axios.post('/api/tournaments/change-team-to-new-division', data).then(results => {
				if(results.data.success === true) {
					vm.grabRegisteredTeams()
					vm.changeDivisionSwitch = false
					this.$buefy.snackbar.open({
                        message: 'Team moved to new division successfully.',
                        type: 'is-success' // is-danger
                    })
				}
			})
		},
        addTeamToEvent: function() {
			var vm = this

			vm.addTeamToEventSwitch = true
		},
        registerTeamToEvent: function() {
			var vm = this
			
			axios.post('/api/tournaments/add-team-to-event', {
				registration: vm.registerForTournament,
				tournament_name: vm.tournament.name,
				tournament_id: vm.tournament.id
			}).then(results => {
				if(results.data.success === true) {
					vm.grabRegisteredTeams()
					vm.addTeamToEventSwitch = false
					this.$buefy.snackbar.open({
                        message: 'Team added successfully to your event.',
                        type: 'is-success' // is-danger
                    })
				}
			})
		},
        removeTeam: function(team) {
			var vm = this
			var r = confirm("Are you sure you want to remove this team from your event?")
			
			if(r === true) {
				axios.post('/api/tournaments/remove-team-from-event', {
					team_id: team.id
				}).then(results => {
					vm.grabRegisteredTeams()
					this.$buefy.snackbar.open({
						message: 'Removed team from this event',
						type: 'is-success', // is-danger
						duration: 4000,
						queue: false
					})
				})
			}
		},
		sendRostersToUserTrigger: function() {
			var vm = this

			var r = confirm("Are you sure you want to email these rosters to this user?")

			if(r === true) {
				var i = 0
				var rosters = []

				for(i; i < vm.selectedRegisteredTeams.length; i++) {
					var roster = vm.selectedRegisteredTeams[i]

					rosters.push({ id: roster.id, team_id: roster.team_id })
				}

				axios.post('/api/superuser/send-rosters-to-email', {
					rosters: rosters,
					email: vm.sendRostersToUserEmail
				}).then(results => {
					if(results.data.success === true) {
						this.$buefy.snackbar.open({
							message: 'Rosters emailed!',
							type: 'is-success', // is-danger
							duration: 4000,
							queue: false
						})
						vm.selectedRegisteredTeams = []
						vm.emailRostersToUser = false
					}
				})
			}
		},
		approveTeam: function(team) {
			var vm = this
			var r = confirm("Are you sure you want to approve this team for this event?")

			if(r === true) {
				axios.post('/api/superuser/approve-team-for-event', {
					team: team.id,
				}).then(results => {
					if(results.data.success === true) {
						this.$buefy.snackbar.open({
							message: 'Team approved',
							type: 'is-success', // is-danger
							duration: 4000,
							queue: false
						})
					}
				})
			}
		},
		unapproveTeam: function(team) {
			var vm = this
			

		
			axios.post('/api/superuser/unapprove-team-for-event', {
				team: team.id,
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
						message: 'Team moved to unapproved',
						type: 'is-success', // is-danger
						duration: 4000,
						queue: false
					})
				}
			})
		
		},
		pendingTeam: function(team) {
			var vm = this
			

		
			axios.post('/api/superuser/pending-team-for-event', {
				team: team.id,
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
						message: 'Team moved to unapproved',
						type: 'is-success', // is-danger
						duration: 4000,
						queue: false
					})
				}
			})
		
		},
		moment: function(date, format = null) {
			return moment(date, format)
		}
    }
}
</script>
