<template>
	<div>
		<b-loading :is-full-page="true" v-model="isLoading"></b-loading>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<b-modal 
            v-model="approveUmpireSwitch"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal>
			<template #default="props">
				<div class="modal-card">
					<header class="modal-card-head">
						<p class="modal-card-title">Edit Umpire</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body" v-if="selectedUmpire" style="padding-bottom: 30px;">
						<b-field label="Change Umpire Status">
							<b-select v-model="selectedUmpire.approved">
								<option :value="null">Awaiting Approval</option>
								<option :value="1">Approved</option>
								<option :value="0">Unapproval</option>
							</b-select>
						</b-field>
						<div v-if="selectedUmpire.order">
							<b-field v-if="selectedUmpire.approved === 1" label="Select Payment Method">
								<b-select v-model="selectedUmpire.order.payment_type_id">
									<option :value="0">Pay via SPN</option>
									<option :value="1">EMT</option>
									<option :value="2">Cheque</option>
								</b-select>
							</b-field>
							<b-field v-if="selectedUmpire.approved === 1" label="Enter Payment Tracking # (EMT# or Cheque #)">
								<b-input v-model="selectedUmpire.order.payment_tracking_id"></b-input>
							</b-field>
						</div>
						<b-field 
							style="margin-top: 10px;"
							:grouped="true"
							label="Umpire Permissions (Check All That Apply)"
							:group-multiline="true">
							<b-checkbox :true-value="1" v-model="selectedUmpire.find_gigs">Find Gigs</b-checkbox>
							<b-checkbox :true-value="1" v-model="selectedUmpire.cheat_sheet">Cheat Sheet</b-checkbox>
							<b-checkbox :true-value="1" v-model="selectedUmpire.clinics">Clinics</b-checkbox>
						</b-field>
						
					</section>
					<footer class="modal-card-foot">
                        <button v-on:click="approveUmpire()" class="admin-button"><i class="fas fa-save"></i> Edit Umpire</button>
                    </footer>
				</div>
			</template>
		</b-modal>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper">
				<div :class="['page-header', { sidebarClosed: !content_width }]">
					<div class="row">
						<div class="col-md-3">
							<div class="page-header-title">
								Manage Umpire Exam
							</div>
						</div>
						<div class="col-md-9">
							<div class="padding">
				
							</div>
						</div>
					</div>
				</div>
				<div class="admin-area">
                    <h3>Exam Questions</h3>
                    <p>Below is a list of exam questions and answers. You can remove questions, add and edit questions. They will automatically be applied to new quizzes. Yellow questions are <b>required</b>.</p>

                    
                    <div class="row">
                        <div class="col-md-6">
                            
                                    <div class="questions">
                                        <div :class="['question-backend', { required: question.required === 1 }]" style="margin-top: 20px;" v-for="question in questions" :key="question.id">
                                            <div class="row">
                                                <div class="col-md-9">
													<p>Ref #{{ question.id }}</p>
                                                    <p><b>{{ question.subject }}: </b></p>
                                                    <div v-html="question.text"></div>
                                                    <p><b>{{ question.rulebook_section }}</b></p>
                                                    <b-tag v-if="question.correct_answer === 1 || question.correct_answer === true" type="is-success">True</b-tag>
                                                    <b-tag v-if="question.correct_answer === 0 || question.correct_answer === false" type="is-danger">False</b-tag>
													
                                                </div>
                                                <div class="col-md-3" style="text-align: right;">
													<a class="button is-small" style="float: right;" v-on:click="editQuestion(question)">Edit Question</a>
                                                    <a class="button is-small" style="float: right;" v-on:click="removeQuestion(question.id)">Remove Question</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
         
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <div class="panel">
                                    <div class="panel-heading">
                                        <p v-if="newQuestion.id">Edit a Question</p>
										<p v-else>Add a Question</p>
                                    </div>
                                    <div class="panel-content" style="padding: 20px;">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <b-field label="Subject">
                                                    <b-input v-model="newQuestion.subject"></b-input>
                                                </b-field>
                                            </div>
                                            <div class="col-md-6">
                                                <b-field label="Subject (FR)">
                                                    <b-input v-model="newQuestion.fr_subject"></b-input>
                                                </b-field>
                                            </div>
                                        </div>
                                        
                                        <div class="row">
                                            <div class="col-md-6">
                                                <b-field label="Rule Explanation">
                                                    <ckeditor :editor="editor" v-model="newQuestion.text" :config="editorConfig"></ckeditor>
                                                </b-field>
                                            </div>
                                            <div class="col-md-6">
                                                <b-field label="Rule Explanation (FR)">
                                                    <ckeditor :editor="editor" v-model="newQuestion.fr_text" :config="editorConfig"></ckeditor>
                                                </b-field>
                                            </div>
                                        </div>
                                        
                                        <b-field label="Rulebook Section">
                                            <b-input v-model="newQuestion.rulebook_section"></b-input>
                                        </b-field>
                                        <b-field label="Correct Answer">
                                            <b-radio v-model="newQuestion.correct_answer"
                                                size="is-large"
                                                :native-value="true">
                                                True
                                            </b-radio>
                                            <b-radio v-model="newQuestion.correct_answer"
                                                size="is-large"
                                                :native-value="false">
                                                False
                                            </b-radio>
                                        </b-field>
                                        <b-field label="Required">
                                            <b-radio v-model="newQuestion.required"
                                                size="is-large"
                                                :native-value="true">
                                                Yes
                                            </b-radio>
                                            <b-radio v-model="newQuestion.required"
                                                size="is-large"
                                                :native-value="false">
                                                No
                                            </b-radio>
                                        </b-field>
                                        <a class="admin-button" v-on:click="saveNewQuestion()" style="margin-top: 10px;">
											<span v-if="newQuestion.id">Edit</span>
											<span v-else>Add</span> Question to Exam</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import axios from 'axios'
import moment from 'moment'
export default {
	data() {
		return {
			isLoading: true,
			authUser: { first_name: null },
			questions: [],
			umpires: [],
			checkedRows: [],
			approvalType: 'awaiting',
			content_width: false,
			tabs: 'umpires',
			selectedUmpire: null,
			approveUmpireSwitch: false,
			approvalTypes: [{
				slug: 'awaiting',
				name: 'Awaiting Approval',
			}, {
				slug: 'approved',
				name: 'Approved',
			}, {
				slug: 'unapproved',
				name: 'Unapproved',
			}, {
				slug: 'all',
				name: 'All'
			}],
			newQuestion: {
				subject: null,
				fr_subject: null,
				text: null,
				fr_text: null,
				correct_answer: true,
				required: false
			},
			editor: ClassicEditor,
            editorData: '<p>Rich-text editor content.</p>',
            editorConfig: {
                // The configuration of the rich-text editor.
            },
		};
	},
	components: {
		Header,
		Footer,
	},
	watch: {
		tabs: function() {
			
			if(this.tabs !== this.$route.params.slug) {
				this.$router.replace({ path: '/superadmin/manage/umpires/' + this.tabs })
			}
		}
	},
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

		if(this.$route.params.slug === 'exam') {
			vm.tabs = 'exam'	
		}

		if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
			
            if(results.data.type === "success") {
                vm.authUser = results.data.user

                if(results.data.user_permissions.manage_umpires !== 1) {
                    this.$router.push({ name: 'Dashboard' })
				}
				
						axios.get('/api/superuser/exam-questions').then(results3 => {
							if(results3.data.success === true) {
								
								vm.questions = results3.data.questions

								vm.isLoading = false
							}
						})
				

				
			}
        })
	},
	computed: {
		computedQuestionGroups: function() {
			var vm = this
			var groupBy = _.groupBy(vm.questions, 'subject')
			
			return groupBy
		}
	},
	methods: {
		moment:function(date, format = null) {
			return moment(date, format)
		},
		saveNewQuestion: function() {
			var vm = this
			axios.post('/api/superuser/save-umpire-question', {
				question: vm.newQuestion
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.toast.open({
						message: 'Question has been added/updated',
						type: 'is-success', // is-danger
						duration: 1000,
						queue: false
					})
					vm.newQuestion = {
						subject: null,
						fr_subject: null,
						text: null,
						fr_text: null,
						correct_answer: true,
						required: false
					}
					vm.questions = results.data.questions
				}
			})
		},
		editUmpire: function(umpire) {
			var vm = this
			vm.approveUmpireSwitch = true
			
			vm.selectedUmpire = Object.assign({}, umpire)
		},
		editQuestion: function(question) {
			var vm = this
			var internalQ = question
			internalQ.correct_answer = internalQ.correct_answer === 1 ? true : false
			internalQ.required = internalQ.correct_answer === 1 ? true : false
			vm.newQuestion = internalQ
		},
		removeQuestion: function(question_id) {
			var vm = this
			var r = confirm('Are you sure you want to delete this question?')

			if(r === true) {
				axios.post('/api/superuser/remove-umpire-question', {
					question_id: question_id
				}).then(results => {
					if(results.data.success === true) {
						vm.questions = results.data.questions
					}
				})
			}
		},
		switchWidth: function($event) {
			this.content_width = !$event
		},
		approveUmpire: function() {
			var vm = this

			axios.post('/api/superuser/update-umpire', {
				order_id: vm.selectedUmpire.order_id,
				payment_type_id: vm.selectedUmpire.order.payment_type_id || null,
				payment_tracking_id: vm.selectedUmpire.order.payment_tracking_id || null,
				find_gigs: vm.selectedUmpire.find_gigs,
				cheat_sheet: vm.selectedUmpire.cheat_sheet,
				clinics: vm.selectedUmpire.clinics,
				selected_umpire_id: vm.selectedUmpire.id,
				approved: vm.selectedUmpire.approved
			}).then(results => {
				if(results.data.success === true) {
					vm.approveUmpireSwitch = false
					vm.selectedUmpire = null

					this.$buefy.snackbar.open({
						message: 'Umpire officially updated',
						type: 'is-success' // is-danger
					})
				} else {
					this.$buefy.snackbar.open({
						message: 'Something went wrong when updating the umpire.',
						type: 'is-danger' // is-danger
					})
				}
			})
		}
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
