<template>
	<div>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper" v-if="user">
                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="page-header-title">
                                {{ user.first_name }} {{ user.last_name }}'s Signed Waiver
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a onclick="history.back(); return false;" class="button">Back to User</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                <b-tabs style="margin-top: 30px;">
                    <b-tab-item :label="`Waiver Signed ${moment(waiver.created_at).format('YYYY-MM-DD HH:MM:SS')}`" v-for="waiver of user.waivers" :key="waiver.id">
                        <div class="row">
                            <div class="col-md-6">
                                <b-label>Send a copy of this to:</b-label>
                                <b-field>
                                    
                                    <b-input v-model="sendToThisEmail"></b-input>
                                </b-field>
                                <a class="admin-button" v-on:click="sendWaiverCopyToEmail(waiver)">Send Waiver</a>
                            </div>
                        </div>
                        <br><br><hr><br><br>
                        <div class="waiver-section" v-for="(section, key) of parseJSON(waiver)" :key="key">
                            <div v-html="section.section"></div>
                            <div class="i-agree-row" style="padding: 10px; background: #eee; margin-bottom: 10px; margin-top: 2px;">
                                <b-checkbox disabled :value="true">
                                    I have read, agree, acknowledge, and understand the conditions above.
                                </b-checkbox>
                            </div>
                        </div>
                        <div v-if="waiver.signature_data">
                            <p><b>Signature</b></p>
                            <img :src="waiver.signature_data" alt="">
                        </div>                        
                    </b-tab-item>
                    
                </b-tabs>
              
            </div>
        </div>
	</div>
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import RankComponent from '../components/RankComponent.vue'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
export default {
	data() {

		return {
           
           authUser: null,
           authPermissions: null,
            en: true,
            // editor: ClassicEditor,
           user: null,
           sendToThisEmail: null
		};
	},
	components: {
		Header,
		Footer,
        RankComponent
    },
    watch: {
        addressEditSwitch: function(result) {
            var vm = this
        
        }
    },
	mounted: function() {
		// grab all the dashboard information now
        var vm = this
        var id = this.$route.params.id

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
        
        var options = {}
		
		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                vm.authPermissions = results.data.user_permissions
                if(results.data.user_permissions.manage_players !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }

                axios.get('/api/superuser/grab-user/' + id).then(results => {
                    if(results.data.success === true) {
                        vm.user = results.data.user
                        vm.user.date_of_birth = new Date(vm.user.date_of_birth)
                        if(vm.user.banned_until) {
                            vm.user.banned_until = new Date(vm.user.banned_until)
                        }
                        vm.user.teams_theyre_on = null

                        vm.user.waivers = _.orderBy(vm.user.waivers, 'created_at', 'desc')
                        vm.duplicateUser = results.data.user
                    }
                })

                axios.get('/api/superuser/grab-user-player-coach/' + id).then(results => {
                    if(results.data.success === true) {
                        vm.teams_theyre_on = results.data.teams_theyre_on
                        vm.tournaments_entered = results.data.tournaments_entered
                        vm.leagues_entered = results.data.leagues_entered

                        // vm.user = results.data.user
                        // vm.user.date_of_birth = new Date(vm.user.date_of_birth)
                        // vm.duplicateUser = results.data.user
                    }
                })

                // axios.get('/api/superuser/pages').then(results => {
                //     if(results.data.success === true) {
                //         vm.pages = results.data.pages
                //     }
                // })
            }
        })
	},
	computed: {
	
	},
	methods: {
        updateCertNumber: function() {
            var vm = this

            axios.post('/api/superuser/update-cert-number', {
                user: vm.user.id,
                umpire_info: vm.user.umpire_permissions
            }).then(results => {
                this.$buefy.snackbar.open({
                    message: 'Updated.',
                    type: 'is-success' // is-danger
                })
            })
        },
        updateUser: function() {
            var vm = this
            axios.get('/api/superuser/grab-user/' + vm.user.id).then(results => {
                if(results.data.success === true) {
                    vm.user = results.data.user
                    vm.user.date_of_birth = new Date(vm.user.date_of_birth)
                    if(vm.user.banned_until) {
                        vm.user.banned_until = new Date(vm.user.banned_until)
                    }
                    vm.user.teams_theyre_on = null
                    vm.user.tournaments_entered = null
                    vm.user.leagues_entered = null

                    vm.duplicateUser = results.data.user
                }
            })

            axios.get('/api/superuser/grab-user-player-coach/' + vm.user.id).then(results => {
                if(results.data.success === true) {
                    vm.teams_theyre_on = results.data.teams_theyre_on
                    vm.tournaments_entered = results.data.tournaments_entered
                    vm.leagues_entered = results.data.leagues_entered

                    // vm.user = results.data.user
                    // vm.user.date_of_birth = new Date(vm.user.date_of_birth)
                    // vm.duplicateUser = results.data.user
                }
            })
        },
        fillInAddress: function() {
			var vm = this
			var place = this.autocompleteAddress.getPlace();
			var fields = this.autocompleteAddress.getFields()
			this.location = place.formatted_address
            
			// find components
			var address_components = place.address_components
			var postal_code = _.find(address_components, function(address) {
				if(address.types.includes('postal_code')) {
					return true
				}
			})

			var province = _.find(address_components, function(address) {
				if(address.types.includes('administrative_area_level_1')) {
					return true
				}
			})

			if(province.short_name === 'NS' || province.short_name === 'PE' || province.short_name === 'NB' ) {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MT') {
						return true
					}
				})

				vm.google_primary_location.region = region_data
			} else {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === province.short_name) {
						return true
					}
				})
				
				vm.google_primary_location.region = region_data
			}

			var city = _.find(address_components, function(address) {
				if(address.types.includes('locality')) {
					return true
				} else {
                    if(address.types.includes('sublocality')) {
                        return true
                    }
                }
			})

			var street_number = _.find(address_components, function(address) {
				if(address.types.includes('street_number')) {
					return true
				}
			})

			var route = _.find(address_components, function(address) {
				if(address.types.includes('route')) {
					return true
				}
			})

			vm.google_primary_location = {
				written_address: place.formatted_address,
				street_address: street_number.long_name + ' ' + route.long_name,
				city: city.long_name,
				province: province.short_name,
				postal_code: postal_code.long_name,
				latitude:  place.geometry.location.lat(),
				longitude: place.geometry.location.lng()
            }
            
            vm.location = place.formatted_address

			// set province to region  -- newTournamentRegion - need to only send the shortcode and then match them here (some provinces will have to apply to multiple areas)
				// maritimes == NS, PEI, NB

				

			// var i = 0
			// var string = ''
			// for(i; i < place.address_components.length; i++) {
			// 	if(place.address_components[i].types[0] !== 'subpremise') {
			// 		string += place.address_components[i].long_name + ', '
			// 	}
			// }
			// this.newTournament.headquarter_location = string
			// console.log(string)
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        moment: function(date, format = null) {
            return moment(date, format)
        },
        switchWidth: function($event) {
			this.content_width = !$event
        },
        isString: function($event) {
            
        },
        findRegion: function(row) {
            var vm = this
            var region = _.find(vm.regions, function(region) {
                return region.id === row
            })
            if(region) {
                return region.name
            }

            return null
        },
        resetPassword: function(user) {
            

        },
        saveBasicDetails: function() {
            var vm = this

            axios.post('/api/superuser/update-user', {
                type: 'basic',
                user_id: vm.duplicateUser.id,
                first_name: vm.duplicateUser.first_name,
                default_region_id: vm.duplicateUser.default_region_id,
                middle_name: vm.duplicateUser.middle_name,
                last_name: vm.duplicateUser.last_name,
                email: vm.duplicateUser.email,
                gender: vm.duplicateUser.gender,
                phone: vm.duplicateUser.phone,
                username: vm.duplicateUser.username,
                date_of_birth: vm.duplicateUser.date_of_birth,
                current_rank_id: vm.duplicateUser.current_rank_id,
                banned_until: vm.duplicateUser.banned_until,
                updated_account: vm.duplicateUser.updated_account
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Basic details updated, refresh to see all changes.',
                        type: 'is-success' // is-danger
                    })
                    vm.basicDetailsEditSwitch = false
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Basic details did not update',
                        type: 'is-danger' // is-danger
                    })
                }
            })
            
            
        },
        saveSecurityDetails: function() {
            var vm = this

            axios.post('/api/superuser/update-user', {
                type: 'security',
                user_id: vm.duplicateUser.id,
                security_question: vm.duplicateUser.security_question,
                security_answer: vm.duplicateUser.security_answer,
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Security details updated',
                        type: 'is-success' // is-danger
                    })
                    vm.securityEditSwitch = false
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Security details did not update',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        saveEmergencyDetails: function() {
            var vm = this

            axios.post('/api/superuser/update-user', {
                type: 'emergency',
                user_id: vm.duplicateUser.id,
                emergency_contact: vm.duplicateUser.emergency_contact,
                emergency_contact_phone: vm.duplicateUser.emergency_contact_phone,
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Emergency updated',
                        type: 'is-success' // is-danger
                    })
                    vm.emergencyEditSwitch = false
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Emergency did not update.',
                        type: 'is-danger' // is-danger
                    })
                }
            })

            
        },
        saveAddressDetails: function() {
            var vm = this
            axios.post('/api/superuser/update-user', {
                type: 'address',
                user_id: vm.duplicateUser.id,
                user: vm.user,
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Address updated! Refresh for this to update on-screen.',
                        type: 'is-success' // is-danger
                    })
                    vm.addressEditSwitch = false
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Address did not save properly.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        addNote: function() {
            var vm = this
            axios.post('/api/superuser/save-note', {
                user_id: vm.duplicateUser.id,
                note: vm.note
            }).then(results => {
                if(results.data.success === true) {
                    vm.notes = results.data.notes
                    vm.note = null

                    this.$buefy.snackbar.open({
                        message: 'Successfully added a note to their file.',
                        type: 'is-success' // is-danger
                    })

                } else {

                    this.$buefy.snackbar.open({
                        message: 'Failed to add a note to their file.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        addSuperadminPermissions: function() {
            var r = confirm('Are you sure you want to do this? Note: this will NOT give them permissions just create a profile. Please add each individual permission after using the checkboxes.')
            var vm = this
            if(r === true) {
                axios.post('/api/superuser/add-superadmin-permissions', {
                    user_id: vm.duplicateUser.id
                }).then(results => {
                    if(results.data.success === true) {
                        
                        this.$buefy.snackbar.open({
                            message: 'Added their permissions profile.',
                            type: 'is-success' // is-danger
                        })
                        vm.updateUser()
                    } else {

                        this.$buefy.snackbar.open({
                            message: 'Did not add their permissions profile successfully.',
                            type: 'is-danger' // is-danger
                        })
                    }
                })
            }

        },
        addUmpirePermissions: function() {
            var r = confirm('Are you sure you want to do this? Note: this will NOT give them permissions just create a profile. Please add each individual permission after using the checkboxes.')
            var vm = this
            if(r === true) {
                axios.post('/api/superuser/add-umpire-permissions', {
                    user_id: vm.duplicateUser.id
                }).then(results => {
                    if(results.data.success === true) {

                        this.$buefy.snackbar.open({
                            message: 'Added their permissions profile.',
                            type: 'is-success' // is-danger
                        })
                        vm.updateUser()
                    } else {

                        this.$buefy.snackbar.open({
                            message: 'Did not add their permissions profile successfully.',
                            type: 'is-danger' // is-danger
                        })
                    }
                })
            }
        },
        addConvenorPermissions: function() {
            var r = confirm('Are you sure you want to do this? Note: this will NOT give them permissions just create a profile. Please add each individual permission after using the checkboxes.')
            var vm = this
            if(r === true) {
                axios.post('/api/superuser/add-convenor-permissions', {
                    user_id: vm.duplicateUser.id
                }).then(results => {
                    if(results.data.success === true) {

                        this.$buefy.snackbar.open({
                            message: 'Added their permissions profile.',
                            type: 'is-success' // is-danger
                        })
                        vm.updateUser()
                    } else {
                        
                        this.$buefy.snackbar.open({
                            message: 'Did not add their permissions profile successfully.',
                            type: 'is-danger' // is-danger
                        })
                    }
                })
            }
        },
        updateSuperadmin: function($event) {
            var vm = this
            axios.post('/api/superuser/update-superadmin-permissions', {
                user_id: vm.duplicateUser.id,
                superadmin_permissions: vm.user.user_permissions
            }).then(results => {
                if(results.data.success === true) {
                    
                    this.$buefy.snackbar.open({
                        message: 'Updated superadmin permissions.',
                        type: 'is-success' // is-danger
                    })
                    vm.updateUser()
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Did not superadmin permissions.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        updateConvenor: function($event) {
            var vm = this
            axios.post('/api/superuser/update-convenor-permissions', {
                user_id: vm.duplicateUser.id,
                convenor_permissions: vm.user.convenor_permissions
            }).then(results => {
                if(results.data.success === true) {
                    
                    this.$buefy.snackbar.open({
                        message: 'Updated convenor permissions.',
                        type: 'is-success' // is-danger
                    })
                    vm.updateUser()
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Did not convenor permissions.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        updateUmpire: function($event) {
            var vm = this
            axios.post('/api/superuser/update-umpire-permissions', {
                user_id: vm.duplicateUser.id,
                umpire_permissions: vm.user.umpire_permissions
            }).then(results => {
                if(results.data.success === true) {
                    
                    this.$buefy.snackbar.open({
                        message: 'Updated umpires permissions.',
                        type: 'is-success' // is-danger
                    })
                    vm.updateUser()
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Did not umpires permissions.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        deleteThisUser: function() {
            var vm = this
            var r = confirm('Are you sure you want to delete this user?')

            if(r === true) {
                axios.post('/api/superuser/delete-user', {
                    user_id: vm.user.id
                }).then(results => {
                    if(results.data.success === true) {
                        vm.updateUser()
                        this.$buefy.snackbar.open({
                            message: 'Deleted successfully.',
                            type: 'is-success' // is-danger
                        })
                    } else {
                        this.$buefy.snackbar.open({
                            message: 'Did not delete successfully.',
                            type: 'is-danger' // is-danger
                        })
                    }
                })
            }
        },
        undelete: function() {
            var vm = this
            axios.post('/api/superuser/undelete', {
                user_id: vm.user.id
            }).then(results => {
                if(results.data.success === true) {
                    vm.updateUser()
                    this.$buefy.snackbar.open({
                        message: 'Undeleted successfully.',
                        type: 'is-success' // is-danger
                    })
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Did not undelete successfully.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        manuallyConfirmUser: function() {
            var vm = this

            axios.post('/api/superuser/manually-confirm-user', {
                user_id: vm.user.id
            }).then(results => {
                if(results.data.success === true) {
                    vm.updateUser()

                    this.$buefy.snackbar.open({
                        message: 'Account has been confirmed.',
                        type: 'is-success'
                    })
                }
            })
        },
        addToPlayersSelected: function(user) {
            this.playersSelected.push(user)
        },
        removeFromPlayersSelected: function(key) {
            this.playersSelected.splice(key, 1)
        },
        addToClinic: function() {
            
            var vm = this

            axios.post('/api/superuser/umpires-add-to-clinic', {
                registrants: vm.playersSelected,
                clinic_id: vm.displayRegistrants.id
            }).then(results => {
                
                if(results.data.success === true) {
                    
                    vm.playersSelected = []
                    vm.viewRegistrantsSwitch = false

                    this.$buefy.snackbar.open({
                        message: 'You have successfully added registrants to your clinic.',
                        type: 'is-success' // is-danger
                    })
                } else {
                    vm.error = results.data.error
                    this.$buefy.snackbar.open({
                        message: 'Adding registrants to your clinic did not work.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
		updatePlayerSearch: function($event) {
            var vm = this

            vm.isLoading = true

            if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                axios.post(`/api/users/search/players`, {
                    search_name: $event,
					region: vm.displayRegistrants.region_id
                }).then(results => {
                    if(results.data.success === true) {
                        vm.isLoading = false
                        vm.searchList = results.data.users
                    }
                })
            }, 500)
        },
        removeUmpirePermissions: function(user) {
            var vm = this

            var r = confirm("Are you sure you want to do this?")

            if(r === true) {
                axios.post('/api/superuser/remove-umpire-permissions', {
                    user: user
                }).then(results => {
                    this.updateUser()
                })
            }
        },
        resetPassword: function(user) {
            var r = confirm('Are you sure you want to reset their password? Remind them, they will be sent an email, which they must click the link and log in.')
            var vm = this
            if(r === true) {
                axios.post('/auth/reset-password', {
                    email: user.email
                }).then(results => {
                    if(results.data.success === true) {
                        vm.rememberEmail = true
                        this.$buefy.snackbar.open({
                            message: 'Password reset sent to ' + user.first_name + ' ' + user.last_name,
                            type: 'is-success', // is-danger
                            duration: 4000,
                            queue: false
                        })
                        this.updateUser()
                    }
                })
            }
        },
        resendConfirmAccount: function(user) {
            var vm = this


            axios.post('/auth/resend-confirm-account', {
                email: user.email
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Confirm email resent to ' + user.first_name + ' ' + user.last_name,
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    this.updateUser()
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Confirm email failed ' + user.first_name + ' ' + user.last_name,
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        resetFailedAttempts: function(user) {
            var vm = this

            axios.post('/auth/reset-failed-attempts', {
                email: user.email
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Failed attempts reset for ' + user.first_name + ' ' + user.last_name,
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    this.updateUser()
                }
            })
        },
        sendWaiverReminder: function(user) {
            var vm = this
            axios.post('/api/users/send-waiver-reminder', {
                user: user
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Waiver reminder has been sent!',
                        type: 'is-success' // is-danger
                    })
                    this.updateUser()
                }
            })
        },
        parseJSON: function(waiver) {
            if(waiver.waiver.written_waiver) {
                return JSON.parse(waiver.waiver.written_waiver)
            }
            return []
        },
        sendWaiverCopyToEmail: function(waiver) {
            var vm = this
            axios.post('/api/users/send-completed-waiver', {
                user: vm.user.id,
                waiver: waiver.id,
                email: vm.sendToThisEmail
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.toast.open({
                        message: 'Sent successfully',
                        type: 'is-success', // is-danger
                        duration: 1000,
                        queue: false
                    })
                }
            })
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
