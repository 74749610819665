<template>
	<div>
		
		<b-modal v-model="registerPopup" :width="640">
			<template #default="props">
				<div class="modal-card" style="width: 600px;">
					<header class="modal-card-head">
						<p class="modal-card-title">Register To Clinic</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<div v-if="authUser">
							<section class="modal-card-body">
								<table class="table">
									<tr>
										<td><b>Clinic Name:</b></td>
										<td>{{ clinicSelected.name }}</td>
									</tr>
									<tr>
										<td><b>Address:</b></td>
										<td>{{ clinicSelected.street_address }}, {{ clinicSelected.city }}, {{ clinicSelected.postal_code }}</td>
									</tr>
									<tr>
										<td><b>Max Entries:</b></td>
										<td>{{ clinicSelected.max_participants }}</td>
									</tr>
									<tr>
										<td><b>Currently Registered:</b></td>
										<td>{{ clinicSelected.registrants.length }}</td>
									</tr>
									<tr>
										<td><b>Hosted By:</b></td>
										<td>{{ clinicSelected.clinician.first_name }} {{ clinicSelected.clinician.last_name }}</td>
									</tr>
									<tr v-if="clinicSelected.fee_to_register > 0">
										<td><b>Cost to Enter (to be paid to the host):</b></td>
										<td>${{ clinicSelected.fee_to_register }}</td>
									</tr>
									<tr v-if="clinicSelected.fee_to_register === 0">
										<td><b>Cost to Enter:</b></td>
										<td>Free</td>
									</tr>
								</table>
								<p><b>Description & Notes</b></p>
								<p>{{ clinicSelected.description }}</p>
								<!-- <iframe
									
									width="100%"
									height="200"
									frameborder="0" style="margin-top: 30px;border:0"
									:src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyCAnO39NnB7qlv22s3nm-mM7QOCqYFsTI8&q=' + clinicSelected.written_address" allowfullscreen>
								</iframe> -->
								<div v-if="clinicSelected.max_participants > clinicSelected.registrants.length">
									<a  v-on:click="submitUmpireRegistration(false)" class="admin-button" style="margin-top: 10px;"><i class="fas fa-save"></i>Register to Umpire Clinic</a>
								</div>
								<div v-else>
									<a  v-on:click="submitUmpireRegistration(true)"  v-if="clinicSelected.allow_waitlist === 1" class="admin-button" style="margin-top: 10px;"><i class="fas fa-save"></i>Join Waitlist</a>
								</div>
							</section>
						</div>
						<div v-if="!authUser">
							<p style="text-align: center;"><b>You need to log in to register for this clinic!</b></p>
							<LoginRegister
								v-if="authUser === null"
								:redirect="false"
								:redirect-to="false"
								style="margin-top: 20px;"
								v-on:logged:in="triggerAuth($event)">
							</LoginRegister>
						</div>
					</section>
				</div>
			</template>
        </b-modal>
		<Header></Header>
		
		<div class="content-area">
			<div class="standard-page-header">
				Umpire Clinics
			</div>
			
            <div class="frontend-content-wrapper">
				<div class="section-spacing">
					<p><b>Select a region icon to see the region's information for umpire clinics.</b></p>
                    <div class="provinces">
                        <div :class="['province', { selected: selectedRegion === 2 }]">
                            <a v-on:click="selectedRegion = 2">
                                <img src="../../images/header/crest/BCCrest.png" width="100%" alt="">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 1 }]">
                            <a v-on:click="selectedRegion = 1">
                                <img src="../../images/header/crest/ABCrest.png" width="100%" alt="">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 8 }]">
                            <a v-on:click="selectedRegion = 8">
                                <img src="../../images/header/crest/SKCrest.png" width="100%" alt="">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 3 }]">
                            <a v-on:click="selectedRegion = 3">
                                <img src="../../images/header/crest/MBCrest.png" width="100%" alt="">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 4 }]">
                            <a v-on:click="selectedRegion = 4">
                                <img src="../../images/header/crest/ONCrest.png" width="100%" alt="">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 6 }]">
                            <a v-on:click="selectedRegion = 6">
                                <img src="../../images/header/crest/QCCrest.png" width="100%" alt="">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 5 }]">
                            <a v-on:click="selectedRegion = 5">
                                <img src="../../images/header/crest/MTCrest.png" width="100%" alt="">
                            </a>
                        </div>
                        <div :class="['province', { selected: selectedRegion === 7 }]">
                            <a v-on:click="selectedRegion = 7">
                                <img src="../../images/header/crest/NLCrest.png" width="100%" alt="">
                            </a>
                        </div>                
                    </div>
                    <div class="textarea-content-block" v-if="clinics">
						<b-table 
							:data="officialClinics" 
							:striped="true"
							:focusable="true"
							paginated
							per-page="10"
						>

							<b-table-column field="name" :searchable="true" label="Name">
								<template v-slot="props">
									{{ props.row.name }}/{{ props.row.fr_name }}
								</template>
							</b-table-column>
							<b-table-column field="event_date" :searchable="true" label="Event Date">
								<template v-slot="props">
									{{ moment(props.row.event_date).utc().format('YYYY-MM-DD') }}
								</template>
							</b-table-column>
							<b-table-column field="start_time" label="Time">
								<template v-slot="props">
									{{ momentUtc(props.row.start_time, 'HH:mm:ss').local().format('h:mm A') }} -  {{ momentUtc(props.row.end_time, 'HH:mm:ss').local().format('h:mm A') }}
								</template>
							</b-table-column>
							<b-table-column field="street_address" label="Street Address">
								<template v-slot="props">
									{{ props.row.street_address }}
								</template>
							</b-table-column>
							<b-table-column :searchable="true" field="city" label="City">
								<template v-slot="props">
									{{ props.row.city }}
								</template>
							</b-table-column>
							<b-table-column  field="postal_code" label="Postal Code">
								<template v-slot="props">
									{{ props.row.postal_code }}
								</template>
							</b-table-column>
							<b-table-column  field="button" label="Register">
								<template v-slot="props">
									<a class="button is-info is-small" v-on:click="popupRegisterClinic(props.row)">Register</a>
								</template>
							</b-table-column>
						</b-table>
                    </div>
                </div>
			</div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
import moment from 'moment'
import LoginRegister from '../components/LoginRegister.vue'
export default {
	metaInfo() {
        return { 
            title: "Umpire Clinics - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Umpire clinic information across Canada! Come check out Slo-Pitch National to register.' },
				{ property: 'og:title', content: "Umpire Clinics - Slo-Pitch National"},
				{ property: 'og:url', content:  'https://www.slo-pitch.com/umpire-clinics' },
				{ property: 'og:site_name', content: 'Slo-Pitch National'},
				{ property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/umpireclinics.png' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			en: true,
            page_url: null,
			page: null,
			clinics: null,
			registerPopup: false,
			selectedRegion: null,
			clinicSelected: null,
			authUser: null,
            umpires: [],
			sendMessageToClinic: null
		};
	},
	components: {
		Header,
		Footer,
		Form,
		LoginRegister
	},
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }

        var vm = this

        axios.get('/api/umpires/all-clinics').then(results => {
            
            if(results.data.success === true) {
                vm.clinics = results.data.clinics
            }
		})
		
		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
				vm.authUser = results.data.user		
			}
		})
 
	},
	componentWillReceiveProps(nextProps){

		
	},
	computed: {
        officialComputedUmpires: function() {
            var umpires = this.umpires

            var grouped = _.groupBy(umpires, 'region_name')

            return grouped
		},
		officialClinics: function() {
			var vm = this

			var clinics = vm.clinics

			if(vm.selectedRegion) {
				clinics = _.filter(clinics, function(clinic) {
					return clinic.region_id === vm.selectedRegion
				})
			}

			return clinics
		}
	},
	methods: {
		popupRegisterClinic: function(clinic) {
			var vm = this
			
			vm.registerPopup = true

			vm.clinicSelected = clinic
		},
		moment: function(date, format = null) {
			return moment(date, format)
		},
		momentUtc: function(date, format) {
			return moment.utc(date, format)
		},
		openPanel:function(panel) {

			if(panel.switched === "false") {
				panel.switched = "true"

			} else {
				panel.switched = "false"

			}
		},
		createProperTableFunction(table) {
			// first row is your columns
			var first_row = table[0]
			var all_rows = table
			var columns = []
			var data = []
	
			var i = 0
			for(i; i < all_rows.length; i++) {

				var row = all_rows[i]
				if(i === 0) {
					// this is the first row, need to for loop again though
					var s = 0

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						columns.push({
							field: column.name,
							label: column.name
						})
					}
				} else {
					// this is the rest of the rows, need to loop and then call via the loop to the first row
					var s = 0
					
					var object = {}

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						var col_name = all_rows[0].row[s].name
						
						object[col_name] = column.name
					}
					data.push(object)
				}
			}

			// every row after is your data
			


			return {
				columns: columns,
				data: data,
				table: table
			}
		},
		triggerAuth: function(action) {

			var vm = this
			// likely just run another auth check
			this.$nextTick(() => {
				axios.get('/auth/check').then(results => {  
					if(results.data.type === "success") {
						vm.authUser = results.data.user		
					}
				})
			})
		},
		submitUmpireRegistration: function(waitlist) {
			var vm = this
			axios.post('/api/umpires/register-to-umpire-clinic', {
				user_id: vm.authUser.id,
				clinic_id: vm.clinicSelected.id,
				waitlist: waitlist
			}).then(results => {
				if(results.data.success === true) {
					vm.registerPopup = false
					this.$buefy.snackbar.open({
                        message: 'You have been registered to the umpire clinic. Check your email!',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'Something went wrong when registering you',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		}
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
