import Vue from 'vue'
import VueRouter from 'vue-router'

import frontendauth from './middleware/frontendauth';
import auth from './middleware/auth';
import superadmin from './middleware/superadmin';
import login from './middleware/login';

import SuperadminManageLeagues from './pages/superadmin/SuperadminManageLeagues.vue'
import SuperadminManageLeague from './pages/superadmin/SuperadminManageLeague.vue'
import SuperadminManageTournaments from './pages/superadmin/SuperadminManageTournaments.vue'
import SuperadminManageMemberships from './pages/superadmin/SuperadminManageMemberships.vue'
import SuperadminManageTournament from './pages/superadmin/SuperadminManageTournament.vue'
import SuperadminManageTeams from './pages/superadmin/SuperadminManageTeams.vue'
import SuperadminManageTeam from './pages/superadmin/SuperadminManageTeam.vue'
import SuperadminManageUmpires from './pages/superadmin/SuperadminManageUmpires.vue'
import SuperadminManageConvenors from './pages/superadmin/SuperadminManageConvenors.vue'
import SuperadminManageUmpireExam from './pages/superadmin/SuperadminManageUmpireExam.vue'
import SuperadminManageClinics from './pages/superadmin/SuperadminManageClinics.vue'
import SuperadminLeaguesReporting from './pages/superadmin/SuperadminLeaguesReporting.vue'
import SuperadminTournamentsReporting from './pages/superadmin/SuperadminTournamentsReporting.vue'
import SuperadminPaymentsReporting from './pages/superadmin/SuperadminPaymentsReporting.vue'
import SuperadminManageWaivers from './pages/superadmin/SuperadminManageWaivers.vue'
import SuperadminManagePages from './pages/superadmin/SuperadminManagePages.vue'
import SuperadminManageCommunication from './pages/superadmin/SuperadminManageCommunication.vue'
import SuperadminManageBlogPosts from './pages/superadmin/SuperadminManageBlogPosts.vue'
import SuperadminManagePlayers from './pages/superadmin/SuperadminManagePlayers.vue'
import SuperadminManageBanned from './pages/superadmin/SuperadminManageBanned.vue'
import SuperadminManagePlayer from './pages/superadmin/SuperadminManagePlayer.vue'
import SuperadminManagePlayerWaiver from './pages/superadmin/SuperadminManagePlayerWaiver.vue'
import SuperadminManageForms from './pages/superadmin/SuperadminManageForms.vue'
import SuperadminManageOrders from './pages/superadmin/SuperadminManageOrders.vue'
import SuperadminManageFormResults from './pages/superadmin/SuperadminManageFormResults.vue'
import SuperadminManageFormResult from './pages/superadmin/SuperadminManageFormResult.vue'
import SuperadminManageTournamentResults from './pages/superadmin/SuperadminManageTournamentResults.vue'
import SuperadminMergeUsers from './pages/superadmin/SuperadminMergeUsers.vue'
import SuperadminManageDoesNotMatchProvince from './pages/superadmin/SuperadminManageDoesNotMatchProvince.vue'
import SuperadminManagePayment from './pages/superadmin/SuperadminManagePayment.vue'
import SuperadminViewPerRegion from './pages/superadmin/SuperadminViewPerRegion.vue'
import SuperadminStatisticsCenter from './pages/superadmin/SuperadminStatisticsCenter.vue'
import GrandSlamSeries from './pages/frontend/GrandSlamSeries.vue'

import Dashboard from './pages/dashboard/Index.vue'
import DashboardForm from './pages/dashboard/Form.vue'
import Rulebook from './pages/frontend/Rulebook.vue'
import DashboardPlayers from './pages/dashboard/players/Index.vue'
import DashboardUmpires from './pages/dashboard/umpires/Index.vue'
import DashboardConvenors from './pages/dashboard/convenors/Index.vue'
import DashboardAccountInformation from './pages/dashboard/AccountInformation.vue'
import PlayerEvent from './pages/dashboard/players/PlayerEvent.vue'
import PlayerEventPDF from './pages/dashboard/players/PlayerEventPDF.vue'
import Contests from './pages/frontend/Contests.vue'
import LegendsOfFebruary from './pages/frontend/LegendsOfFebruary.vue'

import ApproveRoster from './pages/frontend/ApproveRoster.vue'

import EditTeam from './pages/dashboard/players/EditTeam.vue'
import CreateNewTournament from './pages/dashboard/convenors/CreateTournament.vue'
import CreateNewLeague from './pages/dashboard/convenors/CreateLeague.vue'
import DashboardConvenorTournament from './pages/dashboard/convenors/ConvenorTournament.vue'
import DashboardConvenorTournamentEventRoster from './pages/dashboard/convenors/EventRoster.vue'
import DashboardConvenorLeagueEventRoster from './pages/dashboard/convenors/LeagueEventRoster.vue'

import SuperadminConvenorTournamentEventRoster from './pages/dashboard/convenors/SuperadminTournamentEventRoster.vue'
import SuperadminConvenorLeagueEventRoster from './pages/dashboard/convenors/SuperadminLeagueEventRoster.vue'

import DashboardConvenorLeague from './pages/dashboard/convenors/ConvenorLeague.vue'
import EventRosterPrintable from './pages/dashboard/convenors/EventRosterPrintable.vue'
import AllEventRosterPrintable from './pages/dashboard/convenors/AllEventRosterPrintable.vue'
import DashboardConvenorTournamentPaperwork from './pages/dashboard/convenors/TournamentPaperwork.vue'

import FrontendHome from './pages/frontend/Home.vue'
import FrontendTournamentCalendar from './pages/frontend/FrontendTournamentCalendar.vue'
import FrontendStandardPage from './pages/frontend/StandardPage.vue'
import FrontendInsurance from './pages/frontend/Insurance.vue'
import FrontendForms from './pages/frontend/Forms.vue'
import FrontendContactUs from './pages/frontend/ContactUs.vue'
import FrontendFrequentlyAskedQuestions from './pages/frontend/FrequentlyAskedQuestions.vue'
import Login from './pages/frontend/Login.vue'
import TempLogin from './pages/frontend/TempLogin.vue'
import Register from './pages/frontend/Register.vue'
import RecoverAccount from './pages/frontend/RecoverAccount.vue'
import RecoverPassword from './pages/frontend/RecoverPassword.vue'
import TakeTheExam from './pages/frontend/TakeTheExam.vue'
import FrontendResetPassword from './pages/frontend/ResetPassword.vue'
import FrontendTournamentPage from './pages/frontend/TournamentPage.vue'
import FrontendTournamentRegistration from './pages/frontend/TournamentRegistration.vue'
import FrontendLeaguePage from './pages/frontend/LeaguePage.vue'
import FrontendStandardNews from './pages/frontend/StandardNews.vue'
import OfficialCardedUmpires from './pages/frontend/OfficialCardedUmpires.vue'
import NationalChampionship from './pages/frontend/NationalChampionships.vue'
import MillerLiteChallenge from './pages/frontend/MillerLiteChallenge.vue'
import HallOfHonour from './pages/frontend/HallOfHonour.vue'
import HallOfFame from './pages/frontend/HallOfFame.vue'
import MembershipByRegion from './pages/frontend/MembershipByRegion.vue'
import NorthernShootout from './pages/frontend/NorthernShootout.vue'
import SeniorSloPitch from './pages/frontend/SeniorSloPitch.vue'
import CanAmEvents from './pages/frontend/CanAmEvents.vue'
import UmpireClinics from './pages/frontend/UmpireClinics.vue'
import Umpires from './pages/frontend/Umpires.vue'
import ProvincialChampionships from './pages/frontend/ProvincialChampionships.vue'
import ConfirmRegister from './pages/frontend/ConfirmRegister.vue'


import UmpireCertificate from './pages/dashboard/umpires/PrintableUmpireCertificate.vue'

import Sponsor from './pages/frontend/Sponsor.vue'

import PageNotFound from './PageNotFound.vue'

Vue.use(VueRouter)

// TODO Tomorrow: create all the frontend routes you expect

const router = new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '/', component: FrontendHome, name: 'Frontend-Home',
			meta: { middleware: frontendauth }
		},
		{
			path: '/en/tournament-calendar', component: FrontendTournamentCalendar, name: 'Frontend-Tournament-Calendar',
			meta: { middleware: frontendauth }
		},
		{
			path: '/tournament-calendar', component: FrontendTournamentCalendar, name: 'Frontend-Tournament-Calendar-Hotlink',
			meta: { middleware: frontendauth }
		},
		{
			path: '/umpires', component: Umpires, name: 'Frontend-Umpires',
			meta: { middleware: frontendauth }
		},
		{
			path: '/umpire-clinics', component: UmpireClinics, name: 'Frontend-UmpireClinic',
			meta: { middleware: frontendauth }
		},
		{
			path: '/can-am-events', component: CanAmEvents, name: 'Frontend-CanAmEvents',
			meta: { middleware: frontendauth }
		},
		{
			path: '/northern-shootout', component: NorthernShootout, name: 'Frontend-NorthernShootout',
			meta: { middleware: frontendauth }
		},
		{
			path: '/hall-of-honour', component: HallOfHonour, name: 'Hall of Honour',
			meta: { middleware: frontendauth }
		},
		{
			path: '/hall-of-fame', component: HallOfFame, name: 'Hall of Fame',
			meta: { middleware: frontendauth }
		},
		{
			path: '/contests', component: Contests, name: 'Contests',
			meta: { middleware: frontendauth }
		},
		{
			path: '/senior-slo-pitch', component: SeniorSloPitch, name: 'SeniorSloPitch',
			meta: { middleware: frontendauth }
		},
		{
			path: '/legends-of-february', component: LegendsOfFebruary, name: 'LegendsOfFebruary',
			meta: { middleware: frontendauth }
		},
		{
			path: '/memberships-by-region/:region?', component: MembershipByRegion, name: 'Membership By Region',
			meta: { middleware: frontendauth }
		},
		{
			path: '/provincial-championships', component: ProvincialChampionships, name: 'Provinical Championship',
			meta: { middleware: frontendauth }
		},
		{
			path: '/en/content/2021-national-championships', component: NationalChampionship, name: 'National Championship-Hotlink',
			meta: { middleware: frontendauth }
		},
		{
			path: '/national-championships', component: NationalChampionship, name: 'National Championship',
			meta: { middleware: frontendauth }
		},
		{
			path: '/grand-slam-series', component: GrandSlamSeries, name: 'GrandSlamSeries',
			meta: { middleware: frontendauth }
		},
		// {
		// 	path: '/miller-lite-challenge', component: MillerLiteChallenge, name: 'Miller Lite Challenge',
		// 	meta: { middleware: frontendauth }
		// },
		{
			path: '/news/:page', component: FrontendStandardNews, name: 'Frontend-News',
			meta: { middleware: frontendauth }
		},
		{
			path: '/official-carded-umpires', component: OfficialCardedUmpires, name: 'OfficialCardedUmpires',
			meta: { middleware: frontendauth }
		},
		{
			path: '/en/content/online-registration', component: FrontendStandardPage, name: 'Frontend-Page-Hotlink',
			meta: { middleware: frontendauth }
		},
		{
			path: '/content/:page', component: FrontendStandardPage, name: 'Frontend-Page',
			meta: { middleware: frontendauth }
		},
		{
			path: '/en/region/:page', component: MembershipByRegion, name: 'Membership By Region-Hotlink',
			meta: { middleware: frontendauth }
		},
		{
			path: '/region/:page', component: MembershipByRegion, name: 'Region',
			meta: { middleware: frontendauth }
		},

		{
			path: '/league/:page', component: FrontendLeaguePage, name: 'FrontendLeaguePage',
			meta: { middleware: frontendauth }
		},
		{
			path: '/tournament/:page', component: FrontendTournamentPage, name: 'FrontendTournamentPage',
			// meta: { middleware: frontendauth }
		},
		{
			path: '/tournament/:page/register', component: FrontendTournamentRegistration, name: 'FrontendTournamentRegistration',
			// meta: { middleware: frontendauth }
		},
		{
			path: '/reset-password/:id', component: FrontendResetPassword, name: 'Frontend-ResetPassword',
			meta: { middleware: frontendauth }
		},
		{
			path: '/umpires/take-the-exam', component: TakeTheExam, name: 'TakeTheExam',
			meta: { middleware: frontendauth }
		},
		{
			path: '/frequently-asked-questions', component: FrontendFrequentlyAskedQuestions, name: 'Frequently-Asked-Questions',
			meta: { middleware: frontendauth }
		},
		{
			path: '/insurance', component: FrontendInsurance, name: 'Insurance',
			meta: { middleware: frontendauth }
		},
		{
			path: '/sponsor', component: Sponsor, name: 'Sponsor',
			meta: { middleware: frontendauth }
		},
		{
			path: '/forms', component: FrontendForms, name: 'Forms',
			meta: { middleware: frontendauth }
		},
		{
			path: '/contact-us', component: FrontendContactUs, name: 'Contact Us',
			meta: { middleware: frontendauth }
		},
		{
			path: '/login', component: TempLogin, name: 'Login',
			meta: { middleware: login }
		},
		{
			path: '/confirm-register/:slug', component: ConfirmRegister, name: 'ConfirmRegister',
			meta: { middleware: login }
		},
		{
			path: '/register', component: Register, name: 'Register',
			meta: { middleware: login }
		},
		{
			path: '/recover-account', component: RecoverAccount, name: 'Recover Account',
			meta: {  middleware: login }
		},
		{
			path: '/recover-password', component: RecoverPassword, name: 'Recover Password',
			meta: { middleware: login }
		},
		// Dashboard Pages
		{
			path: '/approve-roster/:registrant/:tournament', component: ApproveRoster, name: 'Approved Roster'
		},
		{
			path: '/dashboard', component: Dashboard, name: 'Dashboard',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/form/:slug', component: DashboardForm, name: 'DashboardForm',
			meta: { middleware: auth }
		},
		{
			path: '/rulebook', component: Rulebook, name: 'Rulebook',
		},
		{
			path: '/dashboard/account-information', component: DashboardAccountInformation, name: 'AccountInformation',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/players/event/:slug/:roster/:type', component: PlayerEvent, name: 'PlayerEvent',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/players/event/:slug/:roster/:type/pdf', component: PlayerEventPDF, name: 'PlayerEventPDF',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/players/edit-team/:slug', component: EditTeam, name: 'EditTeam',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/players/:page', component: DashboardPlayers, name: 'DashboardPlayers',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/umpires/:page', component: DashboardUmpires, name: 'DashboardUmpires',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/umpires/printable-certificate/:userId', component: UmpireCertificate, name: 'UmpireCertificate',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/convenors/create-tournament', component: CreateNewTournament, name: 'CreateNewTournament',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/convenors/league/:slug/:page', component: DashboardConvenorLeague, name: 'LeagueDashboard',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/convenors/tournament/:slug/paperwork', component: DashboardConvenorTournamentPaperwork, name: 'DashboardConvenorTournamentPaperwork',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/convenors/tournament/:slug/:page', component: DashboardConvenorTournament, name: 'TournamentDashboard',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/convenors/tournaments/:slug/event-roster/:id', component: DashboardConvenorTournamentEventRoster, name: 'TournamentDashboardEventRoster',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/convenors/leagues/:slug/event-roster/:id', component: DashboardConvenorLeagueEventRoster, name: 'LeagueDashboardEventRoster',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/convenors/:eventType/:slug/event-rosters/printable/all', component: AllEventRosterPrintable, name: 'AllEventRosterPrintable',
			meta: { middleware: auth }
		},
		
		{
			path: '/dashboard/convenors/:eventType/:slug/event-roster/:id/printable', component: EventRosterPrintable, name: 'EventRosterPrintable',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/convenors/create-league', component: CreateNewLeague, name: 'CreateNewLeague',
			meta: { middleware: auth }
		},
		{
			path: '/dashboard/convenors/:page',	component: DashboardConvenors, name: 'DashboardConvenors',
			meta: { middleware: auth }
		},
		{
			path: '/superadmin/manage/tournament/:slug/event-roster/:id', component: SuperadminConvenorTournamentEventRoster, name: 'SuperadminTournamentDashboardEventRoster',
			meta: { middleware: auth }
		},
		{
			path: '/superadmin/manage/leagues/:slug/event-roster/:id', component: 
			SuperadminConvenorLeagueEventRoster, name: 'SuperadminLeagueDashboardEventRoster',
			meta: { middleware: auth }
		},
		//  Superadmin
		{
			path: '/superadmin/manage/form-results',	component: SuperadminManageFormResults, name: 'SuperadminManageFormResults',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/form-results/:form',	component: SuperadminManageFormResult, name: 'SuperadminManageFormResult',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/site-pages',	component: SuperadminManagePages, name: 'SuperadminManagePages',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/view-waivers-per-region',	component: SuperadminViewPerRegion, name: 'SuperadminViewPerRegion',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/statistics-center',	component: SuperadminStatisticsCenter, name: 'SuperadminStatisticsCenter',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/memberships',	component: SuperadminManageMemberships, name: 'SuperadminManageMemberships',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/blog-posts',	component: SuperadminManageBlogPosts, name: 'SuperadminManageBlogPosts',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/forms',	component: SuperadminManageForms, name: 'SuperadminManageForms',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/communication-log',	component: SuperadminManageCommunication, name: 'SuperadminManageCommunication',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/merge-users',	component: SuperadminMergeUsers, name: 'SuperadminMergeUsers',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/does-not-match-province',	component: SuperadminManageDoesNotMatchProvince, name: 'SuperadminManageDoesNotMatchProvince',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/manual-payment',	component: SuperadminManagePayment, name: 'SuperadminManagePayment',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/users',	component: SuperadminManagePlayers, name: 'SuperadminManagePlayers',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/banned',	component: SuperadminManageBanned, name: 'SuperadminManageBanned',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/users/:id/waivers',	component: SuperadminManagePlayerWaiver, name: 'SuperadminManagePlayerWaiver',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/users/:id',	component: SuperadminManagePlayer, name: 'SuperadminManagePlayer',
			meta: { middleware: superadmin }
		},
	
		{
			path: '/superadmin/manage/waivers',	component: SuperadminManageWaivers, name: 'SuperadminManageWaivers',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/leagues',	component: SuperadminManageLeagues, name: 'SuperadminManageLeagues',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/leagues/:id',	component: SuperadminManageLeague, name: 'SuperadminManageLeague',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/tournaments',	component: SuperadminManageTournaments, name: 'SuperadminManageTournaments',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/tournaments/:id',	component: SuperadminManageTournament, name: 'SuperadminManageTournament',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/tournament-results', component: SuperadminManageTournamentResults, name: 'SuperadminManageTournamentResults',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/teams',	component: SuperadminManageTeams, name: 'SuperadminManageTeams',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/teams/:id',	component: SuperadminManageTeam, name: 'SuperadminManageTeam',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/umpires/umpires',	component: SuperadminManageUmpires, name: 'SuperadminManageUmpires',
			meta: { middleware: superadmin }
		},
		
		{
			path: '/superadmin/manage/convenors',	component: SuperadminManageConvenors, name: 'SuperadminManageConvenors',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/umpires/exam',	component: SuperadminManageUmpireExam, name: 'SuperadminManageUmpireExam',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/clinics',	component: SuperadminManageClinics, name: 'SuperadminManageClinics',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/manage/orders',	component: SuperadminManageOrders, name: 'SuperadminManageOrders',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/reports/leagues',	component: SuperadminLeaguesReporting, name: 'SuperadminLeaguesReporting',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/reports/tournaments',	component: SuperadminTournamentsReporting, name: 'SuperadminTournamentsReporting',
			meta: { middleware: superadmin }
		},
		{
			path: '/superadmin/reports/payments',	component: SuperadminPaymentsReporting, name: 'SuperadminPaymentsReporting',
			meta: { middleware: superadmin }
		},



		{ path: '*', component: PageNotFound }
	],

})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
	const subsequentMiddleware = middleware[index];
	// If no subsequent Middleware exists,
	// the default `next()` callback is returned.
	if (!subsequentMiddleware) return context.next;

	return (...parameters) => {
	// Run the default Vue Router `next()` callback first.
	context.next(...parameters);
	// Then run the subsequent Middleware with a new
	// `nextMiddleware()` callback.
	const nextMiddleware = nextFactory(context, middleware, index + 1);
	subsequentMiddleware({ ...context, next: nextMiddleware });
	};
}

router.beforeEach((to, from, next) => {
	if (to.meta.middleware) {
	const middleware = Array.isArray(to.meta.middleware)
		? to.meta.middleware
		: [to.meta.middleware];

	const context = {
		from,
		next,
		router,
		to,
	};
	const nextMiddleware = nextFactory(context, middleware, 1);

	return middleware[0]({ ...context, next: nextMiddleware });
	}

	return next();
});

export default router
