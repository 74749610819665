<template>
    <div class="modal-card" style="width: 100%">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
        </header>
        <section class="modal-card-body" style="min-height: 140px; overflow: scroll;">
            <b-tabs>
                <b-tab-item label="Player Pool" v-if="addTab">
                    <div class="row" style="margin-bottom: 10px;" v-for="(user, key) in eventTeam.team.core_roster" :key="key">
                        <div class="col-xs-1">
                            <span v-if="checkForSelected(user.user) === false">
                                <a class="button is-small" v-on:click="addToPlayersSelected(user.user)"><i class="fas fa-plus"></i></a>
                            </span>

                            <span v-if="checkForSelected(user.user) === true">
                                <a class="button is-small is-success"><i class="fas fa-check"></i></a>
                            </span>
                        </div>
                        <div class="col-xs-11">
                            <div class="row">
                                <div class="col-xs-2">
                                    {{ user.user.gender }}
                                </div>
                                <div class="col-xs-6">
                                    <b>{{ user.user.first_name }} {{ user.user.last_name }}</b>
                                    <b-tag v-if="user.role_on_team === 1">Coach</b-tag>
                                    <b-tag v-if="user.role_on_team === 2">Team Contact</b-tag>
                                </div>
                                <div class="col-xs-2">
                                    {{ user.user.city }}
                                </div>
                                <div class="col-xs-1">
                                    <RankComponent :currentRank="user.user.current_rank_id"></RankComponent>
                                </div>
                                <div class="col-xs-1">
                                    <span v-if="user.user.latest_waiver_signed === $latest_waiver">
                                        <b-tag type="is-success"><i class="fas fa-check"></i></b-tag>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </b-tab-item>
                <b-tab-item label="Search for Player">
                    <p style="color: red; font-size: 12px;"><b>If the player is not registered in the SPN system, you will not be able to add them until they have registered for an account. Please send them to the Login page to register an account, and then you can find them here!</b></p>
                    <b-field 
                        :label="(en === true ? 'Search for a player' : 'Recherche D’un Joueur')"
                        :message="(en === true ? 'You can enter email, phone, username or first &amp; last name' : `Vous pouvez entrer l’adresse courriel, le téléphone, le nom d’utilisateur, le prénom et nom de famille `)">
                        <b-input v-on:input="updatePlayerSearch($event)" placeholder="Search" ></b-input>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="allRegionSearch" v-on:input="updatePlayerSearch()"> Include out of region players in your search</b-checkbox>    
                    </b-field>
                    <div class="row" v-for="(user, key) in searchList" :key="key">
                        <div class="col-xs-1">
                            <span v-if="checkForSelected(user) === false && new Date(user.banned_until) < new Date()">
                                <a class="button is-small" v-on:click="addToPlayersSelected(user)"><i class="fas fa-plus"></i></a>
                            </span>

                            <span v-if="checkForSelected(user) === true">
                                <a class="button is-small is-success"><i class="fas fa-check"></i></a>
                            </span>
                        </div>
                        <div class="col-xs-11">
                            <div class="row">
                                <div class="col-xs-2">
                                    {{ user.gender }}
                                </div>
                                <div class="col-xs-6">
                                    <b>{{ user.first_name }} {{ user.last_name }}</b>
                                    <b-tag type="is-danger" v-if="user.banned === 1">Banned until: {{ moment(user.banned_until).format('YYYY-MM-DD')}}</b-tag>
                                </div>
                                <div class="col-xs-2">
                                    {{ user.city }}
                                </div>
                                <div class="col-xs-1">
                                    <RankComponent :currentRank="user.current_rank_id"></RankComponent>
                                </div>	
                                <div class="col-xs-1">
                                    <span v-if="user.latest_waiver_signed === $latest_waiver">
                                        <b-tag type="is-success"><i class="fas fa-check"></i></b-tag>
                                    </span>
                                </div>							
                            </div>
                        </div>
                    </div>
                </b-tab-item>
            </b-tabs>
        </section>
        <div class="adding">
            <p><b>Adding:</b></p>
        </div>
        
        <section class="modal-card-body">
            <nav class="level" v-for="(user, key) in playersSelected" :key="key">
                <div class="level-left">
                    <p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
                    <p class="level-item">{{ user.city }}</p>
                </div>
                <div class="level-right">
                    <a class="button is-danger is-small" v-on:click="removeFromPlayersSelected(key)"><i class="fas fa-trash"></i></a>
                </div>
            </nav>
        </section>
        <footer class="modal-card-foot">
            <div v-if="addTab === false">
                <div v-if="team.core_roster.length + playersSelected.length <= 40">
                    <p>You have {{ team.core_roster.length }} in your player pool. You can have a maximum of 40.</p>
                    <br>
                    <a class="admin-button" v-if="playersSelected.length > 0" v-on:click="addToRoster()">{{ en === true ? 'Add Players To Player Pool' : 'Ajouter des Joueurs à la Liste' }}</a>
                </div>
                <div v-if="team.core_roster.length + playersSelected.length > 40">
                    You will be over the maximum 40 in your player pool. Please remove players to reveal the button.
                </div>
            </div>
            <div v-else>
                <a class="admin-button" v-on:click="addToRoster()">{{ en === true ? 'Add Players' : 'Ajouter des Joueurs à la Liste' }}</a>
            </div>
        </footer>
    </div>
</template>

<script>
import RankComponent from '../components/RankComponent.vue'
import axios from 'axios'
import moment from 'moment'
export default {
    data() {
        return {
            searchList: [],
            playersSelected: [],
            allRegionSearch: false,
            user: null
        }
    },
    props: {
        eventTeam: {
            type: Number
        },
        regionId: {
            type: Number
        },
        addTab: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: 'Add Players To Team'
        },
        en: {
            type: Boolean,
            default: true
        },
        team: {
            type: Object,
            default: null
        }
    },
    
    components: {
        RankComponent
    },
    mounted: function() {
        var vm = this
        axios.get('/auth/check').then(results => {
            if(results.data.type === "success") {
                vm.user = results.data.user
            }
        })
    },
    methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        updatePlayerSearch: function($event) {
            var vm = this

			if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {

				axios.post(`/api/users/search/players`, {
					search_name: $event,
					region: this.regionId,
                    all_regions: vm.allRegionSearch
				}).then(results => {
					if(results.data.success === true) {
						vm.searchList = results.data.users
					}
				})

                axios.post('/api/users/search/logging', {
                    search_name: $event,
                    region: this.regionId,
                    user_id: this.user.id
                })
			}, 2000)
		},
        addToPlayersSelected: function(user) {
            this.playersSelected.push(user)
        },
        removeFromPlayersSelected: function(key) {
            this.playersSelected.splice(key, 1)
		},
        checkForSelected: function(user) {
            var find = this.playersSelected.find(player => {
                return player.id === user.id
            })

            var find2 = false

            if(this.addTab === true) {
                find2 = this.eventTeam.roster.roster_players.find(player => {
                    return player.user.id === user.id
                })
            }

            if(find || find2) {
                return true
            }

            return false
        },
        addToRoster: function() {
            var playerMap = this.playersSelected.map(player => {
				return {
					id: player.id
				}
			})


            this.$emit('addPlayersToRoster', {
                playersSelected: playerMap
            })
        },
    }
}
</script>
