<template>
	<div>
		<Header></Header>

		<div class="content-area">
			<div class="standard-page-header">
				League, Team, Player, Umpire & Event Insurance
			</div>
            <div class="frontend-content-wrapper">
				<div class="textarea-content-block" >
					<img src="../../images/insurance/basesheader.png" style="margin: 0 auto; display: block; width: 100%; max-width: 700px;" alt="">
					<div style="text-align: center;">
						<p>With the Slo-Pitch National Insurance Program presented by Holman.</p>
						<div class="row">
							<div class="col-xs-2"></div>
							<div class="col-xs-8">
								<img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/Holman Logo.png" style="max-width: 250px;" width="100%" alt="">
							</div>
							<div class="col-xs-2"></div>
						</div>
						<h3 style="margin-top: 0px;">Insurance programs offered by Slo-Pitch National are dependable, affordable and convenient.</h3>
						<p><b>Programs and Coverage for:</b></p>
						<p>- Tournaments/Events</p>
						<p>- Host Liquor Liability Policies</p>
						<p>- League Executive & Member Policies</p>
						<p>- Team and Player Policies</p>
						<p>- Umpire Protection</p>
						<p>Contact us, or click here for more information about all of our insurance programs.</p>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="padding">
								<img src="../../images/insurance/unnamed.jpg" width="100%" alt="">
							</div>
						</div>
						<div class="col-md-6">
							<div class="padding">
								<h2 style="margin-top: 0px;">Tournaments/Events</h2>
								<p>Event liability insurance can help protect you from financial loss if you are held legally responsible for property damage to the venue or bodily injury to someone injured at your event. Our policies and coverages are affordable and extended to all fully sanctioned Slo-Pitch National Events. Municipalities can be co named under your policy, and Slo-Pitch National will handle proof on insurance certificates and documents making it fast and convenient to provide to facility rental operators and city recreation departments ahead of your event.</p>
							</div>
						</div>
					</div>
					<div class="row">

						<div class="col-md-6">
							<div class="padding">
								<h2 style="margin-top: 0px;">Host Liquor Liability Policies</h2>
								<p>Host Liquor Liability Insurance quotes are available by applying directly to Holman Insurance Brokers Ltd.</p>
								<img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/Holman Logo.png" width="200px" alt="">
							</div>
						</div>
						<div class="col-md-6">
							<div class="padding">
								<img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/DSC08642.jpg" width="100%" alt="">
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="padding">
								<img src="../../images/insurance/unnamed-4.jpg" width="100%" alt="">
							</div>
						</div>
						<div class="col-md-6">
							<div class="padding">
								<h2 style="margin-top: 0px;">League Executive & Member Policies</h2>
								<p>Slo-Pitch National Insurance coverage is available to leagues and their members, it is fast and convenient via registering on our online registration portal. Coverage is extended to all league executive positions and participants within the league. Cities and municipalities can be co named on the leagues policy, and Slo-Pitch National will provide proof on insurance certificates to leagues upon request. League Banquet insurance is available to Slo-Pitch National member leagues.</p>
							</div>
						</div>
					</div>
					<div class="row">

						<div class="col-md-6">
							<div class="padding">
								<h2 style="margin-top: 0px;">Team and Player Policies</h2>
								<p>Players, managers and coaches are covered by Slo-Pitch National insurance when registration has been completed for a fully insured annual membership. Coverage includes 5 million dollars liability as well as secondary accident coverage. This coverage does not include loss of wage expenses.</p>
							</div>
						</div>
						<div class="col-md-6">
							<div class="padding">
								<img src="../../images/insurance/unnamed-3.jpg" width="100%" alt="">
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="padding">
								<img src="../../images/insurance/unnamed-1.jpg" width="100%" alt="">
							</div>
						</div>
						<div class="col-md-6">
							<div class="padding">
								<h2 style="margin-top: 0px;">Carded Umpires</h2>
								<p>All carded umpires are fully insured under the Slo-Pitch National insurance coverage. This includes 5 million dollars liability as well as secondary accident coverage. This coverage does not include loss of wage expenses.</p>
							</div>
						</div>
					</div>
			
				</div>
			</div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
export default {
	metaInfo() {
        return { 
            title: "Insurance - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Insurance programs offered by Slo-Pitch National are dependable, affordable and convenient. Programs and Coverage for: - Tournaments/Events - Host Liquor Liability Policies - League Executive & Member Policies - Team and Player Policies - Umpire Protection - Contact us, or click here for more information about all of our insurance programs.' },
				{ property: 'og:title', content: "Insurance - Slo-Pitch National"},
				{ property: 'og:url', content:  'https://www.slo-pitch.com/insurance' },
				{ property: 'og:site_name', content: 'Slo-Pitch National'},
				{ property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/insurance.png' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {

		};
	},
	components: {
		Header,
		Footer
	},
	mounted: function() {


	},
	computed: {

	},
	methods: {

	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
