<template>
	<div>
        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>

		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			
                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="page-header-title">
                                Manage Form Result
                            </div>
                        </div>
                    </div>
                </div>
                <div class="admin-area">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="padding" v-if="form_results">
                                <div v-if="form_results.user">
                                    <h1>Submitted by {{ form_results.user.first_name }} {{ form_results.user.last_name }}</h1>
                                    <a :href="`/superadmin/manage/users/${form_results.user_id}`" class="button">View User</a>
                                </div>
                                <div v-else>
                                    <!-- <h1>Submitted by {{ form_results.user.first_name }} {{ form_results.user.last_name }}</h1> -->
                                    <!-- <a :href="`/superadmin/manage/users/${form_results.user_id}`" class="button">View User</a> -->
                                </div>
                                <h2>{{ form_results.form.name }}</h2>
                                <p><b>Region selected:</b> {{ form_results.region.name }}</p>

                                <div class="form-component-questions">
                                    <div style="text-align: center; padding: 10px;" v-if="form.fr_name">
                                        <a :class="['lang', { selected: en === true }]" v-on:click="selectLang(true)">EN</a>|<a :class="['lang', { selected: en === false }]" v-on:click="selectLang(false)">FR</a>
                                    </div>
                                    <div :class="['form-component-question', { componentHeader: question.type_id === 'section_break' }, { horizontal: form.direction === 'horizontal' }]" v-for="(question, key) in form.questions" :key="key"> 
                                        <div v-if="question.type_id === 'section_break'">
                                            <h2 style="margin-top: 0px; margin-bottom: 0px;">{{ (en === true ? question.label : question.fr_label) }}</h2>
                                            <p>{{ (en === true ? question.description : question.fr_description) }}</p>
                                        </div>
                                        <div v-if="question.type_id === 'signature_pad'">
                                            <b-field 
                                                :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                                                
                                                :message="[question.description, question.error]"
                                            >   
                                                <img :src="grabResponse(question)" style="width: 500px; height: 250px; background: #fff;" alt="">
                                                
                                                <!-- <b-input 
                                                    :placeholder="question.placeholder"
                                                    :value="grabResponse(question)"
                                                    
                                                ></b-input> -->
                                            </b-field>

                                        </div>
                                        <div v-if="question.type_id === 'short_text'">
                                            <b-field 
                                                :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                                                
                                                :message="[question.description, question.error]"
                                            >
                                                <b-input 
                                                    :placeholder="question.placeholder"
                                                    :value="grabResponse(question)"
                                                    
                                                ></b-input>
                                            </b-field>

                                        </div>
                                        <div v-if="question.type_id === 'long_text'">
                                            <b-field 
                                                :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                                                
                                                :message="[question.description, question.error]"
                                            >
                                                <b-input 
                                                    :placeholder="question.placeholder"
                                                    :value="grabResponse(question)"
                                                    type="textarea"
                                                    minlength="10"
                                                ></b-input>
                                            </b-field>
                                        </div>
                                        <div v-if="question.type_id === 'true_or_false'">
                                            <b-field 
                                                :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                                                
                                                :message="[question.description, question.error]"
                                            >
                                                <b-radio
                                                    size="is-large"
                                                    :value="grabResponse(question)"
                                                    :native-value="true">
                                                    {{ en === true ? 'True' : 'Vrai '}}
                                                </b-radio>
                                                <b-radio
                                                    size="is-large"
                                                    :value="grabResponse(question)"
                                                    :native-value="false">
                                                    {{ en === true ? 'False' : 'Faux '}}
                                                </b-radio>
                                            </b-field>
                                        </div>
                                        <div v-if="question.type_id === 'select'">
                                            <b-field 
                                                :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                                                
                                                :message="[question.description, question.error]"
                                            >
                                                {{ grabResponse(question) }}
                                            </b-field>
                                        </div>
                                        <div v-if="question.type_id === 'checkbox'">
                                            <b-field 
                                                :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                                                
                                                :message="[question.description, question.error]"
                                            >
                                                <div class="field">
                                                    <b-checkbox
                                                        :value="grabResponse(question)"
                                                        true-value="1"
                                                        size="is-large">
                                                        {{ question.label }}
                                                    </b-checkbox>
                                                </div>
                                                
                                            </b-field>
                                        </div>
                                        <div v-if="question.type_id === 'date'">
                                            <b-field 
                                                :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                                                
                                                :message="[question.description, question.error]"
                                            >
                                                {{ grabResponse(question )}}
                                            </b-field>
                                        </div>
                                        <div v-if="question.type_id === 'time'">
                                            <b-field 
                                                :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                                                
                                                :message="[question.description, question.error]"
                                            >
                                                <b-timepicker
                                                    v-model="question.value"
                                                    placeholder="Click to select..."
                                                    icon="clock"
                                                    :hour-format="'12'">
                                                </b-timepicker>
                                            </b-field>
                                        </div>
                                        <div v-if="question.type_id === 'first_name'">
                                            <b-field 
                                                :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                                                
                                                :message="[question.description, question.error]"
                                            >
                                                <b-input 
                                                    :placeholder="question.placeholder"
                                                    v-model="question.value"
                                                ></b-input>
                                            </b-field>
                                        </div>
                                        <div v-if="question.type_id === 'last_name'">
                                            <b-field 
                                                :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                                                
                                                :message="[question.description, question.error]"
                                            >
                                                <b-input 
                                                    :placeholder="question.placeholder"
                                                    v-model="question.value"
                                                ></b-input>
                                            </b-field>
                                        </div>
                                        <div v-if="question.type_id === 'email'">
                                            <b-field 
                                                :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                                                
                                                :message="[question.description, question.error]"
                                            >
                                                <b-input 
                                                    :placeholder="question.placeholder"
                                                    v-model="question.value"
                                                ></b-input>
                                            </b-field>
                                        </div>
                                        <div v-if="question.type_id === 'hidden'">
                                            
                                        </div>
                                    </div>
                                </div>
                                <!-- <pre>{{ form_results }}</pre> -->
                            </div>
                        </div>
                    </div>
                </div>
			
		</div>
	</div>
</template>

<script>

 
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'

import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'
export default {
	data() {
		return {
            isLoading: true,
            createNewFormSwitch: false,
            authUser: { first_name: null },
            defaultOpenedDetails: [1],
            showDetailIcon: true,
            waivers: [],
            excelColumns: [
                {
                    label: 'Submitted On',
                    field: 'created_at'
                },
                {
                    label: 'Region',
                    field: 'region.short_name'
                },
                {
                    label: 'First Name',
                    field: 'first_name'
                },
                {
                    label: 'Last Name',
                    field: 'last_name'
                },
                {
                    label: 'Email',
                    field: 'email'
                },
                
            ],
            questionTypes: [
                {
                    value: 'short_text',
                    name: 'Short Text'
                },
                {
                    value: 'long_text',
                    name: 'Long Text'
                },
                {
                    value: 'true_or_false',
                    name: 'True or False'
                },
                {
                    value: 'select',
                    name: 'Select'
                },
                {
                    value: 'checkbox',
                    name: 'Checkbox'
                },
                {
                    value: 'date',
                    name: 'Date'
                },
                {
                    value: 'time',
                    name: 'Time'
                },
                {
                    value: 'first_name',
                    name: 'First Name'
                },
                {
                    value: 'last_name',
                    name: 'Last Name'
                },
                {
                    value: 'email',
                    name: 'Email'
                },
                {
                    value: 'hidden',
                    name: 'Hidden'
                },
                
            ],

            form: {
                name: null,
                fr_name: null,
                collection: 'form_collection',
                region: '-1'
            },
            form_questions: [
                {
                    type: 'section_break', //long_text, true_or_false, select, date, time, checkbox 
                    placeholder: null,
                    label: 'Section 1 - ',
                    description: null,
                    required: false,
                    value: null,
                    options: []
                },
                {
                    type: 'short_text', //long_text, true_or_false, select, date, time, checkbox 
                    placeholder: null,
                    label: 'Label',
                    description: null,
                    required: false,
                    value: null,
                    options: []
                }
            ],
            form_preview: null,
            confirmSaveWaiver: false,
            content_width: false,
            forms: [],
            submitted_forms: [],
            selectedForm: null,
            editFormSwitch: null,
            user_perms: null,
            en: true,
            form_results: null
		};
	},
	components: {
		Header,
		Footer,
    },
    computed: {
        computedWaiverSubmission: function() {
            
        },
        computedFormResults: function() {
            var vm = this
            
            if(vm.selectedForm) {
                var formResults = vm.selectedForm.responses
                if(vm.user_perms) {
                    if(vm.user_perms.specific_region_only) {
                        formResults = _.filter(formResults, function(result) {
                            return result.region_id === vm.user_perms.specific_region_only
                        })
                    }
                }

                return formResults
            }

            return []
        },
        computedExcelColumns: function() {
            var vm = this
            var columns = vm.excelColumns

            if(vm.selectedForm) {

                var i = 0

                var answersColumns = []
                
                for(i; i < vm.selectedForm.questions.length; i++) {
                    var question = vm.selectedForm.questions[i]
                    columns.push({
                        label: question.label,
                        field: 'question' + question.id
                    })
                }
                
                return columns
            }
            return []
        },
        computedResponses: function() {
            var vm = this

            var array = []

            if(vm.selectedForm) {
                var responses = vm.selectedForm.responses

                var i = 0

                for(i; i < responses.length; i++) {
                    var response = responses[i]

                    var s = 0

                    for(s; s < response.answers.length; s++) {
                        var answer = response.answers[s]
                        
                        responses[i]['question' + answer.form_question_id] = answer.value
                    }
                }
                return responses
            }
            
            return []
        }
    },
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
			
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                vm.user_perms = results.data.user_permissions
                if(results.data.user_permissions.manage_form_results !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }

                
			
                axios.get(`/api/superuser/form-result/${this.$route.params.form}`).then(results => {
                    
                    if(results.data.success === true) {
                        vm.form_results = results.data.form_results
                        vm.form = results.data.form
                        vm.isLoading = false
                    }
                })
            }
        })
	},
	methods: {
        removeOption: function(question, key) {
            
            question.options.splice(key, 1)
        },
        removeEditRow: function(index) {
            this.selectedForm.questions.splice(index, 1);
        },
        removeRow: function(index) {
            this.form_questions.splice(index, 1);
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        switchWidth: function($event) {
			this.content_width = !$event
        },
        addFormQuestion: function() {
			this.form_questions.push({
                type: 'short_text', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                fr_placeholder: null,
                label: 'Label',
                fr_label: 'French Label',
                description: null,
                fr_description: null,
                required: false,
                value: null,
                options: []
            })
        },
        addSectionBreak: function() {
            this.form_questions.push({
                type: 'section_break', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                label: 'Section ',
                description: null,
                required: false,
                value: null,
                options: []
            })
        },
        editAddFormQuestion: function() {
            this.selectedForm.questions.push({
                type_id: 'short_text', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                fr_placeholder: null,
                label: 'Label',
                fr_label: 'French Label',
                description: null,
                fr_description: null,
                required: false,
                value: null,
                options: []
            })
        },
        editAddSectionBreak: function() {
            
            this.selectedForm.questions.push({
                type_id: 'section_break', //long_text, true_or_false, select, date, time, checkbox, section_break
                placeholder: null,
                label: 'Section ',
                description: null,
                required: false,
                value: null,
                options: []
            })
        },
        addFormOption: function(question) {
            question.options.push({
                name: '',
                fr_name: ''
            })
        },
        editForm: function(form) {
            var vm = this
            vm.selectedForm = form
            vm.editFormSwitch = true
        },
        saveForm: function() {
            var vm = this
            axios.post('/api/superuser/save-new-form', {
                form: vm.form,
                form_questions: vm.form_questions
            }).then(results => {
                if(results.data.success === true) {

                    vm.$buefy.snackbar.open({
                        message: 'Saved form successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    vm.forms = results.data.forms
                    vm.createNewFormSwitch = false
                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Something went wrong when saving your form! Please contact us',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        updateForm: function() {
            var vm = this
            axios.post('/api/superuser/update-form', {
                selected_form: vm.selectedForm,
            }).then(results => {
                if(results.data.success === true) {

                    vm.$buefy.snackbar.open({
                        message: 'Saved form successfully',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
                    vm.forms = results.data.forms
                    vm.editFormSwitch = false
                } else {
                    vm.$buefy.snackbar.open({
                        message: 'Something went wrong when saving your form! Please contact us',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            })
        },
        viewFormResults: function(selectedForm) {
            var vm = this

            vm.selectedForm = selectedForm
        },
        moment: function(date, format = null) {
            return moment(date, format)
        },
        grabResponse: function(question) {
            var vm = this
            var find = _.find(vm.form_results.answers, function(answer, index) {
                return answer.form_question_id === question.id
            })
            if(find) {
                // if(find.question.type_id === 'date') {
                //     // console.log('date', find.id, find)
                // }

                
                return find.value
            }
            return null
        },
        selectLang: function(lang) {
            this.en = lang

            sessionStorage.setItem('lang', lang)
        }
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
