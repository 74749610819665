<template>
    <div>
        <section style="overflow: scroll;" v-if="tournamentSelected">
            <div class="row" v-if="tournamentSelected">
                <!-- <div class="col-md-4">
                    <div class="padding">
                        <div class="custom-event-area" style="text-align: center;">
                            <div class="you-selected">
                                You Selected
                            </div>           
                            <div class="poster">
                                <img src="https://placehold.it/500x312" v-if="!tournamentSelected.poster_image_url" alt="">
                                <img :src="tournamentSelected.poster_image_url" v-if="tournamentSelected.poster_image_url" alt="">
                            </div>
                            <div class="custom-event-box">
                                <div class="custom-tournament-name">
                                    {{ tournamentSelected.name }}
                                </div>
                                <div class="custom-tournament-description">
                                    {{ tournamentSelected.description }}
                                </div>
                                <div class="custom-tournament-divisions">
                                    <div v-if="tournamentSelected.divisions.length > 0">
                                        <div class="custom-tournament-divisions-header">
                                            Divisions Offered:
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4" style="flex: 1" v-if="filterDivision(tournamentSelected.divisions, 'mens').length > 0">
                                                <div class="custom-tournament-division-header">
                                                    Men's
                                                </div>
                                                
                                                <b-tag
                                                    type="is-danger"
                                                    :key="key"
                                                    v-for="(division, key) in filterDivision(tournamentSelected.divisions, 'mens')">
                                                    {{ division.rank_id }}
                                                </b-tag>
                                            
                                            </div>
                                            <div class="col-md-4" v-if="filterDivision(tournamentSelected.divisions, 'ladies').length > 0">
                                                <div class="custom-tournament-division-header">
                                                    Ladies
                                                </div>
                                                
                                                <b-tag
                                                    type="is-danger"
                                                    :key="key"
                                                    v-for="(division, key) in filterDivision(tournamentSelected.divisions, 'ladies')">
                                                    {{ division.rank_id }}
                                                </b-tag>
                                            
                                            </div>
                                            <div class="col-md-4" v-if="filterDivision(tournamentSelected.divisions, 'coed').length > 0">
                                                <div class="custom-tournament-division-header">
                                                    Coed
                                                </div>
                                                
                                                <b-tag
                                                    type="is-danger"
                                                    :key="key"
                                                    v-for="(division, key) in filterDivision(tournamentSelected.divisions, 'coed')">
                                                    {{ division.rank_id }}
                                                </b-tag>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="custom-tournament-entry-fees">
                                    <div class="entry-fee-text">
                                        Entry Fee:
                                    </div>
                                    <div class="entry-fee-team">
                                        <i class="fas fa-users"></i> ${{ tournamentSelected.team_registration_fee }}
                                    </div>
                                    <div class="entry-fee-team" v-if="tournamentSelected.player_registration_fee">
                                        <i class="fas fa-user"></i> ${{ tournamentSelected.player_registration_fee }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-12">
                    <div style="background: #da0000; color: #fff;
                        text-align: center; font-weight: 700;">Registering for {{ tournamentSelected.name }}</div>
                    <div class="padding" style="background: #eee;">
                        
                        <div  v-if="step === 0">
                            <div class="row" style="margin-top: 10px;">
                                <div class="col-md-4">
                                    <b-field label="Select Your Team">
                                        <b-select v-if="teams" v-model="selectedTeam" placeholder="Select your team">
                                            <option :value="team" :key="key" v-for="(team,key) in teams.coach">{{ team.team_name }}</option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="col-md-6">
                                    <b-field label="Enter a Division" vertical>
                                        <b-select placeholder="Select Gender" v-model="registerForTournament.gender">
                                            <option value="mens" v-if="totalDivisions.mens.length > 0">Mens</option>
                                            <option value="ladies" v-if="totalDivisions.ladies.length > 0">Ladies</option>
                                            <option value="coed" v-if="totalDivisions.coed.length > 0">Coed</option>
                                        </b-select>
                                        
                                        <b-select style="margin-left: 10px;" placeholder="Select Division" v-model="registerForTournament.division">
                                            <option :value="division" :key="division.id" v-for="division in totalDivisions[registerForTournament.gender]">{{ division.rank_id }}</option>
                                        </b-select>
                                    </b-field>
                                </div>
                            </div>
                            
                            <div v-if="team_roster">
                                <div v-if="registerForTournament.division">
                                    <b-field label="Select Players on Your Roster for This Event" style="margin-top: 20px;"></b-field>
                                    <b-message type="is-danger" has-icon aria-close-label="Close message">
                                        Please check off all players on your team roster playing with you this event. Don't worry, you can change this until the roster lock date in the event after you've completed registration. <span v-if="tournamentSelected.max_roster_size">The max roster size for this event is: {{ tournamentSelected.max_roster_size }}</span>
                                    </b-message>
                                        
                                    <p class="note" style="font-size: 14px;"></p>
                                    <div class="players-selected">
                                        <p style="text-align: center;"><b>{{ selectedRosterPlayers.length }} Players Selected</b></p>
                                    </div>
                                    <b-table
                                        :data="filterRoster(team_roster)"
                                        checkable
                                        :checked-rows.sync="selectedRosterPlayers"
                                        ref="table">

                                        <b-table-column
                                            field="gender"
                                            label="Gender">
                                            <template v-slot="props">
                                                <span v-if="props.row.user.gender === 'Male'">
                                                    <i class="fas fa-mars"></i>
                                                </span>
                                                <span v-if="props.row.user.gender === 'Female'">
                                                    <i class="fas fa-venus"></i>
                                                </span>
                                            </template>
                                        </b-table-column>

                                        <b-table-column
                                            field="first_name"
                                            label="First Name">
                                            <template v-slot="props">
                                                {{ props.row.user.first_name }}
                                            </template>
                                        </b-table-column>
                                        
                                        <b-table-column
                                            field="last_name"
                                            label="Last Name">
                                            <template v-slot="props">
                                                {{ props.row.user.last_name }}
                                            </template>
                                        </b-table-column>
                                        <b-table-column
                                            field="city"
                                            label="City">
                                            <template v-slot="props">
                                                {{ props.row.user.city }}
                                            </template>
                                        </b-table-column>
                                        <b-table-column
                                            field="correct_age"
                                            label="">
                                            <template v-slot="props">
                                                <span v-if="props.row.user.correct_age === false">
                                                    Player does not fulfill the age restriction.
                                                </span>
                                            </template>
                                        </b-table-column>
                                    </b-table>
                                    <div class="players-selected">
                                        <p style="text-align: center;"><b>{{ selectedRosterPlayers.length }} Players Selected</b></p>
                                    </div>
                       

                                    <b-message type="is-danger" has-icon aria-close-label="Close message" v-if="overLimit">
                                        Your roster is over the max roster size limit. You may be asked to remove players by the convenor of the event.
                                    </b-message>
                                    <b-message type="is-danger" has-icon aria-close-label="Close message" v-if="wrong_age === true">
                                        Someone on your roster is not in the age group for this division and needs to be removed.
                                    </b-message>
                                    <span >
                                                      
                                        <div class="check-for-spn-registration" v-if="selectedRosterPlayers.length > 0">
                                            <div class="spn-number-box does-have-spn-number" v-if="spnRegistration === 'fully_insured'">
                                                Your team already has paid for SPN Membership for this team in this division! 
                                            </div>
                                            <div class="spn-number-box does-not-have-spn-number" v-if="spnRegistration === 'upgrade'">
                                                <h4 style="margin-top: 0px; font-weight: 700;">Upgrade Your SPN4You Membership & Insurance</h4>
                                                <p>The last time that you purchased membership with this team, it was single event membership. To play in another event, you must upgrade:</p>
                                                <div class="row">
                                                    <div class="col-md-6" :key="key" v-for="(option, key) in regionRateOptions">
                                                        <div
                                                            v-if="paymentSuccess === false"
                                                            v-on:click="selectInsuranceOption(option)" :class="['selection-box', { 'selected': option === selectedInsuranceOption }]">
                                                            <div class="select-box-header">{{ option.name }}</div>
                                                            <div class="select-box-price">${{ option.price }}</div>
                                                        </div>
                                                        <div
                                                            v-else
                                                            :class="['selection-box', { 'selected': option === selectedInsuranceOption }]">
                                                            <div class="select-box-header">{{ option.name }}</div>
                                                            <div class="select-box-price">${{ option.price }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="selectedInsuranceOption">
                                                    <span v-if="overLimit === false">
                                                        <b-message>You are over the limit for the number of roster players allowed for this event. You may be assked to remove them from your final roster by Slo-Pitch National's admin team.</b-message>
                                                    </span>
                                                    <span v-if="wrong_age === false">
                                                        <b-message>You have players outside the age range for this event. You may be asked to remove them from your final roster by Slo-Pitch Naional's admin team.</b-message>
                                                    </span>
                                                    
                                                    <NewPaymentProcessor
                                                        :productId="selectedInsuranceOption.id"
                                                        :regionId="region_rate.region_id"
                                                        v-on:payment:successful="paymentSuccessSwitch($event)"
                                                        :teamName="selectedTeam.name"
                                                        >
                                                    </NewPaymentProcessor>
                                                </div>
                                            </div>
                                            <div class="spn-number-box does-not-have-spn-number" v-if="spnRegistration === false">
                                                <h4 style="margin-top: 0px; font-weight: 700;">Your SPN4You Membership & Insurance</h4>
                                                <p>You must have an SPN4You Membership for each gendered division your team enters. So after this payment, you will be able to play any {{ registerForTournament.gender }} division in future tournaments this year.</p>
                                                <div class="row">
                                                    <div class="col-md-4" :key="key" v-for="(option, key) in regionRateOptions">
                                                        <div
                                                            v-if="paymentSuccess === false"
                                                            v-on:click="selectInsuranceOption(option)" :class="['selection-box', { 'selected': option === selectedInsuranceOption }]">
                                                            <div class="select-box-header">{{ option.name }}</div>
                                                            <div class="select-box-price">${{ option.with_insurance === 1 ? (option.price + 28.50).toFixed(2) : option.price.toFixed(2) }} + TAX & FEES</div>
                                                        </div>
                                                        <div
                                                            v-else
                                                            :class="['selection-box', { 'selected': option === selectedInsuranceOption }]">
                                                            <div class="select-box-header">{{ option.name }}</div>
                                                            <div class="select-box-price">${{ option.with_insurance === 1 ? (option.price + 28.50).toFixed(2) : (option.price).toFixed(2) }} + TAX & FEES</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="note">If you select Single Use, the next time you sign up with this team you will be prompted to pay the difference.</p>
                                                <div v-if="selectedInsuranceOption">
                                                    <span v-if="overLimit === false">
                                                        <b-message>You are over the limit for the number of roster players allowed for this event. You may be assked to remove them from your final roster by Slo-Pitch National's admin team.</b-message>
                                                    </span>
                                                    <span v-if="wrong_age === false">
                                                        <b-message>You have players outside the age range for this event. You may be asked to remove them from your final roster by Slo-Pitch Naional's admin team.</b-message>
                                                    </span>
                                                    <NewPaymentProcessor
                                                        :productId="selectedInsuranceOption.id"
                                                        :regionId="tournamentSelected.region_id"
                                                        v-on:payment:successful="paymentSuccessSwitch($event)"
                                                        :teamName="selectedTeam.name"
                                                        >
                                                    </NewPaymentProcessor>

                                                </div>
                                            </div>
                                        </div>
                                    
                                    
                                        <button v-if="triggerFinalize && selectedRosterPlayers.length > 0" v-on:click="finalizeAndRegister()" class="admin-button" style="margin-top: 20px;">Finalize & Register</button>   
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import NewPaymentProcessor from '../components/NewPaymentProcessor.vue'
var getAge = require('get-age')
export default {
	data() {
		return {
            en: true,
            upcomingTournaments: [],
            upcomingTournamentsStep: 0,
            tournamentSelected: null,
            step: 0,
            selectedTeam: null,
            selectedRosterPlayers: [],
            registerForTournament: {
                team: null,
                gender: null,
                division: null
            },
            wrong_age: false,
            teams: null,
            team_roster: [],
            region_rate: null,
            selectedInsuranceOption: null,
            currentInsurance: null,
            paymentSuccess: false,
            overLimit: false,
            triggerFinalize: false,
            provincialsOrNationals: false,
            paidForProvNatsEvent: false
		};
	},
	components: {
        // add multiple teams to the divisions, limit at one team per mens coed and ladies
        NewPaymentProcessor
    },
    watch: {
        registerForTournament: {
            deep: true,
            handler() {
                
            }
        },
        selectedRosterPlayers: function($event) {
            var vm = this
            var min_age = vm.registerForTournament.division.min_age

            var wrong_age = false
            var i = 0

            for(i; i < vm.selectedRosterPlayers.length; i++) {
                var user = vm.selectedRosterPlayers[i].user
                var date_of_birth = user.date_of_birth

                var format_to_first_of_year = moment(date_of_birth).format('YYYY') + '-01-01'
                var their_age = getAge(format_to_first_of_year)
                if(their_age < min_age) {

                    vm.selectedRosterPlayers[i].user.correct_age = false

                    wrong_age = true
                }
            }
            
            if(wrong_age === true) {
                vm.wrong_age = true
            } else {
                vm.wrong_age = false
            }

            if(vm.tournamentSelected.max_roster_size === null) {
                vm.tournamentSelected.max_roster_size = 18
            }

            if($event.length > vm.tournamentSelected.max_roster_size) {
                this.$buefy.snackbar.open({
                    message: 'This puts your roster over the max players limit',
                    type: 'is-danger', // is-danger
                    duration: 4000,
                    queue: false
                })

                vm.overLimit = true
            } else {
                vm.overLimit = false
            }
        },
        selectedTeam: function($event) {
            var vm = this
            axios.get('/api/rosters/grab-roster/' + $event.team_slug).then(results => {
                if(results.data.success === true) {
                    vm.team_roster = results.data.team.core_roster

                    var i = 0

                    for (i; i < vm.team_roster.length; i++) {
                        vm.team_roster[i].user.correct_age = true
                    }
                }
            })
        },
        paymentSuccess: async function($event) {
            
            // now trigger automatically Submit & Receive Your Certificate

            await this.finalizeAndRegister()
        }
    },
    props: ['registerForTournamentDetails', 'tournamentId'],
	mounted: function() {
        var vm = this

        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
                

                var location = null

                if(sessionStorage.getItem('user_location')) {
                    location = sessionStorage.getItem('user_location')
                }

                if(vm.tournamentId) {

                    axios.get('/api/users/get-tournament/' + vm.tournamentId).then(results => {
                        
                        if(results.data.success === true) {
                            vm.tournamentSelected = results.data.tournament

                            

                            axios.post('/api/users/region-rate', {
                            	region: vm.tournamentSelected.region_id
                            }).then(results => {
                            	if(results.data.success === true) {
                                    vm.region_rate = results.data.region_rate
                                    
                            	}
                            })

                            // need region rate
                            // keep searching for registerforTournamentDetails
                        }
                    })

                    // need to grab tournament, teams
                }


            }
        })

        axios.get('/api/users/teams').then(results => {
            if(results.data.type === 'success') {
                
                // need to review which teams are already in the event

                vm.teams = results.data.data.teams

                axios.get(`/api/tournaments/get-registered-teams/${vm.eventId}`).then(results => {

                    var registered_teams = results.data.registrants

                    var final_teams = vm.teams
                    var teams_array = []

                    for(const final_team of final_teams) {
                        var find = registered_teams.find(registered_team => {
                            return registered_team.team_id === final_team.id
                        })
                        
                        if(!find) {
                            teams_array.push(find)
                        }
                    }
                    
                    vm.teams = teams_array
                })
                
                

            }
        })

        vm.tournamentSelected = vm.tournamentSelected
        vm.region_rate = vm.region_rate
    },
    
	computed: {
        reviewRosterRestrictions: function() {
            // check to make sure max number of players are good



            // check to make sure ages work

            return {
                over_max_players: false,
                outside_age_group: false,
                success: false
            }

        },
        totalDivisions: function() {
            var vm = this
            var mens = vm.filterDivision(vm.tournamentSelected.divisions, 'mens')
            var ladies = vm.filterDivision(vm.tournamentSelected.divisions, 'ladies')
            var coed = vm.filterDivision(vm.tournamentSelected.divisions, 'coed')
            
            var count = 0
            if(mens.length > 0) {
                count = count + 1
            } 
            if(ladies.length > 0) {
                count = count + 1
            }
            if(coed.length > 0) {
                count = count + 1
            }

            return {
                mens: mens,
                ladies: ladies,
                coed: coed,
                count: count
            }
        },
        spnRegistration: function() {
            // grab the registrations of insurance from the team
            // cross reference the division
            var vm = this

            var team = vm.selectedTeam

            var selected_gender = vm.registerForTournament.gender
            if(team) {
                
                var insurance = team.insurance
                
                var find_insurance = _.find(insurance, function(paper) {
                    if(paper.gender === selected_gender) {
                        return true
                    }
                })
                
                // check validity of the insurance that was found (must be 2020, must not be single use - if it's single use it means it's been used already (because they signed up and it was single use))
                // if a single use one is found, it must be upgraded
                // when updated it will remove single use from the row

                // code in the upgradable pricing

                if(find_insurance) {
                    
                    vm.currentInsurance = find_insurance
                    if(find_insurance.single_event !== 1) {
                        vm.triggerFinalize = true
                        return 'fully_insured'
                    } else {
                        return 'upgrade'
                    }
                }  else {
                    vm.currentInsurance = null
                    vm.triggerFinalize = false
                    return false
                }
                
            } else {
                vm.currentInsurance = null
                vm.triggerFinalize = false
                return false
            }
        },
        regionRateOptions: function() {
            var vm = this

            var array = []
            if(vm.region_rate) {
                if(vm.spnRegistration === false) {
                    if(vm.region_rate.rate.single_use_tournament_team !== 0) {
                        array.push({
                            name: 'Play One Time',
                            price: vm.region_rate.rate.single_use_tournament_team,
                            with_insurance: null,
                            id: 8
                        })
                    }
                    if(vm.region_rate.rate.un_insured_tournament_team !== 0) {
                        array.push({
                            name: 'Uninsured Tournament Team',
                            price: vm.region_rate.rate.un_insured_tournament_team,
                            with_insurance: null,
                            id: 7
                        })
                    }
                    if(vm.region_rate.rate.fully_insured_tournament_team !== 0) {
                        array.push({
                            name: 'Fully Insured Tournament Team',
                            price: vm.region_rate.rate.fully_insured_tournament_team,
                            with_insurance: 1,
                            id: 6
                        })
                    }
                }
                if(vm.spnRegistration === 'upgrade') {
                    if(vm.region_rate.rate.upgrade_to_un_insured_tournament_team !== 0) {
                        array.push({
                            name: 'Upgrade - Uninsured Tournament Team',
                            price: vm.region_rate.rate.upgrade_to_un_insured_tournament_team,
                            id: 11
                        })
                    }
                    if(vm.region_rate.rate.upgrade_to_fully_insured_tournament_team !== 0) {
                        array.push({
                            name: 'Upgrade - Fully Insured Tournament Team',
                            price: vm.region_rate.rate.upgrade_to_fully_insured_tournament_team,
                            with_insurance: 1,
                            id: 10
                        })
                    }
                }
            }

            return array
        }
	},
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        },
        filterRoster: function(roster) {
            // cannot have ladies in mens, cannot have mens in ladies

            var vm = this

            var roster = vm.team_roster

            // if(vm.registerForTournament.gender === 'mens') {
            //     roster = _.filter(roster, function(player) {
            //         if(player.user.gender === 'Male') {
            //             return true
            //         }
            //     })
            // }
            // if(vm.registerForTournament.gender === 'ladies') {
            //     roster = _.filter(roster, function(player) {
            //         if(player.user.gender === 'Female') {
            //             return true
            //         }
            //     })
            // }

            return roster
        },

        paymentSuccessSwitch: function($event) {
            var vm = this
            vm.paymentSuccess = $event
            this.triggerFinalize = true

            vm.$nextTick(() => {
                this.finalizeAndRegister()
            })
        },
        finalizeAndRegister: async function() {
            var vm = this
            // verify that players are selected, so is team, etc
            var order_id = null
            
            if(vm.paymentSuccess) {
                order_id = vm.paymentSuccess.order.id
            }
            
            
            var current_insurance_id = null
            if(vm.currentInsurance) {
                current_insurance_id = vm.currentInsurance.id
            } else {

                var test_insurance = await axios.post('/api/users/assign-insurance-on-purchase', {
                    info: vm.paymentSuccess,
                    insurance: vm.selectedInsuranceOption,
                    team: vm.selectedTeam.team_id,
                    gender: vm.registerForTournament.gender
                })

                if(test_insurance) {
                    current_insurance_id = test_insurance.data.team_registration.id
                }
            }

            
            var selected_roster_players = []

            if(vm.selectedRosterPlayers) {
                var s = 0

                for(s; s < vm.selectedRosterPlayers.length; s++) {
                    var selected_player = vm.selectedRosterPlayers[s]

                    selected_roster_players.push({
                        id: selected_player.id,
                        team_id: selected_player.team_id,
                        user_id: selected_player.user_id,
                        role_on_team: selected_player.role_on_team,
                    })
                }
            }

            axios.post('/api/users/register-for-tournament', {
                team_id: vm.selectedTeam.team_id,
                roster: selected_roster_players,
                gender_id: vm.registerForTournament.gender,
                division_id: vm.registerForTournament.division.id,
                tournament_id: vm.tournamentId,
                insurance_id: current_insurance_id,
                order_id: order_id
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'You have successfully registered for this tournament!',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })

                    this.$emit('register:complete')
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Registration failed. Please review that all information is submitted correctly and try again. If this persists, contact us.',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
                }
            }).catch(err => {
                this.$buefy.snackbar.open({
                    message: 'Registration failed. Please review that all information is submitted correctly and try again. If this persists, contact us.',
                    type: 'is-danger', // is-danger
                    duration: 4000,
                    queue: false
                })
            })
        },
        selectInsuranceOption: function(option) {
            var vm = this
            vm.selectedInsuranceOption = option

            
        }
	}
}
</script>