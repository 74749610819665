<template>
    <div>
		<b-loading :is-full-page="true" v-model="isLoading"></b-loading>
		<b-modal v-model="sendMessageToClinic" :width="640">
			<template #default="props">
				<div class="modal-card" style="width: 600px;">
					<header class="modal-card-head">
						<p class="modal-card-title">Send Message to Registrants</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						
						<section class="modal-card-body">
							<table class="table">
								<tr>
									<td><b>Clinic Name:</b></td>
									<td>{{ clinicSelected.name }}</td>
								</tr>
							</table>
							<b-input type="textarea" v-model="sendMessage"></b-input>
							<a v-on:click="sendTheMessage()" class="admin-button">Send to Registrants</a>
						</section>
					
					</section>
				</div>
			</template>
        </b-modal>
		
		<b-modal
			has-modal-card
			:width="600"
			
			v-model="editClinicSwitch">
			<template #default="props">
                <div class="modal-card">
					<header class="modal-card-head">
						<p class="modal-card-title">Edit {{ clinicSelected.name }}</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<div class="row">
							<div class="col-md-6">
								<div class="padding">
									<b-field :label="(en === true ? 'Clinic Name' : 'Nom de la Clinique')">
										<b-input v-model="clinicSelected.name"></b-input>
									</b-field>
								</div>
							</div>
							<div class="col-md-6">
								<div class="padding">
									<b-field :label="(en === true ? 'Clinic Name (FR)' : 'Nom de la Clinique Fr')">
										<b-input v-model="clinicSelected.fr_name"></b-input>
									</b-field>
								</div>
							</div>
							
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="padding">
									<p><b>Location Currently: {{ clinicSelected.written_address }}</b></p>
									<b-field label="Location">
										<b-input icon-pack="fas"
											icon="street-view"
											expanded
											id="autocomplete" v-model="clinicSelected.location"></b-input>
									</b-field>
									<p class="note" style="color: red;">Leave this above EMPTY if you are not changing the location.</p>
								</div>
							</div>
							<div class="col-md-6">
								<div class="padding">
									<b-field :label="(en === true ? 'Cost to Attend ($)' : 'Cout de Participation')">
										<b-numberinput v-model="clinicSelected.fee_to_register"></b-numberinput>
									</b-field>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="padding">
									<b-field :label="(en === true ? 'Clinic Date' : 'Date de la Clinique')">
										<b-datepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinicSelected.event_date"
											horizontal-time-picker>
										</b-datepicker>
									</b-field>
								</div>
							</div>
							<div class="col-md-6">
								<div class="padding">
									<b-field :label="(en === true ? 'Clinic Start Time' : 'Heure de Début de la Clinique')">
										<b-timepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinicSelected.start_time"
											horizontal-time-picker>
										</b-timepicker>
									</b-field>
								</div>
							</div>
							<div class="col-md-6">
								<div class="padding">
									<b-field :label="(en === true ? 'Clinic End Time' : 'Heure de la Fin ')">
										<b-timepicker
											placeholder="Click to select..."
											icon="calendar-today"
											v-model="clinicSelected.end_time"
											horizontal-time-picker>
										</b-timepicker>
									</b-field>
								</div>
							</div>
							<div class="col-md-3">

							</div>
						</div>
						
						<div class="row">
							<div class="col-md-6">
								<div class="padding">
									<b-field extended label="Description">
										
										<b-input v-model="clinicSelected.description" maxlength="200" type="textarea"></b-input>
										
									</b-field>
								</div>
							</div>
							<div class="col-md-6">
								<div class="padding">
									<b-field :label="(en === true ? 'Maximum Registrants' : 'Nombre Maximum De Participants')">
										<b-numberinput v-model="clinicSelected.max_participants"></b-numberinput>
									</b-field>
									<b-checkbox v-model="clinicSelected.allow_waitlist">
                                        {{ en === true ? 'Allow a waitlist' : 'Permettre Liste D’Attente' }}
                                    </b-checkbox>
								</div>
							</div>
						</div>
						
					</section>
					<footer class="modal-card-foot">
                        <button v-on:click="updateClinic()" class="admin-button"><i class="fas fa-save"></i> Update Clinic</button>
                    </footer>
                </div>
            </template>
		</b-modal>
		<b-modal
			:width="640"
			v-model="viewRegistrantsSwitch">
			<template #default="props">
                <div class="modal-card">
					
					<header class="modal-card-head">
						<p class="modal-card-title">
							View Registrants for {{ displayRegistrants.name }} <vue-excel-xlsx
							:data="computedRegistrants"
							:columns="columnTemplate"
							:filename="'filename'"
							:sheetname="'sheetname'"
							:class="'button is-success'"
							>
							<i class="fas fa-file-export"></i>&nbsp;&nbsp; Export to Excel
						</vue-excel-xlsx>
						</p>
						
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<b-tabs>
							<b-tab-item label="View Registrants">
								<table style="width: 100%;" class="table">
									<tr :key="registrant.id" v-for="(registrant, key) in displayRegistrants.registrants">
										<td style="width: 50%;">{{ registrant.user.first_name }} {{ registrant.user.last_name }}</td>
										<td>
											<span v-if="registrant.on_waitlist === 1 || registrant.on_waitlist === true">On Waitlist</span>
										</td>
										<td>
											<a v-on:click="moveRegistrantWaitlist(registrant)" v-if="registrant.on_waitlist === 0 || registrant.on_waitlist === false" class="button is-small is-info">Move Onto Waitlist</a>
											<a v-on:click="moveRegistrantWaitlist(registrant)" v-if="registrant.on_waitlist === 1 || registrant.on_waitlist === true" class="button is-small is-warning">Move Off Waitlist</a>
										</td>
										<td>
											<a v-on:click="removeRegistrant(registrant, key)" class="button is-small is-danger">Remove from Registrants</a>
										</td>
									</tr>
								</table>
							</b-tab-item>
							<b-tab-item label="Add to Registrants">
								<div class="add-a-registrant">
									<div class="row">
										<div class="col-md-12">
											<b-field label="Add a Registrant">
												<b-input v-on:input="updatePlayerSearch($event)" placeholder="Add their SPN registered email"></b-input>
											</b-field>
										</div>
									</div>
								</div>
								<div style="margin: 20px;">
									<nav class="level" v-for="(user, key) in searchList" :key="key">
										<div class="level-left">
											<p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
											<p class="level-item">{{ user.city }}</p>
											<p class="level-item">{{ user.gender }}</p>
											
											<p class="level-item">
												<RankComponent :currentRank="user.current_rank_id"
												></RankComponent>
												
											</p>
											<p class="level-item">
												<span v-if="user.latest_waiver_signed === $latest_waiver">Signed</span>
											</p>
										</div>
										<div class="level-right">
											<a class="button is-small" v-on:click="addToPlayersSelected(user)"><i class="fas fa-plus"></i></a>
										</div>
									</nav>
								</div>
								<div class="adding">
									<p><b>Adding:</b></p>
								</div>
								
								<section class="modal-card-body">
									<nav class="level" v-for="(user, key) in playersSelected" :key="key">
										<div class="level-left">
											<p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
											<p class="level-item">{{ user.city }}</p>
										</div>
										<div class="level-right">
											<a class="button is-danger is-small" v-on:click="removeFromPlayersSelected(key)"><i class="fas fa-trash"></i></a>
										</div>
									</nav>
								</section>
								<footer class="modal-card-foot">
									<a class="admin-button" v-on:click="addToClinic()">Add Players To Clinic</a>
								</footer>
							</b-tab-item>
						</b-tabs>
					</section>
                </div>
            </template>
		</b-modal>
		<div class="row">
			<div class="col-md-6">
				<h2 v-if="en">Umpire Clinics</h2>
				<h2 v-if="en === false">Cliniques D'arbitres</h2>
			</div>
			<div class="col-md-6">
				
			</div>
		</div>
        
		<div class="padding" style="padding-left: 0px;">
			<b-table 
				:data="clinics" 
				:striped="true"
				:focusable="true"
				paginated
				per-page="10"
			>
				<b-table-column field="name" :label="en === true ? 'Name' : 'Nom'">
					<template v-slot="props">
						{{ props.row.name }}/{{ props.row.fr_name }}
					</template>
				</b-table-column>
				<b-table-column field="event_date" :label="en === true ? 'Event Date' : 'Date de la Clinique'">
					<template v-slot="props">
						{{ momentUtc(props.row.event_date).format('YYYY-MM-DD') }}
					</template>
				</b-table-column>
				<b-table-column field="start_time" :label="en === true ? 'Time' : 'Heure'">
					<template v-slot="props">
						{{ momentUtc(props.row.start_time, 'HH:mm:ss').local().format('h:mm A') }} -  {{ momentUtc(props.row.end_time, 'HH:mm:ss').local().format('h:mm A') }}
					</template>
				</b-table-column>
				<b-table-column field="street_address" :label="en === true ? 'Street Address' : 'Adresse'">
					<template v-slot="props">
						{{ props.row.street_address }}
					</template>
				</b-table-column>
				<b-table-column :searchable="true" field="city" :label="en === true ? 'City' : 'Ville'">
					<template v-slot="props">
						{{ props.row.city }}
					</template>
				</b-table-column>
				<b-table-column  field="postal_code" :label="en === true ? 'Postal Code' : 'Code Postal'">
					<template v-slot="props">
						{{ props.row.postal_code }}
					</template>
				</b-table-column>
				<b-table-column field="buttons">
					<template v-slot="props">
						<a class="button is-small is-info" v-if="props.row.is_registered === 0" v-on:click="registerForClinic(props.row)">Register</a>
						<a class="button is-small is-success" v-if="props.row.is_registered === 1">Already Registered</a>
						
					</template>
				</b-table-column>
			</b-table>
		</div>

		<div class="row">
			<div class="col-md-6">
				<h2 v-if="en === true">Clinics I Created</h2>
				<h2 v-if="en === false">Cliniques Que J’ai Créer</h2>
			</div>
			<div class="col-md-6">
				
			</div>
		</div>
		<div>
			<b-table 
				:data="clinics_i_created" 
				:striped="true"
				:focusable="true"
				paginated
				per-page="10"
			>
				<b-table-column field="status" :label="en === true ? 'Status' : 'Status'">
					<template v-slot="props">
						<b-tag type="is-success" v-if="props.row.status === 'approved'">Approved</b-tag>
						<b-tag type="is-warning" v-if="props.row.status === 'unapproved'">Awaiting Approval</b-tag>
						<b-tag type="is-danger" v-if="props.row.status === 'rejected'">Rejected</b-tag>
					</template>
				</b-table-column>
				<b-table-column field="name" :label="en === true ? 'Name' : 'Nom'">
					<template v-slot="props">
						{{ props.row.name }}/{{ props.row.fr_name }}
					</template>
				</b-table-column>
				<b-table-column field="event_date" :label="en === true ? 'Event Date' : 'Date de la Clinique'">
					<template v-slot="props">
						{{ moment(props.row.event_date).utc().format('YYYY-MM-DD') }}
					</template>
				</b-table-column>
				<b-table-column field="start_time" :label="en === true ? 'Time' : 'Heure'">
					<template v-slot="props">
						{{ momentUtc(props.row.start_time, 'HH:mm:ss').local().format('h:mm A') }} -  {{ momentUtc(props.row.end_time, 'HH:mm:ss').local().format('h:mm A') }}
					</template>
				</b-table-column>
				<b-table-column field="street_address" :label="en === true ? 'Street Address' : 'Adresse'">
					<template v-slot="props">
						{{ props.row.street_address }}
					</template>
				</b-table-column>
				<b-table-column :searchable="true" field="city" :label="en === true ? 'City' : 'Ville'">
					<template v-slot="props">
						{{ props.row.city }}
					</template>
				</b-table-column>
				<b-table-column  field="postal_code" :label="en === true ? 'Postal Code' : 'Code Postal'">
					<template v-slot="props">
						{{ props.row.postal_code }}
					</template>
				</b-table-column>
				<b-table-column field="buttons">
					<template v-slot="props">
						<a class="button is-small is-info" v-on:click="editClinic(props.row)">Edit</a>
						<a class="button is-small is-success" v-on:click="viewRegistrants(props.row)">View Registrants</a>
						<a class="button is-small is-info" v-on:click="sendMessageToRegistrants(props.row)">Send Message to Registrants</a>
					</template>
				</b-table-column>
			</b-table>
		</div>


    </div>
</template>

<script>

import moment from 'moment'
import axios from 'axios'
import RankComponent from '../../../components/RankComponent.vue'

export default {
	data() {
		return {
			registerForClinicSwitch: true,
			clinics: [],
			clinics_i_created: [],
			timer: null,
			isLoading: true,
			searchList: [],
			editClinicSwitch: false,
			columnTemplate: [
				{ title: 'First Name', label: 'First Name', field: 'first_name', visible: true, searchable: false },
				{ title: 'Last Name', label: 'Last Name', field: 'last_name', visible: true, searchable: false },
				{ title: 'Email', label: 'Email', field: 'email', visible: true, searchable: false },
				{ title: 'Phone', label: 'Phone', field: 'phone', visible: true, searchable: false },
			],
			clinic: {
				name: null,
				fr_name: null,
				description: null,
				location: null,
				secondary_clinician: null,
				clinic_date: null,
				start_time: null,
				end_time: null,
				headquarter_google_address: null,
				max_participants: 1,
				registration_start_date: null,
				registration_end_date: null,
				allow_waitlist: false,
				fee_to_register: 0,
			},
			viewRegistrantsSwitch: false,
			displayRegistrants: null,
			playersSelected: [],
			sendMessageToClinic: false,
			sendMessage: null
		};
	},
	components: {
		RankComponent
	},
	watch: {
		editClinicSwitch: function(result) {
			var vm = this

			if(result === true) {
				vm.$nextTick(() => {

                })
			}
		}
	},
	props: ['en'],
	mounted: function() {
		var vm = this
        axios.get('/api/umpires/local-clinics').then(results => {
			if(results.data.success === true) {
				vm.clinics = results.data.clinics
				vm.isLoading = false
			}
		})

		axios.get('/api/users/clinics-i-created').then(results => {
			if(results.data.success === true) {
				vm.clinics_i_created = results.data.clinics
			}
		})
	},
	computed: {
		computedRegistrants: function() {
			var vm = this
			
			var array = []

			var i = 0
			for(i; i < vm.displayRegistrants.registrants.length; i++) {
				var registrant = vm.displayRegistrants.registrants[i]
				array.push({
					first_name: registrant.user.first_name,
					last_name: registrant.user.last_name,
					email: registrant.user.email,
					phone: registrant.user.phone,
				})
			}

			return array
		}
	},
	methods: {
		updatePlayerSearch: function($event) {
            var vm = this

            vm.isLoading = true

            if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                axios.post(`/api/users/search/players`, {
                    search_name: $event,
					region: vm.displayRegistrants.region_id
                }).then(results => {
                    if(results.data.success === true) {
                        vm.isLoading = false
                        vm.searchList = results.data.users
                    }
                })
            }, 500)
        },
		moment: function(date, format = null) {
            return moment(date, format)
		},
		momentUtc: function(date, format) {
			return moment.utc(date, format)
		},
		registerForClinic: function(clinic) {
			this.$emit('register:clinic', clinic)
		},
		viewRegistrants: function(clinic) {
			var vm = this

			vm.viewRegistrantsSwitch = true

			vm.displayRegistrants = clinic
		},
		editClinic: function(clinic) {
			var vm = this
		
			vm.clinicSelected = clinic

			if(vm.clinicSelected.event_date) {
				var formatted = moment(vm.clinicSelected.event_date, 'YYYY-MM-DD').utc()
				
				vm.clinicSelected.event_date = new Date(parseInt(formatted.format('YYYY')), parseInt(formatted.format('M')) - 1, parseInt(formatted.format('D')))
			}
			// vm.clinicSelected.start_time = vm.momentUtc(vm.clinicSelected.start_time, 'HH:mm:ss').local()
			// vm.clinicSelected.end_time = vm.momentUtc(vm.clinicSelected.end_time, 'HH:mm:ss').local()
			if(vm.clinicSelected.start_time) {
				vm.clinicSelected.start_time = vm.momentUtc(vm.clinicSelected.start_time, 'HH:mm:ss')
			}
			if(vm.clinicSelected.end_time) {
				vm.clinicSelected.end_time = vm.momentUtc(vm.clinicSelected.end_time, 'HH:mm:ss')
			}

			
			vm.$nextTick(() => {
				
			})


			vm.editClinicSwitch = true
		},
		removeRegistrant: function(registrant, key) {
			var vm = this
			axios.post('/api/umpires/remove-registrant-waitlist', {
				waitlist: registrant.id
			}).then(results => {
				if(results.data.success === true) {
					
					this.displayRegistrants.registrants.splice(key, 1)
				}
			})
		},
		updateClinic: function() {
			var vm = this

			vm.clinicSelected.headquarter_google_address = vm.clinic.headquarter_google_address
		
			var clinic = vm.clinicSelected

			delete clinic.registrants

			axios.post('/api/umpires/update-clinic', {
				clinic: clinic
			}).then(results => {
				if(results.data.success === true) {
					

					vm.editClinicSwitch = false

					vm.clinic = {
						name: null,
						fr_name: null,
						description: null,
						location: null,
						secondary_clinician: null,
						clinic_date: null,
						start_time: null,
						end_time: null,
						headquarter_google_address: null,
						max_participants: 1,
						registration_start_date: null,
						registration_end_date: null,
						allow_waitlist: false,
						fee_to_register: 0,
					}

					this.$buefy.snackbar.open({
                        message: 'Updated clinic',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'Clinic failed to save',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		moveRegistrantWaitlist: function(registrant) {
			var vm = this
			axios.post('/api/umpires/update-registrant-waitlist', {
				waitlist: registrant.id
			}).then(results => {
				if(results.data.success === true) {
					
					registrant.on_waitlist = !registrant.on_waitlist
				}
			})
		},
		addToPlayersSelected: function(user) {
            this.playersSelected.push(user)
        },
        removeFromPlayersSelected: function(key) {
            this.playersSelected.splice(key, 1)
        },
		addToClinic: function() {
            
            var vm = this

            axios.post('/api/umpires/add-to-clinic', {
                registrants: vm.playersSelected,
                clinic_id: vm.displayRegistrants.id
            }).then(results => {
                
                if(results.data.success === true) {
                    
                    vm.playersSelected = []
                    vm.viewRegistrantsSwitch = false

                    this.$buefy.snackbar.open({
                        message: 'You have successfully added registrants to your clinic.',
                        type: 'is-success' // is-danger
                    })
                } else {
                    vm.error = results.data.error
                    this.$buefy.snackbar.open({
                        message: 'Adding registrants to your clinic did not work.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
		sendMessageToRegistrants: function(clinic) {
			this.sendMessageToClinic = true

			this.clinicSelected = clinic
		},
		sendTheMessage: function() {
			axios.post('/api/umpires/send-message', {
				clinic: this.clinicSelected.id,
				message: this.sendMessage
			}).then(results => {
				if(results.data.success === true) {
					this.sendMessageToClinic = false

					this.$buefy.snackbar.open({
                        message: 'You have sent a message to your registrants',
                        type: 'is-success' // is-danger
                    })
				}
			})
		}
	}
}
</script>

<style lang="scss">
	.modal {
		z-index: 2000 !important;
	}
</style>
