<template>
	<div>
        <b-modal 
            v-model="addPlayerModal"
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal>
            <template #default="props">
                <AddPlayerToTeamModal
                    :addTab="false"
                    :regionId="team.region_id"
                    :title="'Add Players to Player Pool'"
                    :en="en"
                    :team="team"
                    v-on:addPlayersToRoster="addPlayersToRoster"
                >
                </AddPlayerToTeamModal>
            </template>
        </b-modal>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" v-if="team" >
                <div :class="['page-header', { sidebarClosed: !content_width }]" style="background: #eee !important;">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="page-header-title" :style="{ color: team.color_1 }">
                                <img :src="team.logo" width="50px" style="display: inline; float: left; margin-top: 10px; margin-right: 10px;" alt=""> {{ en === true ? 'Manage' : 'Gérer' }} {{ team.name }}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a href="/dashboard/players/my-teams" class="button">{{ en === true ? 'Back to My Teams' : 'Retour à Mes Équipes' }}</a>
                                    <a v-if="myRole" v-on:click="addPlayerModal = !addPlayerModal" class="button is-danger">{{ en === true ? 'Add Player to Team' : 'Ajouter un Joueur à L’équipe' }}</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="admin-area">
                    <div class="padding" style="padding-top: 0px;">
                        <img :src="team.logo" style="display: block; width: 100%; max-width: 140px; margin-top: 0px; margin-right: 10px;" alt="">
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <b-message type="is-danger" has-icon aria-close-label="Close message">
								<b>Your roster can handle <u>all</u> players within your playing circle, and then when you enter a league or tournament you can pick specific players for that event. No need anymore to continually add or remove players anymore! Add everyone to this roster, and when you enter a league or tournament, you can specify which players are actually attending!</b>
							</b-message>	
                            <Accordion>
                                <template v-slot:header>
                                    {{  en === true ? 'Player Pool' : 'Votre Liste Complète' }}
                                </template>
                                <template v-slot:content>
                                    <vue-excel-xlsx
                                        :data="team.core_roster"
                                        :columns="columnTemplate"
                                        :filename="'filename'"
                                        :sheetname="'sheetname'"
                                        :row-class="rowClass"
                                        :class="'button is-success'"
                                        >
                                        <i class="fas fa-file-export"></i>&nbsp;&nbsp; Export to Excel
                                    </vue-excel-xlsx>
                                    <p v-if="team.core_roster">You have {{ team.core_roster.length }} in your player pool. You can have a maximum of 40.</p>
                                    <b-table
                                        :data="team.core_roster"
                                        :striped="true"
                                        :focusable="true"
                                        paginated
                                        :row-class="rowClass"
                                        per-page="20"
                                    >
                                        
                                        <b-table-column
                                            field="waiver_signed"
                                            :label="(en === true ? 'Waiver' : 'Renonciation')"
                                        >
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template v-slot="props">
                                                <div style="text-align: center;" >
                                                    <span v-if="props.row.latest_waiver_signed === $latest_waiver"><i class="fas fa-check-circle"></i></span>
                                                    <span v-else>
                                                        <a v-on:click="sendWaiverReminder(props.row)" class="button is-small" v-if="myRole" style="height: 3em;">Send Waiver <br>Reminder</a>
                                                    </span>
                                                </div>
                                            </template>
                                        </b-table-column>
                                        <b-table-column
                                            field="first_name"
                                            :label="(en === true ? 'First Name' : 'Prénom')"
                                            :searchable="true"
                                        >
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template slot="searchable" slot-scope="props">
                                                <b-input
                                                    v-model="props.filters[props.column.field]"
                                                    placeholder="Search"
                                                    size="is-small"
                                                ></b-input>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.first_name }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column
                                            field="last_name"
                                            :label="(en === true ? 'Last Name' : 'Nom de Famille')"
                                            :searchable="true"
                                        >

                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template slot="searchable" slot-scope="props">
                                                <b-input
                                                    v-model="props.filters[props.column.field]"
                                                    placeholder="Search"
                                                    size="is-small"
                                                ></b-input>
                                            </template>
                                            <template v-slot="props">
                                                <b>{{ props.row.last_name }}</b>
                                            </template>
                                        </b-table-column>
                                        <b-table-column
                                            field="current_rank_id"
                                            :label="(en === true ? 'Level' : 'Niveau')"
                                            :searchable="true"
                                        >
                                            <template slot="searchable" slot-scope="props">
                                                <b-input
                                                    v-model="props.filters[props.column.field]"
                                                    size="is-small"
                                                    style="width: 30px;"
                                                ></b-input>
                                            </template>
                                            <template v-slot="props">
                                                <RankComponent :currentRank="props.row.current_rank_id"
                                                ></RankComponent>
                                               
                                            </template>
                                        </b-table-column>
                                        <b-table-column field="role" :label="(en === true ? 'Role' : 'Rôle')">
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template v-slot="props">
                                                <span class="tag is-success" v-if="props.row.role_on_team === 1">
                                                    {{ team.primary_coach_id === props.row.user_id ? "Primary" : "" }} Coach
                                                </span>
                                                <span class="tag is-warning" v-if="props.row.role_on_team === 2">
                                                    Team Contact
                                                </span>
                                                <span class="tag is-danger" v-if="props.row.role_on_team === 3">
                                                    Player
                                                </span>
                                                <b-checkbox 
                                                    v-on:input="updateCoachOnly($event, props.row)" 
                                                    v-model="props.row.coach_only"
                                                    v-if="props.row.role_on_team === 1" 
                                                    style="margin-top: 10px;"
                                                    :true-value="1"
                                                    :false-value="null">
                                                    <span style="font-size: 12px; font-weight: 700;
                                                    text-transform: uppercase;">Coach <u>Only</u></span>
                                                </b-checkbox>
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="city" :label="(en === true ? 'City' : 'Ville')">
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.city }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="gender" :label="(en === true ? 'Gender' : 'Sexe')">
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template v-slot="props">
                                                <span class="tag is-light">
                                                    <b>{{ props.row.gender }}</b>
                                                </span>
                                            </template>
                                        </b-table-column>
                                        <b-table-column v-if="myRole" field="date_of_birth" :label="(en === true ? 'Date of Birth' : 'Date de Naissance')">
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template v-slot="props">
                                                <span class="tag is-light">
                                                    <b>{{ moment(props.row.date_of_birth).utc().format('YYYY-MM-DD') }}</b>
                                                </span>
                                            </template>
                                        </b-table-column>
                                        <b-table-column
                                            field="positions"
                                            label="Positions"
                                        >
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template slot="searchable" slot-scope="props">
                                                <b-input
                                                    v-model="props.filters[props.column.field]"
                                                    placeholder="Search"
                                                    size="is-small"
                                                ></b-input>
                                            </template>
                                            <template v-slot="props">
                                                <span v-if="props.row.positions">
                                                    <b-tag v-for="position in JSON.parse(props.row.positions)" :key="position">
                                                        <b>{{ position }}</b>
                                                    </b-tag>
                                                        
                                                    
                                                </span>
                                            </template>
                                        </b-table-column>
                                        <b-table-column field="actions" label="Actions" v-if="myRole">
                                            <template slot="header" slot-scope="{ column }">
                                                <b-tooltip :label="column.label" dashed>
                                                    {{ column.label }}
                                                </b-tooltip>
                                            </template>
                                            <template v-slot="props">
                                                <b-dropdown hoverable aria-role="list">
                                                    <button class="button is-info" slot="trigger">
                                                        <span>Actions</span>
                                                        <b-icon icon="menu-down"></b-icon>
                                                    </button>
                                                    <b-dropdown-item aria-role="listitem" v-on:click="changePlayerRole(props.row, 1)" v-if="props.row.role_on_team !== 1">Change To Coach</b-dropdown-item>
                                                    <b-dropdown-item aria-role="listitem" v-on:click="changeToPrimaryCoach(props.row)" v-if="team.primary_coach_id !== props.row.user_id && props.row.role_on_team !== 1">Change To Primary Coach</b-dropdown-item>
                                                    <b-dropdown-item aria-role="listitem" v-on:click="changePlayerRole(props.row, 2)" v-if="props.row.role_on_team !== 2  && props.row.role_on_team !== 1">Change To Team Contact</b-dropdown-item>
                                                    <b-dropdown-item aria-role="listitem" v-on:click="changePlayerRole(props.row, 3)"  v-if="props.row.role_on_team !== 3 && props.row.role_on_team !== 1">Change to Player</b-dropdown-item>
                                                    
                                                    <b-dropdown-item v-if="props.row.role_on_team !== 1" aria-role="listitem" v-on:click="removeFromTeam(props.row)">Remove from Team</b-dropdown-item>
                                                </b-dropdown>
                                            </template>
                                        </b-table-column>
                                    </b-table>
                                    
                                    <div class="row" style="margin-top: 50px;">
                                        <nav class="panel" style="background: #fff;">
                                            <div class="panel-heading" style="text-align: center;">
                                                <b>Roster Layout</b>
                                            </div>
                                            <div class="panel-block">
                                                <table class="roster-table">
                                                    <tr>
                                                        <td class="head" :key="key" v-for="(item, key) in team.core_roster_count">
                                                            <b-tag v-if="key === 'A'" type="is-success">
                                                                {{ key === 'null' ? 'N/A': key }}
                                                            </b-tag>
                                                            <b-tag v-else-if="key === 'B'" type="is-danger">
                                                                {{ key === 'null' ? 'N/A': key }}
                                                            </b-tag>
                                                            <b-tag v-else-if="key === 'C'" type="is-warning">
                                                                {{ key === 'null' ? 'N/A': key }}
                                                            </b-tag>
                                                            <b-tag v-else-if="key === 'D'" type="is-info">
                                                                {{ key === 'null' ? 'N/A': key }}
                                                            </b-tag>
                                                            <b-tag v-else-if="key === 'E'" type="is-link" style="color: #fff;">
                                                                {{ key === 'null' ? 'N/A': key }}
                                                            </b-tag>
                                                            <b-tag v-else>
                                                                {{ key === 'null' ? 'N/A': key }}
                                                            </b-tag>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="num" :key="key" v-for="(item, key) in team.core_roster_count">
                                                            {{ item.length }}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </nav>
                                    </div>
                                </template>
                            </Accordion>
                            <Accordion>
                                <template v-slot:header>
                                    {{  en === true ? 'Your Event Rosters' : 'Votre Liste D’Événement' }}
                                </template>
                                <template v-slot:content>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <b-menu
                                                :accordion="false"
                                            >
                                                <b-menu-list label="Event Rosters">
                                                    <b-menu-item
                                                        expanded>
                                                        <template
                                                            slot="label" slot-scope="props">
                                                            <b>Your Event Rosters</b>
                                                        </template>
                                                        <b-menu-item
                                                            :label="formatRosterName(roster.name) + ' *' +  moment(roster.created_at).format('YYYY-MM-DD') + '*'"
                                                            v-for="(roster, key) in team.event_rosters"
                                                            :key="key"
                                                            v-on:click="event_roster_menu = roster">
                                                        </b-menu-item>
                                                    </b-menu-item>
                                                </b-menu-list>
                                            </b-menu>
                                        </div>
                                        <div class="col-md-9">
                                            <div v-if="event_roster_menu">
                                                    <div v-if="event_roster_menu !== 'create'">
                                                        <h3>{{ event_roster_menu.name }} Event Roster</h3>
                                                        <h5><b>Current Team Rank Based on Roster:</b> {{ event_roster_menu.roster_score }}</h5>
                                                        <b-table
                                                            :data="event_roster_menu.roster"
                                                            :striped="true"
                                                            :focusable="true"
                                                            paginated
                                                            per-page="20"
                                                        >
                                                        
                                                            <b-table-column
                                                                field="first_name"
                                                                label="First Name"
                                                                :searchable="true"
                                                            >
                                                                <template slot="header" slot-scope="{ column }">
                                                                    <b-tooltip :label="column.label" dashed>
                                                                        {{ column.label }}
                                                                    </b-tooltip>
                                                                </template>
                                                                <template slot="searchable" slot-scope="props">
                                                                    <b-input
                                                                        v-model="props.filters[props.column.field]"
                                                                        icon="magnify"
                                                                        placeholder="Search"
                                                                        size="is-small"
                                                                    ></b-input>
                                                                </template>
                                                                <template v-slot="props">
                                                                    {{ props.row.first_name }}
                                                                </template>
                                                            </b-table-column>

                                                            <b-table-column
                                                                field="last_name"
                                                                label="Last Name"
                                                                :searchable="true"
                                                            >

                                                                <template slot="header" slot-scope="{ column }">
                                                                    <b-tooltip :label="column.label" dashed>
                                                                        {{ column.label }}
                                                                    </b-tooltip>
                                                                </template>
                                                                <template slot="searchable" slot-scope="props">
                                                                    <b-input
                                                                        v-model="props.filters[props.column.field]"
                                                                        icon="magnify"
                                                                        placeholder="Search"
                                                                        size="is-small"
                                                                    ></b-input>
                                                                </template>
                                                                <template v-slot="props">
                                                                    <b>{{ props.row.last_name }}</b>
                                                                </template>
                                                            </b-table-column>
                                                            <b-table-column
                                                                field="level"
                                                                label="Level"
                                                                :searchable="true"
                                                            >
                                                                <template slot="searchable" slot-scope="props">
                                                                    <b-input
                                                                        v-model="props.filters[props.column.field]"
                                                                        icon="magnify"
                                                                        placeholder="Search"
                                                                        size="is-small"
                                                                    ></b-input>
                                                                </template>
                                                                <template v-slot="props">
                                                                    <b-tag v-if="props.row.current_rank_id === 'A'" type="is-success">
                                                                        A
                                                                    </b-tag>
                                                                    <b-tag v-if="props.row.current_rank_id === 'B'" type="is-danger">
                                                                        B
                                                                    </b-tag>
                                                                    <b-tag v-if="props.row.current_rank_id === 'C'" type="is-warning">
                                                                        C
                                                                    </b-tag>
                                                                    <b-tag v-if="props.row.current_rank_id === 'D'" type="is-info">
                                                                        D
                                                                    </b-tag>
                                                                    <b-tag v-if="props.row.current_rank_id === 'E'" type="is-link" style="color: #fff;">
                                                                        E
                                                                    </b-tag>
                                                                </template>
                                                            </b-table-column>


                                                            <b-table-column field="gender" label="Gender">
                                                                <template slot="header" slot-scope="{ column }">
                                                                    <b-tooltip :label="column.label" dashed>
                                                                        {{ column.label }}
                                                                    </b-tooltip>
                                                                </template>
                                                                <template v-slot="props">
                                                                    <span class="tag is-light">
                                                                        <b>{{ props.row.gender }}</b>
                                                                    </span>
                                                                </template>
                                                            </b-table-column>
                                                            <b-table-column field="date_of_birth" label="Date of Birth">
                                                                <template slot="header" slot-scope="{ column }">
                                                                    <b-tooltip :label="column.label" dashed>
                                                                        {{ column.label }}
                                                                    </b-tooltip>
                                                                </template>
                                                                <template v-slot="props">
                                                                    <span class="tag is-light">
                                                                        <b>{{ moment(props.row.date_of_birth).utc().format('YYYY-MM-DD') }}</b>
                                                                    </span>
                                                                </template>
                                                            </b-table-column>
                                                            <b-table-column
                                                                field="positions"
                                                                label="Positions"
                                                                :searchable="true"
                                                            >
                                                                <template slot="header" slot-scope="{ column }">
                                                                    <b-tooltip :label="column.label" dashed>
                                                                        {{ column.label }}
                                                                    </b-tooltip>
                                                                </template>
                                                                <template slot="searchable" slot-scope="props">
                                                                    <b-input
                                                                        v-model="props.filters[props.column.field]"
                                                                        icon="magnify"
                                                                        placeholder="Search"
                                                                        size="is-small"
                                                                    ></b-input>
                                                                </template>
                                                                <template v-slot="props">
                                                                    <span v-if="props.row.positions">
                                                                        <span class="tag is-light" :key="key" v-for="(position, key) in JSON.parse(props.row.positions)">
                                                                            {{ position }}
                                                                        </span>
                                                                    </span>
                                                                </template>
                                                            </b-table-column>
                                          
                                                        </b-table>
                                                    </div>
                                                </div>

                                        </div>
                                        
                                    </div>
                                </template>
                            </Accordion>
                            <Accordion>
                                <template v-slot:header>
                                    {{  en === true ? 'Edit Your Team' : 'Modifier Votre Équipe' }}
                                </template>
                                <template v-slot:content>
                                    <div class="row">
                                        <div class="col-md-4">
                                            
                                            <b-field>
                                                <img src="" alt="">
                                                <b-upload
                                                    v-model="teamLogo"
                                                    expanded
                                                    drag-drop>
                                                    <section class="section">
                                                        <div v-if="team.logo">
                                                            <img :src="team.logo" width="100%" alt="">
                                                            <p style="text-align: center; font-size: 12px; font-weight: 700;">Upload your logo here! Then click update team.</p>
                                                        </div>
                                                        <div class="content has-text-centered" v-if="team.logo === null">
                                                            <p>
                                                                <b-icon
                                                                    icon="upload"
                                                                    size="is-large">
                                                                </b-icon>
                                                            </p>
                                                            <p style="text-align: center; font-size: 12px; font-weight: 700;">Upload your logo here! Then click update team.</p>
                                                        </div>
                                                    </section>
                                                </b-upload>
                                            </b-field>
                                        </div>
                                        <div class="col-md-8"  style="padding-left: 20px;">
                                            <b-field label="Team Name">
                                                <b-tooltip type="is-dark" label="Choose something creative!">
                                                    <b-input v-model="team.name"></b-input>
                                                </b-tooltip>
                                            </b-field>

                                            <b-field label="Primary City">
                                                
                                                <b-input v-model="team.city"></b-input>
                                                
                                            </b-field>

                                            <label for="" class="standard-input">
                                                <span>Team's Primary Colour</span>
                                                <v-swatches
                                                v-model="team.color_1"
                                                swatches="text-advanced"></v-swatches>
                                            </label>

                                            <p class="note">
                                                <span>Please note:</span> your team will automatically be moved to the appropriate division based on the players you've added to your roster, and the tournaments and leagues you select to enter.
                                            </p>

                                            <a v-on:click="updateTeam()" class="admin-button">
                                                <i class="far fa-save"></i>&nbsp;Update Team
                                            </a>
                                        </div>
                                    </div>
                                </template>
                            </Accordion>
                            <Accordion>
                                <template v-slot:header>
                                    {{  en === true ? 'SPN Registration Numbers' : 'Numéros D’enregistrements SPN' }}
                                </template>
                                <template v-slot:content>
                                    <span v-if="team">
                                        <SPNRegistrationNumbers :show_revoke="false" :team_slug="team.slug"></SPNRegistrationNumbers>
                                    </span>
                                </template>
                            </Accordion>
                            <Accordion>
                                <template v-slot:header>
                                    {{  en === true ? 'Archive Your Team' : 'Archiver votre Équipe' }}
                                </template>
                                <template v-slot:content>
                                    <p>Archive your team if you no longer use it or it's outdated. </p>
                                    <p>Do not archive your team if it's a team you're still on.</p>
                                    <p><b>Click this only when you're sure!</b></p>
                                    <b-field label="Type CONFIRMDELETE to prove you're not a robot">
                                        <b-input v-model="confirmdelete"></b-input>
                                    </b-field>
                                    <a v-on:click="archiveTeam()" class="button">Archive Your Team</a>
                                </template>
                            </Accordion>

                            <!-- <b-tabs multiline>
                                <b-tab-item :label="(en === true ? 'Player Pool' : 'Votre Liste Complète')">
                                    
                                </b-tab-item>
                                <b-tab-item :label="(en === true ? 'Your Event Rosters' : 'Votre Liste D’Événement')" v-if="myRole">
                                    
                                </b-tab-item>
                                <b-tab-item :label="(en === true ? 'Edit Your Team' : 'Modifier Votre Équipe')" v-if="myRole">
                                    
                                </b-tab-item>
                                <b-tab-item :label="(en === true ? 'SPN Registration #s' : 'Numéros D’enregistrements SPN')" v-if="myRole">
                                    
                                </b-tab-item>
                                <b-tab-item :label="(en === true ? 'Archive Your Team' : 'Archiver votre Équipe')" v-if="myRole">
                                    
                                </b-tab-item>

                            </b-tabs> -->
                        </div>
                    </div>
                    
             
                <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/2022 Bases Instructions.png" width="100%" alt="">


			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import _ from 'lodash'
import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'
import SPNRegistrationNumbers from '../../SPNRegistrationNumbers.vue'
import NewPaymentProcessor from '../../components/NewPaymentProcessor.vue'
import RankComponent from '../../components/RankComponent.vue'
import AddPlayerToTeamModal from '../../components/AddPlayerToTeamModal.vue'
export default {
	data() {
		return {
            addPlayerModal: false,
            playersSelected: [],
            page: null,
            user: null,
            logo: null,
            searchName: null,
            allRegionSearch: false,
            timer: null,
            completedPurchase: false,
            selectedInsuranceOption: null,
            region_rate: null,
            content_width: false,
            teamLogo: null,
            newTeam: {
                name: null,
                last_year_team_name: null,
                primary_location: null
            },
            newEventRoster: {
                name: null,
                roster: [],
            },
            en: true,
            isLoading: false,
            error: null,
            confirmdelete: null,
            searchList: [],
            selected_players_for_event_roster: [],
            event_roster_menu: null,
            team: { color: '#000' },
            roster: null,
            cutoff: 2,
            products: [],
            classes: {
                A: 1,
                B: 2,
                C: 3,
                D: 4,
                E: 5
            },
            existingInsurance: null,
            columnTemplate: [
				{ title: 'First Name', label: 'First Name', field: 'first_name', visible: true, searchable: false, width: 200 },
                { title: 'Last Name', label: 'Last Name', field: 'last_name', visible: true, searchable: false, 
                width: 200 },
                { title: 'Gender', label: 'Gender', field: 'gender', visible: true, searchable: false, width: 200 },
                { title: 'Rank', label: 'Rank', field: 'current_rank_id', visible: true, searchable: false, width: 200 },
                { title: 'DOB', label: 'DOB', field: 'date_of_birth', visible: true, searchable: false, width: 200 },   
			]
		};
	},
	components: {
		Header,
		Footer,
        NewPaymentProcessor,
        SPNRegistrationNumbers,
        RankComponent,
        AddPlayerToTeamModal
    },
    watch: {
        teamLogo: function() {
            var vm = this

            const form = new FormData()
            form.append('logo', vm.teamLogo)

            axios.post('/api/teams/upload-photo', form, { headers: { 'Content-Type': 'multipart/form-data' } }).then(results => {
                if(results.data.success === true) {
                    vm.team.logo = results.data.data.logo_path
                }
            })
        }
    },
    computed: {
        myRole: function() {
            var vm = this
            if (vm.team.name) {
                if(vm.team.yourself.role_on_team === 3) {
                    return false
                } else {
                    return true
                }
            }
            return false
        }
    },
	mounted: function() {
        // grab all the dashboard information now
        this.page = this.$route.params.slug
        var vm = this

        if(window.innerWidth < 800) {
            vm.content_width = true
		}
        
        if(sessionStorage.getItem('lang')) {      
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        axios.get('/auth/check').then(results => {
            if(results.data.type === "success") {
                var user = results.data.user
                vm.user = user

                axios.post(`/api/teams/${vm.page}`, {
                    user: user
                }).then(results => {
                    if(results.data.success === true) {
                        vm.team = results.data.data.team
                    }
                })
            }
        })
	},
	methods: {
        updateTeamAPI: function() {
            var vm = this
            axios.post(`/api/teams/${vm.team.slug}`, {
                    user: vm.user.id
                }).then(results => {
                    if(results.data.success === true) {
                        vm.team = results.data.data.team
                        

                        this.$buefy.snackbar.open({
                            message: 'Updated your team data',
                            type: 'is-success', // is-danger
                            duration: 4000,
                            queue: false
                        })  
                    }
            })
        },
        changePlayerRole: function(player, role) {
            var vm = this
            
            axios.post('/api/teams/update-role', {
                role: role,
                player_id: player.core_roster_id,
            }).then(results => {
                if(results.data.success === true) {
                    vm.fireTeamUpdate().then(results => {
                        if(results.data.success === true) {
                            vm.team = results.data.data.team

                            this.$buefy.snackbar.open({
                                message: 'You have updated the role of a player on your team.',
                                type: 'is-success' // is-danger
                            })
                        } else {
                            this.$buefy.snackbar.open({
                                message: 'Updating the role of a player did not work. #001',
                                type: 'is-danger' // is-danger
                            })
                        }
                    })
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Updating the role of a player did not work. #002',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        addPlayersToRoster: function(data) {
			this.addToRoster(data.playersSelected)
		},
        addToRoster: function(players) {
            var vm = this

            axios.post('/api/teams/add-to-roster', {
                roster_players: players,
                team_id: vm.team.id
            }).then(results => {
                
                if(results.data.success === true) {
                    vm.updateRoster()
                    vm.playersSelected = []
                    vm.addPlayerModal = false

                    this.$buefy.snackbar.open({
                        message: 'You have successfully added player(s) to your team.',
                        type: 'is-success' // is-danger
                    })
                } else {
                    vm.error = results.data.error
                    this.$buefy.snackbar.open({
                        message: 'Adding players to your team did not work.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        updateRoster: function() {
            var vm = this
            axios.post(`/api/teams/${vm.team.slug}`, {
                user: vm.user
            }).then(results => {
                if(results.data.success === true) {
                    vm.team = results.data.data.team
                }
            })
        },
        updateTeam: function() {
            var vm = this

            const form = new FormData()
            form.append('id', vm.team.id)
            form.append('name', vm.team.name)
            form.append('city', vm.team.city)
            form.append('color_1', vm.team.color_1)
            form.append('logo', vm.team.logo)

            axios.post(`/api/teams/${vm.page}/update-team`, form, { headers: { 'Content-Type': 'multipart/form-data' } }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Updated your team data',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })  
                }
            })
        },
        moment: function(date, format = null) {
            return moment(date, format)
        },
        fireTeamUpdate: function() {
            var vm = this
            return axios.post(`/api/teams/${vm.page}`, {
                user: vm.user
            })
        },
        switchWidth: function($event) {
			this.content_width = !$event
        },
        removeFromTeam: function(user) {
            var vm = this
            
            var r = confirm('Are you sure you want to kick this player off your roster?')
            if(r === true) {
                axios.post('/api/teams/remove-player', {
                    team_id: vm.team.id,
                    user_id: user.core_roster_id
                }).then(results => {
                    if(results.data.success === true) {
                        
                        vm.updateTeamAPI()

                        this.$buefy.snackbar.open({
                            message: 'Player removed from roster',
                            type: 'is-success' // is-danger
                        })
                    } else {
                        this.$buefy.snackbar.open({
                            message: 'Did not remove player from roster successfully.',
                            type: 'is-success' // is-danger
                        })
                    }
                })
            }
        },
        changeToPrimaryCoach: function(player) {
			var vm = this
            
            var r = confirm('Are you sure you want to set a new primary coach?')
            if(r === true) {
                axios.post('/api/teams/set-primary-coach', {
                    team_id: vm.eventTeam.team.id,
                    user_id: player.user_id,
					player_id: player.core_roster_player.id,
                }).then(results => {
                    if(results.data.success === true) {
                        
                        this.$buefy.snackbar.open({
                            message: 'New player set as primary coach',
                            type: 'is-success' // is-danger
                        })
                    }
                })
            }
		},
        archiveTeam: function() {
            var vm = this

            if(vm.confirmdelete === 'CONFIRMDELETE') {
                axios.post('/api/teams/archive-team', {
                    team_id: vm.team.id,
                }).then(results => {
                    if(results.data.success === true) {
                        this.$buefy.snackbar.open({
                            message: 'Your team has been archived. You will be redirected momentarily.',
                            type: 'is-success' // is-danger
                        })

                        this.$router.push({ path: '/dashboard/players/my-teams' })
                    }
                })
            } else {
                this.$buefy.snackbar.open({
                    message: 'You did not type CONFIRMDELETE correctly.',
                    type: 'is-danger' // is-danger
                })
            }
        },
        sendWaiverReminder: function(user) {
            axios.post('/api/users/send-waiver-reminder', {
                user: user
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Waiver reminder has been sent!',
                        type: 'is-success' // is-danger
                    })
                }
            })
        },
        updateCoachOnly: function($event, user) {
            axios.post('/api/users/update-coach-only', {
                core_roster_id: user.core_roster_id,
                result: $event 
            }).then(results => {
                if(results.data.success === true) {
                    this.$buefy.snackbar.open({
                        message: 'Updated this coach.',
                        type: 'is-success' // is-danger
                    })
                } else {
                    this.$buefy.snackbar.open({
                        message: 'Something went wrong.',
                        type: 'is-danger' // is-danger
                    })
                }
            })
        },
        formatRosterName: function(name) {
            return name.split('- 2')[0] || name
        },
        rowClass(row) {
            
            return this.user && row.user_id === this.user.id ? 'highlight-row' : '';
        },
	}
}
</script>

<style lang="scss">

</style>
