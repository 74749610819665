<template>
	<div>
		<b-modal
			v-model="markPaidSwitch"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal>
			<template #default="props">
				<div class="modal-card">
					<header class="modal-card-head">
						<p class="modal-card-title">Mark Payment Details</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<p><b>They owe:</b> {{ markPaidItem.amount_due }}</p>
						<hr>
						<span v-if="markPaidItem.amount_due > 0">
							<b-field label="Confirm Payment Type">
								<b-select placeholder="Payment Type" v-model="paymentTypeSelected">
									<option :value="'card'">Via Stripe</option>
									<option :value="'emt'">EMT</option>
									<option :value="'cheque'">Cheque</option>
								</b-select>
							</b-field>
							<b-field label="Stripe Invoice ID" v-if="paymentTypeSelected === 'card'">
								<b-input v-model="savePayment.stripe_invoice_id"></b-input>
							</b-field>
							<b-field label="E-Transfer Reference #" v-if="paymentTypeSelected === 'emt'">
								<b-input v-model="savePayment.emt_ref_num"></b-input>
							</b-field>
							<b-field label="Cheque Reference #" v-if="paymentTypeSelected === 'cheque'">
								<b-input v-model="savePayment.cheque_ref_num"></b-input>
							</b-field>
							<b-field label="Amount Paid">
								<b-input v-model="savePayment.amount_paid"></b-input>
							</b-field>
						</span>
						<a v-on:click="savePaymentDetails()" class="admin-button">Save Payment Details</a>
					</section>
				</div>
			</template>
		</b-modal>
		<b-loading :is-full-page="true" v-model="isLoading"></b-loading>
		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
			<div class="content-wrapper">
				<div :class="['page-header', { sidebarClosed: !content_width }]">
					<div class="row">
						<div class="col-md-3">
							<div class="page-header-title">
								Manage Orders 
							</div>
						</div>
						<div class="col-md-9">
							<div class="padding">
							
							</div>
						</div>
					</div>
				</div>
				
				<div class="admin-area" v-if="orders">
                    <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
						<div
							slot="trigger" 
							slot-scope="props"
							class="card-header"
							role="button"
							aria-controls="contentIdForA11y3">
							<p class="card-header-title">
								Search & Control
							</p>
							<a class="card-header-icon">
								<b-icon
									:icon="props.open ? 'menu-down' : 'menu-up'">
								</b-icon>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
			
								<b-field grouped group-multiline >
									<b-field label="Region" v-if="specific_region === false">
										<b-select v-model="searchables.region">
											<option value="*">All</option>
											<option :value="1">Alberta</option>
											<option :value="2">British Columbia</option>
											<option :value="3">Manitoba</option>
											<option :value="4">Ontario</option>
											<option :value="5">Maritimes</option>
											<option :value="6">Quebec</option>
											<option :value="7">Newfoundland & Labrador</option>
											<option :value="8">Saskatchewan</option>
										</b-select>
									</b-field>
									<b-field label="Number of Results">
										<b-select v-model="searchables.limit">
											<option :value="100">100</option>
											<option :value="500">500</option>
											<option :value="1000">1000</option>
											<option :value="2500">2500</option>
											<option :value="5000">5000</option>
										</b-select>
									</b-field>
									<b-field label="Per Page">
										<b-select v-model="perPage">
											<option value="10">10 per page</option>
											<option value="25">25 per page</option>
											<option value="50">50 per page</option>
											<option value="100">100 per page</option>
										</b-select>
									</b-field>
									<b-field label="Show Deleted">
										<b-switch v-model="searchables.deleted_at"></b-switch>
									</b-field>
								</b-field>
								<b-field grouped group-multiline style="margin-top: 30px;" >
									
									<b-field label="ID" style="width: 60px;">
										<b-input v-model="searchables.id"></b-input>
									</b-field>
									<b-field label="Receipt ID" style="width: 150px;">
										<b-input v-model="searchables.receipt_id"></b-input>
									</b-field>
									<b-field label="Payment" style="width: 150px;">
										<b-select v-model="searchables.payment">
											<option value="*">All</option>
											<option :value="1">Received</option>
											<option :value="0">Awaiting</option>
											<option :value="-1">Cancelled</option>
											<option :value="-2">Refunded</option>
										</b-select>
									</b-field>
									<b-field label="Type">
										<b-select v-model="searchables.type">
											<option value="*">All</option>
											<option :value="'card'">Via Stripe</option>
											<option :value="'emt'">Via EMT</option>
											<option :value="'cheque'">Via Cheque</option>
										</b-select>
									</b-field>
									<b-field label="First Name">
										<b-input v-model="searchables.user_first_name"></b-input>
									</b-field>
									<b-field label="Last Name">
										<b-input v-model="searchables.user_last_name"></b-input>
									</b-field>
									<b-field label="Email">
										<b-input v-model="searchables.user_email"></b-input>
									</b-field>
									<b-field label="Phone">
										<b-input v-model="searchables.user_phone"></b-input>
									</b-field>
							
									<b-field label="Product">
										<b-select v-model="searchables.product">
											<option value="*">All</option>
											<option :value="1">League Application Fee</option>
											<option :value="2">Non-Qualifier Tournament</option>
											<option :value="3">Qualifier Tournament</option>
											<option :value="4">Fully Insured League Team</option>
											<option :value="5">Uninsured League Team</option>
											<option :value="6">Fully Insured Tournament Team</option>
											<option :value="7">Uninsured Individual Team</option>
											<option :value="8">Play One Time</option>
											<option :value="9">Umpire Registration Fee</option>
											<option :value="10">Upgrade to Fully Insured Tournament Team</option>
											<option :value="11">Upgrade to Fully Uninsured Tournament Team</option>
											<option :value="12">League Tournament</option>
											<option :value="13">SPN Tournament Registration</option>
										</b-select>
									</b-field>
									<b-field label="Sort By">
										<b-select v-model="searchables.sortBy">
											<option value="date_invoiced">Date Invoiced</option>
											<option value="date_paid">Date Paid</option>
											
										</b-select>
									</b-field>
								</b-field>
								<hr>
								<div class="content">
									
								</div>
							</div>
						</div>
					</b-collapse>
                    <b-table
                        	:data="orders"
							detailed
							:narrowed="true"
							:paginated="true"
							:per-page="perPage"
							:pagination-simple="false"
							:pagination-position="'bottom'"
							detail-key="id"
							@details-open="(row, index) => grabInvoiceInfo(row)"
                        > 
						<template>
							<b-table-column label="Date Invoiced">
								<template v-slot="props">
									{{ moment(props.row.created_at).format('YYYY-MM-DD') }}
								</template>
							</b-table-column>
							<b-table-column label="Date Paid">
								<template v-slot="props">
									<span v-if="props.row.date_paid">
										{{ moment(props.row.date_paid).format('YYYY-MM-DD') }}
									</span>
									<span v-else>
										<span v-if="props.row.complete === 1">
											<b-tag>
												Paid but old system
											</b-tag>
										</span>
									</span>
									
								</template>
							</b-table-column>
							<b-table-column label="Payment">
								<template v-slot="props">
									<span v-if="props.row.complete === 1">
										<b-tag type="is-success">
											Received
										</b-tag>
									</span>
									<span v-else-if="props.row.complete === 0 || props.row.complete === null">
										<b-tag type="is-warning">
											Awaiting
										</b-tag>
									</span>
									<span v-else-if="props.row.complete === -1">
										<b-tag type="is-danger">
											Cancelled
										</b-tag>
									</span>
									<span v-else-if="props.row.complete === -2">
										<b-tag type="is-info">
											Refunded
										</b-tag>
									</span>
								</template>
							</b-table-column> 
							<b-table-column label="Type">
								<template v-slot="props">
									<span v-if="props.row.payment_type === 'card'">
										<b-tag type="is-success">
											Via Stripe
										</b-tag>
									</span>
									<span v-else-if="props.row.payment_type === 'emt'">
										<b-tag type="is-info">
											Via EMT
										</b-tag>
									</span>
									<span v-else-if="props.row.payment_type === 'cheque'">
										<b-tag type="is-danger">
											Via Cheque
										</b-tag>
									</span>
									<span v-else>
										<b-tag type="is-warning">
											NO CHARGE
										</b-tag>
									</span>
								</template>
							</b-table-column> 
							<b-table-column label="Region">
								<template v-slot="props">
									{{ props.row.region.short_name }}
								</template>
							</b-table-column> 
							
							<b-table-column label="First Name">
								<template v-slot="props">
									{{ props.row.user.first_name }}
								</template>
							</b-table-column>
							<b-table-column label="Last Name">
								<template v-slot="props">
									{{ props.row.user.last_name }}
								</template>
							</b-table-column>
								<b-table-column label="Email">
								<template v-slot="props">
									{{ props.row.user.email }}
								</template>
							</b-table-column>
							
							
							<b-table-column label="Products">
								<template v-slot="props">
								
									<b-tag type="is-warning">
										<b>{{ props.row.order_item.product.name }}</b>
									</b-tag>
									<span v-if="props.row.tournament">
										<b-tag type="is-warning">
											{{ props.row.tournament.name }}
										</b-tag>
										<b-tag type="is-warning">
											#{{ props.row.tournament.id }}
										</b-tag>
									</span>
									
									<span v-if="props.row.order_item.product.id === 6 || props.row.order_item.product.id === 7 || props.row.order_item.product.id === 8">
										<span  :key="member.id" v-for="member in props.row.membership">
											<b-tag type="is-warning">
												{{ member.team.name }} - #{{ member.team.id }}
											</b-tag>
										</span>
									</span>

									<span v-if="props.row.order_item.product.id === 4 || props.row.order_item.product.id === 5">
										<b-tag type="is-warning" v-if="props.row.membership[0]">
											{{ props.row.membership[0].league.name }} - #{{props.row.membership[0].league.id }}
										</b-tag>
									</span>
								</template>
							</b-table-column>
						
							<b-table-column label="Total">
								<template v-slot="props">
									{{ props.row.amount_due.toFixed(2) }}
								</template>
							</b-table-column>
						</template>
						<template slot="detail" slot-scope="props">
							<div class="row">
								<div class="col-md-4">
									<b-tag>
										<b>Receipt ID: {{ props.row.receipt_id }}</b>
									</b-tag>
									<p><b>Email: </b>{{ props.row.user.email }}</p>
									<p><b>Phone: </b>{{ props.row.user.phone }}</p>
									<a class="button is-danger" v-on:click="markPaidSwitch = true; markPaidItem = props.row" v-if="props.row.complete === null">Mark Paid</a>
									<br>
									<a class="button is-success" v-if="props.row.complete === 1" v-on:click="markRefunded(props.row)">Refund</a>
									
									<a v-on:click="markCancelled(props.row)" class="button is-warning">Mark Cancelled</a>
									<a v-on:click="markAwaiting(props.row)" class="button is-warning">Mark Awaiting</a>
									<br>
									<a :href="'/superadmin/manage/users/' + props.row.user.id" class="button is-info">View User</a>	
									
									<p class="note" v-if="props.row.complete === null">This only marks it in the SPN system. Please go to stripe to complete card purchases or refunds.</p>							
								</div>
								<div class="col-md-4" >
									<span v-if="invoice">
										<a :href="'https://dashboard.stripe.com/invoices/' + invoice.id" class="button is-primary">View Invoice</a>
										<a :href="invoice.hosted_invoice_url" class="button is-secondary">View Invoice In Stripe</a>
										<a :href="invoice.invoice_pdf" class="button is-warning">View Invoice PDF</a>
									</span>
									
									<div v-if="props.row.tournament">	<b>Tournament: </b> {{ props.row.tournament.id }} - {{ props.row.tournament.name }} <a :href="'/superadmin/manage/tournaments/' + props.row.tournament.id" class="button">View Tournament</a></div>
								</div>
								<div class="col-md-4">
									
									<div v-if="props.row.membership.length > 0">
										<div >
											<table class="table">
												<tr>
													<td>
														<b>Team #</b>
													</td>
													<td>
														<b>Team Name</b>
													</td>
													<td></td>
													<td></td>
												</tr>
												<tr :key="member.id" v-for="member in props.row.membership">
													<td>
														{{ member.team.id }}
													</td>
													<td>
														{{ member.team.name }}
													</td>
													<td>
														<a :href="'/superadmin/manage/teams/' + member.team.slug" class="button is-small">Manage Team</a>
													</td>
													<td v-if="member.league_id">
														<a :href="'/superadmin/manage/leagues/' + member.league_id" class="button is-small">View League</a>	
													</td>
												</tr>
											</table>
										</div>
										
									</div>
								</div>
								<div class="col-md-4">
									<div class="padding">
										<p><b>Change Receipt User</b></p>
										<p>Superadmins: use this to update what user should be on the order if you processed the payment for the customer.</p>

										<b-input v-model="userOrderChangeID" placeholder="Enter ID# Only"></b-input>
										<button v-on:click="updateOrderId(props.row)" class="button is-success">Update User</button>
									</div>
								</div>
							</div>
						</template>
                    </b-table>

				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
export default {
	data() {
		return {
			userOrderChangeID: null,
			markPaidSwitch: false,
			isLoading: true,
			authUser: { first_name: null },
			questions: [],
			umpires: [],
			checkedRows: [],
			specific_region: false,
			markPaidItem: null,
			paymentTypeSelected: null,
			approvalType: 'awaiting',
			content_width: false,
			tabs: 'umpires',
			selectedUmpire: null,
			approveUmpireSwitch: false,
			invoice: null,
			perPage: 50,
			user_permissions: null,
			searchables: {
                id: null,
                user_first_name: null,
                user_last_name: null,
                user_email: null,
				user_phone: null,
				receipt_id: null,
                region: '*',
				payment: '*',
				type: '*',
				product: '*',
                deleted_at: false,
                limit: 100,
				sortBy: 'date_invoiced'
            },
			savePayment: {
				stripe_invoice_id: null,
				emt_ref_num: null,
				cheque_ref_num: null,
				amount_paid: null
			},
			approvalTypes: [{
				slug: 'awaiting',
				name: 'Awaiting Approval',
			}, {
				slug: 'approved',
				name: 'Approved',
			}],
			newQuestion: {
				subject: null,
				fr_subject: null,
				text: null,
				fr_text: null,
				correct_answer: true,
				required: false
            },
            orders: null,
			editor: ClassicEditor,
            editorData: '<p>Rich-text editor content.</p>',
            editorConfig: {
                // The configuration of the rich-text editor.
            },
		};
	},
	components: {
		Header,
		Footer,
	},
	created() {
        this.debouncedCheckingThis = _.debounce(this.updateOrderList, 1000);
    },
	watch: {
		tabs: function() {
			if(this.tabs !== this.$route.params.slug) {
				this.$router.replace({ path: '/superadmin/manage/umpires/' + this.tabs })
			}
		},
		searchables: {
            handler(val) {  
                this.debouncedCheckingThis()
            }, deep: true
        }
    },
	mounted: function() {
		// grab all the dashboard information now
		var vm = this

		if(this.$route.query.id) {
			this.searchables.id = this.$route.query.id
		}

		if(this.$route.params.slug === 'exam') {
			vm.tabs = 'exam'	
		}

		if(window.innerWidth < 800) {
            vm.content_width = true
		}
		
		axios.get('/auth/check').then(results => {  
			
            if(results.data.type === "success") {
                vm.authUser = results.data.user
				vm.user_permissions = results.data.user_permissions
                if(results.data.user_permissions.manage_umpires !== 1) {
                    this.$router.push({ name: 'Dashboard' })
                }
				vm.searchables.specific_region = vm.user_permissions.specific_region_only
                vm.isLoading = false
				axios.get('/api/superuser/orders', {
                    params: vm.searchables
                }).then(results2 => {
					if(results2.data.success === true) {

						var orders = results2.data.orders
						vm.orders = results2.data.orders
						
						if(vm.user_permissions) {
							if(vm.user_permissions.specific_region_only) {
								// vm.orders = _.filter(orders, function(order) {
								// 	if(order.region_id === vm.user_permissions.specific_region_only) {
								// 		return true
								// 	}
								// 	return false
								// })
								// vm.specific_region = true
							}
						}
					}
				})
			}
        })
	},
	computed: {
		computedQuestionGroups: function() {
			var vm = this
			var groupBy = _.groupBy(vm.questions, 'subject')
			
			return groupBy
		}
	},
	methods: {
		updateOrderList: function() {
			var vm = this
			vm.orders = []
			axios.get('/api/superuser/orders', {
				params: vm.searchables
			}).then(results2 => {
				if(results2.data.success === true) {
					var orders = results2.data.orders
					
					vm.orders = results2.data.orders
					
					if(vm.user_permissions) {
						if(vm.user_permissions.specific_region_only) {
							vm.orders = _.filter(orders, function(order) {
								if(order.region_id === vm.user_permissions.specific_region_only) {
									return true
								}
								return false
							})
						}
					}
				}
			})
		},
		moment:function(date, format = null) {
			return moment(date, format)
		},
		saveNewQuestion: function() {
			var vm = this
			axios.post('/api/superuser/save-umpire-question', {
				question: vm.newQuestion
			}).then(results => {
				if(results.data.success === true) {
					
					vm.questions = results.data.questions
				}
			})
		},
		editUmpire: function(umpire) {
			var vm = this
			vm.approveUmpireSwitch = true
			
			vm.selectedUmpire = Object.assign({}, umpire)
		},
		removeQuestion: function(question_id) {
			var vm = this
			var r = confirm('Are you sure you want to delete this question?')

			if(r === true) {
				axios.post('/api/superuser/remove-umpire-question', {
					question_id: question_id
				}).then(results => {
					if(results.data.success === true) {
						vm.questions = results.data.questions
					}
				})
			}
		},
		switchWidth: function($event) {
			this.content_width = !$event
		},
		grabInvoiceInfo: function(row) {
			var vm = this
			if(row.payment_type === 'card') {
				axios.post('/api/superuser/grab-stripe-details', {
					info: row.id
				}).then(results => {
					if(results.data.success === true) {
						vm.invoice = results.data.invoice
					} else {

					}
				})
			}
		},
		savePaymentDetails: function() {
			var vm = this

			delete vm.markPaidItem.membership
			axios.post('/api/superuser/update-payment-details', {
				payment_details: vm.savePayment,
				paymentTypeSelected: vm.paymentTypeSelected,
				item: vm.markPaidItem
			}).then(results=> {
				if(results.data.success === true) {
					 this.$buefy.snackbar.open({
						message: 'Payment information updated',
						type: 'is-success' // is-danger
					})
					vm.updateOrderList()
					vm.markPaidSwitch = false

				} else {

				}
			})
		},
		markRefunded: function(order) {
			var vm = this

			var r = confirm("Are you sure you are refunding their order? REMINDER: you must do this in Stripe as well")

			if(r === true) {
				axios.post('/api/superuser/mark-order-refunded', {
					order_id: order.id
				}).then(results => {
					if(results.data.success === true) {
						vm.updateOrderList()
						this.$buefy.snackbar.open({
							message: 'Order has been successfully cancelled',
							type: 'is-success' // is-danger
						})
					} else {
						this.$buefy.snackbar.open({
							message: 'Order could not be cancelled',
							type: 'is-danger' // is-danger
						})
					}
				})
			}
		},
		updateOrderId: function(order) {
			var vm = this
			
			axios.post("/api/superuser/update-order-user", {
				user_id: vm.userOrderChangeID,
				order_id: order.id
			}).then(results => {
				if(results.data.success === true) {
					this.$buefy.snackbar.open({
						message: 'Order has had user updated, refresh this page to see changes',
						type: 'is-success' // is-danger
					})
				}
			})
		},
		markCancelled: function(order) {
			var vm = this
			
			var r = confirm("Are you sure you want to cancel their order?")

			if(r === true) {
				axios.post('/api/superuser/mark-order-cancelled', {
					order_id: order.id
				}).then(results => {
					if(results.data.success === true) {
						vm.updateOrderList()
						this.$buefy.snackbar.open({
							message: 'Order has been successfully cancelled',
							type: 'is-success' // is-danger
						})
					} else {
						this.$buefy.snackbar.open({
							message: 'Order could not be cancelled',
							type: 'is-danger' // is-danger
						})
					}
				})
			}
		},
		markAwaiting: function(order) {
			var vm = this
			
			var r = confirm("This will set their order back to awaitng payment. Are you sure?")

			if(r === true) {
				axios.post('/api/superuser/mark-order-awaiting', {
					order_id: order.id
				}).then(results => {
					if(results.data.success === true) {
						vm.updateOrderList()
						this.$buefy.snackbar.open({
							message: 'Order has been successfully cancelled',
							type: 'is-success' // is-danger
						})
					} else {
						this.$buefy.snackbar.open({
							message: 'Order could not be cancelled',
							type: 'is-danger' // is-danger
						})
					}
				})
			}
		}
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
