<template>
    <div>
        <div class="custom-event-area">
            <div class="upload-poster" v-if="posterUploadAbility">
                <b-upload
                    v-model="posterUpload"
                    expanded
                    drag-drop>
                
                    <div v-if="event.poster_image_url">
                       
                        <img :src="event.poster_image_url" v-if="event.poster_image_url" alt="">
                        <p style="text-align: center; font-size: 12px; font-weight: 700;">Upload your poster here! </p>
                    </div>
                    <div class="content has-text-centered" v-if="event.poster_image_url === null">
                         <span v-if="event.status === 'provincials'">
                            <img src="https://slo-pitch.com/uploads/facebook/provincials.png" v-if="!event.poster_image_url" alt="">
                        </span>
                        <span v-else>
                            <img src="https://slo-pitch.com/uploads/facebook/sanctionedtournament.png" v-if="!event.poster_image_url" alt="">
                        </span>
                        <p>
                            <b-icon
                                icon="upload"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p style="text-align: center; font-size: 12px; font-weight: 700;">Above is your current poster. Click anywhere in this box to upload your poster here!</p>
                    </div>
                    
                </b-upload>
            </div>
            <div  class="image-poster" v-if="posterUploadAbility === false">
                <span v-if="event.status === 'provincials'">
                    <img src="https://slo-pitch.com/uploads/facebook/provincials.png" v-if="!event.poster_image_url" alt="">
                </span>
                <span v-else>
                    <img src="https://slo-pitch.com/uploads/facebook/sanctionedtournament.png" v-if="!event.poster_image_url" alt="">
                </span>
                <img :src="event.poster_image_url" v-if="event.poster_image_url" alt="">
            </div>
            <div v-if="bottomHalf">
                <div class="custom-event-box">
                    <div class="custom-tournament-name">
                        {{ event.name }}
                    </div>
                    <div class="starts-on">
                        <b>{{ moment(event.event_start_date).format('MMMM D') }} - {{ moment(event.event_end_date).format('MMMM D')}}</b>
                    </div>
                    <div>
                        <pre :class="['custom-tournament-description', { viewmore: fullDescript === true }]" v-html="event.description">
                        
                        </pre>
                        <div style="text-align: center;">
                            <a class="custom-tournament-view-more button is-small" v-on:click="fullDescript = !fullDescript">
                                <span v-if="fullDescript === false">View Full Description</span>
                                <span v-if="fullDescript === true">View Less</span>
                            </a>
                        </div>
                        <p style="text-align: center;">City: {{ event.city }}</p>
                    </div>
                    
                    <div class="custom-tournament-divisions">
                        <div v-if="event.divisions.length > 0">
                        
                            <div class="row" v-if="filterDivision(event.divisions, 'mens').length > 0">
                                <div class="col-md-3">
                                    <div class="custom-tournament-division-header">
                                        Mens
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <b-tag
                                        type="is-danger"
                                        :key="key"
                                        v-for="(division, key) in filterDivision(event.divisions, 'mens')">
                                        {{ division.rank_id }}
                                    </b-tag>
                                </div>
                            </div>
                            <div class="row margin-top" v-if="filterDivision(event.divisions, 'ladies').length > 0">
                                <div class="col-md-3">
                                    <div class="custom-tournament-division-header">
                                        Ladies
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <b-tag
                                        type="is-danger"
                                        :key="key"
                                        v-for="(division, key) in filterDivision(event.divisions, 'ladies')">
                                        {{ division.rank_id }}
                                    </b-tag>
                                </div>
                            </div>
                            <div class="row margin-top" v-if="filterDivision(event.divisions, 'coed').length > 0">
                                <div class="col-md-3">
                                    <div class="custom-tournament-division-header">
                                        Coed
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <b-tag
                                        type="is-danger"
                                        :key="key"
                                        v-for="(division, key) in filterDivision(event.divisions, 'coed')">
                                        {{ division.rank_id }}
                                    </b-tag>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-tournament-entry-fees">
                        <div class="entry-fee-text">
                            Entry Fee:
                        </div>
                        <div class="entry-fee-team" v-if="event.team_registration_fee">
                            <i class="fas fa-users"></i> ${{ event.team_registration_fee.toFixed(2) }}
                        </div>
                        <div class="entry-fee-team" v-if="event.player_registration_fee">
                            <i class="fas fa-user"></i> ${{ event.player_registration_fee.toFixed(2) }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="advertiseIt">
                <a style="width: 100%; text-align: center;" :href="'/'+ eventType +'/' + event.slug" class="admin-button">
                    View Event Details
                    <span>Registration ends {{ moment(event.registration_end_date).format('MMMM D') }}!</span>
                </a>

            </div>            
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
	data() {
		return {
            en: true,
            posterUpload: null,
            fullDescript: false
		};
	},
	components: {

	},
    watch: {
        posterUpload: function() {
			var vm = this

            const form = new FormData()
            form.append('logo', vm.posterUpload)

            axios.post('/api/teams/upload-photo', form, { headers: { 'Content-Type': 'multipart/form-data' } }).then(results => {
                if(results.data.success === true) {
					vm.event.poster_image_url = results.data.data.logo_path

                    // check here
                    if(vm.eventType === 'league') {
                        axios.post('/api/leagues/save-photo', {
                            league: vm.event.id,
                            poster_image_url: results.data.data.logo_path
                        }).then(results => {
                            if(results.data.success === true) {
                                this.$buefy.snackbar.open({
                                    message: 'Uploaded your new poster!',
                                    type: 'is-success' // is-danger
                                })	
                            }
                        })
                    }
                    if(vm.eventType === 'tournament') {
                        axios.post('/api/tournaments/save-photo', {
                            tournament: vm.event.id,
                            poster_image_url: results.data.data.logo_path
                        }).then(results => {
                            if(results.data.success === true) {
                                this.$buefy.snackbar.open({
                                    message: 'Uploaded your new poster!',
                                    type: 'is-success' // is-danger
                                })	
                            }
                        })
                    }
                }
			})
		}
    },
	mounted: function() {
        var vm = this
        
    },
    props: ['posterUploadAbility','eventType','event','advertiseIt','bottomHalf'],
	computed: {
	
	},
	methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        },
	}
}
</script>