<template>
    <span>
        <div class="form-component" v-if="form">
            <a v-if="form.form_url" :href="form.form_url" style="float: right;" class="button is-info">Download the PDF Version</a>
            <div class="form-component-title">
                {{ en === true ? form.name : form.fr_name }}
            </div>
            <div class="form-component-questions">
                <div style="text-align: center; padding: 10px;" v-if="form.fr_name">
                    <a :class="['lang', { selected: en === true }]" v-on:click="selectLang(true)">EN</a>|<a :class="['lang', { selected: en === false }]" v-on:click="selectLang(false)">FR</a>
                </div>
                <div :class="['form-component-question', { horizontal: form.direction === 'horizontal' } ]" v-if="form.region_id < 0">
                    <b-field label="Select Your Region *">
                        <b-select v-model="selectedRegion">
                            <option value="1">Alberta & NWT</option>
                            <option value="2">British Columbia</option>
                            <option value="3">Manitoba</option>
                            <option value="4">Ontario</option>
                            <option value="5">Maritimes</option>
                            <option value="6">Quebec</option>
                            <option value="7">Newfoundland & Labrador</option>
                            <option value="8">Saskatchewan</option>
                        </b-select>
                    </b-field>
                    {{ selectedRegionsError }}
                </div>
                <div :class="['form-component-question', { componentHeader: question.type_id === 'section_break' }, { horizontal: form.direction === 'horizontal' }]" v-for="(question, key) in form.questions" :key="key"> 
                    <div v-if="question.type_id === 'section_break'">
                        <h2 style="margin-top: 0px; margin-bottom: 0px;">{{ (en === true ? question.label : question.fr_label) }}</h2>
                        <p>{{ (en === true ? question.description : question.fr_description) }}</p>
                    </div>
                    <div v-if="question.type_id === 'signature_pad'">
                        <b-field 
                            :type="{ 'is-danger': question.error !== null }"
                            :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')" :message="[question.error]"></b-field>
                        <FormSignature
                            v-on:signature:sent="updateSignature($event, question)">
                        </FormSignature>
                        
                    </div>
                    <div v-if="question.type_id === 'short_text'">
                        <b-field 
                            :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                            :type="{ 'is-danger': question.error !== null }"
                            :message="[question.description, question.error]"
                        >
                            <b-input 
                                :placeholder="question.placeholder"
                                v-model="question.value"
                            ></b-input>
                        </b-field>

                    </div>
                    <div v-if="question.type_id === 'long_text'">
                        <b-field 
                            :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                            :type="{ 'is-danger': question.error !== null }"
                            :message="[question.description, question.error]"
                        >
                            <b-input 
                                :placeholder="question.placeholder"
                                v-model="question.value"
                                type="textarea"
                                minlength="10"
                            ></b-input>
                        </b-field>
                    </div>
                    <div v-if="question.type_id === 'true_or_false'">
                        <b-field 
                            :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                            :type="{ 'is-danger': question.error !== null }"
                            :message="[question.description, question.error]"
                        >
                            <b-radio
                                size="is-large"
                                v-model="question.value"
                                :native-value="true">
                                {{ en === true ? 'True' : 'Vrai '}}
                            </b-radio>
                            <b-radio
                                size="is-large"
                                v-model="question.value"
                                :native-value="false">
                                {{ en === true ? 'False' : 'Faux '}}
                            </b-radio>
                        </b-field>
                    </div>
                    <div v-if="question.type_id === 'select'">
                        <b-field 
                            :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                            :type="{ 'is-danger': question.error !== null }"
                            :message="[question.description, question.error]"
                        >
                            <b-select 
                                v-model="question.value"
                                :placeholder="question.placeholder"> 
                                <option 
                                    :key="key3" 
                                    v-for="(option, key3) in question.options"
                                    :value="option.name">
                                    {{ option.name }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                    <div v-if="question.type_id === 'checkbox'">
                        <b-field 
                            :label="''"
                            :type="{ 'is-danger': question.error !== null }"
                            :message="[question.description, question.error]"
                        >
                            <div class="field">
                                <b-checkbox
                                    v-model="question.value"
                                    size="is-large">
                                    {{ question.label }}
                                </b-checkbox>
                            </div>
                            
                        </b-field>
                    </div>
                    <div v-if="question.type_id === 'date'">
                        <b-field 
                            :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                            :type="{ 'is-danger': question.error !== null }"
                            :message="[question.description, question.error]"
                        >
                            <b-datepicker
                                v-model="question.value"
                                :placeholder="question.placeholder"
                                icon="calendar-today"
                                trap-focus>
                            </b-datepicker>
                        </b-field>
                    </div>
                    <div v-if="question.type_id === 'time'">
                        <b-field 
                            :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                            :type="{ 'is-danger': question.error !== null }"
                            :message="[question.description, question.error]"
                        >
                            <b-timepicker
                                v-model="question.value"
                                placeholder="Click to select..."
                                icon="clock"
                                :hour-format="'12'">
                            </b-timepicker>
                        </b-field>
                    </div>
                    <div v-if="question.type_id === 'first_name'">
                        <b-field 
                            :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                            :type="{ 'is-danger': question.error !== null }"
                            :message="[question.description, question.error]"
                        >
                            <b-input 
                                :placeholder="question.placeholder"
                                v-model="question.value"
                            ></b-input>
                        </b-field>
                    </div>
                    <div v-if="question.type_id === 'last_name'">
                        <b-field 
                            :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                            :type="{ 'is-danger': question.error !== null }"
                            :message="[question.description, question.error]"
                        >
                            <b-input 
                                :placeholder="question.placeholder"
                                v-model="question.value"
                            ></b-input>
                        </b-field>
                    </div>
                    <div v-if="question.type_id === 'email'">
                        <b-field 
                            :label="(en === true ? question.label : question.fr_label) + (question.required === 1 ? '*' : '')"
                            :type="{ 'is-danger': question.error !== null }"
                            :message="[question.description, question.error]"
                        >
                            <b-input 
                                :placeholder="question.placeholder"
                                v-model="question.value"
                            ></b-input>
                        </b-field>
                    </div>
                    <div v-if="question.type_id === 'hidden'">
                        
                    </div>
                </div>
            </div>
            <button class="admin-button" v-on:click="submitForm()" v-if="formSubmitted === false"><i class="fas fa-save"></i>Submit This Form</button>
            <button class="admin-button" v-if="formSubmitted === true"><i class="fas fa-save"></i>Submit This Form</button>
        </div>
    </span>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import FormSignature from './FormSignature.vue'
export default {
    data() {
        return {
            data: null,
            form: null,
            user: null,
            en: true,
            selectedRegion: null,
            selectedRegionsError: null,
            formSubmitted: false,
        };
    },
    components: {
        FormSignature
    },
    computed: {
        
    },
    props: ['form-id'],
    mounted: function() {
        if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        var vm = this

        axios.get('/auth/check').then(results => {
            if(results.data.type === "success") {
                var user = results.data.user
                vm.user = user
            }
        })

        axios.get('/api/forms/' + vm.formId).then(results => {
            if(results.data.success === true) {
                
                if(results.data.form) {
                    vm.form = vm.prepareForm(results.data.form)
                }
            } else {

            }
        })
    },
    methods: {
        prepareForm: function(form) {
            var vm = this
            var i = 0

            // unstringify all the options
            for(i; i < form.questions.length; i++) {
                form.questions[i].options = JSON.parse(form.questions[i].options)

                // if auth check is successful, autofill first name last name email
                if(vm.user) {
                    if(form.questions[i].type_id === 'first_name') {
                        form.questions[i].value = vm.user.first_name
                    }
                    if(form.questions[i].type_id === 'last_name') {
                        form.questions[i].value = vm.user.last_name
                    }
                    if(form.questions[i].type_id === 'email') {
                        form.questions[i].value = vm.user.email
                    }
                }
            }

            // create the required validation for the form

            return form
        },
        consolidateForm: function(form) {
            var the_form = {
                id: form.id,
                questions: []
            }

            var i = 0

            for(i; i < form.questions.length; i++) {
                var question = form.questions[i]
                if(question.type_id !== 'section_break') {
                    the_form.questions.push({
                        id: question.id,
                        type_id: question.type_id,
                        value: question.value
                    })
                }
            }

            return the_form
        },
        submitForm: function() {
            var vm = this
            
            // run through each form question before submitting
            var i = 0

            var errors = false
            
            if(vm.selectedRegion === null) {
                errors = true
                vm.selectedRegionsError = 'You need to fill this in!'
            }
            for(i; i < vm.form.questions.length; i++) {
                var question = vm.form.questions[i]
                vm.form.questions[i].error = null
                
                if(question.required === 1) {
                    if(question.value === null) {
                        vm.form.questions[i].error = "This is a required field."
                        errors = true
                    }
                }
            }
            
            var the_form = this.consolidateForm(vm.form)

            if(errors === false) {
                this.formSubmitted = true
                axios.post('/api/forms/submit-form-results', {
                    form_slug: vm.formId,
                    region: vm.selectedRegion,
                    form: the_form
                }).then(results => {

                    if(results.data.success === true) {
                        this.$buefy.snackbar.open({
                            message: 'Form is successfully submitted',
                            type: 'is-success' // is-danger
                        })

                        axios.get('/api/forms/' + vm.formId).then(results => {
                            if(results.data.success === true) {
                                
                                if(results.data.form) {
                                    vm.form = vm.prepareForm(results.data.form)

                                    this.formSubmitted = false
                                }
                            } else {

                            }
                        })
                    } else {
                        this.$buefy.snackbar.open({
                            message: 'Form did not submit properly',
                            type: 'is-danger' // is-danger
                        })
                    }
                }).catch(err => {
                    this.$buefy.snackbar.open({
                        message: 'Form did not submit properly',
                        type: 'is-danger' // is-danger
                    })
                })
            } else {
                this.$buefy.snackbar.open({
                    message: 'We could not submit this form because it is missing required fields.',
                    type: 'is-danger' // is-danger
                })
            }

            // check where vm.form.path_to_form_save goes
            // validate the response

            // send the axios and await success

        },
        selectLang: function(lang) {
            this.en = lang

            sessionStorage.setItem('lang', lang)
        },
        updateSignature: function($event, question) {
            question.value = $event.data
            // console.log(key, question)
            // console.log(this.$refs.signaturePad)
            // console.log(this.$refs.signaturePad[0].toData())
            // const { isEmpty, data } = this.$refs.signaturePad[0].saveSignature()
            // console.log(isEmpty)
            // console.log(data)
            
        }
    }
}
</script>

<style lang="scss">
    
</style>