<template>
	<div>
		<b-modal
			v-model="changeTeamSwitch" :width="600">
			<template #default="props">
				<div class="modal-card" style="width: 600px;">
					<header class="modal-card-head">
						<p class="modal-card-title">Change Team Name</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						
						<b-field label="Change Team Name">
							<b-input v-model="changeTeamInfo.team.name"></b-input>
						</b-field>
						<p><b>Reminder:</b> this will change the team name for the coach as well - please make sure you let them know you did this.</p>
						<a class="admin-button" v-on:click="submitTeamNameChange()">Change Team Name</a>
					</section>
				</div>
			</template>
		</b-modal>
		<b-modal
			v-model="changeDivisionSwitch" :width="600">
			<template #default="props">
				<div class="modal-card" style="width: 600px;">
					<header class="modal-card-head">
						<p class="modal-card-title">Change Divisions</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<b-field label="Enter a Division" vertical style="margin-top: 20px;">
							<b-select placeholder="Select Gender" v-model="changeDivisionDetails.gender">
								<option value="mens" v-if="totalDivisions.mens.length > 0">Mens</option>
								<option value="ladies" v-if="totalDivisions.ladies.length > 0">Ladies</option>
								<option value="coed" v-if="totalDivisions.coed.length > 0">Coed</option>
							</b-select>
							
							<b-select style="margin-left: 10px;" placeholder="Select Division" v-model="changeDivisionDetails.division">
								<option :value="division" :key="division.id" v-for="division in totalDivisions[changeDivisionDetails.gender]">{{ division.rank_id }}</option>
							</b-select>
						</b-field>
						<p class="note">Note: team changing gendered division will change their league insurance.</p>
						<div>
							<a v-on:click="moveTeamToNewDivision()" style="margin-top: 10px;" class="admin-button">Change Division</a>
						</div>
						
					</section>
				</div>
			</template>
		</b-modal>
		<b-modal
			v-model="addNewDivisionSwitch" :width="600">
			<template #default="props">
				<div class="modal-card" style="width: 600px;">
					<header class="modal-card-head">
						<p class="modal-card-title">Add New Division</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<b-field label="Enter a Division" vertical style="margin-top: 20px;">
							<b-select placeholder="Select Gender" v-model="addDivisionDetails.gender">
								<option value="mens" >Mens</option>
								<option value="ladies">Ladies</option>
								<option value="coed">Coed</option>
							</b-select>
							
							<b-select style="margin-left: 10px;" placeholder="Select Division" v-model="addDivisionDetails.rank_id">
								<option :value="division" :key="division.id" v-for="division in divisions">{{ division.name }}</option>
							</b-select>
						</b-field>
						<b-field label="Min Age" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.min_age"></b-numberinput>
						</b-field>
						<b-field label="Max Age" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.max_age"></b-numberinput>
						</b-field>
						<b-field label="Min # of Teams" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.min_number_of_teams"></b-numberinput>
						</b-field>
						<b-field label="Max # of Teams" style="margin-top: 20px;">
							<b-numberinput type="is-danger" v-model="addDivisionDetails.max_number_of_teams"></b-numberinput>
						</b-field>

						<p class="note">Note: team changing gendered division will change their league insurance.</p>
						<div>
							<a v-on:click="addNewDivision()" style="margin-top: 10px;" class="admin-button">Add Division</a>
						</div>
						
					</section>
				</div>
			</template>
		</b-modal>
		<b-modal
			v-model="removeTeamModal">
			<template #default="props">
				<div class="modal-card">
					<header class="modal-card-head">
						<p class="modal-card-title">Remove Team From Event</p>
						<button
						type="button"
						class="delete"
						@click="props.close"/>
					</header>
					<section class="modal-card-body">
						<!-- <p class="note">Revoke insurance when the team requires a full refund and will not be playing in the league, period. You will still be on the hook to pay if you do not revoke their insurance.</p> -->
						 <!-- <b-select 
							v-model="revokeInsurance">
							<option :value="true">
								Revoke Insurance 
							</option>
							<option :value="false">
								DO NOT Revoke Insurance
							</option>
						</b-select> -->
						<a v-on:click="removeTeam()" style="margin-top: 10px;" class="admin-button">Remove Team From Event</a>
					</section>
				</div>
			</template>
		</b-modal>
		<b-modal
			v-model="addTeamToEventSwitch" :width="900">
			<template #default="props">
				<div class="modal-card" style="width: 100%;">
					<header class="modal-card-head">
						<p class="modal-card-title">Find Team</p>
                        <button
                            type="button"
                            class="delete"
                            @click="props.close"/>
					</header>
					<section class="modal-card-body">
						<div v-if="selectedTeam === null">
							<b-field label="Search for team" style="width: 100%;">
								<b-input v-model="searchForTeam" placeholder="Start typing..." v-on:input="searchForTeamFunction($event)"></b-input>
							</b-field>

							<b-field>
								<b-checkbox v-on:input="searchForTeamFunction()" v-model="selectRegion">Include out-of-region teams</b-checkbox>
							</b-field>

							<table class="table">
								<tr>
									<td></td>
									<td><b>Season</b></td>
									<td><b>Team Name</b></td>
									<td><b>Division</b></td>
									<td><b>Coach Name</b></td>
									<td><b>City</b></td>
									<td><b>Region</b></td>
									
								</tr>
								<tr :key="team.id" v-for="team in searchResults">
									<td>
										<a class="button is-small" v-on:click="selectedTeam = team; registerForTournament.team = team">Add</a>
									</td>
									<td>
										<b-tag>{{ team.season }}</b-tag>
										<span v-for="insurance of team.insurance" :key="insurance.id">
											<b-tag type="is-primary" v-if="insurance.fully_paid === 1">{{ insurance.gender.toUpperCase() }} {{ insurance.season }} PAID</b-tag>
											<b-tag type="is-danger" v-if="insurance.fully_paid !== 1">UNPAID</b-tag>
										</span>
									</td>
									<td>
										{{ team.name }}
									</td>
									<td style="text-transform: uppercase;">
										<b-tag>{{ team.division_id }}</b-tag>
									</td>
									<td>
										{{ team.primary_coach.first_name }} {{ team.primary_coach.last_name }}
									</td>
									<td>
										{{ team.city }}
									</td>
									<td>
										{{ team.region.name }}
									</td>
									
								</tr>
							</table>
						</div>
						<div v-else>
							<a v-on:click="selectedTeam = null" class="button is-small">&lt; Select Different Team</a>
							
							<b-field label="Enter a Division" vertical style="margin-top: 20px;">
								<b-select placeholder="Select Gender" v-model="registerForTournament.gender">
									<option value="mens" v-if="totalDivisions.mens.length > 0">Mens</option>
									<option value="ladies" v-if="totalDivisions.ladies.length > 0">Ladies</option>
									<option value="coed" v-if="totalDivisions.coed.length > 0">Coed</option>
								</b-select>
								
								<b-select style="margin-left: 10px;" placeholder="Select Division" v-model="registerForTournament.division">
									<option :value="division" :key="division.id" v-for="division in totalDivisions[registerForTournament.gender]">{{ division.rank_id }}</option>
								</b-select>
							</b-field>
						
							<p class="note">You will be billed for adding this team and be responsible for collecting their insurance fees.</p>
							<a v-on:click="registerTeamToEvent()" style="margin-top: 10px;" class="admin-button">Register To The League</a>
						</div>
					</section>
				</div>
			</template>
		</b-modal>

		<Header v-on:switch:navigation="switchWidth($event)"></Header>
		<div :class="['main-dashboard-area content-area', { sidebarClosed: content_width }]" >
            <div class="content-wrapper" v-if="league">
                <div :class="['page-header', { sidebarClosed: !content_width }]">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="page-header-title">
                                Manage {{ league.name }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="padding">
                                <b-field  grouped position="is-right">
                                    <a href="/dashboard/convenors/my-leagues" class="button">Back to Convenor/League Central</a>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="admin-area">
					<div class="row">
						<div class="col-md-6">
							<div  style="padding-bottom: 20px;"> 
								<b-tag type="is-large is-success" v-if="league.status === 'sanctioned'"><b>Current Status:</b> Approved</b-tag>
								<b-tag type="is-large is-warning" v-if="league.status === 'unapproved'"><b>Current Status:</b> Awaiting</b-tag>
								<b-tag type="is-large is-danger" v-if="league.status === 'rejected'"><b>Current Status:</b> Rejected</b-tag>

								<p v-if="league.status === 'unapproved'">The Current Status of your league will remain as “Awaiting” until SPN has received your league payment in full.</p>
							</div>
							
							<b-field label="Copy and share this link to advertise your event!" v-if="league.status === 'approved'">
								<b-input :value="'https://slo-pitch.com/tournament/' + league.slug"></b-input>
							</b-field>
							
						</div>
						<div class="col-md-6">
							<a class="admin-button" v-if="!editDisabled" style="float: right;" v-on:click="addTeamToEvent()"><i class="fas fa-plus"></i>Add Team to Your League</a>
						</div>
					</div>
						
					<div class="row" style="margin-top: 20px;">

						<div class="col-md-12">
							<div class="padding" style="padding-top: 0px;">
								<b-tabs multiline>
									<b-tab-item label="Teams">	
										<b-tabs multiline v-model="currentTab">
											<b-tab-item label="Registered Teams" value="Registered Teams">
												<div>
													<div>
														<p><b>Mass Features</b></p>
													</div>
													<vue-excel-xlsx
														:data="computedSelectedRegisteredTeams"
														:columns="columnTemplate"
														:filename="'filename'"
														:sheetname="'sheetname'"
														:class="'button is-success'"
														>
														<i class="fas fa-file-export"></i>&nbsp;&nbsp; Export to Excel ({{selectedRegisteredTeams.length}})
													</vue-excel-xlsx>
													<a class="button" v-on:click="sendWaiversToLeague()">Send Waiver Reminder to Selected Teams ({{selectedRegisteredTeams.length}})</a>
												
												</div>
												<a class="button is-small" style="margin-top: 20px;" v-on:click="selectedRegisteredTeams = registered_teams">Select All Teams</a>
												<a class="button is-small is-danger" style="margin-top: 20px;" v-on:click="selectedRegisteredTeams = []" v-if="selectedRegisteredTeams.length > 0">Clear Selected Teams</a>
												<b-table
													:data="registered_teams"
													ref="table"
													checkable
													detailed

													:show-detail-icon="true"
													detail-key="id"
            										:checked-rows.sync="selectedRegisteredTeams"
												>
													<b-table-column
														field="team.name"
														:sortable="true"
														:searchable="true"
														label="Name">
														<template v-slot="props">
															<div class="color-strip" :style="'border-top: ' + props.row.team.color_1 + ' 5px solid;'">
																<img width="30px" style="float: left;" :src="props.row.team.logo" alt="">
																<b style="float: left; margin-left: 10px;">{{ props.row.team.name }}</b>
															</div>
															
														</template>
													</b-table-column>
													<b-table-column
														field="team.primary_coach.first_name"
														:sortable="true"
														label="Coach">
														<template v-slot="props">
															{{ props.row.team.primary_coach.first_name }}
															{{ props.row.team.primary_coach.last_name }}
														</template>
													</b-table-column>
													<b-table-column
														field=""
														label="# of Players">
														<template v-slot="props">
															{{ props.row.roster.roster_players.length }}
														</template>
													</b-table-column>
													<b-table-column
														field="league_gender_id"
														:sortable="true"
														:searchable="true"
														width="100"
														label="Gender">
														<template v-slot="props">
															<span v-if="props.row.league_gender_id">
																{{ props.row.league_gender_id.charAt(0).toUpperCase() + props.row.league_gender_id.slice(1) }}
															</span>
															
														</template>
														
													</b-table-column>
													<b-table-column
														field="division.rank_id"
														:sortable="true"
														:searchable="true"
														width="100"
														label="Rank">
														<template v-slot="props">
															<span v-if="props.row.division">
																{{ props.row.division.rank_id }}
															</span>
														</template>
													</b-table-column>
													<b-table-column
														field="division.rank_id"
														label="Registered">
														<template v-slot="props">
															<span v-if="props.row.insurance">
																<b-tag type="is-success" v-if="props.row.insurance.with_insurance === 1">Membership + Insurance</b-tag>
																<b-tag type="is-danger" v-else-if="props.row.insurance.single_event === 1">One-Time Fee</b-tag>
																<b-tag type="is-primary" v-else>Membership Only</b-tag>
																<b-tag type="is-primary" v-if="props.row.insurance.fully_paid === 1">PAID</b-tag>
																<b-tag type="is-danger" v-if="props.row.insurance.fully_paid !== 1">UNPAID</b-tag>
															</span>
														</template>
													</b-table-column>
													
													<!-- <b-table-column
														field="id"
														label="Actions">
														<template  v-slot="props">
															<a @click="props.toggleDetails(props.row)" class="button">View</a>
														</template>
													</b-table-column> -->
													
													<template #detail="props">
													
													
														<div class="row">
															<div class="col-md-3">
																<h3 style="margin-top: 0px;"><b>{{ props.row.team.name }}</b></h3>
																<p><b>Coach:</b> {{ props.row.team.primary_coach.first_name }} {{ props.row.team.primary_coach.last_name }}</p>
																<p><b>Team ID: </b> {{ props.row.team.id }}</p>
																<p><b>Event Team ID: </b> {{ props.row.id }}</p>
															</div>
															<div class="col-md-9">
																<div>
																	<b>Actions</b>
																</div>
																<div>
																	<a v-on:click="changeTeamSwitch = true; changeTeamInfo = props.row" class="button is-small is-info">Change Team Name</a>
																	<a v-on:click="changeDivisionFunction(props.row)"  class="button is-small is-warning" v-if="!editDisabled">
																		Change Division
																	</a>
																	<a :href="'/dashboard/convenors/leagues/' + league.slug + '/event-roster/' + props.row.id " class="button is-small is-info">View Event Roster</a>
																	<a v-on:click="removeTeamModal = true; removeTeamSelected = props.row" class="button is-small is-danger" v-if="!editDisabled">
																		Remove Team
																	</a>
																</div>
															</div>
														</div>
													
													</template>
												</b-table>
											</b-tab-item>
											<b-tab-item label="Make League Payment"  v-if="computedForPayments.length > 0">
												<div class="row">
													<div class="col-md-4">
														<p><b>Total Teams Registered:</b> {{ registered_teams.length }}</p>
														<p><b>Total Teams Paid For:</b> {{ computePaid }}</p>
														<hr>
														<p><b>Total Selected For Checkout: </b> {{ selectedLeaguesForPayment.length }}</p>
														<a v-if="selectedLeaguesForPayment.length > 0" class="admin-button" v-on:click="payForTeams = true" style="font-size: 14px;">Pay for Selected Teams ({{ selectedLeaguesForPayment.length }})</a>
													</div>
													<div class="col-md-8">
														<div v-if="payForTeams" style="margin-bottom: 20px;">
															<NewPaymentProcessor
																:productId="computedProduct"
																:regionId="league.region_id"
																:quantityOverride="selectedLeaguesForPayment.length"
																:teams="formatForProcessing(selectedLeaguesForPayment)"
																v-on:payment:successful="paymentSuccessSwitch($event)"
																>
															</NewPaymentProcessor>
															
														</div>
													</div>
												</div>
												<div v-if="computePaid === registered_teams.length">
													<div class="payment-complete">
														<div class="icon-row">
															<i class="far fa-check-circle"></i>
														</div>
														<div class="complete-text">
															<p>You have successfully paid for all teams!</p>
														</div>
													</div>
												</div>
												<b-table
													:data="computedForPayments"
													v-if="computePaid < registered_teams.length"
													ref="table"
													checkable
													:is-row-checkable="(row) => row.league_gender_id !== null"
													:checked-rows.sync="selectedLeaguesForPayment"
												>
													<b-table-column field="color" label="Color">
														<template v-slot="props">
															
															<div class="color-box" :style="'background: ' + props.row.team.color_1">
																
															</div>
														</template>
													</b-table-column>
													<b-table-column
														field="team.name"
														:sortable="true"
														:searchable="true"
														label="Name">
														<template v-slot="props">
															{{ props.row.team.name }}
														</template>
													</b-table-column>
													<b-table-column
														field="team.name"
														:sortable="true"
														label="Coach">
														<template v-slot="props">
															{{ props.row.team.primary_coach.first_name }}
															{{ props.row.team.primary_coach.last_name }}
														</template>
													</b-table-column>
													<b-table-column
														field="team.name"
														:sortable="true"
														label="Division">
														<template v-slot="props">
															<span v-if="props.row.league_gender_id">
																{{ props.row.league_gender_id.toUpperCase() }}
															</span>
															<span v-else>
																Add this team to a division before payment on the registered teams page.
															</span>
														</template>
													</b-table-column>
												</b-table>
											</b-tab-item>
											<b-tab-item label="Create League Teams" v-if="!editDisabled">
												<b-message type="is-danger" has-icon aria-close-label="Close message">
													<b>This feature is <u>not recommended to be relied on,</u> and is only there for the rare situations that coaches are making things more difficult than they need to be. <br><br>
													More than anything, we want to encourage our convenors to make their coaches and players prepare their teams, and for convenors to only add currently existing teams.
													<br><br>
													With that being said, this feature is available to let you create teams and users that may not exist.
													<br><br>
													Please make sure that emails are 100% accurate. We do not want multiple accounts per user. Typos will cause a new account to be created, leaving a headache for the player to deal with.</b>
												</b-message>
												<a class="admin-button" style="background: #333;" v-on:click="createTeamSwitch = !createTeamSwitch">Yes, I understand, and need to create a team</a>
												<div class="create-a-team" style="margin-top: 30px;" v-if="createTeamSwitch">
													<div class="row" v-for="(team, key) in createTeams" :key="key" >
														<div class="col-md-2">
															<b-field label="Team Name">
																<b-input v-model="team.team_name"></b-input>
															</b-field>
														</div>
														<div class="col-md-2">
															<b-field label="Team Gender">
																<b-select v-model="team.team_gender_id">
																	<option value="mens" v-if="totalDivisions.mens.length > 0">Mens</option>
																	<option value="ladies" v-if="totalDivisions.ladies.length > 0">Ladies</option>
																	<option value="coed" v-if="totalDivisions.coed.length > 0">Coed</option>
																</b-select>
															</b-field>
														</div>
														<div class="col-md-2">
															<b-field label="Division Entered">
																<b-select v-model="team.league_division_id">
																	<option :value="division.id" :key="division.id" v-for="division in totalDivisions[team.team_gender_id]">{{ division.rank_id }}</option>
																</b-select>
															</b-field>
														</div>
														<div class="col-md-2">
															<b-field label="Coach First Name">
																<b-input v-model="team.coach_first_name"></b-input>
															</b-field>
														</div>
														<div class="col-md-2">
															<b-field label="Coach Last Name">
																<b-input v-model="team.coach_last_name"></b-input>
															</b-field>
														</div>
														<div class="col-md-2">
															<b-field label="Coach Email">
																<b-input v-model="team.coach_email"></b-input>
															</b-field>
														</div>
													</div>
													<a class="button is-danger" v-if="createTeams.length < 11" v-on:click="addCreatedTeam()" style="margin-top: 10px;">+ Create Another Team</a>
													<div v-if="createTeams.length > 10">You can only add 10 teams at a time.</div>
													<a class="button is-success" v-on:click="submitCreatedTeams()" style="margin-top: 10px;">Submit Created Teams</a>
												</div>
											</b-tab-item>
											<b-tab-item label="Import Previous League Teams" 
											:value="'Import Previous League Teams'"
											v-if="previous_registered_teams.length > 0">
												<b-message type="is-danger" has-icon aria-close-label="Close message">
													Please only import 10-15 teams at a time!
												</b-message>
												<a class="admin-button" v-if="addTeamsToNewListSwitch === false" v-on:click="addTeamsToNewLeague()">Add {{ selectedPreviousTeams.length }} Teams to {{ moment().format('YYYY') }}</a>
												<a class="admin-button" v-if="addTeamsToNewListSwitch === true">Processing Please Wait</a>
												<div class="row">
													<div class="col-md-3">
														<div style="margin-top: 10px;">
															<p><b>Already Transferred:</b></p>
															<p :key="team.id" v-for="team in computedImportPreviousTeams.already_transferred">
																{{ team.team.name }}
															</p>
														</div>
													</div>
													<div class="col-md-9">
														<b-table
															:header-checkable="false"
															style="margin-top: 30px;"
															:data="computedImportPreviousTeams.previous"
															ref="table"
															checkable
															:checked-rows.sync="selectedPreviousTeams"
														>
															<b-table-column field="color" label="Color" :sortable="true">
																<template v-slot="props">
																	<div class="color-box" :style="'background: ' + props.row.team.color_1">
																		
																	</div>
																</template>
															</b-table-column>
															<b-table-column
																:sortable="true"
																:searchable="true"
																field="team.name"
																label="Name">
																<template v-slot="props">
																	{{ props.row.team.name }}
																</template>
															</b-table-column>
															<b-table-column
																:sortable="true"
																field="primary_coach.first_name"
																label="Coach Name">
																<template v-slot="props">
																	
																	<span v-if="props.row.team.primary_coach">
																		{{ props.row.team.primary_coach.first_name }} {{ props.row.team.primary_coach.last_name }}
																	</span>
																</template>
															</b-table-column>
															<b-table-column
																label="Gender">
																<template v-slot="props">
																	<b-select v-model="props.row.league_gender_id">
																		<option value="mens" v-if="totalDivisions.mens.length > 0">Mens</option>
																		<option value="ladies" v-if="totalDivisions.ladies.length > 0">Ladies</option>
																		<option value="coed" v-if="totalDivisions.coed.length > 0">Coed</option>
																	</b-select>
																	
																</template>
															</b-table-column>
															<b-table-column
																label="Division">
																<template v-slot="props">
																	<b-select v-model="props.row.league_division_id">
																		<option :value="division" :key="division.id" v-for="division in totalDivisions[props.row.league_gender_id]">{{ division.rank_id }}</option>
																	</b-select>
																</template>
															</b-table-column>
															<b-table-column
																label="Roll Rosters?">
																<template v-slot="props">
																	<b-checkbox v-model="props.row.roll_rosters"></b-checkbox>
																</template>
															</b-table-column>
														</b-table>
													</div>
												</div>
												

												
											</b-tab-item>
										</b-tabs>	
										
										
									</b-tab-item>
									<b-tab-item label="Executive Access">
										<b-message type="is-danger" has-icon aria-close-label="Close message">
											This tab will allow you to create permissions for unlimited numbers of members to access your league as a "convenor".
											<br>
											They will only receive limited convenor access. They will not be able to create leagues or events. Please get them to apply with the National Office for full convenor access if they need more control.
										</b-message>
										
										<h3>Executive Access</h3>
										
										<table class="table" v-if="executive_access_players">
											<tr>
												<td><b>User</b></td>
												<td><b>Role</b></td>
												<td><b>Action</b></td>
											</tr>
											<tr :key="player.id" v-for="player in executive_access_players">
												<td>{{ player.user.first_name }} {{ player.user.last_name }}</td>
												<td>{{ player.role }}</td>
												<td v-if="myRole.role === 'President' || myRole.role === 'Secretary'">
													<a v-on:click="makePrimaryConvenor(player)" class="button is-small is-info">Make Primary Convenor</a>
													<a v-on:click="removeTheirAccess(player)" class="button is-danger is-small" v-if="!editDisabled">Remove Their Access</a>
												</td>
											</tr>
										</table>
										<span>
											<span  v-if="myRole.role === 'President' || myRole.role === 'Secretary'">
												<h3>Add to Executive Access</h3>

												<div class="row">
													<div class="col-md-9">
														<b-field label="Search for someone" message="You can enter email, phone, username or first &amp; last name">
															<b-input v-on:input="updatePlayerSearch()" v-model="searchName"  placeholder="Search">
															</b-input>
														</b-field>
													</div>
													<div class="col-md-3">
														<b-field>
															<b-checkbox v-model="allRegionSearch" v-on:input="updatePlayerSearch()"> Include out of region users in your search</b-checkbox>    
														</b-field>
													</div>
												</div>
												<div style="max-height: 400px; overflow: scroll; -webkit-overflow-scrolling: touch;">
													<nav class="level" v-for="(user, key) in searchList" :key="key">
														<div class="level-left">
															<p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
															<p class="level-item">{{ user.city }}</p>
															<p class="level-item">
																{{ user.email }}	
															</p>
															<p class="level-item">{{ user.gender }}</p>
									
															<p class="level-item">
																<span v-if="user.latest_waiver_signed === $latest_waiver">Signed</span>
															</p>
														</div>
														<div class="level-right">
															<a class="button is-small" v-on:click="addToPlayersSelected(user)"><i class="fas fa-plus"></i></a>
														</div>
													</nav>
												</div>
												<div class="adding">
													<p><b>Adding:</b></p>
												</div>
												
												<section class="modal-card-body">
													<nav class="level" v-for="(user, key) in playersSelected" :key="key">
														<div class="level-left">
															<p class="level-item"><b>{{ user.first_name }} {{ user.last_name }}</b></p>
															<p class="level-item">
																<b-select v-model="user.access_title">
																	<option value="President">
																		President
																	</option>
																	<option value="Secretary">
																		Secretary
																	</option>
																	<option value="Executive Member">
																		Executive Member
																	</option>
																</b-select>
															</p>
														</div>
														<div class="level-right">
															<a class="button is-danger is-small" v-on:click="removeFromPlayersSelected(key)"><i class="fas fa-trash"></i></a>
														</div>
													</nav>
												</section>
												<button class="admin-button" style="margin-top: 20px;" v-on:click="addToExecutiveAccess()">Add User to Executive Access</button>
											</span>
										</span>
									</b-tab-item>
									<b-tab-item label="Edit League">
										<div class="row">
											<div class="col-md-9">
												<b-tabs multiline v-on:input="updateAddressTab()">
													<b-tab-item label="Edit Basic Details">
														<div class="row">
															<div class="col-md-4">
																<b-field label="League Name">
																	<b-input v-model="editLeague.name" :disabled="editDisabled"></b-input>
																</b-field>
															</div>
															<div class="col-md-1"></div>
															<div class="col-md-4">
																<b-field label="League Name (FR)">
																	<b-input v-model="editLeague.fr_name" :disabled="editDisabled"></b-input>
																</b-field>
															</div>
															<div class="col-md-3">

															</div>
														</div>
														<div class="row row-margin">
															<div class="col-md-6">
																<b-field label="League Start Date">
																	<b-datetimepicker
																		placeholder="Click to select..."
																		icon="calendar-today"
																		v-model="editLeague.event_start_date"
																		:disabled="editDisabled"
																		horizontal-time-picker>
																	</b-datetimepicker>
																</b-field>
															</div>
															<div class="col-md-6">
																<b-field label="League End Date">
																	<b-datetimepicker
																		placeholder="Click to select..."
																		icon="calendar-today"
																		:disabled="editDisabled"
																		v-model="editLeague.event_end_date"
																		horizontal-time-picker>
																	</b-datetimepicker>
																</b-field>
															</div>
														</div>

														<div class="row row-margin">
															<div class="col-md-6">
																<b-field label="Registration Start Date">
																	<b-datetimepicker
																		placeholder="Click to select..."
																		icon="calendar-today"
																		:disabled="editDisabled"
																		v-model="editLeague.registration_start_date"
																		horizontal-time-picker>
																	</b-datetimepicker>
																</b-field>
															
															</div>
															<div class="col-md-6">
																<b-field label="Registration End Date">
																	<b-datetimepicker
																		placeholder="Click to select..."
																		icon="calendar-today"
																		:disabled="editDisabled"
																		v-model="editLeague.registration_end_date"
																		horizontal-time-picker>
																	</b-datetimepicker>
																</b-field>
															</div>
														</div>
														<div class="row row-margin">
															<div class="col-md-6">
																<b-field label="Roster Lock Date">
																	<b-datetimepicker
																		placeholder="Click to select..."
																		icon="calendar-today"
																		:disabled="editDisabled"
																		v-model="editLeague.roster_lock_date"
																		horizontal-time-picker>
																	</b-datetimepicker>
																</b-field>
															</div>
														</div>

														<div class="row row-margin">
															<div class="col-md-6">
																<b-field label="Team Price">
																	<b-input 
																		v-model="editLeague.team_registration_fee"
																		:disabled="editDisabled"
																		icon-pack="fas"
																		icon="dollar-sign"
																	></b-input>
																</b-field>
															</div>
															
														</div>
														<div class="row" style="margin-top:10px;">
															<div class="col-md-6">
																<b-field extended label="League Description" style="margin-right: 10px;"> 
																	<b-input style="width: 100%;" :disabled="editDisabled" v-model="editLeague.description" maxlength="2000" type="textarea"></b-input>
																</b-field>
															</div>
														</div>

														<div class="row" style="margin-left: -10px;">
															<div class="col-md-6">
																<div class="padding">
																	<b-field label="Games Guaranteed">
																		<b-numberinput :disabled="editDisabled" v-model="editLeague.games_guaranteed"></b-numberinput>
																	</b-field>
																</div>
															</div>
															<div class="col-md-6">
																<div class="padding">
																	<b-field label="How are games scheduled?">
																		<b-select :disabled="editDisabled" v-model="editLeague.games_scheduled_format">
																			<option 
																				:value="'single'"
																				>
																					Single Scheduled Game
																			</option>
																			<option :value="'double'">Double Scheduled Game</option>
																			<option :value="'both'">Single & Double Scheduled Game</option>
																			<option :value="'other'">Other</option>
																		</b-select>
																	</b-field>
																</div>
															</div>
														</div>
														<div class="row" style="margin-left: -10px;">
															<div class="col-md-4">
																<div class="padding">
																	<b-field>
																		<b-checkbox :disabled="editDisabled" v-model="editLeague.preseason_meeting">Preseason Meeting</b-checkbox>
																	</b-field>
																</div>
															</div>
															<div class="col-md-4">
																<div class="padding">
																	<b-field>
																		<b-checkbox
																			:disabled="editDisabled"
																			v-model="editLeague.in_league_tournaments">
																				In-League Tournaments
																		</b-checkbox>
																	</b-field>
																</div>
															</div>
															<div class="col-md-4">
																<div class="padding">
																	<b-field>
																		<b-checkbox
																			:disabled="editDisabled"
																			v-model="editLeague.carded_umpires">
																				Carded Umpires
																		</b-checkbox>
																	</b-field>
																</div>
															</div>
														</div>
														<div class="row" style="margin-left: -10px;">
															<div class="col-md-4">
																<div class="padding">
																	<b-field>
																		<b-checkbox
																			:disabled="editDisabled"
																			v-model="editLeague.year_end_banquet">
																				Year-End Banquet
																		</b-checkbox>
																	</b-field>
																</div>
															</div>
															<div class="col-md-4">
																<div class="padding">
																	<b-field>
																		<b-checkbox 
																		:disabled="editDisabled"
																		v-model="editLeague.year_end_awards"
																		>Year-End Awards</b-checkbox>
																	</b-field>
																</div>
															</div>
														</div>

														<div class="row row-margin">
														
															<div class="col-md-6">
																<b-field label="Max Roster Size per team (default is 16)">
																	<div class="block">
																		<b-numberinput :disabled="editDisabled" v-model="editLeague.max_roster_size"></b-numberinput>
																	</div>
																</b-field>
															</div>	
														</div>

														<a class="admin-button" v-on:click="updateLeagueInfo()"><i class="fas fa-save"></i> Update League Info</a>
													</b-tab-item>
													<b-tab-item label="Edit Divisions">
														<p class="note">You cannot delete a division until you have moved all teams from that division. Use the registered teams tab to change the division of the teams. When the team count for that division hits zero, the delete button will appear.</p>
														<a class="admin-button" v-on:click="addNewDivisionSwitch = true">Add a New Division</a>
														<div class="panel" style="margin-top: 20px;" v-for="division in editLeague.divisions" :key="division.id">
															<div class="panel-heading">
																<div class="row">
																	<div class="col-md-6">
																		{{ division.gender_slug.toUpperCase() }} {{ division.rank_id }}
																	</div>
																	<div class="col-md-3">
																		Team Count: {{ teamCount(division) }}
																	</div>
																	<div class="col-md-3" v-if="teamCount(division) === 0">
																		<a class="button is-small is-danger" v-on:click="deleteDivision(division)">Delete Division</a>
																	</div>
																</div>
															</div>
															<div class="panel-block" style="display: block;">
																<div class="row">
																	<div class="col-md-3">
																		<div class="padding">
																			<b-field label="Minimum # of Teams" min="3" custom-class="is-small">
																				<b-numberinput type="is-danger" v-model="division.min_number_of_teams"></b-numberinput>
																			</b-field>
																		</div>
																	</div>
																	<div class="col-md-3">
																		<div class="padding">
																			<b-field label="Max # of Teams" custom-class="is-small">
																				<b-numberinput type="is-danger" min="3" v-model="division.max_number_of_teams"></b-numberinput>
																			</b-field>
																		</div>
																	</div>
																	<div class="col-md-3">
																		<div class="padding">
																			<b-field label="Minimum Age" custom-class="is-small">
																				<b-numberinput type="is-danger" min="16" max="99" v-model="division.min_age"></b-numberinput>
																			</b-field>
																		</div>
																	</div>
																	<div class="col-md-3">
																		<div class="padding">
																			<b-field label="Maximum Age" custom-class="is-small">
																				<b-numberinput type="is-danger" min="16" max="99" v-model="division.max_age"></b-numberinput>
																			</b-field>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</b-tab-item>	
													<b-tab-item label="Edit Primary Location/Headquarters" >
														<p><b>Current Address:</b> {{ league.written_address }}</p>
														<p><b>Current City:</b> {{ league.city }}</p>
														<b-field label="Update The Address">
															<b-input icon-pack="fas"
																icon="street-view"
																expanded
																
															 v-model="newTournament.written_address"></b-input>
														</b-field>
														<b-field label="Update The City">
															<b-input icon-pack="fas"
																icon="street-view"
																expanded
																
																 v-model="newTournament.city"></b-input>
														</b-field>
														<a class="admin-button" v-on:click="updateLocation()">Update Location</a>
													</b-tab-item>
												</b-tabs>
											</div>
											<div class="col-md-3">
												<LeagueTournamentPreviewCard
													:event="league"
													:bottomHalf="true"
													:eventType="'league'"
													:posterUploadAbility="true"
													>
												</LeagueTournamentPreviewCard>
											</div>
											
										</div>
													
									</b-tab-item>
						
							
									<b-tab-item label="Umpires/Rulesheet" v-if="!editDisabled">
										<div class="content-area">
											<div class="row">
												<div class="col-md-6">
													<h2 style="margin-top: 0px;">Umpire Cheat Sheet</h2>
													<p>Fill out this umpire cheat sheet to help them see and make sure they can umpire according to your event.</p>
													
													<table class="table" style="width: 100%; margin-top: 20px;">
														<tr>
															<td><b>Innings</b></td>
															<td>
																<b-numberinput
																	v-model="cheatSheet.innings">
																</b-numberinput>
															</td>
														</tr>
														<tr>
															<td><b>Call last inning at __ minutes</b></td>
															<td>
																<b-numberinput
																	v-model="cheatSheet.call_last_inning_after">
																</b-numberinput>
															</td>
														</tr>
														<tr>
															<td><b>Minimum players on field</b></td>
															<td>
																<b-numberinput
																	v-model="cheatSheet.minimum_players">
																</b-numberinput>
															</td>
														</tr>
														<tr>
															<td><b>HR Rule</b></td>
															<td>
																<b-input
																	v-model="cheatSheet.hr_rule">
																</b-input>
															</td>
														</tr>
														<tr>
															<td><b>Anticipation</b></td>
															<td>
																<b-switch :true-value="1" :false-value="0" v-model="cheatSheet.anticipation"></b-switch>
															</td>
														</tr>
														<tr>
															<td><b>Ties</b></td>
															<td>
																<b-switch :true-value="1" :false-value="0" v-model="cheatSheet.ties"></b-switch>
															</td>
														</tr>
														<tr>
															<td><b>Courtesy Runners</b></td>
															<td>
																<b-switch :true-value="1" :false-value="0"  v-model="cheatSheet.courtesy_runners"></b-switch>
															</td>
														</tr>
														<tr>
															<td><b>Max runs per inning</b></td>
															<td>
																<b-input
																	v-model="cheatSheet.max_runs_per_inning">
																</b-input>
															</td>
														</tr>
														<tr>
															<td><b>HR Rule</b></td>
															<td>
																<b-input
																	v-model="cheatSheet.hr_rule">
																</b-input>
															</td>
														</tr>
														<tr>
															<td><b>Mercy Rule</b></td>
															<td>
																<b-switch :true-value="1" :false-value="0"  v-model="cheatSheet.mercy_rule"></b-switch>
															</td>
														</tr>
														<tr>
															<td><b>Tag at home</b></td>
															<td>
																<b-switch :true-value="1" :false-value="0"  v-model="cheatSheet.tag_at_home"></b-switch>
															</td>
														</tr>
														<tr>
															<td><b>Throw to first from outfield</b></td>
															<td>
																<b-switch :true-value="1" :false-value="0"  v-model="cheatSheet.throw_to_first_from_outfield"></b-switch>
															</td>
														</tr>
														<tr>
															<td><b>Additional Rules</b></td>
															<td>
																<b-input type="textarea" v-model="cheatSheet.additional_rules"></b-input>
															</td>
														</tr>
													</table>
													<a v-if="savedCheatSheet === false" v-on:click="saveUmpireCheatSheet()" class="admin-button"><i class="fas fa-save"></i> Create Umpire Cheat Sheet</a>
													<a v-if="savedCheatSheet === true" v-on:click="saveUmpireCheatSheet()" class="admin-button"><i class="fas fa-save"></i> Update Umpire Cheat Sheet</a>
													
												</div>
												<div class="col-md-6">
													<div class="padding" v-if="savedCheatSheet">
														<h2 style="margin-top: 0px;">Umpires Hired</h2>
													
														<p>Add your umpires to this list via their email address to add them to the tournament. For security reasons, their email has to be a complete match.</p>

														<b-input style="margin-top: 10px;" placeholder="Search for a carded umpire by email"
															type="search"
															icon="magnify"
															icon-clickable
															v-model="umpSearch"
															v-on:input="umpireEmailSearch($event)"
														>
														</b-input>
														<div style="text-align: center; margin-top: 10px;">
															<p class="note" style="color: red;" v-if="umpireFound === null && umpSearch !== null">Umpire Not Found</p>
															<p class="note" style="color: green;" v-if="umpireFound && umpSearch !== null">Umpire Found!!</p>

															<a v-on:click="addUmpireToTournament()" v-if="umpireFound && umpSearch !== null" class="admin-button"><i class="fas fa-plus"></i> Add Umpire to Tournament</a>
														</div>
														<div>
															<h2>Umpires Added</h2>
															<table class="table">
																<tr :key="user.id" v-for="user in addedToCheatSheet">
																	<td>{{ user.user.email }}</td>
																	<td><a style="margin-left: 10px;" class="button is-small is-danger" v-on:click="removeUmpire(user)">Remove</a></td>
																</tr>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</b-tab-item>
								</b-tabs>
							</div>
						</div>
					</div>
				</div>
            </div>
        </div>
		
	</div>
</template>

<script>

import Header from '../components/PrimaryHeader.vue'
import Footer from '../components/PrimaryFooter.vue'
import NewPaymentProcessor from '../../components/NewPaymentProcessor.vue'
import LeagueTournamentPreviewCard from '../../components/LeagueTournamentPreviewCard.vue'
import axios from 'axios'
import moment from 'moment'
export default {
	data() {
		return {
			searchName: null,
			allRegionSearch: false,
			changeTeamSwitch: false,
			changeTeamInfo: null,
			currentTab: 'Registered Teams',
			createTeams: [{
				team_name: null,
				team_gender_id: null,
				league_division_id: null,
				coach_first_name: null,
				coach_last_name: null,
				coach_email: null
			}],
			columnTemplate: [
				// { title: 'ID', field: 'id', visible: false, searchable: false },
				{ title: 'Team ID', label: 'Team ID', field: 'team_id', visible: true, searchable: false },
				{ title: 'Team Name', label: 'Team Name', field: 'team_name', visible: true, searchable: false },
				{ title: 'Role', label: 'Role', field: 'role', visible: true, searchable: false },
				{ title: 'Rank', label: 'Rank', field: 'level', visible: true, searchable: false },
				{ title: 'First Name', label: 'First Name', field: 'first_name', visible: false, searchable: false },
				{ title: 'Last Name', label: 'Last Name', field: 'last_name', visible: false, searchable: false },
				{ title: 'Gender', label: 'Gender', field: 'gender', visible: false, searchable: false },
				{ title: 'Date of Birth', label: 'Date of Birth', field: 'date_of_birth', visible: true, searchable: false },
				{ title: 'Email', label: 'Email', field: 'email', visible: false, searchable: false },
				{ title: 'Street Address', label: 'Street Address', field: 'street_address', visible: true, searchable: false },
				{ title: 'City', label: 'City', field: 'city', visible: true, searchable: false },
				{ title: 'Province', label: 'Province', field: 'province', visible: false, searchable: false },
				{ title: 'Postal Code', label: 'Postal Code', field: 'postal_code', visible: false, searchable: false },
				{ title: 'Phone', label: 'Phone', field: 'phone', visible: false, searchable: false },
				{ title: 'Waiver', label: 'Waiver', field: 'waiver', visible: false, searchable: false },
				{ title: 'Role', label: 'Role', field: 'role', visible: false, searchable: false },
				{ title: 'Date Added To Roster', label: 'Date Added To Roster', field: 'date_added_to_roster', visible: false, searchable: false },
			],
			selectedRegisteredTeams: [],
			timer: null,
			divisions: null,
			revokeInsurance: false,
			editLeague: null,
			addTeamToEventSwitch: false,
			page: null,
			league: null,
			changeDivisionSwitch: false,
			previous: null,
			payForTeams: false,
			upcoming: null,
			umpSearch: null,
			registered_teams: [],
			authUser: null,
			content_width: false,
			previous_league: null,
			mountCheckboxes: null,
			changeDivisionDetails: {
				team: null,
				gender: null,
				division: null
			},
			canvasAdditionalDetailsLeft: null,
			savedCheatSheet: false,
			umpireFound: null,
			addedToCheatSheet: null,
			selectedTeam: null,
			searchForTeam: null,
			selectRegion: false,
			removeTeamModal: false,
			addTeamsToNewListSwitch: false,
			removeTeamSelected: null,
			searchResults: null,
			selectedPreviousTeams: [],
			previous_registered_teams: [],
			selectedLeaguesForPayment: [],
			executive_access_players: null,
			searchList: [],
			createTeamSwitch: false,
			playersSelected: [],
			newTournament: {
				headquarter_location: null,
				headquarter_google_address: null,
			},
			addNewDivisionSwitch: false,
			addDivisionDetails: {
				gender: null,
				rank_id: null,
				min_number_of_teams: 4,
				max_number_of_teams: 8,
				min_age: 18,
				max_age: 99
			},
			registerForTournament: {
                team: null,
                gender: null,
                division: null
            },
			editDisabled: false,
			timer: null,
			cheatSheet: {
				innings: 7,
				call_last_inning_after: 75,
				minimum_players: 10,
				hr_rule: '1',
				anticipation: true,
				ties: true,
				courtesy_runners: true,
				max_runs_per_inning: 6,
				mercy_rule: true,
				tag_at_home: true,
				throw_to_first_from_outfield: true,
				additional_rules: null
			},
			
		};
	},
	watch: {

	},
	components: {
		Header,
		Footer,
		NewPaymentProcessor,
		LeagueTournamentPreviewCard
	},
	mounted: function() {
        // grab all the dashboard information now
		var vm = this
		this.page = this.$route.params.page
		var slug = this.$route.params.slug

		axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user

				axios.get('/api/leagues/league-executive-access/' + slug).then(results => {
					if(results.data.success === true) {
						vm.executive_access_players = results.data.executive_access

						var check_for_user = _.filter(vm.executive_access_players, function(player) {
							return player.user_id === vm.authUser.id
						})
						
						if(check_for_user.length === 0) {
							this.$router.push({ name: 'Dashboard' })
							this.$buefy.snackbar.open({
								message: 'You do not have permission to access this.',
								type: 'is-success' // is-danger
							})
						}
					}
				})
            }
		})
		
		axios.get('/api/tournaments/divisions').then(results => {
			vm.divisions = results.data.divisions
		})


		axios.post('/api/tournaments/review-checklist').then(results => {
			if(results.data.success === true) {
				vm.mountCheckboxes = results.data.checklist
			}
		})

		

		axios.get(`/api/leagues/get-league/${slug}`).then(results => {
			if(results.data.success === true) {				
				vm.league = results.data.league
				vm.editLeague = results.data.league
				
				if(parseInt(vm.editLeague.season_id) < parseInt(moment().format('YYYY'))) {
					vm.editDisabled = true
				}
				if(vm.editLeague.event_start_date) {
					vm.editLeague.event_start_date = new Date(vm.editLeague.event_start_date)
				}
				if(vm.editLeague.event_end_date) {
					vm.editLeague.event_end_date = new Date(vm.editLeague.event_end_date)
				}
				if(vm.editLeague.registration_start_date) {
					vm.editLeague.registration_start_date = new Date(vm.editLeague.registration_start_date)
				}
		
				if(vm.editLeague.registration_end_date) {
					vm.editLeague.registration_end_date = new Date(vm.editLeague.registration_end_date)
				}

				if(vm.editLeague.roster_lock_date) {
					vm.editLeague.roster_lock_date = new Date(vm.editLeague.roster_lock_date)
				}
			
				vm.editLeague.preseason_meeting = vm.editLeague.preseason_meeting === 1 ? true : false
				vm.editLeague.in_league_tournaments = vm.editLeague.in_league_tournaments === 1 ? true : false
				vm.editLeague.carded_umpires = vm.editLeague.carded_umpires === 1 ? true : false
				vm.editLeague.year_end_banquet = vm.editLeague.year_end_banquet === 1 ? true : false
				vm.editLeague.year_end_awards = vm.editLeague.year_end_awards === 1 ? true : false

				if(vm.league.previous_league_id) {

					axios.get(`/api/leagues/get-league/${vm.league.previous_league_id}`).then(results => {
						if(results.data.success === true) {				
							vm.previous_league = results.data.league

							axios.get('/api/leagues/get-registered-teams/' + vm.previous_league.slug).then(results => {
								if(results.data.success === true) {
									vm.previous_registered_teams = results.data.registrants

									vm.previous_registered_teams.map((team, index) => {
										if(this.totalDivisions[team.league_gender_id]) {
											team.league_division_id = this.totalDivisions[team.league_gender_id].find(division => {
												return division.rank_id === team.division.rank_id
											})
											team.roll_rosters = true
											return team
										}
									})
								}
							})
						}
					})
				}

				if(this.$route.query.nav === 'import') {
					this.currentTab = "Import Previous League Teams"
				}
			

				axios.post('/api/tournaments/review-checklist', {
					event_id: vm.league.id,
					event_type: 'league',	
				}).then(results => {
					if(results.data.success === true) {

                        vm.mountCheckboxes = results.data.checklist
                        if(results.data.notes) {
                            vm.canvasAdditionalDetailsLeft = results.data.notes.result
                        }
					}
				})

				axios.get('/api/umpires/cheat-sheet/' + vm.league.id + '/league').then(results => {
					if(results.data.success === true) {
						if(results.data.cheat_sheet) {
							vm.cheatSheet = results.data.cheat_sheet
							vm.savedCheatSheet = true
						
							axios.get('/api/umpires/added-to-cheat-sheet/' + vm.league.id + '/league').then(results => {
								if(results.data.success === true) {
									vm.addedToCheatSheet = results.data.added_to_cheat_sheet
									
								}
							})
						}
					}
				})
			}
		})
	
		axios.get('/api/leagues/get-registered-teams-with-rosters/' + slug).then(results => {
			if(results.data.success === true) {
				vm.registered_teams = results.data.registrants
			}
		})
	},
	computed: {
		myRole: function() {
			var vm = this	
			if(vm.authUser) {
				
				var find = _.find(vm.executive_access_players, function(player) {
					return player.user_id === vm.authUser.id
				})

				

				return find
			}

			return null
			
		},
		computedSelectedRegisteredTeams: function() {
            var vm = this
			
			var teams = this.selectedRegisteredTeams
			var array = []
			var i = 0
		
			for(i; i < teams.length; i++) {
				var team = teams[i]
				
				var s = 0

				for(s; s < team.roster.roster_players.length; s++) {
					var roster = team.roster.roster_players[s]	

					console.log('roster', roster)

					var role = 'Player'

					if(roster.core_roster_player.role_on_team === 1) {
						role = 'Coach'

						if(roster.user_id === team.team.primary_coach_id) {
							role = "Primary Coach"
						}
					}
					if(roster.core_roster_player.role_on_team === 2) {
						role = 'Team Contact'
					}
					var waiver = false
					if(roster.user.waivers.length > 0) {
						// Order the waivers by latest ID to least recent ID
						roster.user.waivers.sort((a, b) => b.waiver_id - a.waiver_id);
						waiver = roster.user.waivers[0].waiver_id;
					}

					console.log('waiver === this.$latest_waiver', waiver === this.$latest_waiver)
					console.log('waiver', waiver)
					console.log('this.$latest_waiver', this.$latest_waiver)

					array.push({
						id: team.id,
						team_id: team.team.id,
						team_name: team.team.name,
						first_name: roster.user.first_name,
						last_name: roster.user.last_name,
						gender: roster.user.gender,
						date_of_birth: roster.user.date_of_birth,
						email: roster.user.email,
						street_address: roster.user.street_address,
						city: roster.user.city,
						province: roster.user.province,
						postal_code: roster.user.postal_code,
						phone: roster.user.phone,
						level: roster.user.current_rank_id,
						waiver: (waiver === this.$latest_waiver ? 'Yes' : 'No'),
						role: role,
						date_added_to_roster: roster.created_at
					})
				}	
			}

			return array
        },
		computedImportPreviousTeams: function() {
			var vm = this

			var i = 0
			var previous = []

			var already_transferred = []

			for(i; i < vm.previous_registered_teams.length; i++) {
				var prev_reg = vm.previous_registered_teams[i]
				var find_current = _.find(vm.registered_teams, function(team) {
					if(team.team_id === prev_reg.team_id) {
						return true
					} return false
				})


				if(find_current) {
					already_transferred.push(prev_reg)
				} else {
					previous.push(prev_reg)
				}
			}

			return { previous, already_transferred }
		},
		computedForPayments: function() {
			var vm = this
			
			var teams = vm.registered_teams
			teams = _.reject(teams, function(team) {
				return team.insurance && team.insurance.single_event === null
			})

			return teams
		},
		computePaid: function() {
			var vm = this

			if(vm.registered_teams) {
				var teams = _.filter(vm.registered_teams, function(team) {
					return team.insurance && team.insurance.single_event === null
				})
			
				return teams.length
			}

			return 0
		},
		computedProduct: function() {
			var vm = this
			if(vm.league.type_id === 1) {
				return 4
			} else {
				return 5
			}
			
        },
		filterGroup: function() {
			var vm = this
			var grouped = _.groupBy(vm.mountCheckboxes, 'group')

			return grouped
		},
		totalDivisions: function() {
            var vm = this
            var mens = vm.filterDivision(vm.league.divisions, 'mens')
            var ladies = vm.filterDivision(vm.league.divisions, 'ladies')
            var coed = vm.filterDivision(vm.league.divisions, 'coed')

            var count = 0
            if(mens.length > 0) {
                count = count + 1
            } 
            if(ladies.length > 0) {
                count = count + 1
            }
            if(coed.length > 0) {
                count = count + 1
			}

            return {
                mens: mens,
                ladies: ladies,
                coed: coed,
                count: count
            }
        },
	},
	methods: {
		moment: function(date, format = null) {
			return moment(date, format)
		},
		refreshLeague: function() {
			var vm = this

			axios.get(`/api/leagues/get-league/${vm.league.slug}`).then(results => {
			if(results.data.success === true) {				
				vm.league = results.data.league
				vm.editLeague = results.data.league
				if(vm.editLeague.event_start_date) {
					vm.editLeague.event_start_date = new Date(vm.editLeague.event_start_date)
				}
				if(vm.editLeague.event_end_date) {
					vm.editLeague.event_end_date = new Date(vm.editLeague.event_end_date)
				}
				if(vm.editLeague.registration_start_date) {
					vm.editLeague.registration_start_date = new Date(vm.editLeague.registration_start_date)
				}
		
				if(vm.editLeague.registration_end_date) {
					vm.editLeague.registration_end_date = new Date(vm.editLeague.registration_end_date)
				}
			
				vm.editLeague.preseason_meeting = vm.editLeague.preseason_meeting === 1 ? true : false
				vm.editLeague.in_league_tournaments = vm.editLeague.in_league_tournaments === 1 ? true : false
				vm.editLeague.carded_umpires = vm.editLeague.carded_umpires === 1 ? true : false
				vm.editLeague.year_end_banquet = vm.editLeague.year_end_banquet === 1 ? true : false
				vm.editLeague.year_end_awards = vm.editLeague.year_end_awards === 1 ? true : false

				if(vm.league.previous_league_id) {

					axios.get(`/api/leagues/get-league/${vm.league.previous_league_id}`).then(results => {
						if(results.data.success === true) {				
							vm.previous_league = results.data.league

							axios.get('/api/leagues/get-registered-teams/' + vm.previous_league.slug).then(results => {
								if(results.data.success === true) {
									vm.previous_registered_teams = results.data.registrants

									vm.previous_registered_teams.map((team, index) => {
										if(this.totalDivisions[team.league_gender_id]) {
											team.league_division_id = this.totalDivisions[team.league_gender_id].find(division => {
												return division.rank_id === team.division.rank_id
											})
											team.roll_rosters = true
											return team
										}
									})
								}
							})
						}
					})
				}
			

				axios.post('/api/tournaments/review-checklist', {
					event_id: vm.league.id,
					event_type: 'league',	
				}).then(results => {
					if(results.data.success === true) {

                        vm.mountCheckboxes = results.data.checklist
                        if(results.data.notes) {
                            vm.canvasAdditionalDetailsLeft = results.data.notes.result
                        }
					}
				})

				axios.get('/api/umpires/cheat-sheet/' + vm.league.id + '/league').then(results => {
					if(results.data.success === true) {
						if(results.data.cheat_sheet) {
							vm.cheatSheet = results.data.cheat_sheet
							vm.savedCheatSheet = true
						
							axios.get('/api/umpires/added-to-cheat-sheet/' + vm.league.id + '/league').then(results => {
								if(results.data.success === true) {
									vm.addedToCheatSheet = results.data.added_to_cheat_sheet
									
								}
							})
						}
					}
				})
			}
		})
	
			
		axios.get('/api/leagues/get-registered-teams-with-rosters/' + vm.league.slug).then(results => {
			if(results.data.success === true) {
				vm.registered_teams = results.data.registrants
			}
		})
		},
		updateTeams: function() {
			var vm = this
			if(vm.league) {
				axios.get('/api/leagues/get-registered-teams-with-rosters/' + vm.league.slug).then(results => {
					if(results.data.success === true) {
						vm.registered_teams = results.data.registrants
					}
				})
			}
		},
		checkIfPreviousDate: function(tournament) {
			
			var start_date = moment(tournament.event_start_date)

			var is_after = start_date.isAfter(moment())
			// var now = moment().format('YYYY-MM-DD')
			// var tournament_start_date = moment(tournament.event_start_date).format('YYYY-MM-DD')
			// console.log('isAfter', now.isAfter(tournament.event_start_date))
			return is_after
		},
		registerTeamToEvent: function() {
			var vm = this
			axios.post('/api/leagues/add-team-to-event', {
				registration: vm.registerForTournament,
				league: vm.league.id
			}).then(results => {
				if(results.data.success === true) {
					vm.addTeamToEventSwitch = false

					vm.updateTeams()

					this.$buefy.snackbar.open({
                        message: 'Team successfully added to your event',
                        type: 'is-success' // is-danger
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'Team could not be added to your event',
                        type: 'is-danger' // is-danger
                    })
				}
			})
		},

		filterDivision: function(divisions, filter) {
            var vm = this

            var filtered_divisions = _.filter(divisions, function(division) {
                return division.gender_slug === filter
            })

            return filtered_divisions
        },
		switchWidth: function($event) {
			this.content_width = !$event
		},
		saveUmpireCheatSheet: function() {
			var vm = this

			axios.post('/api/umpires/save-cheat-sheet', {
				cheat_sheet: vm.cheatSheet,
				event_id: vm.league.id,
				type: 'league'
			}).then(results => {
				if(results.data.success === true) {
					vm.savedCheatSheet = true
					
					vm.refreshLeague()
					// then go over and make it so you can add umpires to a tournament

					// then go over to umpire cheat sheet and make it display the cheat sheet you just got added to

					// then go back and connect the event checklistbnmkj
				}
			})
		},
		umpireEmailSearch: function(text) {
			var vm = this
			axios.post('/api/umpires/search-for-umpire', {
				search: text
			}).then(results => {
				if(results.data.success === true) {
					
					vm.umpireFound = results.data.umpire
				}
			})
		},
		addUmpireToTournament: function() {
			var vm = this
			axios.post('/api/umpires/add-to-cheat-sheet', {
				umpire_found: vm.umpireFound,
				cheat_sheet: vm.cheatSheet.id
			}).then(results => {
				if(results.data.success === true) {
					vm.addedToCheatSheet = results.data.added_to_cheat_sheet
					vm.refreshLeague()
					this.$buefy.snackbar.open({
                        message: 'Added user to the cheat sheet',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'User was not added to the cheat sheet',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		eventChecklistSave: function($event, box) {
			var vm = this
			axios.post('/api/tournaments/save-checklist', {
				box: box,
				event_id: vm.league.id,
				event_type: 'league',
				result: $event
			}).then(results => {
				if(results.data.success === true) {
					vm.refreshLeague()
					this.$buefy.snackbar.open({
                        message: 'Saved your event checklist',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		saveEventNotes: function($event) {
			var vm = this

			axios.post('/api/tournaments/save-checklist-notes', {
				event_id: vm.league.id,
				event_type: 'league',
				result: $event
			}).then(results => {
				if(results.data.success === true) {
					vm.refreshLeague()
					this.$buefy.snackbar.open({
                        message: 'Saved your checklist notes',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		removeUmpire: function(user) {
			var vm = this
			axios.post('/api/umpires/remove-from-cheat-sheet', {
				user_id: user.user.id,
				cheat_sheet: vm.cheatSheet.id
			}).then(results => {
				if(results.data.success === true) {
					vm.addedToCheatSheet = results.data.added_to_cheat_sheet
					vm.refreshLeague()
					this.$buefy.snackbar.open({
                        message: 'Removed user from the cheat sheet',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'User was not deleted to the cheat sheet',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		removeTeam: function() {
			var vm = this

			var team = vm.removeTeamSelected

			axios.post('/api/leagues/remove-team-from-event', {
				team_id: team.id,
				revoke: vm.revokeInsurance
			}).then(results => {
				if(results.data.success === true) {

					vm.removeTeamModal = false
					vm.updateTeams()
					this.$buefy.snackbar.open({
                        message: 'Remove team from your event',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {
					this.$buefy.snackbar.open({
                        message: 'Could not remove team',
                        type: 'is-danger', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		}, 
		addTeamToEvent: function() {
			var vm = this

			vm.addTeamToEventSwitch = true
		},
		searchForTeamFunction: function($event) {
			var vm = this
			if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
				if($event) {
					axios.post('/api/leagues/search-for-team', {
						tournament_id: vm.league.id,
						search: $event,
						region_on: vm.selectRegion,
						region_id: vm.league.region_id
					}).then(results => {
						if(results.data.success === true) {
							vm.searchResults = results.data.teams
						}
					})
				} else {
					axios.post('/api/leagues/search-for-team', {
						tournament_id: vm.league.id,
						search: vm.searchForTeam,
						region_on: vm.selectRegion,
						region_id: vm.league.region_id
					}).then(results => {
						if(results.data.success === true) {

							vm.searchResults = results.data.teams
						}
					})
				}
			}, 500)
		},
		changeDivisionFunction: function(team) {
			var vm = this
			vm.changeDivisionDetails.team = team
			vm.changeDivisionSwitch = true
		},
		moveTeamToNewDivision: function(team) {
			var vm = this

			var data = {
				details: {
					team: {
						id: vm.changeDivisionDetails.team.id,
						insurance_id: vm.changeDivisionDetails.team.insurance_id
					},
					division: {
						id: vm.changeDivisionDetails.division.id
					},
					gender: vm.changeDivisionDetails.gender
				}
			}
			 
			axios.post('/api/leagues/change-team-to-new-division', {
				details: {
					team: {
						id: vm.changeDivisionDetails.team.id,
						insurance_id: vm.changeDivisionDetails.team.insurance_id
					},
					division: {
						id: vm.changeDivisionDetails.division.id
					},
					gender: vm.changeDivisionDetails.gender
				}
			}).then(results => {
				if(results.data.success === true) {
					vm.updateTeams()
					vm.changeDivisionSwitch = false
					this.$buefy.snackbar.open({
                        message: 'Team moved to new division successfully.',
                        type: 'is-success' // is-danger
                    })
				}
			})
		},
		addTeamsToNewLeague: function() {
			var vm = this

			vm.addTeamsToNewListSwitch = true
			
			// axios.post('/api/leagues/')
			var i = 0

			var failed = false

			var previous_teams = []

			for(i; i < vm.selectedPreviousTeams.length; i++) {
				var previous_team = vm.selectedPreviousTeams[i]
				
				if(previous_team.league_gender_id === null) {
					failed = true
				}

				if(previous_team.league_division_id === null) {
					failed = true
				}	

				

				previous_teams.push({
					id: previous_team.id,
					team_id: previous_team.team_id,
					league_division_id: previous_team.league_division_id,
					league_gender_id: previous_team.league_gender_id,
					team_roster_id: previous_team.team_roster_id,
					roll_rosters: previous_team.roll_rosters
				})
			}
			
			if(failed === false) {
				axios.post('/api/leagues/add-teams-from-previous-season', {
					previous_teams: previous_teams,
					league_id: vm.league.id
				}).then(results => {
					if(results.data.success === true) {
						vm.addTeamsToNewListSwitch = false
						vm.selectedPreviousTeams = []
						vm.refreshLeague()
						this.$buefy.snackbar.open({
							message: 'Added teams to the new league. Make sure you pay for them in the league payment area. Refresh to see the changes.',
							type: 'is-success' // is-danger
						})	
					} else {
						this.$buefy.snackbar.open({
							message: 'There was a problem importing your teams. Please check all your division and genders for missing information.',
							type: 'is-danger' // is-danger
						})
					}
				}).catch(err => {
					this.$buefy.snackbar.open({
						message: 'There was a problem importing your teams. Please check all your division and genders for missing information.',
						type: 'is-danger' // is-danger
					})
				})
				
				
			} else {
				this.$buefy.snackbar.open({
					message: 'Please fill out the division and gender of each team',
					type: 'is-danger' // is-danger
				})
			}


			
		},
		paymentSuccessSwitch: function($event) {
			var vm = this
			
			// var json = JSON.stringify($event)
			// sessionStorage.setItem('order-league', json);
			vm.paymentSuccess = $event
			// this needs to trigger the submission script automatically

			var teamsSelected = []
			var i = 0
			for(i; i < vm.selectedLeaguesForPayment.length; i++) {
				var team = vm.selectedLeaguesForPayment[i]
				teamsSelected.push({
					id: team.id,
					team_id: team.team_id,
					league_id: team.league_id,
					league_gender_id: team.league_gender_id,
				})
			}

			axios.post('/api/leagues/update-teams-paid', {
				teams: teamsSelected,
				league: vm.league.id,
				order: $event
			}).then(results => {
				if(results.data.success === true) {
					vm.selectedLeaguesForPayment = []
					vm.payForTeams = false
					vm.updateTeams()
					this.$buefy.snackbar.open({
						message: 'Paid for those teams! Refresh to see the updates.',
						type: 'is-success' // is-danger
					})
				} else {
					this.$buefy.snackbar.open({
						message: 'Payment succeeded, but teams were not assigned to the purchase. Contact us.',
						type: 'is-danger' // is-danger
					})
				}
			})
		},
		grabExecutiveAccessList: function() {
			var vm = this

			axios.get('/api/leagues/league-executive-access/' + vm.league.id).then(results => {
				if(results.data.success === true) {
					vm.executive_access_players = results.data.executive_access
				}
			})
		},
		addToExecutiveAccess: function() {
			var vm = this

			axios.post('/api/leagues/add-to-league-executive-access', {
				league_id: vm.league.id,
				users: vm.playersSelected,
			}).then(results => {
				if(results.data.success === true) {
					vm.playersSelected = []
					vm.refreshLeague()
					this.$buefy.snackbar.open({
                        message: 'Added members to the executive!',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				}
			})
		},
		makePrimaryConvenor: function(player) {
			var vm = this
			
			axios.post('/api/leagues/make-primary-convenor', {
				player: player,
				league: vm.league.id
			}).then(results => {
				if(results.data.success === true) {
					vm.refreshLeague()
					this.$buefy.snackbar.open({
                        message: 'Convenor marked as primary executive!',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {

				}
			})
		},
		removeTheirAccess: function(player) {
			var vm = this

			axios.post('/api/leagues/remove-executive-access', {
				player: player,
				league: vm.league.id
			}).then(results => {
				if(results.data.success === true) {
					vm.refreshLeague()
					this.$buefy.snackbar.open({
                        message: 'Removed executive access successfully!',
                        type: 'is-success', // is-danger
                        duration: 4000,
                        queue: false
                    })
				} else {

				}
			})
		},
		submitCreatedTeams: function() {
			var vm = this

			var r = confirm("Please ensure you are intending to create brand new teams and potentially brand new users once more before submitting!")

			if(r === true) {
				axios.post('/api/leagues/submit-created-teams', {
					createTeams: vm.createTeams,
					league_id: vm.league.id
				}).then(results => {
					if(results.data.success === true) {
						vm.createTeams = [{
							team_name: null,
							team_gender_id: null,
							league_division_id: null,
							coach_first_name: null,
							coach_last_name: null,
							coach_email: null
						}]
						vm.refreshLeague()
						this.$buefy.snackbar.open({
							message: 'You successfully created these new teams and added them to your event.',
							type: 'is-success', // is-danger
							duration: 4000,
							queue: false
						})
					} else {
						this.$buefy.snackbar.open({
							message: 'Creating teams did not work. Please contact us.',
							type: 'is-danger', // is-danger
							duration: 4000,
							queue: false
						})
					}
				})
			}
		},
		moment: function(date, format = null) {
            return moment(date, format)
        },
		addCreatedTeam: function() {
			var vm = this
			if(vm.createTeams.length < 11) {
				vm.createTeams.push({
					team_name: null,
					team_gender_id: null,
					league_division_id: null,
					coach_first_name: null,
					coach_last_name: null,
					coach_email: null
				})
			} else {
				this.$buefy.snackbar.open({
					message: 'Please only create ten teams at a time.',
					type: 'is-danger', // is-danger
					duration: 4000,
					queue: false
				})
			}
		},
		updateLeagueInfo: function() {
			var vm = this
			axios.post('/api/leagues/update-league', {
				league: vm.editLeague
			}).then(results => {
				if(results.data.success === true) {
					vm.refreshLeague()
					this.$buefy.snackbar.open({
						message: 'League Info Has Been Updated',
						type: 'is-success' // is-danger
					})
					vm.league = vm.editLeague
				} else {
					this.$buefy.snackbar.open({
						message: 'Something went wrong when saving',
						type: 'is-danger' // is-danger
					})
				}
			})
		},
		teamCount: function(division) {
			var vm = this

			var teams = _.filter(vm.registered_teams, function(team) {
				return team.league_division_id === division.id
			})

			return teams.length
		},
		deleteDivision: function(division) {
			var r = confirm('Are you sure you want to delete this division?')

			if(r === true) {
				axios.post('/api/leagues/delete-division', {
					division: division.id
				}).then(results => {
					if(results.data.success === true) {
						this.$buefy.snackbar.open({
							message: 'Deleted the division',
							type: 'is-success' // is-danger
						})
						vm.refreshLeague()
					} else {
						this.$buefy.snackbar.open({
							message: 'Something went wrong',
							type: 'is-danger' // is-danger
						})
					}
				})
			}
			
		},
		fillInAddress: function() {
			var vm = this
			var place = this.autocompleteAddress.getPlace();
			var fields = this.autocompleteAddress.getFields()
			this.newTournament.headquarter_location = place.formatted_address

			// find components
			var address_components = place.address_components

			var postal_code = _.find(address_components, function(address) {
				if(address.types.includes('postal_code')) {
					return true
				}
			})

			var province = _.find(address_components, function(address) {
				if(address.types.includes('administrative_area_level_1')) {
					return true
				}
			})

			if(province.short_name === 'NS' || province.short_name === 'PE' || province.short_name === 'NB' ) {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MT') {
						return true
					}
				})

				
				vm.newTournamentRegion = region_data
			} else if (province.short_name === 'NU') {
                
                var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === 'MB') {
						return true
					}
				})
                
				vm.newTournamentRegion = region_data
            } else {
				var region_data = _.find(vm.regions, function(region) {
					if(region.short_name === province.short_name) {
						return true
					}
				})
				
				vm.newTournamentRegion = region_data
			}

			var city = _.find(address_components, function(address) {
				if(address.types.includes('locality')) {
					return true
				} else {
                    if(address.types.includes('sublocality')) {
                        return true
                    }
                }
			})

			var street_number = _.find(address_components, function(address) {
				if(address.types.includes('street_number')) {
					return true
				}
			})

			var route = _.find(address_components, function(address) {
				if(address.types.includes('route')) {
					return true
				}
			})

			var street_number_long_name = null

			if(street_number) {
				street_number_long_name = street_number.long_name
			}

			var route_long_name = null

			if(route) {
				route_long_name = route.long_name
			}


			vm.newTournament.headquarter_google_address = {
				written_address: place.formatted_address,
				street_address: street_number_long_name + ' ' + route_long_name,
				city: city.long_name,
				province: province.short_name,
				postal_code: postal_code.long_name,
				latitude:  place.geometry.location.lat(),
				longitude: place.geometry.location.lng()
			}
		},
		updateAddressTab: function() {
			var vm = this
		
		},
		updateLocation: function() {
			var vm = this

			axios.post('/api/leagues/update-league-location', {
				league: vm.league.id,
				headquarters: vm.newTournament,
				headquarter_google_address: vm.newTournament.headquarter_location
			}).then(results => {
				if(results.data.success === true) {
					vm.refreshLeague()
					this.$buefy.snackbar.open({
						message: 'Updated league location.',
						type: 'is-success', // is-danger
						duration: 4000,
					})
				}
			})
		},
		addNewDivision: function() {
			var vm = this

			axios.post('/api/leagues/add-new-division', {
				league: vm.league.id,
				division: vm.addDivisionDetails
			}).then(results => {
				if(results.data.success === true) {
					vm.addNewDivisionSwitch = false
					vm.refreshLeague()
					this.$buefy.snackbar.open({
						message: 'Added new division',
						type: 'is-success', // is-danger
						duration: 4000,
					})
				} else {
					this.$buefy.snackbar.open({
						message: 'Division not added successfully. Are you sure you do not already have that division?',
						type: 'is-danger', // is-danger
						duration: 4000,
					})
				}
			})
		},
		updatePlayerSearch: function() {            
            var vm = this

			if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
				axios.post(`/api/users/search/players`, {
					search_name: vm.searchName,
					region: vm.league.region_id,
					all_regions: vm.allRegionSearch
				}).then(results => {
					if(results.data.success === true) {
						vm.searchList = results.data.users
					}
				})
			}, 1000)
		},
		  addToPlayersSelected: function(user) {
            this.playersSelected.push(user)
        },
        removeFromPlayersSelected: function(key) {
            this.playersSelected.splice(key, 1)
        },
		sendWaiversToLeague: function() {
			var vm = this

			var i = 0

			var league_registrants = []

			for(i; i < vm.selectedRegisteredTeams.length; i++) {
				var team = vm.selectedRegisteredTeams[i]
				
				league_registrants.push({ id: team.id, team_id: team.team_id })
			}
			
			var r = confirm('Are you sure you want to send an email to all unsigned waiver players?')

			// selected teams
			

			if(r === true) {
				axios.post('/api/leagues/send-waivers', {
					league_id: vm.league.id,
					league_registrants: league_registrants
				}).then(results => {
					if(results.data.success === true) {
						// vm.refreshLeague()
						this.$buefy.snackbar.open({
							message: 'You have sent your waiver reminder email to all unsigned players.',
							type: 'is-success', // is-danger
							duration: 4000,
						})
					}
				})
			}
		},
		formatForProcessing: function(teams) {
			var array = []
			for(const team of teams) {
				array.push({
					team: {
						name: team.team.name,
						id: team.team.id
					}
				})
			}

			return array
		},
		submitTeamNameChange: function() {
			var vm = this
			var team = {
				team: {
					id: vm.changeTeamInfo.team.id,
					name: vm.changeTeamInfo.team.name
				}
			}

			axios.post('/api/leagues/change-team-name', {
				team: team
			}).then(results => {
				if(results.data.success === true) {
					vm.changeTeamSwitch = false
					this.$buefy.snackbar.open({
							message: 'Team name changed successfully',
							type: 'is-success', // is-danger
							duration: 4000,
						})
				}
			})
		}
	}
}
</script>

<style lang="scss">
    
</style>
