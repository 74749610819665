<template>
	<div>
		<Header></Header>
		
		<div class="content-area" >
			<div class="standard-page-header">
				Provincial Championships
			</div>
            <div class="frontend-content-wrapper">
                <p><b>Click on a region to see the regional info.</b></p>
                <div class="provinces">
					<div :class="['province', { selected: currentProvince === 'bc' }]">
						<a v-on:click="contactUsProvince('bc')">
							<img src="../../images/header/crest/BCCrest.png" width="100%" alt="">
						</a>
					</div>
					<div :class="['province', { selected: currentProvince === 'ab' }]">
						<a v-on:click="contactUsProvince('ab')">
							<img src="../../images/header/crest/ABCrest.png" width="100%" alt="">
						</a>
					</div>
					<div :class="['province', { selected: currentProvince === 'sk' }]">
						<a v-on:click="contactUsProvince('sk')">
							<img src="../../images/header/crest/SKCrest.png" width="100%" alt="">
						</a>
					</div>
					<div :class="['province', { selected: currentProvince === 'mb' }]">
						<a v-on:click="contactUsProvince('mb')">
							<img src="../../images/header/crest/MBCrest.png" width="100%" alt="">
						</a>
					</div>
					<div :class="['province', { selected: currentProvince === 'on' }]">
						<a v-on:click="contactUsProvince('on')">
							<img src="../../images/header/crest/ONCrest.png" width="100%" alt="">
						</a>
					</div>
					<div :class="['province', { selected: currentProvince === 'qc' }]">
						<a v-on:click="contactUsProvince('qc')">
							<img src="../../images/header/crest/QCCrest.png" width="100%" alt="">
						</a>
					</div>
					<div :class="['province', { selected: currentProvince === 'mt' }]">
						<a v-on:click="contactUsProvince('mt')">
							<img src="../../images/header/crest/MTCrest.png" width="100%" alt="">
						</a>
					</div>
					<div :class="['province', { selected: currentProvince === 'nf' }]">
						<a v-on:click="contactUsProvince('nf')">
							<img src="../../images/header/crest/NLCrest.png" width="100%" alt="">
						</a>
					</div>                
				</div>
                <div v-if="currentProvince">
                    <div class="section-spacing">
                        <div class="row">
                            <div class="col-md-12" style="text-align: center;">
                                <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/2024/2024 Provincial Championships logo grey PLATE.png" width="100%" style="max-width: 300px;" alt="">
                            </div>
                            
                        </div>
                        <img src="../../images/other/provincial.png" width="100%" alt="">
                        <div style="margin-top: 20px;">
                            <Accordion v-if="computedMainChamps">
                                <template v-slot:header>
                                    {{  computedMainChamps.name }}
                                    <!-- 2024 Provincial Championships -->
                                </template>
                                <template v-slot:content>
                                    <PageContentBox v-if="computedMainChamps" :content="parseMainContent(computedMainChamps.content)"></PageContentBox>
                                </template>
                            </Accordion>

                            <Accordion v-if="computedStartTimes">
                                <template v-slot:header>
                                    {{ computedStartTimes.name }}
                                </template>
                                <template v-slot:content>
                                    <PageContentBox :content="parseMainContent(computedStartTimes.content)"></PageContentBox>
                                </template>
                            </Accordion>

                            <Accordion v-if="computedPolicies">
                                <template v-slot:header>
                                    {{ computedPolicies.name }}
                                </template>
                                <template v-slot:content>
                                    <PageContentBox :content="parseMainContent(computedPolicies.content)"></PageContentBox>
                                </template>
                            </Accordion>

                            <Accordion v-if="computedHotels">
                                <template v-slot:header>
                                    {{ computedHotels.name }}
                                </template>
                                <template v-slot:content>
                                    <PageContentBox :content="parseMainContent(computedHotels.content)"></PageContentBox>
                                </template>
                            </Accordion>

                            <Accordion v-if="computedRules">
                                <template v-slot:header>
                                    Rules
                                </template>
                                <template v-slot:content>
                                    <PageContentBox :content="parseMainContent(computedRules.content)"></PageContentBox>
                                </template>
                            </Accordion>

                            <Accordion v-if="computedNiagara">
                                <template v-slot:header>
                                    {{ computedNiagara.name }}
                                </template>
                                <template v-slot:content>
                                    <PageContentBox :content="parseMainContent(computedNiagara.content)"></PageContentBox>
                                </template>
                            </Accordion>
                            
                            <Accordion v-if="computedBrampton">
                                <template v-slot:header>
                                    Brampton
                                </template>
                                <template v-slot:content>
                                    <PageContentBox :content="parseMainContent(computedBrampton.content)"></PageContentBox>
                                </template>
                            </Accordion>

                            <Accordion>
                                <template v-slot:header>
                                    Results
                                </template>
                                <template v-slot:content>
                                    <div class="panel" v-for="result in computedPreviousResults" :key="result.id">
                                        <div class="panel-heading" v-on:click="result.switch = !result.switch">
                                            {{ result.name }}
                                        </div>
                                        <div class="panel-content" v-if="result.switch">
                                             <PageContentBox :content="parseMainContent(result.content)"></PageContentBox>
                                        </div>
                                    </div>
                                </template>
                            </Accordion>
                        </div>
                    </div>
                </div>
			</div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
import PageContentBox from '../frontend/PageContentBox.vue'
export default {
    metaInfo() {
        return { 
            title: "Provincial Championships - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'Where memories and champions are made.' },
                { property: 'og:title', content: "Provincial Championships - Slo-Pitch National"},
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:url', content:  'https://www.slo-pitch.com/provincial-championships' },
                { property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/provincialchampionships.png' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			en: true,
            page_url: null,
            page: null,
            previous_results: null,
            first_decade: false,
            second_decade: false,
            third_decade: false,
            nationals_hosts: null,
            brampton: false,
            winnipeg: false,
            currentProvince: 'bc',
            edmonton: false,
            leduc: false,
            start_times: null,
            draws: null,
            kelowna: false,
            policies: null,
            main_page_champs: null,
            hotels: null,
            rules: null,
            brampton: null,
            niagara: null,
            placings: null,
		};
	},
	components: {
		Header,
		Footer,
        Form,
        PageContentBox
	},
	mounted: function() {
		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = false
            }
        }
        var vm = this

        if(localStorage.getItem('province')) {
            vm.currentProvince = localStorage.getItem('province')
        }

        // The location of Uluru
        
        // const kelowna = { lat: 49.88307, lng: -119.48568 }
        const center = { lat: 52.1167, lng: -97.2500 }
        // const leduc = { lat: 53.2723418, lng: -113.5847182 }
        // const edmonton = { lat: 53.5557121, lng: -113.6331451 }
        
        // const winnipeg = { lat: 49.8538008, lng: -97.2224356 }
        
        // const brampton = { lat: 43.7249125, lng:-79.8297545 }
        // The map, centered at Uluru
        // const map = new google.maps.Map(document.getElementById("map"), {
        //     zoom: 4,
        //     center: center,
        // });
        // The marker, positioned at Uluru
        //  const contentString =
        //     '<div id="content">' +
        //     '<div id="siteNotice">' +
        //     "</div>" +
        //     '<h1 id="firstHeading" style="font-size: 20px; margin-top: 0px;" class="firstHeading">Kelowna</h1>' +
        //     '<div id="bodyContent">' +
        //     "<p>Coed Comp SS</p>" +
        //     "<p>Coed C SS</p>" +
        //     "<p>Coed D SS</p>" +
        //     "<p>Coed E SS</p>" +
        //     "</div>" +
        //     "</div>";
        // const infowindow = new google.maps.InfoWindow({
        //     content: contentString,
        // });
        // const marker = new google.maps.Marker({
        //     position: kelowna,
        //     map: map,
        // });
        // marker.addListener("click", () => {
        //     infowindow.open(map, marker);
        // });

        // const marker2 = new google.maps.Marker({
        //     position: leduc,
        //     map: map
        // });

        // const marker3 = new google.maps.Marker({
        //     position: edmonton,
        //     map: map,
        // });

        // const contentString2 =
        //     '<div id="content">' +
        //     '<div id="siteNotice">' +
        //     "</div>" +
        //     '<h1 id="firstHeading" style="font-size: 20px; margin-top: 0px;" class="firstHeading">Leduc & Edmonton</h1>' +
        //     '<div id="bodyContent">' +
        //     "<h4 style='margin-top: 0px;'>Leduc</h4>" +
        //     "<p>Men's C</p>" +
        //     "<p>Men's E</p>" +
        //     "<p>Men's 35 Comp</p>" +
        //     "<p>Women's B</p>" +
        //     "<p>Women's D</p>" +
        //     "<h4 style='margin-top: 20px;'>Edmonton</h4>" +
        //     "<p>Men's 60</p>" +
        //     "</div>" +
        //     "</div>";
        // const infowindow2 = new google.maps.InfoWindow({
        //     content: contentString2,
        // });

        // marker2.addListener("click", () => {
        //     infowindow2.open(map, marker2);
        // });

        // marker3.addListener("click", () => {
        //     infowindow2.open(map, marker3);
        // });

        // const contentString3 =
        //     '<div id="content">' +
        //     '<div id="siteNotice">' +
        //     "</div>" +
        //     '<h1 id="firstHeading" style="font-size: 20px; margin-top: 0px;" class="firstHeading">Winnipeg</h1>' +
        //     '<div id="bodyContent">' +
        //     "<p>Men's D</p>" +
        //     "<p>Men's 35 E</p>" +
        //     "<p>Women's C</p>" +
        //     "<p>Women's E</p>" +
        //     "</div>" +
        //     "</div>";
        // const infowindow3 = new google.maps.InfoWindow({
        //     content: contentString3,
        // });

        // const marker4 = new google.maps.Marker({
        //     position: winnipeg,
        //     map: map,
        // });

        // marker4.addListener("click", () => {
        //     infowindow3.open(map, marker4);
        // });

        // const contentString4 =
        //     '<div id="content">' +
        //     '<div id="siteNotice">' +
        //     "</div>" +
        //     '<h1 id="firstHeading" style="font-size: 20px; margin-top: 0px;" class="firstHeading">Brampton</h1>' +
        //     '<div id="bodyContent">' +
        //     "<p>Men's B</p>" +
        //     "<p>Men's 35 C</p>" +
        //     "<p>Men's 35 D</p>" +
        //     "<p>Men's 50 A</p>" +
        //     "<p>Men's 50 IM</p>" +
        //     "<p>Men's 50 E</p>" +
        //     "<p>Women's 35 Comp</p>" +
        //     "<p>Women's 35 IM/E</p>" +
        //     "<p>Coed Comp</p>" +
        //     "<p>Coed C</p>" +
        //     "<p>Coed D</p>" +
        //     "<p>Coed E</p>" +
        //     "</div>" +
        //     "</div>";
        // const infowindow4 = new google.maps.InfoWindow({
        //     content: contentString4,
        // });

        // const marker5 = new google.maps.Marker({
        //     position: brampton,
        //     map: map,
        // });

        // marker5.addListener("click", () => {
        //     infowindow4.open(map, marker5);
        // });
        axios.get('/api/pages/provincial-championships').then(results => {
            if(results.data.success === true) {
                vm.main_page_champs = results.data.main_page_champs
                vm.draws = results.data.draws
                vm.previous_results = results.data.previous_results
                vm.policies = results.data.policies
                vm.hotels = results.data.hotels
                vm.rules = results.data.rules
                vm.brampton = results.data.brampton
                vm.niagara = results.data.niagara
                vm.placings = results.data.placings
                vm.start_times = results.data.start_times
            }
        })
	},
	computed: {
        computedMainContent: function() {
            return "test"
        },
        computedMapsAndDiamonds: function() {
            return "test"
        },
        computedHotelInformation: function() {
            return "test"
        },
        computedProvResults: function() {
            var vm = this

            var previous = vm.previous_results

            if(previous.length > 0) {
                var filtered = _.find(previous, function(result) {
                    if(result.id === 317) {
                        return true
                    }
                    return false
                })
                return filtered
            }
            return null
            
        },
        computedDraws: function() {
             var vm = this
            var previous = vm.draws

            if(previous) {
                var filtered = _.find(previous, function(result){
                    var search = result.slug.search(vm.currentProvince+'-')
                    if(search === -1) {
                        return false
                    }
                    return true
                })

                return filtered
            }

            return null
        },
        
        computedBrampton: function() {
            var vm = this
            var previous = vm.brampton

            if(previous) {
                var filtered = _.find(previous, function(result){
                    var search = result.slug.search(vm.currentProvince+'-')
                    if(search === -1) {
                        return false
                    }
                    return true
                })

                return filtered
            }

            return null
        },
        computedNiagara: function() {
            var vm = this
            var previous = vm.niagara

            if(previous) {
                var filtered = _.find(previous, function(result){
                    var search = result.slug.search(vm.currentProvince+'-')
                    if(search === -1) {
                        return false
                    }
                    return true
                })

                return filtered
            }

            return null
        },
        computedRules: function() {
            var vm = this
            var previous = vm.rules

            if(previous) {
                var filtered = _.find(previous, function(result){
                    var search = result.slug.search(vm.currentProvince+'-')
                    if(search === -1) {
                        return false
                    }
                    return true
                })

                return filtered
            }

            return null
        },
        computedPolicies: function() {
            var vm = this
            var previous = vm.policies

            if(previous) {
                var filtered = _.find(previous, function(result){
                    var search = result.slug.search(vm.currentProvince+'-')
                    if(search === -1) {
                        return false
                    }
                    return true
                })

                return filtered
            }

            return null
        },
        computedStartTimes: function() {
            var vm = this
            var previous = vm.start_times

            if(previous) {
                var filtered = _.find(previous, function(result){
                    var search = result.slug.search(vm.currentProvince+'-')
                    if(search === -1) {
                        return false
                    }
                    return true
                })

                return filtered
            }

            return null
        },
        computedMainChamps: function() {
            var vm = this
            var previous = vm.main_page_champs
            if(previous) {
                var filtered = _.find(previous, function(result){
                    var search = result.slug.search('pc-main-'+vm.currentProvince)
                    if(search === -1) {
                        return false
                    }
                    return true
                })

                return filtered
            }

            return null
        },
        computedHotels: function() {
            var vm = this
            var previous = vm.hotels

            if(previous) {
                var filtered = _.find(previous, function(result){
                    var search = result.slug.search(vm.currentProvince+'-')
                    if(search === -1) {
                        return false
                    }
                    return true
                })

                return filtered
            }

            return null
        },
        computedPlacings: function() {
            var vm = this
            var previous = vm.placings

            if(previous) {
                var filtered = _.find(previous, function(result){
                    var search = result.slug.search(vm.currentProvince+'-')
                    if(search === -1) {
                        return false
                    }
                    return true
                })

                return filtered
            }

            return null
        },
        computedPreviousResults: function() {
            var vm = this
            var previous = vm.previous_results

            // filter by province
            var reverse = []
            var ordered = []

            if(previous) {
                var filtered = _.filter(previous, function(result) {
                    var search = result.slug.search('-'+vm.currentProvince+'-')
                    if(search === -1) {
                        return false
                    }
                    return true
                })

                reverse = _.reverse(filtered)
                ordered = _.orderBy(reverse, 'name', 'desc')
            }

            return ordered
        }
	},
	methods: {
        parseMainContent: function(content) {
            return JSON.parse(content)
        },
        grabNationalsHost: function(city) {
            var vm = this
            var tourney = _.find(vm.nationals_hosts, function(result) {
                return result.slug === 'nationals-host-' + city
            })

            if(tourney) {
                var content = JSON.parse(tourney.content)

                return content
            }
            
            // var content = JSON.parse(tourney)
            return []
            // return tourney
        },
        contactUsProvince: function(province) {
			this.currentProvince = province

            localStorage.setItem('province', province)
		},
        grabTournamentResults: function(year) {
            var vm = this
            var tourney = _.find(vm.previous_results, function(result) {
                return result.slug === 'nc-' + year
            })

            if(tourney) {
                var content = JSON.parse(tourney.content)
                return content
            }
            
            // var content = JSON.parse(tourney)
            return []
            // return tourney
        },
		openPanel:function(panel) {
			if(panel.switched === "false") {
				panel.switched = "true"
			} else {
				panel.switched = "false"
			}
		},
		createProperTableFunction(table) {
			// first row is your columns

			var first_row = table[0]
			var all_rows = table
			var columns = []
			var data = []
	
			var i = 0
			for(i; i < all_rows.length; i++) {
				var row = all_rows[i]
				if(i === 0) {
					// this is the first row, need to for loop again though
					var s = 0

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						columns.push({
							field: column.name,
							label: column.name
						})
					}
				} else {
					// this is the rest of the rows, need to loop and then call via the loop to the first row
					var s = 0
					
					var object = {}

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						var col_name = all_rows[0].row[s].name
						
						object[col_name] = column.name
					}
					data.push(object)
				}
			}

			// every row after is your data
			


			return {
				columns: columns,
				data: data,
				table: table
			}
		},
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
